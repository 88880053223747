import { DisplayData } from 'domain/types';
import { ROUTES } from './../../../routes';
import { push } from 'connected-react-router';
import createApiAction, {
  createAction,
  getAuthHeaders
} from 'helpers/apiActionCreator';
import { Action } from 'redux';
import { BudgetAndSaveAccount } from 'resources/flex/onboarding/onboarding.reducer';
import { Dispatch, ThunkResult, Store } from 'resources/types';
import * as userApi from '../../user/user.api';
import * as applyApi from '../../flex/apply/apply.api';
import * as api from './acceptance.api';
import moment from 'moment';

export type AcceptanceActionType =
  | 'CANCEL_APPLICATION'
  | 'ACCEPTANCE_ENROLL'
  | 'LOAD_SCHEDULE'
  | 'LOAD_APPLICATIONS';

export type AcceptanceAction<Payload = any> = Action<AcceptanceActionType> & {
  payload?: Payload;
};

/**
 * Makes a request to enroll a user in flexible rent
 */
export const enrollFlexibleRentApplication = (userId: string) => {
  const enroll = async (dispatch: Dispatch, getState: () => Store) => {
    if (userId) {
      const applications = await userApi.getApplicationsByUser(
        {
          userId,
          status: 'approved'
        },
        getAuthHeaders()
      );

      const applicationId =
        applications.length > 0 ? applications[0].applicationId : undefined;
      const paymentAccount = getState().onboarding.selectedPaymentAccount;

      if (applicationId && paymentAccount) {
        await dispatch(
          createApiAction('ACCEPTANCE_ENROLL', api.enroll)({
            userId,
            applicationId,
            paymentAccount
          })
        );
      } else {
        throw new Error('Required data missing from enrollment request');
      }
    }
  };
  return createAction('ENROLL_FLEXIBLE_RENT_APPLICATION', enroll)();
};

/**
 * Makes a request to enroll a user in budget and save
 */
export function enrollBudgetAndSaveApplication(
  applicationId: any,
  data: BudgetAndSaveAccount
): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    try {
      await dispatch(
        createApiAction('ACCEPTANCE_ENROLL', api.enrollBudgetAndSave)({
          applicationId,
          payload: data
        })
      );

      // Send to success screen
      dispatch(push(ROUTES.flexpay.rent));
    } catch {
      throw new Error('Required data missing from enrollment request');
    }
  };
}

export const loadEnrollmentData = (userId: string): ThunkResult<any> => {
  return async (dispatch: Dispatch) => {
    if (userId && userId.length > 0) {
      const headers = getAuthHeaders();

      const applications = await userApi.getApplicationsByUser(
        { userId, status: 'approved' },
        headers
      );

      if (applications.length > 0) {
        dispatch<AcceptanceAction>({
          type: 'LOAD_APPLICATIONS',
          payload: applications
        });

        const application = applications[0];

        const args = {
          frequency: application.frequency,
          anchorDate: moment.utc(application.lastPayday).format('YYYY-MM-DD'),
          communityId: application.communityId,
          cashOnHand: application.balance,
          baseRent: application.baseRent,
          totalBilling: application.totalBilling,
          outstandingBalance: application.outstandingBalance,
          firstPaymentDay: application.semiMonthlyIncomeOneFirstPayDate,
          secondPaymentDay: application.semiMonthlyIncomeOneSecondPayDate
        };

        dispatch(
          createApiAction<applyApi.GetScheduleArgs, DisplayData>(
            'LOAD_SCHEDULE',
            applyApi.getSchedule
          )(args)
        );
      }
    } else {
      throw new Error('Error generating Flexible Rent contract');
    }
  };
};
