import * as React from 'react';

function Alarm() {
  return (
    <svg width="28px" height="27px" viewBox="0 0 28 27">
      <g
        id="Design-Concept"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Missing-1-Copy"
          transform="translate(-251.000000, -923.000000)"
          fill="#4E5A6B"
        >
          <g id="Group" transform="translate(123.000000, 837.000000)">
            <g id="Graphics" transform="translate(58.000000, 43.000000)">
              <g
                id="-Icons-/-Basic-/-Alarm"
                transform="translate(65.000000, 37.000000)"
              >
                <g id="Icon-2" transform="translate(5.181818, 6.909091)">
                  <path
                    d="M13.7486279,22.8645389 C18.785128,22.8645389 22.8677455,18.7770758 22.8677455,13.7340007 C22.8677455,8.6909255 18.785128,4.60346242 13.7486279,4.60346242 C8.71212775,4.60346242 4.62951022,8.6909255 4.62951022,13.7340007 C4.62951022,18.7770758 8.71212775,22.8645389 13.7486279,22.8645389 Z M13.7486279,25.9090909 C7.03329437,25.9090909 1.58980433,20.4575812 1.58980433,13.7340007 C1.58980433,7.01042009 7.03329437,1.5589104 13.7486279,1.5589104 C20.4639614,1.5589104 25.9074514,7.01042009 25.9074514,13.7340007 C25.9074514,20.4575812 20.4639614,25.9090909 13.7486279,25.9090909 Z M27.1916159,6.53300377 C26.5986189,7.12694864 25.6351974,7.12694864 25.0422004,6.53300377 L21.1122041,2.59831392 C20.5192071,2.00281014 20.5192071,1.03940351 21.1122041,0.445458648 C21.7052011,-0.148486216 22.6686225,-0.148486216 23.2616196,0.445458648 L27.1916159,4.38014851 C27.7846129,4.97409337 27.7846129,5.93905891 27.1916159,6.53300377 Z M6.52415953,0.445458648 C7.11715655,1.03940351 7.11715655,2.00281014 6.52415953,2.59831392 L2.59416322,6.53300377 C2.00116621,7.12694864 1.03774478,7.12694864 0.444747762,6.53300377 C-0.148249254,5.93905891 -0.148249254,4.97409337 0.444747762,4.38014851 L4.37474408,0.445458648 C4.96774109,-0.148486216 5.93116252,-0.148486216 6.52415953,0.445458648 Z M13.219223,8.84213981 C13.219223,8.26378405 12.7518206,7.79455202 12.1747216,7.79455202 C11.5960328,7.79455202 11.1286303,8.26378405 11.1286303,8.84213981 L11.1286303,14.6428454 L15.6071091,18.9968822 C16.0156889,19.4068756 16.6786373,19.4068756 17.0856272,18.9968822 C17.4942069,18.5884477 17.4942069,17.9259107 17.0856272,17.5174762 L13.219223,13.6638497 L13.219223,8.84213981 Z"
                    id="Icon"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Alarm;
