export const MASKS = {
  PHONE: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  SSN: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  DATE: [
    /[01]/,
    /[0-9]/,
    '/',
    /[0-3]/,
    /[0-9]/,
    '/',
    /[12]/,
    /[90]/,
    /[0-9]/,
    /[0-9]/
  ] // MM/DD/YYYY
};

export const LATE_PAY_OPTIONS = ['0', '1-2', '3+'];
