import * as React from 'react';

export default function Info() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11C20 15.971 15.9706 20 11 20C6.0294 20 2 15.971 2 11C2 6.029 6.0294 2 11 2C15.9706 2 20 6.029 20 11ZM18 11C18 14.866 14.866 18 11 18C7.134 18 4 14.866 4 11C4 7.134 7.134 4 11 4C14.866 4 18 7.134 18 11ZM12 15C12 15.552 11.5523 16 11 16C10.4477 16 10 15.552 10 15V10C10 9.448 10.4477 9 11 9C11.5523 9 12 9.448 12 10V15ZM11 6C10.4477 6 10 6.448 10 7C10 7.552 10.4477 8 11 8C11.5523 8 12 7.552 12 7C12 6.448 11.5523 6 11 6Z"
        fill="#4E5A6B"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 11C20 15.971 15.9706 20 11 20C6.0294 20 2 15.971 2 11C2 6.029 6.0294 2 11 2C15.9706 2 20 6.029 20 11ZM18 11C18 14.866 14.866 18 11 18C7.134 18 4 14.866 4 11C4 7.134 7.134 4 11 4C14.866 4 18 7.134 18 11ZM12 15C12 15.552 11.5523 16 11 16C10.4477 16 10 15.552 10 15V10C10 9.448 10.4477 9 11 9C11.5523 9 12 9.448 12 10V15ZM11 6C10.4477 6 10 6.448 10 7C10 7.552 10.4477 8 11 8C11.5523 8 12 7.552 12 7C12 6.448 11.5523 6 11 6Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
}
