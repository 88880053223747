import TillModal from 'components/modal';
import * as React from 'react';
import styles from './styles.module.css';

interface Props {
  onClose: () => void;
  linkManually?: () => void;
}

export default function AccountLinkHelpModal({ onClose, linkManually }: Props) {
  return (
    <TillModal visible className={styles.modal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.title}>Trouble linking your account?</h1>

        <div className={styles.message}>
          <p>
            {
              "If you're unable to link your bank account through Plaid, please manually enter your bank account information using the link below."
            }
          </p>
          <p className={styles.linkManually} onClick={linkManually}>
            click here to manually enter bank information
          </p>
        </div>
      </div>
    </TillModal>
  );
}
