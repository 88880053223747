import { PlaidAccount, PlaidInstitution } from './types';
import { PlaidAction, PlaidErrorAction } from './plaid.actions';

export interface PlaidState {
  linkedItems: PlaidInstitution[];
  lastLinkedItem?: PlaidInstitution;
  accounts?: PlaidAccount[];
  selectedItem?: PlaidInstitution;
  selectedAccount?: PlaidAccount;
  linkToken?: string;
  itemLinkToken?: string;
  showPciWallet: boolean;
  error: {
    numberOfErrors: number; // number of errors that have occurred when linking a bank account in this session
    lastErrorName?: string; // error name from plaid
  };
}

const getDefaultState = (): PlaidState => ({
  linkedItems: [],
  showPciWallet: false,
  error: {
    numberOfErrors: 0
  }
});

const plaidReducer = (
  state: PlaidState = getDefaultState(),
  action: PlaidAction & PlaidErrorAction
) => {
  const newState = {
    ...state
  };
  switch (action.type) {
    case 'LOAD_LINKED_ITEMS':
      newState.linkedItems = action.payload;
      newState.accounts = undefined;
      return newState;
    case 'LINK_ITEM':
      // need to use concat here instead of push so that it replaces the array and triggers a state change
      newState.linkedItems = newState.linkedItems.concat([action.payload]);
      newState.lastLinkedItem = action.payload;
      return newState;
    case 'LINK_ITEM_FAIL':
      newState.lastLinkedItem = newState.linkedItems.find(
        ({ institutionId }) =>
          institutionId ===
          action.requestData.metadata.institution.institution_id
      );
      return newState;
    case 'CLEAR_LAST_LINKED_ITEM':
      newState.lastLinkedItem = undefined;
      return newState;
    case 'CLEAR_LOADED_ACCOUNTS':
      newState.accounts = undefined;
      newState.selectedAccount = undefined;
      return newState;
    case 'LOAD_ACCCOUNTS_FOR_ITEM':
      newState.accounts = action.payload;
      return newState;
    case 'CREATE_LINK_TOKEN':
      newState.linkToken = action.payload;
      return newState;
    case 'CREATE_ITEM_LINK_TOKEN':
      newState.itemLinkToken = action.payload;
      return newState;
    case 'REMOVE_ITEM_LINK_TOKEN':
      newState.itemLinkToken = undefined;
      return newState;
    case 'PLAID_SET_ERROR':
      newState.error = {
        numberOfErrors: newState.error.numberOfErrors + 1,
        lastErrorName: action.payload
      };
      return newState;
    case 'CLEAR_LAST_ERROR':
      newState.error.lastErrorName = undefined;
      return newState;
    case 'SHOW_PCI_WALLET':
      newState.showPciWallet = action.payload;
      return newState;
    default:
      return state;
  }
};

export default plaidReducer;
