import * as React from 'react';
import TillModal from '../modal';
import styles from './styles.module.css';
import config from '../../helpers/config';
import Button from '../button';

interface Props {
  onClose: () => void;
  onAction: () => void;
  title: string;
  message: string;
  buttonText: string;
}

/**
 * A modal with a button to take an action
 */
const ActionModal = ({
  onClose,
  onAction,
  title,
  message,
  buttonText
}: Props) => {
  return (
    <TillModal visible className={styles.modal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
        <Button
          type="primary"
          className={styles.actionButton}
          onClick={onAction}
        >
          {buttonText}
        </Button>
        <p className={styles.message}>
          {'For additional help, please give us a call at '}
          <a href={`tel:${config.supportPhoneNumber}`}>
            {config.supportPhoneNumber}
          </a>
          {', option #2 to process your request.'}
        </p>
      </div>
    </TillModal>
  );
};

export default ActionModal;
