import * as React from 'react';
import cx from 'classnames';
import Icon from '../icon';
import PaymentsModal from '../payments-modal';

import styles from './add-payment-method.module.css';

interface Props {
  showDebitCard?: boolean;
  className?: string;
  debitIframeUrl: string;
  achIframeUrl: string;
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
}

interface State {
  isOpen: boolean;
}

class AddPaymentMethod extends React.PureComponent<Props, State> {
  state = {
    isOpen: false
  };

  onOpen = () => this.setState({ isOpen: true });
  onClose = () => this.setState({ isOpen: false });

  handlePaymentProfileEvent = (
    status?: number,
    acountToken?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => {
    this.props.handlePaymentProfileEvent(
      status,
      acountToken,
      accountTitle,
      accountType
    );
    this.onClose();
  };
  render() {
    const { className } = this.props;

    return (
      <React.Fragment>
        <PaymentsModal
          showDebitCard={this.props.showDebitCard}
          visible={this.state.isOpen}
          onClose={this.onClose}
          debitIframeUrl={this.props.debitIframeUrl}
          achIframeUrl={this.props.achIframeUrl}
          handlePaymentProfileEvent={this.handlePaymentProfileEvent}
        />
        <button
          type="button"
          className={cx(styles.button, className)}
          onClick={this.onOpen}
        >
          <Icon className={styles.icon} type="plus" />
          add new method
        </button>
      </React.Fragment>
    );
  }
}

export default AddPaymentMethod;
