import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, FormikActions } from 'formik';
import { getSearch } from 'connected-react-router';
import { signup, SignupAction } from 'resources/user/user.actions';
import { Store } from 'resources/types';
import { Signup } from 'resources/user/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import schema from './schema';

// UI Components
import Icon from '../../../components/icon';
import SignupForm from './form';
import logo from '../../../assets/logo.png';
import signUpMainPhoto from '../../../assets/signup-main-1x.jpg';
import styles from './signup.module.css';
import cx from 'classnames';

// prettier-ignore
const playIcon =
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" >
    <circle cx="12" cy="12" r="10"></circle>
    <polygon points="10 8 16 12 10 16 10 8"></polygon>
  </svg>;

// prettier-ignore
const pauseIcon =
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="10" y1="15" x2="10" y2="9"></line>
    <line x1="14" y1="15" x2="14" y2="9"></line>
  </svg>;

interface Props {
  signup: SignupAction;
}

const initialValues: Signup = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  confirmPassword: '',
  agreedToTerms: false,
  hubspotOptions: {
    pageUri: '',
    pageName: '',
    hubspotUserToken: ''
  }
};

function SignupComponent({ signup }: Props) {
  const search = useSelector<Store, string>(getSearch);
  const { useVueSignup: shouldUseVueSignup } = useFlags();

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [loadVimeo, setLoadVimeo] = React.useState(false);
  const [video, setVideo] = React.useState(undefined);
  const [playVideo, setPlayVideo] = React.useState(false);

  React.useEffect(() => {
    if (shouldUseVueSignup) window.location.replace('/v/signup');
  });

  const complete = async (
    data: Signup,
    { setSubmitting }: FormikActions<Signup>
  ) => {
    try {
      await signup({
        ...data,
        hubspotOptions: {
          pageUri: window.location.href,
          pageName: document.title,
          hubspotUserToken: window.__hsUserToken || ''
        }
      });
      setShowSuccessMessage(true);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setSubmitting(false);
  };

  const playPauseVideo = (videoIsPlaying: Boolean) => {
    if (video) {
      // @ts-ignore
      videoIsPlaying ? video.pause() : video.play();
      // @ts-ignore
      video.on('play', () => setPlayVideo(true));
      // @ts-ignore
      video.on('pause', () => setPlayVideo(false));
      // @ts-ignore
      video.on('ended', () => setPlayVideo(false));
    }
  };

  useEffect(() => {
    // Load the video
    if (loadVimeo) {
      const options = {
        id: '661277637',
        controls: false,
        byline: false,
        title: false,
        portrait: false,
        responsive: true
      };

      // @ts-ignore
      setVideo(new Vimeo.Player('video', options));
    }
  }, [loadVimeo]);

  useEffect(() => {
    document.title = 'Till - Welcome to Till ';

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = 'https://player.vimeo.com/api/player.js';

    document.body.appendChild(script);

    setTimeout(() => {
      setLoadVimeo(true);
    }, 500);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className={styles.signUpOuter}>
        <div className={styles.colLeft}>
          <img src={signUpMainPhoto} alt="Welcome to Till" />

          <div
            id="video"
            className={cx(styles.video, playVideo && styles.showVideo)}
          >
            <span onClick={() => playPauseVideo(playVideo)}>Close Video</span>
          </div>

          <div className={styles.benefits}>
            <span>
              <Icon type="no-late-fees" />
              Avoid late fees and evictions.
            </span>
            <span>
              <Icon type="recurring" />
              Automate rent payments so you’ll never fall behind.
            </span>
          </div>
        </div>

        <div className={styles.colRight}>
          <div className={styles.formOuter}>
            <div className={styles.formHeader}>
              <img src={logo} alt="Till - Pay rent on your schedule" />
              <h1>Welcome to Till</h1>
              <p className={styles.subtitle}>
                Let’s create a Till account. Already have an account?{' '}
                <Link to={`/login${search}`}>Log In.</Link>
              </p>
              <div
                className={styles.videoButton}
                onClick={() => playPauseVideo(playVideo)}
              >
                <div>
                  <span>
                    Everything you need to know about Till <br /> in 1 minute.
                    <b>{'Watch Video >>'}</b>
                  </span>
                  {playVideo ? pauseIcon : playIcon}
                </div>
              </div>
            </div>

            {errorMessage && (
              <p className={styles.pageError}>
                <b>Error: </b>
                {errorMessage}
              </p>
            )}

            {showSuccessMessage ? (
              <div className={styles.pageSuccess}>
                <h3>Thank you for signing up!</h3>
                <br />
                <p>
                  Before you can log in, please activate your account by
                  clicking the activation link in the email we just sent you.
                </p>
              </div>
            ) : (
              <Formik
                initialValues={{ ...initialValues }}
                validationSchema={schema}
                onSubmit={complete}
                component={SignupForm}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state: Store) => ({
    search: getSearch(state)
  }),
  {
    signup
  }
)(SignupComponent);
