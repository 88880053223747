import cx from 'classnames';
import Button from 'components/button';
import Input from 'components/form-input';
import { FormikProps } from 'formik';
import * as React from 'react';
import { ResetPassword } from 'resources/user/types';
import baseStyles from 'styles/base.module.css';
import styles from 'styles/form.module.css';

function ResetForm({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  isValid
}: FormikProps<ResetPassword>) {
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          label="Code"
          name="code"
          placeholder="Enter the code you were given"
          value={values.code}
          touched={touched.code}
          valid={!errors.code}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {touched.code && errors.code && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.code}</span>
        </div>
      )}
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          label="Password"
          name="password"
          type="password"
          placeholder="Enter your password"
          value={values.password}
          touched={touched.password}
          valid={!errors.password}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {touched.password && errors.password && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.password}</span>
        </div>
      )}
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          placeholder="Confirm your password"
          value={values.confirmPassword}
          touched={touched.confirmPassword}
          valid={!errors.confirmPassword}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {touched.confirmPassword && errors.confirmPassword && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.confirmPassword}</span>
        </div>
      )}
      <div className={cx(styles.actionContainer, baseStyles.center)}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Reset Password
        </Button>
      </div>
    </form>
  );
}

export default ResetForm;
