import * as React from 'react';

export default function Cancel() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3C6.59135 3 3 6.59135 3 11C3 15.4087 6.59135 19 11 19C15.4087 19 19 15.4087 19 11C19 6.59135 15.4087 3 11 3ZM11 4.23077C14.7452 4.23077 17.7692 7.25481 17.7692 11C17.7692 12.649 17.1779 14.1587 16.2019 15.3317L6.66827 5.79808C7.84135 4.82212 9.35096 4.23077 11 4.23077ZM5.79808 6.66827L15.3317 16.2019C14.1587 17.1779 12.649 17.7692 11 17.7692C7.25481 17.7692 4.23077 14.7452 4.23077 11C4.23077 9.35096 4.82212 7.84135 5.79808 6.66827Z"
        fill="#475F7B"
      />
    </svg>
  );
}
