import * as React from 'react';
import { useLocation } from 'react-router-dom';
import GeneralMessage from 'components/generalmessage/index';

function GeneralConfirmation() {
  const path = useLocation().pathname;

  return (
    <>
      <GeneralMessage path={path} />
    </>
  );
}

export default GeneralConfirmation;
