import * as React from 'react';

export default function GoodQuality() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 0.272705C4.19054 0.272705 0.272705 4.19054 0.272705 8.99998C0.272705 13.8094 4.19054 17.7272 8.99998 17.7272C13.8094 17.7272 17.7272 13.8094 17.7272 8.99998C17.7272 4.19054 13.8094 0.272705 8.99998 0.272705ZM8.99998 1.61536C13.0856 1.61536 16.3846 4.91431 16.3846 8.99998C16.3846 13.0856 13.0856 16.3846 8.99998 16.3846C4.91431 16.3846 1.61536 13.0856 1.61536 8.99998C1.61536 4.91431 4.91431 1.61536 8.99998 1.61536ZM8.49648 4.30068C8.03494 4.30068 7.64158 4.58914 7.45802 4.95103C7.45802 4.95103 7.43704 4.99299 7.40557 5.06117C7.38459 5.1241 6.93354 5.90033 6.38809 6.61886C5.84788 7.33215 5.14508 8.08739 4.60487 8.43354C4.40557 8.56466 4.28494 8.79543 4.29543 9.03669C4.31116 9.27795 4.44753 9.49299 4.66256 9.60313C4.8776 9.70802 5.13459 9.69753 5.33914 9.56641C6.14158 9.04194 6.86536 8.20802 7.45802 7.42655C7.89333 6.85487 8.10837 6.46676 8.32865 6.07341V7.6783C8.32865 8.05068 8.6276 8.34963 8.99998 8.34963H11.7272C12.0839 8.34963 12.1993 8.44404 12.2622 8.51746C12.3252 8.59613 12.3724 8.7325 12.3514 8.92655C12.3409 8.979 12.021 10.9143 11.8741 11.6958C11.8217 11.9685 11.7535 12.1783 11.6958 12.2517C11.6381 12.3304 11.6591 12.3566 11.4178 12.3566H6.31466C6.0734 12.3514 5.84788 12.4825 5.72725 12.687C5.60137 12.8968 5.60137 13.1591 5.72725 13.3689C5.84788 13.5734 6.0734 13.7045 6.31466 13.6993H11.4178C11.9947 13.6993 12.5192 13.4161 12.7867 13.0384C13.0542 12.6608 13.1328 12.2675 13.1958 11.9475C13.3531 11.0979 13.6835 9.11012 13.6835 9.11012C13.6835 9.09439 13.6888 9.08389 13.6888 9.06816C13.736 8.59089 13.6363 8.06641 13.2902 7.65732C12.944 7.24299 12.3724 7.00697 11.7272 7.00697H9.6713V5.49648C9.6713 4.85662 9.15207 4.30068 8.49648 4.30068Z"
        fill="#475F7B"
      />
    </svg>
  );
}
