import * as Sentry from '@sentry/browser';
import 'antd/dist/antd.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import App from './App';
import './helpers/axios-config';
import APIError from './helpers/api.error';
import config from './helpers/config';
import './index.css';
import './styles/tabs.css';
import './styles/vars.css';

async function renderApp() {
  Modal.setAppElement('#root');

  let rootElement = <App />;

  // Try to wrap App in LaunchDarkly provider
  try {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: config.launchDarkly.clientId,
      options: {
        privateAttributeNames: ['email']
      }
    });
    rootElement = <LDProvider>{rootElement}</LDProvider>;
  } catch (err) {
    Sentry.captureException(err);
  }

  ReactDOM.render(rootElement, document.getElementById('root'));
}
if (config.deployEnv !== 'development') {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.deployEnv,
    denyUrls: ['launchdarkly.com'],
    beforeSend: (event, hint) => {
      const err = hint && hint.originalException;

      // Build a custom fingerprint for Sentry so that it doesn't use stacktrace when grouping events into issues
      if (err instanceof Error) {
        event.fingerprint = [err.message, err.name];
        if (err instanceof APIError) {
          event.fingerprint.push(err.status.toString());
        }
      }
      return event;
    }
  });
}

renderApp();

if (module.hot) {
  module.hot.accept('./routes', () => {
    renderApp();
  });
}
