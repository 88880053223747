import { PlaidAccount, PlaidInstitution } from './types';
import { Store } from 'resources/types';

/**
 * Returns the linked items (aka institutions) that are in the store
 */
export const getLinkedItems = ({ plaid }: Store): PlaidInstitution[] => {
  return plaid ? plaid.linkedItems : [];
};

/**
 * Returns all accounts for the current selected item
 */
export const getItemAccounts = ({
  plaid
}: Store): PlaidAccount[] | undefined => {
  return plaid && plaid.accounts;
};

/**
 * Returns a link token if one exists
 */
export const getLinkToken = ({ plaid }: Store): string | undefined => {
  return plaid && plaid.linkToken;
};

/**
 * Returns a link token for a specific item if one exists
 */
export const getItemLinkToken = ({ plaid }: Store): string | undefined => {
  return plaid && plaid.itemLinkToken;
};

/**
 * Returns the last item linked using Plaid
 */
export const getLastLinkedItem = ({
  plaid
}: Store): PlaidInstitution | undefined => {
  return plaid && plaid.lastLinkedItem;
};

/**
 * Returns the number of Plaid errors received in the session
 */
export const getErrorCount = ({ plaid }: Store): number => {
  return (plaid && plaid.error.numberOfErrors) || 0;
};

/**
 * Returns the name of the last error that occcured when trying to link a bank account
 */
export const getLastErrorName = ({ plaid }: Store): string | undefined => {
  return plaid && plaid.error.lastErrorName;
};

/**
 * Returns whether or not the PCI Wallet input should be showing
 */
export const showPciWallet = ({ plaid }: Store): boolean => {
  return plaid ? plaid.showPciWallet : false;
};
