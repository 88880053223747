import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../routes';
import getLoanDetails from 'resources/loan/account/account.selectors';
import { getUserAccount } from 'resources/user/user.selectors';
import { loadEnrollmentData } from 'resources/flexpay/acceptance/acceptance.actions';

function RootRedirect() {
  const { userId, applicationState } = useSelector(getUserAccount);
  const { loanStatusText } = useSelector(getLoanDetails);
  loadEnrollmentData(userId);

  // The user HAS an open "Rental Loan" status but DO NOT HAVE an open Flex Account or Active Subscription
  const isRentalLoanCustomer =  ['Open', 'Collections'].includes(loanStatusText) && !applicationState.applications.length; // prettier-ignore

  return (
    <Redirect
      to={
        // we can remove this check whenever we remove support for the rental loan from our web application
        isRentalLoanCustomer
          ? ROUTES.overview // direct the customer to the Rental Loan dashboard
          : ROUTES.apply.basePath // direct the customer to the the base path which will handle routing
      }
    />
  );
}

export default RootRedirect;
