import { Dispatch, Store } from 'resources/types';
import { getToken } from './token';

export function requestStart(type: string, data: any) {
  return { type: `${type}_START`, asyncAction: type, requestData: data };
}

export function requestFinish(type: string, data: any, payload: any) {
  return { type, asyncAction: type, requestData: data, payload };
}

export function requestFail(type: string, data: any, error: any) {
  return { type: `${type}_FAIL`, asyncAction: type, requestData: data, error };
}

export function getAuthHeaders() {
  return {
    authorization: `Bearer ${getToken('accessToken')}`
  };
}

export function createTypedApiAction<
  PayloadType,
  ReturnType,
  ActionType extends string
>(type: ActionType, apiHandler: APIHandler<PayloadType, ReturnType>) {
  return createApiAction<PayloadType, ReturnType>(type, apiHandler);
}

type APIHandler<T, S> = (data: T, headers: any) => Promise<S>;

export default function createApiAction<T, S>(
  type: string,
  apiHandler: APIHandler<T, S>
) {
  return (data?: T) => async (dispatch: Dispatch) => {
    dispatch(requestStart(type, data));

    let payload: S;
    try {
      payload = await apiHandler(data as T, getAuthHeaders());
      dispatch(requestFinish(type, data, payload));
    } catch (err) {
      dispatch(requestFail(type, data, err));
      throw err;
    }

    return payload;
  };
}
export function createAction(
  type: string,
  handler: (dispatch: Dispatch, getState: () => Store) => any
) {
  return (data?: any) => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(requestStart(type, data));

    try {
      const payload = await handler(dispatch, getState);
      dispatch(requestFinish(type, data, payload));
    } catch (err) {
      dispatch(requestFail(type, data, err));
      throw err;
    }
  };
}
