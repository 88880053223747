import { PaymentAccount } from 'resources/flex/onboarding/onboarding.reducer';
import { Store } from 'resources/types';
import { FlexOnboardingStep } from './onboarding.reducer';

export const getSteps = ({ onboarding }: Store) => {
  return onboarding.steps;
};

/**
 * Retrieves the next pending step in the onboarding flow
 */
export const getNextPendingStep = ({
  onboarding
}: Store): FlexOnboardingStep | undefined => {
  return onboarding.steps.find(step => step.status === 'pending');
};

/**
 * Retrieves the current step in the onboarding flow
 */
export const getCurrentStep = ({
  onboarding
}: Store): FlexOnboardingStep | undefined => {
  return onboarding.steps[onboarding.currentStepIndex];
};

/**
 * Retrieves the loading state of onboarding
 */
export const isOnboardingLoading = ({ onboarding }: Store): boolean => {
  return onboarding ? onboarding.isLoading : false;
};

/**
 * Retrieves whether or not the onboarding data has been loaded yet
 */
export const isOnboardingDataLoaded = ({ onboarding }: Store): boolean => {
  return onboarding ? onboarding.isOnboardingDataLoaded : false;
};

/**
 * Retrieves any onboarding error that exists
 */
export const getOnboardingError = ({
  onboarding
}: Store): string | undefined => {
  return onboarding && onboarding.error;
};

/**
 * Gets the selected payment account if there was one during onboarding
 */
export const getSelectedPaymentAccount = ({
  onboarding
}: Store): PaymentAccount | undefined => {
  return onboarding && onboarding.selectedPaymentAccount;
};
