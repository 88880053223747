import { Spin } from 'antd';
import config from 'helpers/config';
import ContactSupportModal from 'components/support/modal';
import { useAuthentication } from 'helpers/useAuthentication';
import { usePaymentProfiles } from 'helpers/usePaymentProfiles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import getFlexAccountState from 'resources/flexpay/account/account.selectors';
import { getUserAccount, getPendingFlexibleRentApplication, getApprovedFlexibleRentApplication } from 'resources/user/user.selectors'; // prettier-ignore
import getLoanDetails from 'resources/loan/account/account.selectors';
import FlexAccountSummary from './account-summary';
import styles from './dashboard.module.css';
import LoadDashboardData from 'helpers/loadDashboardData';
import {
  getLoadDashboardDataLoadingState,
  isUseUserDataLoading
} from 'resources/loading/loading.selectors';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';

/**
 * Component which determines what to show the user on their
 * Flexible Rent dashboard.
 */
function DashboardOverview() {
  const { userId, hasActiveSubscription } = useSelector(getUserAccount);
  const loan = useSelector(getLoanDetails);

  LoadDashboardData(userId);

  useAuthentication();
  usePaymentProfiles();

  const useUserDataLoading = useSelector(isUseUserDataLoading);
  const loadDashboardDataLoadingState = useSelector(getLoadDashboardDataLoadingState); // prettier-ignore

  const flexAccountState = useSelector(getFlexAccountState);
  const pendingApplication = useSelector(getPendingFlexibleRentApplication);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);

  const [dashboard, setDashboard] = React.useState<React.ReactElement>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const onCloseSupportModal = () => {
    setErrorMessage(undefined);
  };

  // change this to set state of dashboard view instead of returning one from a method
  React.useEffect(() => {
    if (useUserDataLoading || loadDashboardDataLoadingState !== 'done') {
      setDashboard(
        <div className={styles.spinnerContainer}>
          <Spin size="large" tip="Loading..." />
        </div>
      );
    } else {
      if (
        (loan && ['Open', 'Collections'].includes(loan.loanStatusText)) ||
        (flexAccountState &&
          ['Open', 'Collections'].includes(flexAccountState.loanStatusText)) ||
        hasActiveSubscription
      ) {
        // if they have an open Flex account in LoanPro, show their account management screen
        setDashboard(<FlexAccountSummary account={flexAccountState} />);
      } else if (approvedApplication) {
        const { status, executionArn } = approvedApplication;

        if (
          status === 'pending' ||
          status === 'flagged' ||
          (status === 'approved' && executionArn)
        ) {
          window.location.assign('/v/apply/pending');
        } else if (status === 'schedule_expired') {
          window.location.assign('/v/apply/set-schedule');
        } else if (status === 'payment_method_invalid') {
          window.location.assign('/v/apply/payment-method');
        } else if (status === 'approved') {
          window.location.assign('/v/apply/set-schedule');
        }
      } else if (pendingApplication) {
        // Handle pending apps in the Vue app
        window.location.assign('/v/apply/pending');
      } else {
        // if they don't have an active application or LoanPro account, show the Flexible Rent application
        setDashboard(<Redirect to={ROUTES.apply.basePath} />);
      }
    }
  }, [
    approvedApplication,
    flexAccountState,
    hasActiveSubscription,
    loadDashboardDataLoadingState,
    loan,
    pendingApplication,
    useUserDataLoading
  ]);

  return (
    <div>
      <div className={styles.layout}>{dashboard}</div>
      {errorMessage &&
        ContactSupportModal({
          message: errorMessage,
          supportPhoneNumber: config.supportPhoneNumber,
          onClose: () => onCloseSupportModal()
        })}
    </div>
  );
}

export default DashboardOverview;
