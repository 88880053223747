import FlexApplyLayout from 'layouts/flex/apply';
import FlexOnboardingLayout from 'layouts/flex/onboarding';
import MainLayout from 'layouts/main';
import SimpleLayout from 'layouts/simple';
import SignUpLayout from 'layouts/signup';
import RootRedirect from 'pages/root';
import Account from 'pages/account';
import Activate from 'pages/auth/activate';
import ForgotPasswordComponent from 'pages/auth/forgot-password';
import Login from 'pages/auth/login';
import ResetPasswordComponent from 'pages/auth/reset';
import Signup from 'pages/auth/signup';
import { MyCommunity } from 'pages/onboarding/my-community';
import DashboardOverview from 'pages/overview/dashboard';
import RentOverview from 'pages/rent/dashboard';
import RentManagePayments from 'pages/rentpayments';
import GeneralConfirmation from 'pages/confirmationpage/index';
import EnrollmentSuccessMessage from 'components/enrollment-success-message/index';
import ReportingFAQ from 'pages/flex/faq';
import flexApply from 'pages/flex/apply';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import config from './helpers/config';
import AddPaymentMethod from 'pages/rentpayments/add-payment-method';
import flexOnboarding from 'pages/flex/onboarding';
import OneTimePayment from 'pages/rentpayments/one-time-payment';
import OauthLink from 'components/payment-account-select/oauth-link';
export interface Route {
  path: string;
  exact: boolean;
  Layout: Function;
  Component: Function;
}

export const ROUTES = {
  account: '/account',
  auth: {
    activate: '/activate',
    login: '/login',
    signup: '/signup'
  },
  onboarding: {
    basePath: '/onboarding',
    myCommunity: '/onboarding/my-community',
    noMatch: '/onboarding/no-match'
  },
  apply: {
    basePath: '/apply'
  },
  overview: '/overview',
  flexpay: {
    apply: '/flexpay/apply',
    rent: '/rent',
    payments: '/rent/payments',
    pay: '/rent/pay'
  },
  flex: {
    apply: {
      match: '/flex/apply/match',
      community: '/flex/apply/community',
      address: '/flex/apply/address',
      cashFlow: '/flex/apply/cash-flow',
      schedule: '/flex/apply/schedule',
      payment: '/flex/apply/payment',
      submit: '/flex/apply/submit'
    },
    success: '/flex/success',
    onboarding: {
      setPaymentAccount: '/flex/onboarding/step2',
      confirmScheduledPayments: '/flex/onboarding/step3'
    },
    addPaymentMethod: '/rent/payments/add'
  },
  flexpayEnrollment: {
    revise: '/flexpay/enrollment/revise',
    success: '/flexpay/enrollment/success',
    failure: '/flexpay/enrollment/failure'
  },
  /* External routes that go out to the marketing site:
   * https://github.com/HelloTill/hellotill.com/tree/main/src/pages/products
   */
  products: {
    flexPay: `${config.marketingSiteUrl}/products/flexpay`
  },
  legal: {
    electronicPaymentTerms: `${config.marketingSiteUrl}/electronic-payment-terms/`,
    rentEnrollmentTerms: `${config.marketingSiteUrl}/my-rent-enrollment-terms`,
    termsOfService: `${config.marketingSiteUrl}/terms-of-service`,
    creditReportAuthorization: `${config.marketingSiteUrl}/credit-report-authorization`,
    privacyPolicy: `${config.marketingSiteUrl}/privacy-policy`,
    esignConsent: `${config.marketingSiteUrl}/esign-consent`,
    consentToBeContacted: `${config.marketingSiteUrl}/consent-to-be-contacted`
  },
  reporting: {
    faq: `/reporting/faq`
  }
};

const routes: Route[] = [
  {
    path: '/',
    exact: true,
    Layout: MainLayout,
    Component: RootRedirect
  },
  {
    path: ROUTES.apply.basePath,
    exact: true,
    Layout: MainLayout,
    Component: flexApply.Application
  },
  {
    path: ROUTES.auth.login,
    exact: false,
    Layout: SimpleLayout,
    Component: Login
  },
  {
    path: ROUTES.auth.signup,
    exact: false,
    Layout: SignUpLayout,
    Component: Signup
  },
  {
    path: ROUTES.auth.activate,
    exact: false,
    Layout: SimpleLayout,
    Component: Activate
  },
  {
    path: '/password/forgot',
    exact: false,
    Layout: SimpleLayout,
    Component: ForgotPasswordComponent
  },
  {
    path: '/password/reset',
    exact: false,
    Layout: SimpleLayout,
    Component: ResetPasswordComponent
  },
  {
    path: ROUTES.account,
    exact: false,
    Layout: MainLayout,
    Component: Account
  },
  {
    path: ROUTES.overview,
    exact: true,
    Layout: MainLayout,
    Component: DashboardOverview
  },
  {
    path: ROUTES.flexpay.apply,
    exact: true,
    Layout: FlexApplyLayout,
    Component: () => <Redirect to={ROUTES.apply.basePath} />
  },
  {
    path: '/flexpay/your-community',
    exact: true,
    Layout: FlexApplyLayout,
    Component: () => <Redirect to={ROUTES.apply.basePath} />
  },
  {
    path: '/flexpay/your-cash-flow',
    exact: true,
    Layout: FlexApplyLayout,
    Component: () => <Redirect to={ROUTES.apply.basePath} />
  },
  {
    path: '/flexpay/accept',
    exact: true,
    Layout: FlexApplyLayout,
    Component: () => <Redirect to={ROUTES.apply.basePath} />
  },
  {
    path: '/flexpay/success',
    exact: true,
    Layout: SimpleLayout,
    Component: () => <Redirect to={ROUTES.apply.basePath} />
  },
  {
    path: ROUTES.flexpay.rent,
    exact: true,
    Layout: MainLayout,
    Component: RentOverview
  },
  {
    path: ROUTES.flexpay.payments,
    exact: true,
    Layout: MainLayout,
    Component: RentManagePayments
  },
  {
    path: ROUTES.flexpay.pay,
    exact: true,
    Layout: MainLayout,
    Component: OneTimePayment
  },
  {
    path: ROUTES.onboarding.basePath,
    exact: true,
    Layout: MainLayout,
    Component: () => <Redirect to={ROUTES.onboarding.myCommunity} />
  },
  {
    path: ROUTES.onboarding.myCommunity,
    exact: true,
    Layout: MainLayout,
    Component: MyCommunity
  },
  {
    path: ROUTES.flexpayEnrollment.revise,
    exact: true,
    Layout: SimpleLayout,
    Component: GeneralConfirmation
  },
  {
    path: ROUTES.flexpayEnrollment.success,
    exact: true,
    Layout: SimpleLayout,
    Component: EnrollmentSuccessMessage
  },
  {
    path: ROUTES.flexpayEnrollment.failure,
    exact: true,
    Layout: SimpleLayout,
    Component: GeneralConfirmation
  },
  {
    path: ROUTES.flex.apply.community,
    exact: true,
    Layout: FlexApplyLayout,
    Component: flexApply.Community
  },
  {
    path: ROUTES.flex.apply.address,
    exact: true,
    Layout: FlexApplyLayout,
    Component: flexApply.Address
  },
  {
    path: ROUTES.flex.apply.cashFlow,
    exact: true,
    Layout: FlexApplyLayout,
    Component: flexApply.CashFlow
  },
  {
    path: ROUTES.flex.apply.schedule,
    exact: true,
    Layout: FlexApplyLayout,
    Component: flexApply.Schedule
  },
  {
    path: ROUTES.flex.apply.submit,
    exact: true,
    Layout: FlexApplyLayout,
    Component: flexApply.Submit
  },
  {
    path: ROUTES.reporting.faq,
    exact: true,
    Layout: SimpleLayout,
    Component: ReportingFAQ
  },
  {
    path: ROUTES.flex.addPaymentMethod,
    exact: true,
    Layout: MainLayout,
    Component: AddPaymentMethod
  },
  {
    path: ROUTES.flex.onboarding.setPaymentAccount,
    exact: true,
    Layout: FlexOnboardingLayout,
    Component: flexOnboarding.SetPrimaryPaymentAccount
  },
  {
    path: ROUTES.flex.onboarding.confirmScheduledPayments,
    exact: true,
    Layout: FlexOnboardingLayout,
    Component: flexOnboarding.ConfirmScheduledPayments
  },
  {
    path: ROUTES.flex.success,
    exact: true,
    Layout: MainLayout,
    Component: flexOnboarding.Success
  },
  {
    path: '/oauth-verification',
    exact: true,
    Layout: MainLayout,
    Component: OauthLink
  }
];

export default routes;
