import * as React from 'react';
import cx from 'classnames';

import styles from './payment-option.module.css';

interface Props {
  className?: string;
  optionClassName?: string;
  triangleClassName?: string;
  index: number;
  title: string;
  amount?: number;
  selected?: boolean;
  bodyText?: string;
  onSelect: (index: number) => any;
  disabled?: boolean;
}

class PaymentOption extends React.PureComponent<Props> {
  onClick = () => {
    const { onSelect, index, disabled } = this.props;
    if (!disabled) {
      onSelect(index);
    }
  };

  render() {
    const {
      className = '',
      optionClassName = '',
      triangleClassName = '',
      title,
      amount,
      bodyText,
      selected
    } = this.props;

    return (
      <button className={cx(styles.btn, className)} onClick={this.onClick}>
        <div
          className={cx(styles.option, optionClassName, {
            [styles.selected]: selected
          })}
        >
          <div className={cx(styles.triangle, triangleClassName)} />
          <div className={cx(styles.triangle, triangleClassName)} />
          <div className={cx(styles.triangle, triangleClassName)} />
          <div className={cx(styles.contentContainer)}>
            <section className={styles.title}>{title}</section>
            {amount ? (
              <section className={styles.amount}>${amount}</section>
            ) : (
              <section className={cx(styles.amount, styles.hiddenAmount)}>
                $
              </section>
            )}
            {bodyText ? <p className={styles.bodyText}>{bodyText}</p> : ''}
            <div className={styles.label}>
              {selected ? 'Selected' : 'Select'}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

export default PaymentOption;
