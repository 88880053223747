import * as React from 'react';

export default function Community() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <g transform="translate(-41 -283)">
        <circle
          cx="25"
          cy="25"
          r="25"
          fill="#1eb5f0"
          transform="translate(41 283)"
        />
        <g>
          <path
            fill="none"
            d="M0 0h25.919v25.919H0z"
            transform="translate(53.041 295.041)"
          />
          <path
            fill="#fff"
            d="M15.959 11.72V5.24L12.72 2 9.48 5.24V7.4H3v15.119h19.439V11.72zm-8.64 8.64H5.16V18.2h2.16zm0-4.32H5.16v-2.16h2.16zm0-4.32H5.16V9.56h2.16zm6.48 8.64H11.64V18.2h2.16zm0-4.32H11.64v-2.16h2.16zm0-4.32H11.64V9.56h2.16zm0-4.32H11.64V5.24h2.16zm6.48 12.959h-2.16V18.2h2.16zm0-4.32h-2.16V13.88h2.16z"
            transform="translate(53.041 295.041) translate(0.24 0.16)"
          />
        </g>
      </g>
    </svg>
  );
}
