import * as React from 'react';
import cx from 'classnames';
import styles from './sidebar-mobile.module.css';
import SidebarMenu from 'layouts/sidebar/sidebar-menu';

interface Props {
  className?: string;
  hideNavbar?: boolean;
  path: string;
  isOpen: boolean;
  onClose: () => any;
}

/**
 * The sidebar to display on mobile screen sizes
 */
class SidebarMobile extends React.PureComponent<Props> {
  private node: HTMLElement | null = null;

  componentDidMount() {
    document.addEventListener('click', this.onOutsideClick, true);
  }

  componentDidUpdate(prevProps: Props) {
    const { isOpen, path, onClose } = this.props;

    if (prevProps.path !== path && isOpen) {
      onClose();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onOutsideClick, true);
  }

  onOutsideClick = (e: MouseEvent) => {
    const { isOpen, onClose } = this.props;

    if (isOpen && !(this.node && this.node.contains(e.target as any))) {
      onClose();
    }
  };

  render() {
    const { className, isOpen } = this.props;

    return (
      <aside
        ref={e => (this.node = e)}
        className={cx(styles.sidebar, className, { [styles.open]: isOpen })}
      >
        <SidebarMenu />
      </aside>
    );
  }
}

export default SidebarMobile;
