import * as React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';
import Icon from 'components/icon';

/**
 * items: The items to display in the list
 * onItemSelect: The action to take when an item is selected
 * itemTitle: The title to display for each item
 * itemSubtitle: The subtitle to display for each item
 */
export interface Props<T> {
  items: T[];
  defaultSelectedItem?: T;
  areItemsEqual: (selectedItem?: T, currentItem?: T) => boolean;
  onItemSelect: (item: T) => void;
  itemTitle: (item: T) => string;
  itemSubtitle?: (item: T) => string | undefined;
}

/**
 * A component for displaying a grid of items for selection
 */
export default function GridSelect<SelectItemType>({
  items,
  defaultSelectedItem,
  areItemsEqual,
  onItemSelect,
  itemTitle,
  itemSubtitle
}: Props<SelectItemType>) {
  const [selectedItem, setSelectedItem] = React.useState<
    SelectItemType | undefined
  >(defaultSelectedItem);

  // any time the default selected item is updated, change it in this component
  React.useEffect(() => {
    setSelectedItem(defaultSelectedItem);
  }, [defaultSelectedItem]);

  return (
    <div className={styles.tiles}>
      {items.map(item => {
        return (
          <div
            className={cx(
              styles.tile,
              areItemsEqual(selectedItem, item) && styles.selected
            )}
            key={itemTitle(item)}
            onClick={() => {
              setSelectedItem(item);
              onItemSelect(item);
            }}
          >
            <div className={cx(styles.iconContainer, styles.hidden)}>
              <Icon type="check" className={cx(styles.icon)} />
            </div>
            <div className={cx(styles.itemInfo)}>
              <h2 className={cx(styles.title)}>{itemTitle(item)}</h2>
              {itemSubtitle && itemSubtitle(item) && (
                <p className={cx(styles.subtitle)}>{itemSubtitle(item)}</p>
              )}
            </div>
            <div className={cx(styles.iconContainer)}>
              <Icon
                type="check"
                className={cx(
                  styles.icon,
                  areItemsEqual(selectedItem, item) && styles.selected
                )}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
