import * as React from 'react';

export default function Upload() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5385 3.97607L5.79808 8.72127L6.66346 9.58665L9.92308 6.33184V15.3078H11.1538V6.33184L14.4087 9.58665L15.2837 8.72127L10.5385 3.97607ZM5 17.7693V19.0001H16.0769V17.7693H5Z"
        fill="#475F7B"
      />
    </svg>
  );
}
