import * as React from 'react';
import cx from 'classnames';
import { FormikProps } from 'formik';
import Button from 'components/button';
import Input from 'components/form-input';
import { ResendLink } from 'resources/user/types';
import baseStyles from 'styles/base.module.css';
import styles from 'styles/form.module.css';

function ResendLinkForm({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  isValid
}: FormikProps<ResendLink>) {
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          label="Email"
          name="email"
          placeholder="Enter your email address"
          value={values.email}
          touched={touched.email}
          valid={!errors.email}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {touched.email && errors.email && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.email}</span>
        </div>
      )}
      <div className={cx(styles.actionContainer, baseStyles.center)}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Resend Link
        </Button>
      </div>
    </form>
  );
}

export default ResendLinkForm;
