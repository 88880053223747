import * as React from 'react';

export default function Check() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9862 5.70725C19.3772 5.31725 19.3772 4.68325 18.9862 4.29325C18.5962 3.90225 17.9633 3.90225 17.5723 4.29325L7.01825 14.8472L3.70725 11.5373C3.31725 11.1463 2.68325 11.1463 2.29325 11.5373C1.90225 11.9283 1.90225 12.5613 2.29325 12.9513L6.26125 16.9202C6.27725 16.9372 6.29225 16.9543 6.30925 16.9703C6.50425 17.1663 6.76025 17.2633 7.01625 17.2633C7.06425 17.2633 7.11225 17.2603 7.16025 17.2533C7.36725 17.2243 7.56725 17.1303 7.72625 16.9703C7.74325 16.9543 7.75925 16.9372 7.77425 16.9192L18.9862 5.70725Z"
        fill="#475F7B"
      />
    </svg>
  );
}
