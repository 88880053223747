import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import * as FullStory from '@fullstory/browser';

// @ts-ignore
const _hsq = (window._hsq = window._hsq || []);

interface Props {
  history: any;
  children: React.ReactNode;
}

const AnalyticsListener = ({ history, children }: Props) => {
  const prevLocation = useRef(history.location);

  const sendPageView = (history: any) => {
    // Send event to Hubspot
    _hsq.push(['setPath', history.location.pathname + history.location.search]);
    _hsq.push(['trackPageView']);
    prevLocation.current = history.location;
  };

  // Init hubspot tracking
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/20033384.js';

    document.body.appendChild(script);

    TagManager.initialize({ gtmId: 'GTM-N9MJH4P' });
    FullStory.init({ orgId: '16FZ4V' });

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Trigger pageview on URL change
  useEffect(() => {
    sendPageView(history);
    return history.listen((location: { pathname: string }) => {
      // This check is required to prevent trigger from firing too often
      if (prevLocation.current.pathname !== location.pathname) {
        sendPageView(history);
      }
    });
  }, [history]);

  return <>{children}</>;
};

export default AnalyticsListener;
