import * as React from 'react';
import { Spin } from 'antd';
import styles from './styles.module.css';

interface Props {
  size?: 'small' | 'large' | 'default';
  tip?: string;
  fullScreen?: boolean;
}

export const Spinner = ({ size, tip, fullScreen }: Props) => (
  <Spin
    className={fullScreen ? styles.fullScreenSpinner : styles.spinnerContainer}
    size={size}
    tip={tip}
  />
);

export default Spinner;
