import React from 'react';
import cx from 'classnames';
import { format as formatNumber } from 'helpers/number';
import moment from 'moment';

// UI
import AutopayToggle from 'components/autopay-toggle';
import autopayStyles from './autopay.module.css';
import styles from './cards.module.css';

interface Autopay {
  name: string;
  frequency: string;
  amountType: string;
  amount: number;
  daysInPeriod?: number;
  account?: string;
  date: string;
  recurringPeriods: number;
  applyDate: string;
  type: string;
}
interface Props {
  autopays: Autopay[];
}
const getAutopayFrequency = ({ frequency, daysInPeriod }: Autopay) => {
  switch (frequency) {
    case 'autopay.recurringFrequency.biWeekly':
      return 'Every two weeks';
    case 'autopay.recurringFrequency.monthly':
      return 'Monthly';
    case 'autopay.recurringFrequency.semiMonthly':
      return 'Twice a month';
    case 'autopay.recurringFrequency.weekly':
      return 'Every week';
    case 'autopay.recurringFrequency.custom':
      return `Every ${daysInPeriod || 0} days`;
  }

  return 'Unknown';
};

const getAmount = ({ amountType, amount }: Autopay) => {
  return amountType === 'autopay.amountType.static' || 'autopay.amountType.nextDue' // prettier-ignore
    ? `$${formatNumber(amount, 2)}`
    : 'What is due';
};

export function AutopayCard({ autopays }: Props) {
  return (
    <>
      <div className={cx(styles.card, styles.payments)}>
        <h1>Autopays</h1>
        <AutopayToggle autopays={autopays} transactions={[]} />
        {autopays.map((autopay, i) => {
          return (
            <div className={autopayStyles.autopayContainer} key={i}>
              <h3>{autopay.name}</h3>
              <span>
                Amount
                <span>
                  {getAmount(autopay)}
                  {autopay.name.includes('Fee') && (
                    <p className={autopayStyles.disclaimerText}>
                      This amount includes a prorated portion of your monthly
                      Till fee.
                    </p>
                  )}
                </span>
              </span>
              <span>
                Next Payment
                <span>{moment(autopay.applyDate).format('dddd MMMM Do')}</span>
              </span>
              <span>
                Frequency
                <span>{getAutopayFrequency(autopay)}</span>
              </span>
              <span>
                Payment Account
                <span>{autopay.account || 'Unknown'}</span>
              </span>

              {autopay.recurringPeriods <= 5 && (
                <p>
                  This autopay will end after <b>{autopay.recurringPeriods}</b>{' '}
                  more {autopay.recurringPeriods === 1 ? 'payment' : 'payments'}
                  .
                </p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
