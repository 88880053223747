import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import {
  getCurrentStep,
  getNextPendingStep
} from 'resources/flex/apply/apply.selectors';

/**
 * Moves the user to the appropriate step in the
 * application flow
 *
 * This hook is used on each step of the application
 */
const UseApplicationStep = () => {
  const dispatch = useThunkDispatch();

  const nextPendingStep = useSelector(getNextPendingStep);
  const currentStep = useSelector(getCurrentStep);

  useEffect(() => {
    if (
      nextPendingStep &&
      currentStep &&
      nextPendingStep !== currentStep &&
      currentStep.status === 'pending'
    ) {
      dispatch(push(nextPendingStep.key));
    } else if (currentStep) {
      dispatch(push(currentStep.key));
    }
  }, [dispatch, nextPendingStep, currentStep]);
};

export default UseApplicationStep;
