import { IconType } from 'components/icon';
import { PaymentAccountType } from 'resources/flexpay/account/types';
import { PlaidAccount, PlaidInstitution } from 'resources/plaid/types';
import { ROUTES } from 'routes';
import { OnboardingAction } from './onboarding.actions';

export interface FlexOnboardingStep {
  key: string;
  icon: IconType;
  label: string;
  status: 'pending' | 'complete';
}

export interface FlexOnboardingState {
  steps: FlexOnboardingStep[];
  currentStepIndex: number;
  isLoading: boolean;
  isOnboardingDataLoaded: boolean;
  error?: string;
  selectedPaymentAccount?: PaymentAccount;
}

export interface PaymentAccount {
  linkType?: 'plaid' | 'pciwallet';
  accountType?: PaymentAccountType;
  plaidBankItem?: PlaidInstitution;
  plaidBankAccount?: PlaidAccount;
  pciAccountToken?: string;
  pciAccountTitle?: string;
  selectedPaymentProfileId?: number;
}

export interface BudgetAndSavePaymentProfileConfig {
  accountType?: string;
  plaidItemId?: string;
  plaidAccountId?: string;
  accountTitle?: string;
  accountToken?: string;
  paymentProfileId?: string;
}

export interface BudgetAndSaveAccount {
  paymentProfileConfig: BudgetAndSavePaymentProfileConfig;
}

/**
 * Returns the default state of the flex onboarding flow
 */
const getDefaultState = (): FlexOnboardingState => {
  return {
    steps: [
      {
        key: `${ROUTES.flexpay.rent}`,
        icon: 'file-done-outlined',
        label: 'Sign Contract',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.onboarding.setPaymentAccount}`,
        icon: 'coins',
        label: 'Set Payment Account',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.onboarding.confirmScheduledPayments}`,
        icon: 'date',
        label: 'Confirm Payment',
        status: 'pending'
      }
    ],
    currentStepIndex: 0,
    isLoading: true,
    isOnboardingDataLoaded: false,
    error: undefined
  };
};

/**
 * Finds a step in the state by its key
 */
const findStep = (state: FlexOnboardingState, key: string) => {
  return state.steps.find(step => step.key === key);
};

export default function enrollmentReducer(
  state: FlexOnboardingState = getDefaultState(),
  action: OnboardingAction
) {
  let step;
  const newState = {
    ...state
  };
  switch (action.type) {
    case 'OAUTH_VERIFICATION_COMPLETE':
      newState.steps[0].status = 'complete';
      newState.currentStepIndex = 1;
      return newState;
    case 'ONBOARDING_CONTRACT_COMPLETE':
      step = findStep(newState, ROUTES.flexpay.rent);
      if (step) {
        step.status = 'complete';
      }
      return newState;
    case 'ONBOARDING_PAYMENT_ACCOUNT_COMPLETE':
      newState.selectedPaymentAccount = action.payload;
      step = findStep(newState, ROUTES.flex.onboarding.setPaymentAccount);
      if (step) {
        step.status = 'complete';
      }
      return newState;
    case 'ONBOARDING_CONFIRM_PAYMENT_COMPLETE':
      step = findStep(
        newState,
        ROUTES.flex.onboarding.confirmScheduledPayments
      );
      if (step) {
        step.status = 'complete';
      }
      return newState;
    case 'ONBOARDING_GO_TO_NEXT_STEP':
      const currentStepStatus = newState.steps[newState.currentStepIndex];
      if (currentStepStatus && currentStepStatus.status === 'complete') {
        newState.currentStepIndex = newState.currentStepIndex + 1;
      }
      return newState;
    case 'ONBOARDING_GO_TO_PREVIOUS_STEP':
      newState.currentStepIndex = newState.currentStepIndex - 1;
      return newState;
    case 'SET_LOADING':
      newState.isLoading = action.payload;
      return newState;
    case 'SET_ONBOARDING_DATA_LOADED':
      newState.isOnboardingDataLoaded = action.payload;
      return newState;
    case 'SET_ERROR':
      newState.error = action.payload;
      return newState;
    default:
      return newState;
  }
}
