type PlaidEnvironment = 'sandbox' | 'development' | 'production';
export default {
  marketingSiteUrl: process.env.REACT_APP_MARKETING_SITE_URL || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  stripe: {
    key: process.env.REACT_APP_STRIPE_KEY || '',
    libUrl: process.env.REACT_APP_STRIPE_LIB_URL || ''
  } as const,
  financialToolsUrl: process.env.REACT_APP_FINANCIAL_TOOLS_URL || '',
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  launchDarkly: {
    clientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || ''
  },
  pciWallet: {
    baseUrl: process.env.REACT_APP_PCI_WALLET_BASE_URL || ''
  },
  supportPhoneNumber: process.env.REACT_APP_SUPPORT_PHONE_NUMBER || '',
  supportFormLink: process.env.REACT_APP_SUPPORT_FORM_LINK || '',
  supportEmailAddress: process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS || '',
  autopayMinAmount: 10,
  phoneRegExp: /\d{3}-?\d{3}-?\d{4}/,
  deployEnv: process.env.REACT_APP_DEPLOY_ENV || 'development',
  publicUrl: process.env.PUBLIC_URL
};
