import {
  getApprovedFlexibleRentApplication,
  getApplicationLoadingState
} from './../../../resources/user/user.selectors';
import {
  getOnboardingError,
  isOnboardingDataLoaded
} from './../../../resources/flex/onboarding/onboarding.selector';
import {
  setLoading,
  setError,
  setIsDataLoaded
} from './../../../resources/flex/onboarding/onboarding.actions';
import { getUserAccount } from 'resources/user/user.selectors';
import { loadEnrollmentData } from 'resources/flexpay/acceptance/acceptance.actions';
import { getPreferredPaymentProfile } from './../../../resources/user/user.actions';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { push, replace } from 'connected-react-router';
import { useEffect } from 'react';
import {
  getCurrentStep,
  getNextPendingStep
} from 'resources/flex/onboarding/onboarding.selector';
import { ROUTES } from 'routes';

/**
 * This hook is responsible for loading the initial state of
 * the onboarding flow as well as moving the user to the current
 * step. It also will redirect them out of the flow if they
 * are not supposed to be there (i.e. they don't have an approved
 * application or they haven't signed the contract yet)
 *
 * This hook is used on each step of the onboarding flow
 */
const UseOnboardingStep = () => {
  const dispatch = useThunkDispatch();

  const { userId } = useSelector(getUserAccount);
  const error = useSelector(getOnboardingError);
  const nextPendingStep = useSelector(getNextPendingStep);
  const currentStep = useSelector(getCurrentStep);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);
  const isApplicationLoaded = useSelector(getApplicationLoadingState);
  const isLoaded = useSelector(isOnboardingDataLoaded);
  const urlParams = new URLSearchParams(window.location.search);
  const oauth = urlParams.get('oauth');

  useEffect(() => {
    (async () => {
      if (userId.length && !error) {
        try {
          dispatch(setLoading(true));
          try {
            await dispatch(getPreferredPaymentProfile(userId));
          } catch (err) {
            // silently fails, this just means we can't populate a previously selected preferred payment profile
          }

          await dispatch(loadEnrollmentData(userId));
        } catch (err) {
          setError('We were unable to retrieve your payment schedule');
        }
        dispatch(setLoading(false));
        dispatch(setIsDataLoaded(true));
      }
    })();
  }, [dispatch, userId, error]);

  useEffect(() => {
    if (oauth) {
      return;
    } else if (
      nextPendingStep &&
      currentStep &&
      nextPendingStep !== currentStep &&
      currentStep.status === 'pending'
    ) {
      dispatch(push(nextPendingStep.key));
    } else if (currentStep) {
      dispatch(push(currentStep.key));
    }
  }, [dispatch, nextPendingStep, currentStep, oauth]);

  useEffect(() => {
    if (
      isLoaded &&
      isApplicationLoaded &&
      (!approvedApplication ||
        (approvedApplication &&
          !approvedApplication.contractSignatureRequestId))
    ) {
      // if they don't have an approved application or they do but the contract isn't signed redirect them away from this page
      dispatch(replace(ROUTES.flexpay.rent));
    }
  }, [dispatch, isLoaded, approvedApplication, isApplicationLoaded]);
};

export default UseOnboardingStep;
