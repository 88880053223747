import * as Yup from 'yup';

export default Yup.object().shape({
  code: Yup.string().required('Code is required'),
  password: Yup.string()
    .min(8, 'Password is too short')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match')
    .required('Password is required')
});
