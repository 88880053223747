import * as React from 'react';
import { PaymentProfile } from '../../resources/loan/account/types';
import Modal from '../modal';
import PayNow from './pay';
import styles from './styles.module.css';

interface Props {
  showDebitCard?: boolean;
  account?: 'flex' | 'loan';
  min: number;
  max: number;
  date?: string | Date;
  amount?: number;
  visible: boolean;
  makePayment: (
    amount: number,
    date: Date,
    paymentMethod: PaymentProfile
  ) => void;
  onClose: () => any;
  debitIframeUrl: string;
  achIframeUrl: string;
  methods: PaymentProfile[];
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
  isSubmitting?: boolean;
  selectedMethod?: PaymentProfile;
  paymentId?: number;
}

function PayNowModal({
  showDebitCard,
  account,
  min,
  max,
  date,
  amount,
  visible,
  makePayment,
  onClose,
  debitIframeUrl,
  achIframeUrl,
  handlePaymentProfileEvent,
  methods,
  isSubmitting,
  selectedMethod,
  paymentId
}: Props) {
  return (
    <Modal className={styles.modal} visible={visible} onClose={onClose}>
      <div>
        <h1 className={styles.title}>
          {paymentId ? 'Edit Payment' : 'Pay Now'}
        </h1>
        <PayNow
          showDebitCard={showDebitCard}
          account={account}
          min={min}
          max={max}
          amount={amount}
          date={date}
          makePayment={makePayment}
          methods={methods}
          debitIframeUrl={debitIframeUrl}
          achIframeUrl={achIframeUrl}
          handlePaymentProfileEvent={handlePaymentProfileEvent}
          isSubmitting={isSubmitting}
          selectedMethod={selectedMethod}
          paymentId={paymentId}
        />
      </div>
    </Modal>
  );
}

export default PayNowModal;
