import { Community } from '@hellotill/community-types';
import { Store } from '../types';
import { CommunitySearchResult } from './types';

export function getCommunity({
  community
}: Store): Partial<Community> | undefined {
  return community && community.community;
}

export function getCommunityName({ community }: Store): string {
  if (!community || !community.community) {
    return '';
  }

  return community.community.name || '';
}

export function getSearchResults({
  community
}: Store): CommunitySearchResult[] {
  if (!community || !community.searchResults) {
    return [];
  }

  return community.searchResults;
}
