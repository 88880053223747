import * as React from 'react';

export default function EditFile() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.181 0.181818C1.50038 0.181818 0.939789 0.742406 0.939789 1.42303V8.8703C0.939789 9.55093 1.50038 10.1115 2.181 10.1115H4.2707V9.28404H2.181C1.94809 9.28404 1.76726 9.10321 1.76726 8.8703V1.42303C1.76726 1.19012 1.94809 1.00929 2.181 1.00929H5.90464V3.49172H8.38706V4.31919H9.21454V2.90667L6.48969 0.181818H2.181ZM6.73212 1.5943L7.80202 2.6642H6.73212V1.5943ZM10.2133 5.14991C9.94121 5.15164 9.66972 5.25747 9.46666 5.46506L5.6412 9.35678L5.05777 11.8085L7.50787 11.2251L7.59029 11.1443L11.4012 7.40122C11.8182 6.99366 11.8215 6.31205 11.4093 5.89981L10.9665 5.45698C10.7602 5.2507 10.4866 5.14816 10.2133 5.14991ZM10.2182 5.97247C10.2758 5.97211 10.3345 5.99504 10.3814 6.04197L10.8242 6.4848C10.9168 6.57731 10.917 6.71741 10.8226 6.80965V6.81126L7.09414 10.4719L6.17454 10.6917L6.39272 9.77369L10.0566 6.0452L10.0582 6.04359C10.1039 5.9965 10.1605 5.97284 10.2182 5.97247Z"
        fill="#475F7B"
      />
    </svg>
  );
}
