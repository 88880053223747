import * as React from 'react';
import { Layout } from 'antd';
import cx from 'classnames';
import styles from './sidebar-desktop.module.css';
import SidebarMenu from './sidebar-menu';

interface Props {
  className?: string;
}

/**
 * The sidebar that displays on a desktop screen size
 */
function SidebarDesktop({ className }: Props) {
  return (
    <Layout.Sider width={252} className={cx(styles.sidebar, className)}>
      <SidebarMenu />
    </Layout.Sider>
  );
}

export default SidebarDesktop;
