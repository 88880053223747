import * as React from 'react';
import { ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import config from 'helpers/config';
import styles from './styles.module.css';

interface Props {
  path: string;
}

function GeneralMessage({ path }: Props) {
  let title = '';
  let message = '';
  let route = '';
  let routeMessage = '';

  if (path === '/flexpay/enrollment/revise') {
    title = 'We are on it!';
    message = `A member from our support team is working on your request. We will be in contact with you soon!`;
    route = ROUTES.flexpay.rent;
    routeMessage = 'Go to My Till';
  } else if (path === '/flexpay/enrollment/failure') {
    title = 'Oops! Something went wrong...';
    message = `Please give us a call at ${config.supportPhoneNumber}, option #2 to process your request.`;
    route = ROUTES.flexpay.rent;
    routeMessage = 'Go to My Till';
  }

  return (
    <div>
      <h3 className={styles.title}>{title}</h3>
      <p>{message}</p>
      <Link to={route}>{routeMessage}</Link>
    </div>
  );
}

export default GeneralMessage;
