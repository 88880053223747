import * as React from 'react';

export default function PersonalData() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.90104 3 3 3.90104 3 5V17C3 18.099 3.90104 19 5 19H17C18.099 19 19 18.099 19 17V5C19 3.90104 18.099 3 17 3H5ZM5 4.33333H17C17.375 4.33333 17.6667 4.625 17.6667 5V17C17.6667 17.375 17.375 17.6667 17 17.6667H5C4.625 17.6667 4.33333 17.375 4.33333 17V5C4.33333 4.625 4.625 4.33333 5 4.33333ZM11 7C9.53646 7 8.33333 8.20313 8.33333 9.66667C8.33333 10.3802 8.625 11.0313 9.08333 11.5104C7.84896 12.1927 7 13.4948 7 15H8.33333C8.33333 13.5208 9.52083 12.3333 11 12.3333C12.4792 12.3333 13.6667 13.5208 13.6667 15H15C15 13.4948 14.151 12.1927 12.9167 11.5104C13.375 11.0313 13.6667 10.3802 13.6667 9.66667C13.6667 8.20313 12.4635 7 11 7ZM11 8.33333C11.7448 8.33333 12.3333 8.92187 12.3333 9.66667C12.3333 10.4115 11.7448 11 11 11C10.2552 11 9.66667 10.4115 9.66667 9.66667C9.66667 8.92187 10.2552 8.33333 11 8.33333Z"
        fill="#475F7B"
      />
    </svg>
  );
}
