import { Layout } from 'antd';
import Spinner from '../../../components/spinner';
import cx from 'classnames';

import ProgressBar from 'components/progress-nav-bar';
import LoadDashboardData from 'helpers/loadDashboardData';

import { useAuthentication } from 'helpers/useAuthentication';
import { useUserData } from 'helpers/useUserData';
import Header from 'layouts/header';
import Sidebar from 'layouts/sidebar/sidebar';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getSteps } from 'resources/flex/apply/apply.selectors';
import getFlexAccountState from 'resources/flexpay/account/account.selectors';
import { getLoadDashboardDataLoadingState, isUseUserDataLoading } from 'resources/loading/loading.selectors'; // prettier-ignore
import { getPath } from 'resources/router/router.selectors';
import { getApprovedFlexibleRentApplication, getPendingFlexibleRentApplication, getUserAccount } from 'resources/user/user.selectors'; // prettier-ignore
import { ROUTES } from 'routes';
import styles from './index.module.css';
import baseStyles from '../../../styles/base.module.css';

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export default function FlexApplicationLayout({ children }: Props) {
  useAuthentication();
  useUserData();

  const user = useSelector(getUserAccount);
  LoadDashboardData(user.userId);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const path = useSelector(getPath);
  const steps = useSelector(getSteps);

  const useUserDataLoading = useSelector(isUseUserDataLoading);
  const loadDashboardDataLoadingState = useSelector(
    getLoadDashboardDataLoadingState
  );

  const pendingApplication = useSelector(getPendingFlexibleRentApplication);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);
  const flexAccountState = useSelector(getFlexAccountState);

  // prettier-ignore
  if ( 
    (flexAccountState && ['Open', 'Collections'].includes(flexAccountState.loanStatusText)) ||
    pendingApplication ||
    approvedApplication
  ) {
    return <Redirect to={ROUTES.flexpay.rent} />;
  }

  return (
    <Layout className={styles.layout}>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Layout>
        <div
          className={cx(baseStyles.overlay, {
            [baseStyles.hidden]: !isMenuOpen
          })}
        />
        <div className={styles.sideBarOuter}>
          <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        </div>

        {useUserDataLoading || loadDashboardDataLoadingState === 'loading' ? (
          <Spinner size="large" tip="Loading application..." />
        ) : (
          <div className={styles.content}>
            <h1 className={styles.header}>Till Application</h1>
            <ProgressBar
              className={styles.progress}
              items={steps}
              activeItem={path}
            />
            {children}
          </div>
        )}
      </Layout>
    </Layout>
  );
}
