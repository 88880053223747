import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';

import Header from '../header';
import { Layout } from 'antd';
import styles from './layout.module.css';

import { useSelector } from 'react-redux';
import {
  getUserAccount,
  getPendingFlexibleRentApplication,
  getApprovedFlexibleRentApplication
} from 'resources/user/user.selectors';
import getLoanDetails from 'resources/loan/account/account.selectors';
import { loadLoansByCustomer } from 'resources/loan/account/account.actions';
import { useThunkDispatch } from 'resources';

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

function SimpleLayout({ children }: Props) {
  const dispatch = useThunkDispatch();

  const { userId } = useSelector(getUserAccount);
  const { loanStatusText } = useSelector(getLoanDetails);
  const pendingApplication = useSelector(getPendingFlexibleRentApplication);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);

  const [loanDataLoading, setLoanDataLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      if (loanDataLoading && userId.length) {
        try {
          await dispatch(loadLoansByCustomer(userId));
        } catch (error) {}
        setLoanDataLoading(false);
      }
    })();
  }, [dispatch, loanDataLoading, userId]);

  return ['Open', 'Collections'].includes(loanStatusText) ||
    pendingApplication ||
    approvedApplication ? (
    <Redirect to={ROUTES.flexpay.rent} />
  ) : (
    <Layout>
      <Header hideBurger isMenuOpen={false} setIsMenuOpen={() => {}} />
      <Layout>
        <Layout.Content className={styles.content}>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default React.memo(SimpleLayout);
