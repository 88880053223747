import { transformPaymentMethod } from './../../../domain/transforms';
import Axios from 'axios';
import config from '../../../helpers/config';
import APIError from 'helpers/api.error';
import { PaymentAccount } from 'resources/flex/onboarding/onboarding.reducer';
import { getApplicationsByUser } from '../../user/user.api';

export interface EnrollData {
  userId: string;
  applicationId: string;
  paymentAccount: PaymentAccount;
}

/**
 * Calls the V2 endpoint for enrolling an application in Flexible Rent
 * @param applicationId The ID of the application to enroll
 * @param headers Any necessary headers, like authentication
 */
export const enroll = async (data?: EnrollData, headers?: any) => {
  try {
    if (!data || data.applicationId === undefined) {
      throw Error(
        'Application ID undefined when attempting Flexible Rent enrollment'
      );
    }

    const body = transformPaymentMethod(data.paymentAccount);

    try {
      const response = await Axios.post(
        `${config.apiUrl}/api/v2/flex/application/${data.applicationId}/enroll/`,
        body,
        { headers }
      );
      return response.data;
    } catch (err) {
      if (!err.response || (err.response && err.response.status !== 504)) {
        throw err;
      } else {
        let ct = 0;
        let res;
        do {
          // We'll pause 2 seconds before seeing if the enrollment has
          // succeeded. And so we'll give the API 15 tries, i.e., 30 more seconds,
          // to finish before we give up.
          if (++ct === 15) throw err;
          await new Promise(resolve => setTimeout(resolve, 2000));

          const enrolledApplications = await getApplicationsByUser(
            { userId: data.userId, status: 'enrolled' },
            headers
          );
          for (const enrolledApplication of enrolledApplications) {
            // This enrolled application is the one we just submitted! 🎉
            if (enrolledApplication.applicationId === data.applicationId) {
              // the enroll endpoint returns an empty JSON object on success ¯\_(ツ)_/¯
              res = {
                data: {}
              };
              break;
            }
          }
        } while (!res);
        return res;
      }
    }
  } catch (err) {
    const errorMessage = err.message
      ? `Error Enrolling Your Flexible Rent Account, ${err.message}`
      : 'Error Enrolling Your Flexible Rent Account';
    const errStatus =
      err.response && err.response.status ? err.response.status : 500;
    throw new APIError(errorMessage, errStatus);
  }
};

export const enrollBudgetAndSave = async (data?: any, headers?: any) => {
  try {
    if (!data || !data.applicationId === undefined) {
      throw Error(
        'Application ID undefined when attempting Flexible Rent enrollment'
      );
    }

    try {
      const response = await Axios.post(
        `${config.apiUrl}/application/${data.applicationId}/enroll`,
        data.payload,
        { headers }
      );
      return response.data;
    } catch (err) {
      if (!err.response || (err.response && err.response.status !== 504)) {
        throw err;
      }
    }
  } catch (err) {
    const errorMessage = err.message
      ? `Error Enrolling Your Till Account, ${err.message}`
      : 'Error Enrolling Your Till Account';
    const errStatus =
      err.response && err.response.status ? err.response.status : 500;
    throw new APIError(errorMessage, errStatus);
  }
};
