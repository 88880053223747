import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { clearTokens } from './token';

export default class APIError extends Error {
  code: string | undefined;
  status: number;

  __proto__: any;

  constructor(message: string, status: number, code?: string) {
    super(message);

    // a workaround to make `instanceof ApiError` work in ES5 with babel
    Object.setPrototypeOf(this, APIError.prototype);
    this.constructor = APIError;
    this.__proto__ = APIError.prototype; // eslint-disable-line

    this.code = code;
    this.status = status;
  }
}

export function handleError(err: any, defaultValue: string): any {
  const status = get(err, 'response.status', 500);
  if (status === 401 || status === 403) {
    clearTokens();
  }

  const code = get(err, 'response.data.code', undefined);
  const message = get(err, 'response.data.message', defaultValue);

  const isValidationFailure =
    code === 400 &&
    (message.includes('Invalid request body') ||
      message.includes('Missing required request parameters'));

  const apiError = new APIError(message, status, code);
  if (status >= 500 || isValidationFailure) {
    Sentry.captureException(apiError);
  }

  throw apiError;
}
