import cx from 'classnames';
import Button from 'components/button';
import Icon from 'components/icon';
import { format as formatDate } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import * as React from 'react';
import styles from './cards.module.css';
import { Transaction } from 'resources/loan/account/types';
import OverdueLabel from 'components/overdue-label';
import Switch from 'components/switch';

interface DueDatesProps {
  transactions: Transaction[];
  onSchedulePaymentClick: (transaction: Transaction) => void;
  onEnableAutopayment: () => void;
}
export function DueDatesCard({
  transactions,
  onSchedulePaymentClick,
  onEnableAutopayment
}: DueDatesProps) {
  const paymentSchedule = transactions.map((transaction, i) => {
    const overdue =
      formatDate('YYYYMMDD', transaction.date) <
      formatDate('YYYYMMDD', new Date());
    return (
      <tr key={i}>
        <td>
          {overdue ? (
            <OverdueLabel />
          ) : (
            formatDate('MMM D YYYY', transaction.date)
          )}
        </td>
        <td>${formatNumber(transaction.paymentAmount, 2)}</td>
        <td>
          <Button
            type="button"
            className={styles.tableBtn}
            onClick={() => onSchedulePaymentClick(transaction)}
          >
            Schedule Payment
          </Button>
        </td>
      </tr>
    );
  });
  return (
    <div className={cx(styles.card, styles.payments)}>
      <div className={styles.header}>
        <h2 className={styles.title}>Future Due Dates</h2>

        <Switch
          className={styles.autopaymentSwitch}
          name="autopayment"
          label="Turn on autopayments"
          value={false}
          onChange={onEnableAutopayment}
        />
      </div>
      <div className={styles.content}>
        <table>
          <thead>
            <tr>
              <th>
                <Icon type="date" /> Date
              </th>
              <th>
                <Icon type="debt" /> Amount
              </th>
              <th>
                <Icon type="edit-file" /> Action
              </th>
            </tr>
          </thead>
          <tbody>{paymentSchedule}</tbody>
        </table>
      </div>
    </div>
  );
}
