import cx from 'classnames';
import Status from 'components/autopayment-status';
import Button from 'components/button';
import PaymentMethodList from 'components/payment-method-list';
import ContactSupportModal from 'components/support/modal';
import { InformationModal } from 'components/modal/information-modal';
import TillModal from 'components/modal';
import AutoPay from 'components/autopay';
import config from 'helpers/config';
import { format as formatDate } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import { useAuthentication } from 'helpers/useAuthentication';
import { usePaymentProfiles } from 'helpers/usePaymentProfiles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  addPaymentProfile,
  selectPaymentProfile,
  loadAccountsByCustomer,
  loadPaymentToken,
  submitAutopayment,
  submitAutopaymentCancellation
} from 'resources/flexpay/account/account.actions';
import getAccountDetails, {
  getNewPaymentMethod,
  getSelectedPaymentMethodId,
  getBankPaymentMethods
} from 'resources/flexpay/account/account.selectors';
import { PaymentProfile } from 'resources/flexpay/account/types';
import { getUserAccount } from 'resources/user/user.selectors';
import baseStyles from 'styles/base.module.css';
import styles from './styles.module.css';
import { ROUTES } from 'routes';
import { push } from 'connected-react-router';
import Icon from 'components/icon';

function ManagePayments() {
  useAuthentication();
  const dispatch = useThunkDispatch();
  const [isPaymentSuccessful, setPaymentSuccessful] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );
  const [isAutopayModalOpen, setAutopayModalOpen] = React.useState(false);
  const [isAutopaySubmitting, setAutopaySubmitting] = React.useState(false);
  const [isAutopaySuccessful, setAutopaySuccessful] = React.useState(false);
  const [
    isAutopayCancelSubmitting,
    setAutopayCancelSubmitting
  ] = React.useState(false);
  const [
    isAutopayCancelSuccessful,
    setAutopayCancelSuccessful
  ] = React.useState(false);

  const { userId, email } = useSelector(getUserAccount);
  const paymentMethods = useSelector(getBankPaymentMethods);
  const account = useSelector(getAccountDetails);
  const newPaymentMethod = useSelector(getNewPaymentMethod);
  const selectedPaymentMethodId = useSelector(getSelectedPaymentMethodId);

  React.useEffect(() => {
    (async () => {
      dispatch(loadPaymentToken());
    })();
  }, [dispatch]);

  React.useEffect(() => {
    (async () => {
      try {
        if (userId.length) {
          await dispatch(loadAccountsByCustomer(userId));
        } else {
        }
      } catch (error) {}
    })();
  }, [dispatch, userId]);

  usePaymentProfiles();

  const handlePaymentProfileEvent = (
    status?: number,
    accountToken?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => {
    if (status === 200 && accountToken && accountTitle && accountType) {
      (async () => {
        await dispatch(
          addPaymentProfile(userId, accountType, { accountToken, accountTitle })
        );
      })();
    }
  };

  const onCloseAutopayModal = () => {
    setAutopayModalOpen(false);
  };

  const onCloseErrorModal = () => {
    setErrorMessage(undefined);
  };

  const onCloseSuccessModal = () => {
    setPaymentSuccessful(false);
  };

  const onCloseAutopaySuccessModal = () => {
    setAutopaySuccessful(false);
    setAutopayModalOpen(false);
  };

  const onCloseAutopayCancellationModal = () => {
    setAutopayCancelSuccessful(false);
    setAutopayModalOpen(false);
  };

  const onCloseAutopayCancelSuccessModal = () => {
    setAutopayCancelSuccessful(false);
    setAutopayModalOpen(false);
  };

  const handlePaymentMethodSelect = (
    userId: string,
    paymentProfileId: number
  ) => {
    return dispatch(selectPaymentProfile(userId, paymentProfileId));
  };

  const submitAutopay = (
    amount: number,
    date: Date,
    paymentProfile: PaymentProfile,
    autopayType: string,
    paymentFrequency: string
  ) => {
    (async () => {
      try {
        setAutopaySubmitting(true);
        await dispatch(
          submitAutopayment({
            amount,
            date,
            info: `Recurring payment scheduled on ${date}`,
            loanId: account.id,
            paymentProfileId: paymentProfile.paymentProfileId,
            paymentProfileType: paymentProfile.type,
            autopayType,
            paymentFrequency
          })
        );
        setAutopaySuccessful(true);
      } catch (error) {
        setErrorMessage(
          error.message ||
            'We were unable to complete setting up your autopayments'
        );
      }
      setAutopaySubmitting(false);
    })();
  };

  const confirmCancelAutopay = () => {
    (async () => {
      try {
        setAutopayModalOpen(false);
        setAutopayCancelSubmitting(true);
        await dispatch(submitAutopaymentCancellation(account.id));
        setAutopayCancelSuccessful(true);
      } catch (err) {
        setErrorMessage(
          err.message || 'We were unable to cancel your autopayments'
        );
      }
      setAutopayCancelSubmitting(false);
    })();
  };

  const status: 'autopaymentsOn' | 'autopaymentsOff' = account.autopayEnabled
    ? 'autopaymentsOn'
    : 'autopaymentsOff';

  let activeMethod;
  if (selectedPaymentMethodId && paymentMethods.length > 0) {
    activeMethod = paymentMethods.find(
      v => v.paymentProfileId === selectedPaymentMethodId
    );
  }

  const nextPayment =
    account.scheduledPayments.length === 0
      ? 'No scheduled payments'
      : `Next Payment of $${formatNumber(
          account.scheduledPayments[0].paymentAmount,
          2
        )} ${
          activeMethod
            ? 'will be withdrawn from ' + activeMethod.accountNumber + ' on'
            : 'is scheduled for'
        } ${formatDate('MMMM DD, YYYY', account.scheduledPayments[0].date)}.`;

  interface ConfirmationModalProps {
    isAutopayCancelSubmitting: boolean;
    onClose: () => void;
  }
  function ConfirmationModal({ onClose }: ConfirmationModalProps) {
    return (
      <TillModal visible className={styles.confirmationModal} onClose={onClose}>
        <div className={styles.content}>
          <h1 className={styles.modalTitle}>Turn Off Autopay</h1>
          <p className={styles.message}>
            By selecting "Yes, turn off", you will turn off all scheduled
            autopays. Are you sure you would like to do this?
          </p>
          <p className={styles.message}>
            To speak to an account manager, please call 678-506-0269
          </p>
          <div className={styles.buttons}>
            <Button type="link" onClick={onClose}>
              No, keep active
            </Button>
            <Button type="link" onClick={confirmCancelAutopay}>
              Yes, turn off
            </Button>
          </div>
        </div>
      </TillModal>
    );
  }

  const successAutopayModalInfo = {
    title: 'Success!',
    message: `We have received your scheduled autopayments. We will send you an email with a
    confirmation number to ${email} as soon as it's been processed.`
  };

  const successCancelAutopayModalInfo = {
    title: 'Confirmed',
    message: `Autopayments successfully turned off. To turn them back on, please select "Turn on autopayments" and follow the setup process.`
  };

  const addPaymentMethod = () => {
    dispatch(push(ROUTES.flex.addPaymentMethod));
  };

  return (
    <div>
      <h1 className={cx(styles.title, styles.layout)}>
        Payment Method <br />
        <Status className={styles.status} type={status} />
      </h1>
      <div className={styles.layout}>
        <label
          className={cx(baseStyles.smVisible, styles.autopaymentLabel, {
            [styles.hidden]: account.autopayEnabled
          })}
        ></label>
        <div className={cx(baseStyles.row, styles.row)}>
          <div className={cx(styles.nextPayment)}>
            <div className={styles.header}>
              <div className={styles.title}>{nextPayment}</div>
            </div>
          </div>
        </div>
        <div>
          <h2 className={styles.title}>
            Available Methods
            <Button
              type="button"
              onClick={addPaymentMethod}
              className={cx(styles.addMethodBtn, baseStyles.smHidden)}
            >
              <Icon className={styles.icon} type="plus" />
              Add New Payment Method
            </Button>
          </h2>
        </div>
        <div className={cx(baseStyles.row, styles.row, styles.methods)}>
          <PaymentMethodList
            className={cx(styles.list)}
            userId={userId}
            paymentMethods={paymentMethods}
            selectedPaymentMethodId={selectedPaymentMethodId}
            handlePaymentMethodSelect={handlePaymentMethodSelect}
          />
          <Button
            type="button"
            onClick={addPaymentMethod}
            className={cx(styles.addMethodBtn, baseStyles.smVisible)}
          >
            <Icon className={styles.icon} type="plus" />
            Add New Payment Method
          </Button>
        </div>
      </div>
      {isAutopayModalOpen &&
        (!account.autopayEnabled ? (
          <AutoPay
            min={config.autopayMinAmount}
            amount={account.nextPaymentAmount}
            max={account.loanAmount}
            date={new Date(account.nextPaymentDate)}
            confirmAutopay={submitAutopay}
            onClose={onCloseAutopayModal}
            isAutopaySubmitting={isAutopaySubmitting}
            visible
            methods={paymentMethods}
            frequency={account.paymentFrequency}
            debitIframeUrl={`https://securepayments.loanpro.io/api/capture-form/${account.oboToken}`}
            achIframeUrl={`https://securepayments.loanpro.io/api/check-capture-form/${account.oboToken}?account_type=checking`}
            handlePaymentProfileEvent={handlePaymentProfileEvent}
            selectedMethod={newPaymentMethod || paymentMethods[0]}
          />
        ) : (
          <ConfirmationModal
            isAutopayCancelSubmitting={isAutopayCancelSubmitting}
            onClose={onCloseAutopayCancellationModal}
          />
        ))}
      {errorMessage && (
        <ContactSupportModal
          onClose={onCloseErrorModal}
          supportPhoneNumber={config.supportPhoneNumber}
          message={errorMessage}
        />
      )}
      {isPaymentSuccessful && (
        <InformationModal
          onClose={onCloseSuccessModal}
          title={'Success!'}
          message={`We have received your payment request. We will send you an email with confirmation number to ${email} as soon as we have processed the payment.`}
        />
      )}
      {isAutopaySuccessful && (
        <InformationModal
          onClose={onCloseAutopaySuccessModal}
          title={successAutopayModalInfo.title}
          message={successAutopayModalInfo.message}
        />
      )}
      {isAutopayCancelSuccessful && (
        <InformationModal
          onClose={onCloseAutopayCancelSuccessModal}
          title={successCancelAutopayModalInfo.title}
          message={successCancelAutopayModalInfo.message}
        />
      )}
    </div>
  );
}

export default ManagePayments;
