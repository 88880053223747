import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'components/tabs';
import { getPath } from 'resources/router/router.selectors';
import { Store } from 'resources/types';
import pageStyles from '../../styles/main.module.css';
import ChangePassword from './change-password';
import PersonalData from './personal-data';
import { useAuthentication } from 'helpers/useAuthentication';

interface Props {
  path: string;
}

function Account({ path }: Props) {
  useAuthentication();

  return (
    <div className={pageStyles.page}>
      <Tabs selectedRoute={path} className={pageStyles.pageTabs}>
        <Tab route="/account/personal-data" title="Personal Information">
          <PersonalData />
        </Tab>
        <Tab route="/account/change-password" title="Change Password">
          <ChangePassword />
        </Tab>
      </Tabs>
    </div>
  );
}

export default connect((state: Store) => ({
  path: getPath(state)
}))(Account);
