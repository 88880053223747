import config from 'helpers/config';

type TokenType = 'accessToken';

const prefix = `auth:${config.deployEnv}`;
const storage = {
  get(key: TokenType) {
    return window.localStorage.getItem(`${prefix}:${key}`);
  },
  set(key: TokenType, value: string) {
    return window.localStorage.setItem(`${prefix}:${key}`, value);
  },
  remove(key: TokenType) {
    return window.localStorage.removeItem(`${prefix}:${key}`);
  }
};

export function getToken(key: TokenType) {
  return storage.get(key);
}

export function setToken(key: TokenType, value: string) {
  return storage.set(key, value);
}

export function clearTokens() {
  window.localStorage.removeItem('auth._token.local'); // For our Nuxt app
  storage.remove('accessToken');
}

// Can handle a bare JWT or a token in the header value format:
// Bearer eyJ...
const parseToken = (token: string) =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));

export function tokenIsExpired(token: string): boolean {
  const { exp: expiresAt } = parseToken(token);
  // returns true if the token is expired or will expire within the next 60 seconds
  return (expiresAt - 60) * 1000 < Date.now();
}
