import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { useUserData } from 'helpers/useUserData';
import { useScrollToTop } from 'helpers/useScrollToTop';
import { push } from 'connected-react-router';
import { ROUTES } from 'routes';

import { addPaymentProfile } from 'resources/flexpay/account/account.actions';
import { approveBudgetAndSaveApplication } from 'resources/flex/apply/apply.actions';
import { enrollBudgetAndSaveApplication } from 'resources/flexpay/acceptance/acceptance.actions';
import { getUserAccount, getApprovedFlexibleRentApplication } from 'resources/user/user.selectors'; // prettier-ignore
import { getBudgetAndSaveSchedule } from 'resources/flex/apply/apply.actions';
import { getScheduleDisplayData } from 'resources/flex/apply/apply.selectors';
import { PlaidAccount, PlaidInstitution } from 'resources/plaid/types';
import * as plaidSelectors from 'resources/plaid/plaid.selectors';
import * as plaidActions from 'resources/plaid/plaid.actions';

// UI Components
import Spinner from '../../components/spinner';
import Button from 'components/button';
import PaymentAccountSelect from 'components/payment-account-select';
import PciWalletIframe from 'components/pci-wallet-iframe/pci-wallet-iframe';
import ContactSupportModal from 'components/support/modal';
import rocket from '../../assets/rocket-2x.png';
import tillPlaidLogos from '../../assets/till-plaid-3x.png';
import linkBankGraphic from '../../assets/link-bank-2x.png';
import styles from './add-payment-styles.module.css';

interface LocationState {
  // After successfully adding a payment method, the user
  // will be redirected to this route if it exists
  redirectRoute?: string;
}

/**
 * A page for adding a payment method to your account
 */
const AddPaymentMethod = () => {
  const dispatch = useThunkDispatch();

  const { state } = useLocation<LocationState | undefined>();

  useUserData();
  useScrollToTop();

  const user = useSelector(getUserAccount);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);
  const showPciWallet = useSelector(plaidSelectors.showPciWallet);
  const schedule = useSelector(getScheduleDisplayData);

  const [linkedAccountData, setLinkedAccountData] = React.useState<any>();
  const [isBudgetAndSaveOnboarding, setIsBudgetAndSaveOnboarding] = React.useState<boolean>(false); // prettier-ignore
  const [invalidPaymentReason, setInvalidPaymentReason] = React.useState<any>(null); // prettier-ignore
  const [showAccountAdded, setShowAccountAdded] = React.useState<boolean>(false); // prettier-ignore
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [error, setError] = React.useState<boolean>(false);

  const enrollmentPaymentAmount = schedule && schedule.schedule && schedule.schedule.enrollmentPaymentAmount; // prettier-ignore
  const isSubmitButtonDisabled = !linkedAccountData || linkedAccountData.plaidItemId && !linkedAccountData.plaidAccountId // prettier-ignore

  const todaysPaymentAmount = () => {
    // @ts-ignore
    const { startingPayments } = schedule;

    if (schedule && startingPayments.length > 0) {
      // If the first payment falls today, sum this amount with the enrollment payment amount
      const today = new Date().toISOString().slice(0, 10);
      const firstStartingPayment = startingPayments[0].date;

      // prettier-ignore
      return today === firstStartingPayment
        ? (parseFloat(startingPayments[0].amount) + parseFloat(enrollmentPaymentAmount)).toFixed(2) 
        : enrollmentPaymentAmount;
    } else {
      return enrollmentPaymentAmount;
    }
  };

  React.useEffect(() => {
    (async () => {
      if (approvedApplication) {
        const { applicationType, status, paymentMethodInvalidReason, applicationId } = approvedApplication; // prettier-ignore

        // Route the user back to the budget and save schedule page
        status === 'schedule_expired' && dispatch(push(ROUTES.flexpay.rent));

        setIsBudgetAndSaveOnboarding(
          applicationType === 'BUDGET_AND_SAVE' &&
            (status === 'approved' || status === 'payment_method_invalid')
        );

        !schedule && (await dispatch(getBudgetAndSaveSchedule(applicationId)));

        approvedApplication.status === 'payment_method_invalid' &&
          setInvalidPaymentReason(paymentMethodInvalidReason);
      }
    })();
  }, [approvedApplication, dispatch, isBudgetAndSaveOnboarding, schedule]);

  const enrollBudgetAndSave = async (data?: any) => {
    if (approvedApplication) {
      const applicationId = approvedApplication.applicationId;
      const enrollmentData = { paymentProfileConfig: data };
      const paymentMethodInvalid = approvedApplication.status === 'payment_method_invalid' // prettier-ignore
      try {
        setIsLoading(true);
        if (paymentMethodInvalid) {
          // If the app status is "payment_method_invalid",
          // we first need to re-approve the app before enrolling
          await dispatch(approveBudgetAndSaveApplication({ applicationId }));
        }
        await dispatch(enrollBudgetAndSaveApplication(applicationId, enrollmentData)); // prettier-ignore
      } catch (err) {
        setError(true);
      }
      setIsLoading(false);
    } else {
      setError(true);
    }
  };

  const submitPaymentProfile = async (
    type: 'plaid' | 'debit' | 'ach',
    data: any
  ) => {
    const paymentProfileData =
      type === 'plaid'
        ? { plaidItemId: data.plaidItemId, plaidAccountId: data.account_id }
        : { accountToken: data.accountToken, accountTitle: data.accountTitle };

    try {
      // Add the payment profile
      await dispatch(addPaymentProfile(user.userId, type, paymentProfileData));
      // Show account added confirmation
      !isBudgetAndSaveOnboarding && setShowAccountAdded(true);
    } catch (err) {
      setError(true);
    }
  };

  // Plaid submit
  const addPlaidAccount = async () => {
    try {
      setIsLoading(true);
      // Add the plaid account
      await submitPaymentProfile('plaid', linkedAccountData);

      state && state.redirectRoute
        ? dispatch(push(state.redirectRoute))
        : setIsLoading(false);
    } catch (err) {
      setError(true);
      setIsLoading(false);
    }
  };

  // Manually link bank account
  const addManuallyLinkedAccount = async (
    type: 'debit' | 'ach',
    token: string,
    title: string
  ) => {
    try {
      setIsLoading(true);

      // Save linked account data to state
      setLinkedAccountData({ accountToken: token, accountTitle: title });
      // Add manually linked account
      await submitPaymentProfile(type, { accountToken: token, accountTitle: title }); // prettier-ignore
      // Hide iframe modal
      dispatch(plaidActions.showPciWallet(false));

      state && state.redirectRoute
        ? dispatch(push(state.redirectRoute))
        : setIsLoading(false);
    } catch (err) {
      setError(true);
      setIsLoading(false);
    }
  };

  const budgetAndSaveScheduleContext = isBudgetAndSaveOnboarding && (
    <>
      {/* PAYMENT OVERVIEW */}
      <div className={styles.paymentOverview}>
        <hr />

        {schedule && todaysPaymentAmount() > 0 ? (
          <>
            <h3>Your first payment</h3>
            <div className={styles.enrollmentCard}>
              <img src={rocket} alt="Rocket Icon" />
              <h2>Today's payment to Till</h2>
              <span>${todaysPaymentAmount()}</span>
            </div>
          </>
        ) : (
          <>
            <h3>Confirm enrollment</h3>
            <div className={styles.enrollmentCard}>
              <img src={rocket} alt="Rocket Icon" />
              <h2>
                No payment due today. You'll be able to see your next payments
                in your Till dashboard.
              </h2>
            </div>
          </>
        )}
        <span
          className={styles.viewMySchedule}
          onClick={e =>
            window.location.assign('/v/apply/set-schedule?=reviewSchedule')
          }
        >
          Review My Rent Schedule
        </span>

        {// Show this to the user to confirm they're manually linked account will be charged
        linkedAccountData && linkedAccountData.accountTitle && (
          <span className={styles.linkedAccount}>
            {enrollmentPaymentAmount > 0 ? (
              <> The following account will be charged ${enrollmentPaymentAmount}:{' '} </> // prettier-ignore
            ) : (
              <> The following account will be charged on your next payment date:{' '} </> // prettier-ignore
            )}
            <span>{linkedAccountData.accountTitle.split('added')[0]}</span>
          </span>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className={styles.container}>
        {invalidPaymentReason === 'balance_too_low' && (
          <div className={styles.invalidPaymentReason}>
            <span>Low Balance </span>
            <span>
              We are unable to accept your payment method due to a low balance.
              Please deposit funds into your account or change your payment
              method to complete your enrollment.
            </span>
          </div>
        )}

        {invalidPaymentReason === 'account_not_supported' && (
          <div className={styles.invalidPaymentReason}>
            <span>Invalid Payment Method</span>
            <span>
              We are unable to confirm your payment method. Please add a new
              payment method to complete your enrollment.
            </span>
          </div>
        )}

        <div className={styles.onboardingContainer}>
          <img src={tillPlaidLogos} alt="Till and Plaid" />
          <h1>Ok, let’s link your bank account instantly with Plaid.</h1>
          <img src={linkBankGraphic} alt="Link your bank account with Till" />
          <p>
            We partner with Plaid to help Till easily add your payment details
            to your account.
          </p>
          <hr />
        </div>

        <div className={styles.linkPaymentContainer}>
          <PaymentAccountSelect
            onItemSelection={(item?: PlaidInstitution) => {
              // Add the selected bank to state
              item && setLinkedAccountData({ ...linkedAccountData, plaidItemId: item.plaidItemId }); // prettier-ignore
            }}
            onAccountSelection={async (account?: PlaidAccount) => {
              // Add the selected account to state
              account && setLinkedAccountData({ ...linkedAccountData, plaidAccountId: account.account_id }); // prettier-ignore
            }}
            pageName={'Manage Payments'}
          />
        </div>

        {
          // Additional context the user needs on this page when arriving via the budget and save onboarding flow.
          budgetAndSaveScheduleContext
        }

        {// Conditional submit buttons
        isLoading ? (
          <Spinner size="large" tip="Adding account..." />
        ) : isBudgetAndSaveOnboarding ? (
          // In the budget and save flow this button will enroll users in the program (
          <Button
            type="primary"
            className={styles.submitButton}
            onClick={() => enrollBudgetAndSave(linkedAccountData)}
            disabled={isSubmitButtonDisabled}
          >
            Confirm Enrollment
          </Button>
        ) : (
          // Users that are already enrolled will see the "accountAdded" confirmation modal
          <Button
            type="primary"
            className={styles.submitButton}
            onClick={addPlaidAccount}
            disabled={isSubmitButtonDisabled}
          >
            Add Selected Account
          </Button>
        )}

        <div className={styles.troubleLinking}>
          <h3>Having trouble linking your bank?</h3>
          <p onClick={() => dispatch(plaidActions.showPciWallet(true))}>
            Enter account and routing number manually
          </p>
        </div>
      </div>

      {// Payment successfully added confirmation modal
      showAccountAdded && (
        <div className={styles.modalContainer}>
          <div className={styles.accountAdded}>
            <h3>Your payment account was successfully added!</h3>
            <p>
              To use this payment account to make payments, click Manage Payment
              Accounts.
            </p>
            <Button
              type="primary"
              className={styles.submitButton}
              onClick={() => dispatch(push(ROUTES.flexpay.payments))}
            >
              Manage Payment Accounts
            </Button>
            <Button
              type="primary"
              className={styles.submitButton}
              onClick={() => {
                setShowAccountAdded(false);
                setLinkedAccountData(undefined);
              }}
            >
              Add Another Payment Account
            </Button>
          </div>
        </div>
      )}

      {// Manually link bank account iframe
      showPciWallet && (
        <div className={styles.modalContainer}>
          <PciWalletIframe
            onCancelClick={() => dispatch(plaidActions.showPciWallet(false))}
            onAccountAdded={addManuallyLinkedAccount}
          />
        </div>
      )}

      {// Show error
      error && (
        <ContactSupportModal
          message="Sorry, we couldn't add that payment account. Please try another account or give us a call for help."
          onClose={() => setError(false)}
        />
      )}
    </>
  );
};

export default AddPaymentMethod;
