import cx from 'classnames';
import Button from 'components/button';
import { format as formatDate, diff as dateFromNow } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import * as React from 'react';
import { ScheduledPayment, Transaction } from 'resources/flexpay/account/types';
import { AutopayCard } from './autopay';
import styles from './account-summary.module.css';
import cardStyles from './cards.module.css';
import { DueDatesCard } from './due-dates';
import config from 'helpers/config';
import Icon from 'components/icon';
import { useThunkDispatch } from 'resources';
import { loadAutopays } from 'resources/loan/account/account.actions';
import { Subscription } from 'resources/user/types';

interface Props {
  pastDueAmountDue?: number;
  daysPastDue?: number;
  nextPaymentDate: Date;
  nextPaymentAmount?: number;
  onPayNowClick: () => void;
  autopays: ScheduledPayment[];
  activeSubscription?: Subscription;
}

const updateScheduleUrl =
  'https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=360001534052';

export function AccountSummaryCard({
  pastDueAmountDue = 0,
  daysPastDue = 0,
  nextPaymentDate,
  nextPaymentAmount,
  onPayNowClick,
  autopays,
  activeSubscription
}: Props) {
  /**
   * The Flexible Rent fee is represented as a charge due on the first of the month
   * in LoanPro. However, we don't actually make customers pay that amount on the
   * first of the month. The fee is broken down across their payment frequency
   * and they pay a prorated portion of it with each payment. Because we charge it
   * on the first of the month in LoanPro, the account will be past due
   * the day after that charge is posted, even though we don't require them to pay
   * it at that time. This logic will show a past due message to a customer if they have more
   * than $15 past due on their account. This will avoid (in most cases) showing customers
   * the fee amount as overdue.
   */
  const overdue = daysPastDue > 0 && pastDueAmountDue > 15;
  const formattedPaymentDate = formatDate('dddd, MMMM Do', nextPaymentDate);
  const daysToPayment = dateFromNow('days', nextPaymentDate, new Date()) + 1;
  const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

  const dispatch = useThunkDispatch();

  const calculateNextAutopayTotal = () => {
    let nextAutopayAmount = 0;
    autopays.forEach(({ amount, date }, i) => {
      date === autopays[0].date && (nextAutopayAmount += amount);
    });

    return formatNumber(nextAutopayAmount, 2);
  };
  React.useEffect(() => {
    if (activeSubscription)
      try {
        dispatch(loadAutopays(activeSubscription.subscriptionId));
      } catch (err) {
        setErrorMessage(true);
      }
  }, [dispatch, activeSubscription]);

  const getCardContent = () => {
    if (overdue) {
      // if the customer's payment is overdue
      return (
        <div>
          <p className={styles.emphasize}>
            Your account is {daysPastDue} {daysPastDue === 1 ? 'day' : 'days'}{' '}
            past due.
          </p>
          <Button
            type="primary"
            className={cx(cardStyles.btn, styles.btn, {
              [styles.overdue]: overdue
            })}
            onClick={onPayNowClick}
          >
            Pay now
          </Button>
        </div>
      );
    } else if (
      activeSubscription &&
      activeSubscription.isAutopayEnabled &&
      autopays.length
    ) {
      // if the customer has autopay setup
      return (
        <div>
          <p className={styles.emphasize}>Your account is setup for autopay!</p>
          <div className={styles.autopayDetails}>
            <p>
              We'll automatically withdraw{' '}
              <span className={styles.highlight}>
                {`$${nextPaymentAmount && calculateNextAutopayTotal()} `}
              </span>
              {nextPaymentDate ? (
                <>
                  in{' '}
                  <span className={styles.highlight}>{daysToPayment} days</span>
                  on {formattedPaymentDate}.
                </>
              ) : (
                'on your next scheduled payment date.'
              )}
            </p>
          </div>
          <hr />
          <a
            className={styles.summaryOption}
            href={updateScheduleUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="alarm" />
            <p>
              Make a change to your payment schedule. <Icon type="new-tab" />
            </p>
          </a>

          <div className={styles.summaryOption} onClick={onPayNowClick}>
            <Icon type="debt" />
            <p>
              Make a one-time payment in addition to your automatic payments.
            </p>
          </div>
        </div>
      );
    } else if (
      autopays.length === 0 &&
      activeSubscription &&
      activeSubscription.isAutopayEnabled
    ) {
      // if the customer has autopay setup and does not have any upcoming payments
      return (
        <div>
          <p className={styles.emphasize}>Your account is setup for autopay!</p>
          <div className={styles.autopayDetails}>
            <p>
              You have completed all of your payments this month! We will resume
              your scheduled payments{' '}
              {nextPaymentDate ? (
                <>
                  <span className={styles.highlight}>
                    {' '}
                    on {formattedPaymentDate}
                  </span>
                  .
                </>
              ) : (
                'on your next scheduled payment date.'
              )}
            </p>
          </div>
          <hr />
          <a
            className={styles.summaryOption}
            href={updateScheduleUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="alarm" />
            <p>
              Make a change to your payment schedule. <Icon type="new-tab" />
            </p>
          </a>

          <div className={styles.summaryOption} onClick={onPayNowClick}>
            <Icon type="debt" />
            <p>
              Make a one-time payment in addition to your automatic payments.
            </p>
          </div>
        </div>
      );
      // is autopay is enabled but there is an api error,
      // this means that the autopay data is out of sync with loanpro
    } else if (
      activeSubscription &&
      activeSubscription.isAutopayEnabled &&
      errorMessage
    ) {
      return (
        <div>
          <div className={styles.invalidAutopay}>
            <p className={cx(styles.typeHeading, styles.emphasize)}>
              {' '}
              We encountered an error retrieving your autopay information
            </p>
            <p>
              Plese contact customer support:{' '}
              <a href={`tel:${config.supportPhoneNumber}`}>
                {config.supportPhoneNumber}
              </a>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className={cx(styles.typeHeading, styles.emphasize)}>
            Your account is <u>NOT</u> setup for autopay!
          </p>
          {nextPaymentDate && nextPaymentAmount !== 0 && (
            <div>
              <div className={styles.autopayDetails}>
                <p>
                  A payment of{' '}
                  <span className={styles.highlight}>
                    {`$${nextPaymentAmount &&
                      formatNumber(nextPaymentAmount, 2)}`}
                  </span>{' '}
                  is due{' '}
                  {nextPaymentDate ? (
                    <>
                      in{' '}
                      <span className={styles.highlight}>
                        {daysToPayment} days
                      </span>
                      on {formattedPaymentDate}.
                    </>
                  ) : (
                    'on your next scheduled payment date.'
                  )}
                </p>
              </div>
              <Button
                type="primary"
                className={cx(cardStyles.btn, styles.btn)}
                onClick={onPayNowClick}
              >
                Pay now
              </Button>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div className={styles.summary}>{getCardContent()}</div>
      </div>
    </div>
  );
}

export const getPaymentCard = (
  scheduledPayments: Transaction[],
  autopays: ScheduledPayment[]
) => {
  if (autopays.length) {
    return (
      <AutopayCard
        autopays={autopays.map(autopay => ({
          frequency: autopay.recurringFrequency,
          amount: autopay.amount,
          account: autopay.paymentMethod.title,
          daysInPeriod: autopay.daysInPeriod,
          amountType: autopay.amountType,
          date: autopay.date,
          recurringPeriods: autopay.recurringPeriods,
          applyDate: autopay.applyDate,
          name: autopay.name,
          type: autopay.type
        }))}
      />
    );
  }
  return <DueDatesCard transactions={scheduledPayments} />;
};
