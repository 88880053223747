import * as React from 'react';
import cx from 'classnames';
import Icon from 'components/icon';

import styles from './overdue-alert.module.css';

interface Props {
  className?: string;
  days: number;
}

function OverdueAlert({ className, days }: Props) {
  const payNowStatement =
    days < 10 ? 'Pay Now to avoid late fees.' : 'Pay Now.';
  return (
    <div className={cx(styles.overdueAlert, className)}>
      <div className={styles.iconContainer}>
        <Icon type="expired" className={styles.icon} />
      </div>
      <div className={styles.content}>
        <div>
          Your payment is {days} {days === 1 ? 'day' : 'days'} past due.
        </div>
        <div>{payNowStatement}</div>
      </div>
    </div>
  );
}

export default OverdueAlert;
