import * as React from 'react';
import cx from 'classnames';

import styles from './status.module.css';

interface Props {
  className?: string;
  type: 'autopaymentsOn' | 'autopaymentsOff' | 'overdue';
}

function Status({ className, type }: Props) {
  let text = '';

  switch (type) {
    case 'autopaymentsOff': {
      text = 'Your account is NOT setup for autopay.';
      break;
    }
    case 'autopaymentsOn': {
      text = 'Your account is setup for autopay.';
      break;
    }
    case 'overdue': {
      text = 'Overdue payment';
      break;
    }
  }

  return (
    <div className={cx(styles.status, styles[type], className)}>{text}</div>
  );
}

export default Status;
