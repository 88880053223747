import * as React from 'react';
import PlaidItemLoginRequired from 'components/plaid-item-login-required';
import { useSelector } from 'react-redux';
import { getErrorCount } from 'resources/plaid/plaid.selectors';
import * as plaidSelectors from 'resources/plaid/plaid.selectors';
import { PlaidInstitution } from 'resources/plaid/types';
import { useThunkDispatch } from 'resources';
import * as plaidActions from 'resources/plaid/plaid.actions';
import PlaidLinkButton from './plaid-link';
import Button from 'components/button';

interface Props {
  selectedItem: PlaidInstitution;
}

/**
 * Handles all logic for displaying Plaid Errors when selecting
 * a payment account
 */
const PlaidErrors = ({ selectedItem }: Props) => {
  const plaidErrorCount = useSelector(getErrorCount);
  const plaidLastErrorName = useSelector(plaidSelectors.getLastErrorName);

  if (plaidErrorCount < 2) {
    switch (plaidLastErrorName) {
      case 'ITEM_LOGIN_REQUIRED':
        return <PlaidItemLoginRequired plaidLinkedBank={selectedItem} />;
      case 'INSTITUTION_NOT_RESPONDING':
        return <ErrorDisplay selectedItem={selectedItem} tryAgain />;
      default:
        return <ErrorDisplay selectedItem={selectedItem} tryAgain={false} />;
    }
  } else {
    // if there have been 2 or more errors, give the option to fallback to PCI wallet
    return <ErrorDisplay selectedItem={selectedItem} tryAgain={false} />;
  }
};

interface ErrorDisplayProps {
  selectedItem?: PlaidInstitution;
  tryAgain: boolean;
}

/**
 * Displays an error when Plaid bank accounts fail when loading
 */
const ErrorDisplay = ({ selectedItem, tryAgain }: ErrorDisplayProps) => {
  const dispatch = useThunkDispatch();

  const onPlaidLink = async (publicToken: string, metadata: object) => {
    try {
      await dispatch(plaidActions.linkItem({ publicToken, metadata }));
    } catch (err) {
      // do nothing on error, parent items will take care of that
    }
  };

  const onShowPciWallet = () => {
    dispatch(plaidActions.showPciWallet(true));
  };

  if (tryAgain) {
    return (
      <div>
        <p>
          {`We were unable to retrieve your ${
            selectedItem ? selectedItem.institutionName : 'bank'
          } accounts. Please try again.`}
        </p>
        <PlaidLinkButton
          isPrimary
          buttonText={'Try Again'}
          onSuccess={onPlaidLink}
        />
      </div>
    );
  } else {
    return (
      <div>
        <p>
          {`We are unable to retrieve your ${
            selectedItem ? selectedItem.institutionName : 'bank'
          } accounts. Please manually enter your routing and account number.`}
        </p>
        <Button type={'primary'} onClick={onShowPciWallet}>
          Manual Bank Account Entry
        </Button>
      </div>
    );
  }
};

export default PlaidErrors;
