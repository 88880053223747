import * as React from 'react';

export default function Trash() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5V4C8 2.895 8.895 2 10 2H12C13.105 2 14 2.895 14 4V5H18C18.552 5 19 5.448 19 6C19 6.552 18.552 7 18 7V17C18 18.657 16.657 20 15 20H7C5.343 20 4 18.657 4 17V7C3.448 7 3 6.552 3 6C3 5.448 3.448 5 4 5H8ZM10 5H12V4H10V5ZM6 17V7H16V17C16 17.552 15.552 18 15 18H7C6.448 18 6 17.552 6 17Z"
        fill="#475F7B"
      />
    </svg>
  );
}
