import * as React from 'react';
import cx from 'classnames';
import Selector from './selector';

import baseStyles from '../../styles/payment-method.module.css';
import styles from './payment-method.module.css';
import { PaymentProfile } from 'resources/flexpay/account/types';

interface MethodProps {
  selected: boolean;
  view: 'compact' | 'large';
}

function ACHComponent({
  view,
  title,
  bankName,
  selected,
  accountNumber = ''
}: MethodProps & PaymentProfile) {
  if (view === 'compact') {
    return (
      <div className={cx(styles.listItem, { [styles.selected]: selected })}>
        <div className={styles.radio} />
        <div className={styles.content}>
          <span>{bankName}</span>
          <span className={styles.accountNumber}>{accountNumber}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(baseStyles.card, styles.card, {
        [baseStyles.selected]: selected
      })}
    >
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <span>ACH Payments</span>
      <h3>{accountNumber}</h3>
      <Selector selected={selected} />
      <h6>{title}</h6>
    </div>
  );
}

export default ACHComponent;
