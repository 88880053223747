import * as React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { Spin } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from '../../../components/button';
import PaymentMethod from '../../../components/payment-method';
import styles from './styles.module.css';
import { ROUTES } from '../../../routes';
import {
  addPaymentProfile,
  loadAccountsByCustomer,
  loadPaymentProfiles,
  loadPaymentToken,
  makePayment
} from 'resources/flexpay/account/account.actions';
import { loadLoansByCustomer } from 'resources/loan/account/account.actions';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'resources/user/user.selectors';
import { useUserData } from 'helpers/useUserData';
import { useThunkDispatch } from 'resources';
import getFlexAccountState, {
  getAccountPayments,
  getAllPaymentMethods
} from 'resources/flexpay/account/account.selectors';
import { push } from 'connected-react-router';
import ContactSupportModal from 'components/support/modal';
import FormField from 'form-field';
import TillInput from '../../../components/form-input';
import PaymentOption from 'components/pay-now/payment-option';
import Icon from 'components/icon';
import Spinner from 'components/spinner';
import { PaymentProfile } from 'resources/flexpay/account/types';
import { formatDecimals } from 'helpers/formatDecimal';
import { useLocation } from 'react-router-dom';
import { getQueryVariable } from 'helpers/querystring';
import PciWalletIframe from 'components/pci-wallet-iframe/pci-wallet-iframe';

type PaymentScreenState =
  | 'loading'
  | 'default'
  | 'submitting'
  | 'confirm'
  | 'no-account'
  | 'success'
  | 'payment-method-select'
  | 'iframe';

type PaymentMethodType = 'ach' | 'debit';

interface SubmittedValues {
  amount: number;
  paymentMethod: PaymentProfile;
}

/**
 * Screen to provide a customer the ability to make a one-time payment
 * towards a Flexible Rent account
 */
const OneTimePayment = () => {
  const dispatch = useThunkDispatch();
  useUserData();

  const { search } = useLocation();

  const queryAmount = getQueryVariable(search, 'amount');
  const forceDebit = getQueryVariable(search, 'debit');

  const { userId } = useSelector(getUserAccount);
  const account = useSelector(getFlexAccountState);
  const paymentMethods = useSelector(getAllPaymentMethods);
  const { autopays, scheduledPayments } = useSelector(getAccountPayments);

  const [screenState, setScreenState] = React.useState<PaymentScreenState>(
    'loading'
  );
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentProfile>();
  const [amount, setAmount] = React.useState<string>();
  const [isAmountTouched, setIsAmountTouched] = React.useState(false);
  const [amountError, setAmountError] = React.useState<string>();
  const [submitAmount, setSubmitAmount] = React.useState<number>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isDifferentAmount, setDifferentAmount] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [submittedValues, setSubmittedValues] = React.useState<
    SubmittedValues
  >();
  const { creditDemo: isCreditDemo, useVueMakePaymentScreen } = useFlags();

  useVueMakePaymentScreen &&
    (queryAmount
      ? (window.location.href = `/v/rent/make-payment?amount=${queryAmount}`)
      : (window.location.href = '/v/rent/make-payment'));

  let nextPaymentAmount = account.nextPaymentAmount;

  if (autopays.length > 0) {
    nextPaymentAmount = autopays[0].amount;
  } else if (scheduledPayments.length > 0) {
    nextPaymentAmount = scheduledPayments[0].paymentAmount;
  }

  // need to do this formatting so we limit this to two decimal places and make it match other screens where we show this amount
  nextPaymentAmount = parseFloat(formatDecimals(nextPaymentAmount));

  React.useEffect(() => {
    if (queryAmount) {
      setAmount(queryAmount);
      setDifferentAmount(true);
    }
  }, [queryAmount]);

  React.useEffect(() => {
    setIsValid(
      (isDifferentAmount ? submitAmount !== undefined : true) &&
        paymentMethod !== undefined
    );
  }, [isDifferentAmount, submitAmount, paymentMethod]);

  React.useEffect(() => {
    if (isDifferentAmount) {
      const parsedAmount = parseFloat(amount || '');
      setSubmitAmount(
        isNaN(parsedAmount) ? undefined : parseFloat(parsedAmount.toFixed(2))
      );
    } else {
      setSubmitAmount(nextPaymentAmount);
    }
  }, [isDifferentAmount, nextPaymentAmount, amount]);

  React.useEffect(() => {
    if (
      userId.length &&
      (account.loanStatusText === 'Closed' ||
        account.loanStatusText === 'Loading')
    ) {
      setScreenState('no-account');
    }
  }, [userId, account]);

  React.useEffect(() => {
    (async () => {
      if (userId.length) {
        setScreenState('loading');
        try {
          await dispatch(loadLoansByCustomer(userId));
        } catch (error) {
          try {
            // this will load the user's flex account and payment profiles
            await dispatch(loadAccountsByCustomer(userId));
            await dispatch(loadPaymentProfiles(userId));
            await dispatch(loadPaymentToken());
          } catch (error) {
            setErrorMessage(`Unfortunately, we couldn't find your account`);
          }
        }
        setScreenState('default');
      }
    })();
  }, [dispatch, userId]);

  const onTouchAmount = () => {
    setIsAmountTouched(true);
  };

  const onChangeAmount = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(target.value);
    if (target.value === '') {
      setAmountError('Amount is required');
      setAmount(target.value);
    } else if (isNaN(value)) {
      setAmountError('Amount must be a number');
    } else if (value <= 0) {
      setAmountError('Amount must be greater than 0');
      setAmount(target.value);
    } else {
      setAmountError(undefined);
      setAmount(target.value);
    }
  };

  const clickedApplyNow = () => {
    dispatch(push(ROUTES.apply.basePath));
  };

  const submitPayment = async () => {
    (async () => {
      try {
        setScreenState('submitting');

        // right now, only same-day payments can be made,
        const date = new Date();

        if (paymentMethod && submitAmount) {
          setSubmittedValues({
            amount: submitAmount,
            paymentMethod
          });
          await dispatch(
            makePayment({
              amount: submitAmount,
              date,
              info: `One-time payment scheduled on ${date}`,
              loanId: account.id,
              paymentProfileId: paymentMethod.paymentProfileId,
              paymentProfileType: paymentMethod.type
            })
          );
          setScreenState('success');
        }
      } catch (error) {
        setErrorMessage(
          error.message || 'We were unable to complete your payment'
        );
        setScreenState('default');
      }
    })();
  };

  const onPaymentMethodSelect = (methodType: PaymentMethodType) => {
    if (methodType === 'debit') {
      setScreenState('iframe');
    } else {
      dispatch(
        push(ROUTES.flex.addPaymentMethod, {
          redirectRoute: ROUTES.flexpay.pay
        })
      );
    }
  };

  const addNewMethod = () => {
    if (forceDebit) {
      onPaymentMethodSelect('debit');
    } else {
      setScreenState('payment-method-select');
    }
  };

  const onCloseErrorModal = () => {
    setErrorMessage(undefined);
  };

  const onPaymentDetailsClick = () => {
    dispatch(push(ROUTES.flexpay.rent));
  };

  const onCloseConfirm = () => {
    setScreenState('default');
  };

  const onRecurringAmountClick = () => {
    setAmountError(undefined);
    setDifferentAmount(false);
  };

  const onDifferentAmountClick = () => {
    setDifferentAmount(true);
  };

  const onSubmitClick = () => {
    setScreenState('confirm');
  };

  const oniFrameCancelClick = () => {
    setScreenState('default');
  };

  const onCheckBalanceClick = (amount: number) => {
    const href = `/v/balance-check?amount_due=${amount}`;
    window.location.href = href;
  };

  const onRentProtectionEnroll = (amount: number) => {
    const href = `/v/rent-protection-enrollment?enrollment=true&amount_due=${amount}`;
    window.location.href = href;
  };

  const oniFrameAccountAdded = async (
    type: 'debit' | 'ach',
    token: string,
    title: string
  ) => {
    setScreenState('loading');
    try {
      await dispatch(
        addPaymentProfile(userId, type, {
          accountToken: token,
          accountTitle: title
        })
      );
      await dispatch(loadPaymentProfiles(userId));
    } catch (err) {
      setErrorMessage(
        `Unfortunately, we couldn't load your payment methods. Try refreshing the page.`
      );
    }
    setScreenState('default');
  };

  const methods = paymentMethods
    .filter(method =>
      forceDebit ? method.type === 'paymentAccount.type.credit' : true
    )
    .map(method => {
      const selected = paymentMethod
        ? paymentMethod.paymentProfileId === method.paymentProfileId
        : false;
      return (
        <PaymentMethod
          key={method.paymentProfileId}
          view="compact"
          method={method}
          selected={selected}
          onClick={(method: PaymentProfile) => setPaymentMethod(method)}
        />
      );
    });

  if (screenState === 'loading') {
    return (
      <div className={cx(styles.content, styles.spinnerContainer)}>
        <Spin size="large" tip="Loading account data..." />
      </div>
    );
  }

  if (screenState === 'payment-method-select') {
    return (
      <div className={styles.paymentMethodSelectContainer}>
        <p className={styles.paymentMethodQuestion}>
          What type of payment method would you like to add?
        </p>
        <Button onClick={() => onPaymentMethodSelect('debit')}>
          Debit Card
        </Button>
        <Button onClick={() => onPaymentMethodSelect('ach')}>
          Bank Account
        </Button>
        <Button type="link" onClick={() => setScreenState('default')}>
          Go Back
        </Button>
      </div>
    );
  }

  if (screenState === 'iframe') {
    return (
      <PciWalletIframe
        onCancelClick={oniFrameCancelClick}
        onAccountAdded={oniFrameAccountAdded}
        paymentMethodType={'debit'}
      />
    );
  }

  // confirmation screen for submitting payment
  if (screenState === 'confirm') {
    return (
      <div className={styles.content}>
        <h1 className={styles.modalTitle}>Confirm Payment</h1>
        <p className={styles.message}>
          {'By selecting "Confirm Payment", you acknowledge and agree that '}
          <a
            href={ROUTES.legal.electronicPaymentTerms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Electronic Payment Terms
          </a>
          {' apply to this transaction.'}
        </p>
        <div className={styles.paymentContainer}>
          <p>
            <span className={styles.fieldTitle}>Payment Amount:</span> $
            {submitAmount && formatDecimals(submitAmount)}{' '}
            {paymentMethod &&
              paymentMethod.type === 'paymentAccount.type.credit' && (
                <span className={styles.feeText}>+2.5% processing fee</span>
              )}
          </p>
          <p>
            <span className={styles.fieldTitle}>Payment Date:</span>{' '}
            {moment().format('MM/DD/YYYY')}
          </p>
          <p>
            <span className={styles.fieldTitle}>Payment Account:</span>{' '}
            {paymentMethod &&
              (paymentMethod.type === 'paymentAccount.type.credit'
                ? `${paymentMethod.cardType} ${paymentMethod.cardNumber}`
                : `${paymentMethod.bankName} ${paymentMethod.accountNumber}`)}
          </p>
        </div>
        <div className={styles.confirmPaymentButtons}>
          <Button
            className={styles.confirmPaymentButton}
            type="primary"
            onClick={submitPayment}
          >
            Confirm Payment
          </Button>
          <Button type="link" onClick={onCloseConfirm}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  // if a user navigates to this route and does not have an active account we give them the option to go apply
  if (screenState === 'no-account') {
    return (
      <div className={cx(styles.col, styles.content)}>
        <p>Unfortunately, we couldn't find your account.</p>
        <Button type="primary" onClick={clickedApplyNow}>
          Apply now
        </Button>
      </div>
    );
  }

  if (screenState === 'success') {
    return (
      <div className={cx(styles.paymentContainer, styles.content)}>
        <h1>Your one-time payment is complete.</h1>
        <p>
          <span className={styles.fieldTitle}>Payment Amount:</span> $
          {submittedValues && formatDecimals(submittedValues.amount)}{' '}
          {paymentMethod &&
            paymentMethod.type === 'paymentAccount.type.credit' && (
              <span className={styles.feeText}>+2.5% processing fee</span>
            )}
        </p>
        <p>
          <span className={styles.fieldTitle}>Payment Date:</span>{' '}
          {moment().format('MM/DD/YYYY')}
        </p>
        <p>
          <span className={styles.fieldTitle}>Payment Account:</span>{' '}
          {submittedValues && submittedValues.paymentMethod.bankName}
          {submittedValues && submittedValues.paymentMethod.accountNumber}
        </p>
        <Button onClick={onPaymentDetailsClick}>View Payment Details</Button>
      </div>
    );
  }

  // user has an open flex account, let them make a one-time payment
  return (
    <div className={styles.content}>
      <div>
        {screenState === 'submitting' && <Spinner size="large" fullScreen />}
        <h2 className={styles.header}>Flexible Rent Payment</h2>
        {!queryAmount && (
          <div className={styles.paymentOptions}>
            <PaymentOption
              index={1}
              disabled={screenState === 'submitting'}
              selected={!isDifferentAmount}
              title={'Pay Recurring Amount'}
              amount={nextPaymentAmount}
              className={styles.selectedOptionBtn}
              optionClassName={styles.selectedOption}
              triangleClassName={styles.selectedOptionTriangle}
              onSelect={onRecurringAmountClick}
            />

            <PaymentOption
              index={2}
              disabled={screenState === 'submitting'}
              selected={isDifferentAmount}
              title={'Pay Different Amount'}
              className={styles.selectedOptionBtn}
              optionClassName={styles.selectedOption}
              triangleClassName={styles.selectedOptionTriangle}
              onSelect={onDifferentAmountClick}
            />

            {isCreditDemo ? (
              <PaymentOption
                index={4}
                disabled={screenState === 'submitting'}
                title={'Check My Balance'}
                bodyText="To make sure you have enough $ to cover your rent"
                className={styles.selectedOptionBtn}
                optionClassName={styles.selectedOption}
                triangleClassName={styles.selectedOptionTriangle}
                onSelect={() => onCheckBalanceClick(nextPaymentAmount)}
              />
            ) : (
              ''
            )}

            {isCreditDemo ? (
              <PaymentOption
                index={5}
                disabled={screenState === 'submitting'}
                title={'Enroll In Rent Protection'}
                bodyText="Till will make sure your rent is paid on time"
                className={styles.selectedOptionBtn}
                optionClassName={styles.selectedOption}
                triangleClassName={styles.selectedOptionTriangle}
                onSelect={() => onRentProtectionEnroll(nextPaymentAmount)}
              />
            ) : (
              ''
            )}
          </div>
        )}
        <div className={styles.inputContainer}>
          <FormField touched={isAmountTouched} error={amountError}>
            <TillInput
              label="Amount"
              placeholder="$"
              name="amount"
              value={isDifferentAmount ? amount || '' : nextPaymentAmount}
              touched={isAmountTouched}
              onChange={onChangeAmount}
              setFieldTouched={onTouchAmount}
              valid={!amountError}
              disabled={!queryAmount && !isDifferentAmount}
            />
          </FormField>
          <FormField touched={false} error={undefined}>
            <TillInput
              label="Payment Date"
              name="paymentDate"
              value={moment().format('MM/DD/YYYY')}
              touched={false}
              onChange={() => {}}
              setFieldTouched={() => {}}
              valid={true}
              disabled={true}
            />
          </FormField>
        </div>

        <div className={styles.paymentMethods}>
          <label>Payment Method</label>
          <div className={styles.methods}>{methods}</div>
          <button
            type="button"
            className={cx(styles.button)}
            onClick={addNewMethod}
          >
            <Icon className={styles.icon} type="plus" />
            {forceDebit ? 'add debit card' : 'add new method'}
          </button>
        </div>
        <Button
          type="primary"
          className={styles.action}
          disabled={screenState === 'submitting' || !isValid}
          onClick={onSubmitClick}
        >
          Submit Payment
        </Button>
      </div>
      {errorMessage && (
        <ContactSupportModal
          onClose={onCloseErrorModal}
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default OneTimePayment;
