import * as React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import styles from './styles.module.css';
import { getPaymentToken } from 'resources/flexpay/account/account.selectors';
import { useSelector } from 'react-redux';
import config from 'helpers/config';
import Button from 'components/button';
import { useThunkDispatch } from 'resources';
import Spinner from '../spinner';
import { loadPaymentToken } from 'resources/flexpay/account/account.actions';
import { getUserAccount } from 'resources/user/user.selectors';
import ContactSupportModal from 'components/support/modal';

interface Props {
  // action to take when clicking the cancel button
  onCancelClick: () => void;
  // called when information is successfully entered and token is returned
  onAccountAdded: (type: 'debit' | 'ach', token: string, title: string) => void;
  // the type of account to add using the iframe (defaults to ach)
  paymentMethodType?: 'debit' | 'ach';
}

/**
 * Displays a PCI Wallet Iframe for entering in bank account credentials
 */
const PciWalletIframe = ({
  onCancelClick,
  onAccountAdded,
  paymentMethodType = 'ach'
}: Props) => {
  const dispatch = useThunkDispatch();

  const oboToken = useSelector(getPaymentToken);
  const { userId } = useSelector(getUserAccount);

  const [isPciLoading, setIsPciLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const iFrameUrl =
    paymentMethodType === 'debit'
      ? `${config.pciWallet.baseUrl}/api/capture-form/${oboToken}`
      : `${config.pciWallet.baseUrl}/api/check-capture-form/${oboToken}?account_type=checking`;

  // loads the obo token if it hasn't already been loaded
  React.useEffect(() => {
    (async () => {
      if (!oboToken) {
        try {
          setIsPciLoading(true);
          await dispatch(loadPaymentToken());
        } catch (error) {
          setErrorMessage('We ran into an issue loading this page.');
        }
        setIsPciLoading(false);
      } else {
        setIsPciLoading(false);
      }
    })();
  }, [dispatch, oboToken]);

  // handles response from iFrame
  React.useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      // formats and returns the account title
      function formatAccountTitle(data: any) {
        const bankName = get(
          data,
          'checking-account.bank_name',
          'Unknown Bank'
        );
        return `${bankName} Checking Account added ${moment().format(
          'MMM Do YYYY HH:mm:ss'
        )}`;
      }

      if (event.origin === config.pciWallet.baseUrl) {
        const { status, token, data, response } = event.data;

        if (status === 200) {
          onAccountAdded(
            data.cardType !== undefined ? 'debit' : 'ach',
            token,
            formatAccountTitle(data)
          );
        } else {
          const errorReason =
            (response &&
              response.context &&
              response.context.errors &&
              response.context.errors.split(':').pop()) ||
            '';

          setErrorMessage(`Unable to link bank account. ${errorReason}`);
        }
      }
    };

    if (userId && dispatch) {
      window.addEventListener('message', handleMessageEvent);
    }

    // Effect cleanup
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [dispatch, userId, onAccountAdded]);

  return errorMessage ? (
    <ContactSupportModal
      message={errorMessage}
      supportPhoneNumber={config.supportPhoneNumber}
      onClose={() => {
        setErrorMessage(undefined);
        onCancelClick();
      }}
    />
  ) : (
    <div>
      <h3 className={styles.heading}>
        Enter your bank information below to link your account to Till.
      </h3>
      {isPciLoading ? (
        <Spinner size="large" tip="Loading..." />
      ) : (
        <iframe
          title={paymentMethodType === 'debit' ? 'Debit Card' : 'Bank Account'}
          className={styles.paymentForm}
          frameBorder={0}
          src={iFrameUrl}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      )}
      <Button
        className={styles.cancelButton}
        type="link"
        onClick={onCancelClick}
      >
        Cancel
      </Button>
    </div>
  );
};

export default PciWalletIframe;
