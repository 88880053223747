import * as React from 'react';
import styles from './account-summary.module.css';

function InactiveAccountCard() {
  return (
    <div className={styles.inCollections}>
      <div className={styles.collectionsCard}>
        <div>
          <p>
            <strong>Your account is inactive.</strong>
          </p>
          <span>
            Till is no longer paying your rent. Pay off the balance below to
            bring your account to good standing.
          </span>
        </div>
      </div>
    </div>
  );
}

export default InactiveAccountCard;
