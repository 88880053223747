import * as React from 'react';
import Button from 'components/button';
import moment from 'moment';
import cx from 'classnames';
import config from '../../../../helpers/config';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  goToStep,
  getSchedule,
  saveSchedule
} from 'resources/flex/apply/apply.actions';
import {
  getFlexApplyState,
  getScheduleDisplayData
} from 'resources/flex/apply/apply.selectors';
import { getUserAccount } from 'resources/user/user.selectors';
import styles from './schedule.module.css';
import PaymentInfo from './paymentInfo';
import useApplicationStep from '../useApplicationStep';
import { PageState } from 'pages/types';
import ContactSupportModal from 'components/support/modal';

/**
 * Displays a user's Flexible Rent schedule
 * @param props.onSubmit The action to take when submitting data on this screen
 */
const Schedule = () => {
  const dispatch = useThunkDispatch();
  useApplicationStep();

  const user = useSelector(getUserAccount);
  const flexApplyState = useSelector(getFlexApplyState);
  const scheduleDisplayData = useSelector(getScheduleDisplayData);
  const [pageState, setPageState] = React.useState<PageState>('initial');
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const firstRecurringPaymentDate =
    scheduleDisplayData && scheduleDisplayData.firstRecurringPaymentDate;

  const onSubmit = () => {
    dispatch(saveSchedule());
  };

  const goBack = () => {
    dispatch(goToStep('/flex/apply/cash-flow'));
  };

  const onCloseErrorModal = () => setErrorMessage(undefined);

  React.useEffect(() => {
    (async () => {
      if (user && flexApplyState && pageState === 'initial') {
        setPageState('loading');

        try {
          await dispatch(getSchedule());
          setPageState('done');
        } catch (err) {
          setPageState('error');
        }
      }
    })();
  }, [dispatch, flexApplyState, user, pageState]);

  return (
    <>
      <h2 className={styles.heading}>Your proposed rent schedule</h2>
      <div className={styles.line} />
      <p className={styles.subheading}>PLEASE READ CAREFULLY</p>

      <p className={styles.mainCardFirstParagraph}>
        You’ll start paying rent to your landlord using your Flexible Rent
        schedule starting this{' '}
        <span className={styles.highlight}>
          {`${moment.utc(firstRecurringPaymentDate).format('MMMM')}.`}
        </span>
      </p>

      <PaymentInfo />

      <div className={cx(styles.buttonsContainer)}>
        <Button type="button" onClick={goBack}>
          ← Change schedule
        </Button>

        <Button type="primary" onClick={onSubmit}>
          Next
        </Button>
      </div>

      {errorMessage && (
        <ContactSupportModal
          onClose={onCloseErrorModal}
          supportPhoneNumber={config.supportPhoneNumber}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default Schedule;
