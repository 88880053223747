import * as React from 'react';

export default function Date() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46154 3V4.23077H4.84615C3.83173 4.23077 3 5.0625 3 6.07692V11.6154H4.23077V9.15385H17.7692V17.1538C17.7692 17.5 17.5 17.7692 17.1538 17.7692H4.84615C4.5 17.7692 4.23077 17.5 4.23077 17.1538V15.3077H3V17.1538C3 18.1683 3.83173 19 4.84615 19H17.1538C18.1683 19 19 18.1683 19 17.1538V6.07692C19 5.0625 18.1683 4.23077 17.1538 4.23077H16.5385V3H15.3077V4.23077H6.69231V3H5.46154ZM4.84615 5.46154H5.46154V6.69231H6.69231V5.46154H15.3077V6.69231H16.5385V5.46154H17.1538C17.5 5.46154 17.7692 5.73077 17.7692 6.07692V7.92308H4.23077V6.07692C4.23077 5.73077 4.5 5.46154 4.84615 5.46154ZM6.69231 10.3846V12.8462H3V14.0769H6.69231V16.5385L9.76923 13.4615L6.69231 10.3846Z"
        fill="#475F7B"
      />
    </svg>
  );
}
