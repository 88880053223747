import cx from 'classnames';
import Icon from 'components/icon';
import Switch from 'components/switch';
import { format as formatNumber } from 'helpers/number';
import * as React from 'react';
import styles from './cards.module.css';

interface Autopay {
  frequency: string;
  amountType: string;
  amount: number;
  daysInPeriod?: number;
  account?: string;
  applyDate?: string;
}
interface Props {
  autopays: Autopay[];
  onDisableAutopayment: () => void;
}
const getAutopayFrequency = ({ frequency, daysInPeriod }: Autopay) => {
  switch (frequency) {
    case 'autopay.recurringFrequency.biWeekly':
      return 'Every two weeks';
    case 'autopay.recurringFrequency.monthly':
      return 'Monthly';
    case 'autopay.recurringFrequency.semiMonthly':
      return 'Twice a month';
    case 'autopay.recurringFrequency.weekly':
      return 'Every week';
    case 'autopay.recurringFrequency.custom':
      return `Every ${daysInPeriod || 0} days`;
  }

  return 'Unknown';
};

const getAmount = ({ amountType, amount }: Autopay) => {
  return amountType === 'autopay.amountType.static' || 'autopay.amountType.nextDue' // prettier-ignore
    ? `$${formatNumber(amount, 2)}`
    : 'What is due';
};

export function AutopayCard({ autopays, onDisableAutopayment }: Props) {
  const paymentSchedule = autopays.map((autopay, i) => {
    return (
      <tr key={i}>
        <td>{getAutopayFrequency(autopay)}</td>
        <td>{getAmount(autopay)}</td>
        <td>{autopay.account || 'Unknown'}</td>
      </tr>
    );
  });
  return (
    <div className={cx(styles.card, styles.payments)}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>Scheduled Payments</h2>
          <span>Your autopayments are set</span>
        </div>
        <Switch
          className={styles.autopaymentSwitch}
          name="autopayment"
          label="Turn off autopayments"
          value={true}
          onChange={onDisableAutopayment}
        />
      </div>
      <div className={styles.content}>
        <table>
          <thead>
            <tr>
              <th>
                <Icon type="date" /> Frequency
              </th>
              <th>
                <Icon type="debt" /> Amount
              </th>
              <th>Account</th>
            </tr>
          </thead>
          <tbody>{paymentSchedule}</tbody>
        </table>
      </div>
    </div>
  );
}
