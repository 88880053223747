import * as React from 'react';

import styles from './styles.module.css';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export default function Burger({ isOpen, onClick }: Props) {
  return (
    <div className={styles.burger}>
      <input type="checkbox" checked={isOpen} onChange={onClick} />
      <span />
      <span />
      <span />
    </div>
  );
}
