import cx from 'classnames';
import Button from 'components/button';
import Icon from 'components/icon';
import { format as formatDate } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import * as React from 'react';
import OverdueAlert from '../overdue-alert';
import styles from './cards.module.css';
import { AutopayCard } from './autopay';
import { ScheduledPaymentsCard } from './scheduled-payments';
import { DueDatesCard } from './due-dates';
import {
  Transaction,
  ScheduledPayment,
  LoanAccountState
} from '../../../resources/loan/account/types';

interface Props {
  daysPastDue?: number;
  amount: number;
  nextPaymentDate: Date;
  onPayNowClick: () => void;
}

export function AccountSummaryCard({
  daysPastDue = 0,
  amount,
  nextPaymentDate,
  onPayNowClick
}: Props) {
  const overdue = daysPastDue > 0;
  const buttonText = overdue ? 'Pay now! Avoid late fees...' : 'Pay now';
  return (
    <div className={cx(styles.card)}>
      <h2 className={styles.title}>Account Summary</h2>
      <div className={styles.content}>
        <div className={styles.summary}>
          <div className={styles.item}>
            <Icon type="debt" className={styles.icon} />
            <div className={styles.desc}>
              <span>
                {overdue ? 'Your payment of:' : 'Your next payment of:'}
              </span>
              <span>${formatNumber(amount, 2)}</span>
            </div>
          </div>
          <div className={styles.item}>
            <Icon type="date" className={styles.icon} />
            <div className={styles.desc}>
              {overdue ? (
                <>
                  <span>Is:</span>
                  <span className={styles.overdue}>Due now</span>
                </>
              ) : (
                <>
                  <span>Is due on:</span>
                  <span>
                    {nextPaymentDate
                      ? formatDate('MMM D YYYY', nextPaymentDate)
                      : 'Unknown Date'}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <Button
            type="primary"
            className={cx(styles.btn, {
              [styles.overdue]: overdue
            })}
            onClick={onPayNowClick}
          >
            {buttonText}
          </Button>
          {overdue ? (
            <OverdueAlert
              className={styles.alert}
              days={Math.abs(daysPastDue)}
            />
          ) : (
            <div className={styles.paymentDetails}>
              <p>
                Did you know, you can save money by making an extra payment now?
                Pay Now with your Bank Account or Debit Card
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function NextPaymentCard({
  daysPastDue = 0,
  amount,
  nextPaymentDate,
  onPayNowClick
}: Props) {
  const overdue = daysPastDue > 0;
  const buttonText = 'Make an extra payment';
  return (
    <div className={styles.card}>
      <h2 className={styles.title}>Upcoming Payments</h2>
      <div className={styles.content}>
        <div className={styles.summary}>
          <div className={styles.item}>
            <Icon type="debt" className={styles.icon} />
            <div className={styles.desc}>
              <span>A payment of:</span>
              <span>${formatNumber(amount, 2)}</span>
            </div>
          </div>
          <div className={styles.item}>
            <Icon type="date" className={styles.icon} />
            <div className={styles.desc}>
              <span>Will be charged on:</span>
              <span>
                {nextPaymentDate
                  ? formatDate('MMM D YYYY', nextPaymentDate)
                  : 'Unknown Date'}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.payment}>
          <Button
            type="primary"
            className={cx(styles.btn, {
              [styles.overdue]: overdue
            })}
            onClick={onPayNowClick}
          >
            {buttonText}
          </Button>
          {overdue ? (
            <OverdueAlert
              className={styles.alert}
              days={Math.abs(daysPastDue)}
            />
          ) : (
            <div className={styles.paymentDetails}>
              <p>
                Did you know, you can save money by making an extra payment now?
                Pay Now with your Bank Account or Debit Card
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export const getSummaryCard = (
  loan: LoanAccountState,
  onPayNowClick: () => void
) => {
  const hasPendingPayment = loan.payments.filter(
    payment => payment.status === 'autopay.status.pending'
  ).length;
  if (hasPendingPayment) {
    return (
      <NextPaymentCard
        nextPaymentDate={loan.nextPaymentDate}
        amount={loan.nextPaymentAmount}
        onPayNowClick={onPayNowClick}
        daysPastDue={loan.daysPastDue}
      />
    );
  }
  return (
    <AccountSummaryCard
      nextPaymentDate={loan.nextPaymentDate}
      amount={loan.amountDue}
      onPayNowClick={onPayNowClick}
      daysPastDue={loan.daysPastDue}
    />
  );
};

export const getPaymentCard = (
  transactions: Transaction[],
  scheduledPayments: ScheduledPayment[],
  autopays: ScheduledPayment[],
  onEnableAutopayment: () => void,
  onSchedulePaymentClick: (transaction: Transaction) => void,
  onEditPaymentClick: (scheduledPayment: ScheduledPayment) => void
) => {
  if (autopays.length) {
    return (
      <AutopayCard
        autopays={autopays.map(autopay => ({
          frequency: autopay.recurringFrequency,
          amount: autopay.amount,
          account: autopay.paymentMethod.title,
          daysInPeriod: autopay.daysInPeriod,
          amountType: autopay.amountType
        }))}
        onDisableAutopayment={onEnableAutopayment}
      />
    );
  }
  if (scheduledPayments.length) {
    return (
      <ScheduledPaymentsCard
        payments={scheduledPayments.map(payment => ({
          id: payment.id,
          date: new Date(payment.date),
          amount: payment.amount
        }))}
        onEditPaymentClick={onEditPaymentClick}
        onEnableAutopayment={onEnableAutopayment}
      />
    );
  }
  return (
    <DueDatesCard
      transactions={transactions}
      onEnableAutopayment={onEnableAutopayment}
      onSchedulePaymentClick={onSchedulePaymentClick}
    />
  );
};
