import axios from 'axios';
import config from 'helpers/config';
import { getToken, setToken, clearTokens, tokenIsExpired } from 'helpers/token';

const refreshUrl = `${config.apiUrl}/refresh`;
const ignoreUrls = [refreshUrl, `${config.apiUrl}/login`];

axios.interceptors.request.use(
  /**
   * Intercept requests to refresh the access token, if necessary, before
   * making the request
   */
  async function(config) {
    if (!config.url || ignoreUrls.includes(config.url)) {
      return config;
    }

    const accessToken = getToken('accessToken');
    const isExpired = accessToken && tokenIsExpired(accessToken);
    if (!accessToken || isExpired) {
      console.debug(
        `refresh auth reason: ${
          !accessToken ? 'no access token' : 'token is expired'
        }`
      );

      await handleRefreshAuth();
      // handleRefreshAuth has updated the token for future requests,
      // but we also need to update the current request's auth header
      config.headers['authorization'] = `Bearer ${getToken('accessToken')}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

let _refreshPromise: Promise<void> | null = null;

function performRefresh(): Promise<void> {
  _refreshPromise = new Promise<void>(resolve => {
    console.debug('refreshing access token');
    axios(refreshUrl, {
      method: 'get',
      withCredentials: true
    })
      .then(({ data }) => {
        if (data.accessToken) {
          setToken('accessToken', data.accessToken);
          console.debug('access token refreshed');
        } else {
          throw new Error('Could not refresh access token');
        }
      })
      .catch(err => {
        console.error(err);
        return clearTokens();
      })
      .then(() => {
        _refreshPromise = null;
        resolve();
      });
  });

  return _refreshPromise;
}

function handleRefreshAuth() {
  if (_refreshPromise) {
    return _refreshPromise;
  }
  return performRefresh();
}
