import * as Yup from 'yup';
import config from 'helpers/config';

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is in incorrect format')
    .required('Email is required')
});

export const isValidSSN = (value: any) => {
  if (typeof value !== 'string') {
    return false;
  }

  if (!value.match(/^\d{3}-?\d{2}-?\d{4}$/)) {
    return false;
  }

  const segments = value.split('-').map(value => parseInt(value, 10));

  // None of the SSN segments can be all zeroes
  if (segments.some(segment => segment === 0)) {
    return false;
  }

  // First segment can't be 666 or 900-999
  if (segments[0] === 666 || (segments[0] >= 900 && segments[0] <= 999)) {
    return false;
  }

  return true;
};

export const ssnSchema = () =>
  Yup.string().test(
    'is-valid-ssn',
    'Please enter a valid Social Security Number',
    isValidSSN
  );

export const accountSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dateOfBirth: Yup.date().max(new Date(), 'Please choose a date in the past'),
  email: Yup.string()
    .email('Email is in incorrect format')
    .required('Email is required'),
  phone: Yup.string()
    .matches(config.phoneRegExp, 'Phone number is in incorrect format')
    .required('Phone number is required'),
  rentalAddress: Yup.object().shape({
    address1: Yup.string().required('Please enter your address'),
    address2: Yup.string(),
    zip: Yup.string().required('Please enter your zip code'),
    city: Yup.string().required('Please enter your city'),
    state: Yup.string().required('Please enter your state')
  })
});
