import * as React from 'react';
import cx from 'classnames';
import baseStyles from 'styles/base.module.css';
import formStyles from 'styles/form.module.css';

interface Props {
  children: React.ReactChild | React.ReactChild[];
  error?: string;
  touched?: boolean;
  tooltipMessage?: string;
}

/**
 * Component for handling styling and error display on a form field
 */
const FormField = ({ children, touched, error }: Props) => {
  return (
    <div>
      <div className={cx(baseStyles.row, formStyles.inputContainer)}>
        <div className={cx(baseStyles.col, formStyles.inputCol)}>
          {children}
        </div>
      </div>

      {touched && error && (
        <div className={baseStyles.row}>
          <div className={cx(baseStyles.col, formStyles.inputCol)}>
            <span className={formStyles.error}>{error}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormField;
