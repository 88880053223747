import { useAuthentication } from 'helpers/useAuthentication';
import * as React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  getCommunity,
  getSearchResults
} from 'resources/community/community.selectors';
import { getFlexApplyState } from 'resources/flex/apply/apply.selectors';
import UseApplicationStep from '../useApplicationStep';
import { CommunitySearchResult } from 'resources/community/types';
import { searchCommunity } from 'resources/community/community.action';
import { debounce } from 'lodash';
import TillInput from 'components/form-input';
import styles from './community.module.css';
import CommunityCard from './community-card';
import baseStyles from 'styles/base.module.css';
import applyStyles from '../apply.module.css';
import formStyles from 'styles/form.module.css';
import { Spin } from 'antd';
import Button from 'components/button';
import Icon from 'components/icon';
import {
  denyRentRollMatch,
  saveCommunity
} from 'resources/flex/apply/apply.actions';
import {
  getRentRollMatches,
  getSelectedCommunity
} from 'resources/flex/apply/apply.selectors';
import * as CommunityTypes from '@hellotill/community-types';

/**
 * The community match screen of the Flexible Rent application
 */
const Community = () => {
  useAuthentication();
  UseApplicationStep();

  const ref = React.useRef<HTMLDivElement>(document.createElement('div'));
  const executeScroll = () => ref.current && ref.current.scrollIntoView();

  const dispatch = useThunkDispatch();

  const searchResults = useSelector(getSearchResults);
  const community = useSelector(getCommunity);
  const selectedApplicationCommunity = useSelector(getSelectedCommunity);
  const rentRollMatches = useSelector(getRentRollMatches);
  const flexApplyState = useSelector(getFlexApplyState);

  const previouslySelectedCommunity = selectedApplicationCommunity || community;

  const [isLoading, setLoading] = React.useState(false);
  const [selectedCommunity, setSelectedCommunity] = React.useState<
    Partial<CommunityTypes.Community> | undefined
  >(selectedApplicationCommunity || community);

  React.useEffect(() => {
    dispatch(denyRentRollMatch());
  }, [dispatch]);

  const search = React.useCallback(
    debounce(
      async (name: string) => {
        try {
          await dispatch(searchCommunity(name));
          executeScroll();
        } finally {
          setLoading(false);
        }
      },
      250,
      { trailing: true }
    ),
    [dispatch]
  );

  const onCommunityNameChange = (event: React.ChangeEvent<any>) => {
    setLoading(true);
    const name = event.target.value;
    search(name);
  };

  const onSelectCommunity = (community: CommunitySearchResult) => {
    setSelectedCommunity(community);
  };

  const goBack = () => {
    window.location.assign('/flex/apply/match');
  };

  const onNextClick = () => {
    if (selectedCommunity && flexApplyState) {
      dispatch(
        saveCommunity({
          communityData: selectedCommunity,
          isUnmatched: flexApplyState && flexApplyState.community.isUnmatched
        })
      );
    }
  };

  const onNoMatch = () => {
    dispatch(
      saveCommunity({
        communityData: undefined,
        isUnmatched: true
      })
    );
  };

  return (
    <div className={styles.container}>
      <h1>Your Community</h1>

      {previouslySelectedCommunity && (
        <div className={styles.previouslySelectedCommunity}>
          <div className={styles.previousSelectionContainer}>
            <div>
              <h3 className={styles.communityName}>
                {previouslySelectedCommunity.name}
              </h3>
              <p className={styles.streetAddress}>
                {previouslySelectedCommunity.address1}
              </p>
              <p className={styles.cityStateZip}>
                {previouslySelectedCommunity.city}
                {', '}
                {previouslySelectedCommunity.state}{' '}
                {previouslySelectedCommunity.postalCode}
              </p>
            </div>
            <div className={cx(styles.iconContainer)}>
              <Icon type="check" className={cx(styles.icon, styles.selected)} />
            </div>
          </div>
        </div>
      )}

      {community &&
      community.communityId &&
      community.communityId !== 'unmatched' ? (
        <h3>
          Search for a different community or click NEXT to keep the above
          selection.
        </h3>
      ) : (
        <h3>Search for and select the community you live in.</h3>
      )}

      <div className={baseStyles.row}>
        <div className={cx(baseStyles.col, styles.input)}>
          <TillInput
            label="Community Name"
            placeholder="Start typing to see a list"
            name="community"
            onChange={onCommunityNameChange}
            valid
            setFieldTouched={() => {}}
          />
        </div>
      </div>

      {isLoading ? (
        <Spin
          className={cx(
            styles.spin,
            baseStyles.col25,
            !isLoading && styles.hidden
          )}
          size="large"
        />
      ) : (
        searchResults.length > 0 && (
          <div className={styles.matches}>
            <div className={styles.communityCardMobileBreak} />
            {searchResults.map(community => (
              <div
                key={community.communityId}
                ref={ref}
                className={styles.communityCardContainer}
              >
                <CommunityCard
                  selected={
                    selectedCommunity !== undefined &&
                    community.communityId === selectedCommunity.communityId
                  }
                  community={community}
                  onCommunityClick={onSelectCommunity}
                />
                <div className={styles.communityCardMobileBreak} />
              </div>
            ))}
          </div>
        )
      )}

      <div
        className={cx(formStyles.actionContainer, applyStyles.actionContainer)}
      >
        {rentRollMatches && rentRollMatches.length === 1 && (
          <Button type="button" onClick={goBack}>
            Back
          </Button>
        )}
        <Button
          type="primary"
          onClick={onNextClick}
          disabled={!selectedCommunity}
        >
          Next
        </Button>
      </div>
      <p className={styles.noMatch} onClick={onNoMatch}>
        Don't see your community? <span>Click here to continue.</span>
      </p>
    </div>
  );
};

export default Community;
