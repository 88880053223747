import * as React from 'react';

export default function Toolbox() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.97202 0.727272C6.04982 0.727272 5.2937 1.48339 5.2937 2.40559V2.96503H2.4965C1.5437 2.96503 0.818176 3.80857 0.818176 4.76573V13.4677C0.818176 14.4292 1.5437 15.2727 2.50087 15.2727H14.7998C15.757 15.2727 16.4825 14.4248 16.4825 13.4677V4.7701C16.4825 3.80857 15.757 2.96503 14.8042 2.96503H12.007V2.40559C12.007 1.48339 11.2509 0.727272 10.3287 0.727272H6.97202ZM6.97202 1.84614H10.3287C10.6433 1.84614 10.8881 2.09089 10.8881 2.40558V2.96502H6.41258V2.40558C6.41258 2.09089 6.65734 1.84614 6.97202 1.84614ZM2.4965 4.08391H14.8042C15.0839 4.08391 15.3636 4.35489 15.3636 4.7701V7.44055H1.93706V4.76573C1.93706 4.35489 2.21678 4.08391 2.4965 4.08391ZM1.93706 8.55945H4.17482V9.67833H6.41258V8.55945H10.8881V9.67833H13.1259V8.55945H15.3636V13.4677C15.3636 13.8829 15.0839 14.1539 14.7998 14.1539H2.50087C2.21678 14.1539 1.93706 13.8829 1.93706 13.4677V8.55945Z"
        fill="#475F7B"
      />
    </svg>
  );
}
