import * as React from 'react';
import cx from 'classnames';
import Downshift from 'downshift';
import Arrow from './arrow';
import Icon from '../icon';

import styles from './select.module.css';
import formStyles from 'styles/form.module.css';

interface OptionProps<T> {
  value: T;
  disabled?: boolean;
  children: React.ReactChild | React.ReactChild[];
}

class Option<T> extends React.PureComponent<OptionProps<T>> {
  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

interface SelectProps<T> {
  className?: string;
  iconClassName?: string;
  label: string;
  placeholder?: string;
  name: string;
  value?: T;
  touched?: boolean;
  valid: boolean;
  setFieldValue: (field: string, value: T, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  children:
    | React.ReactElement<OptionProps<T>, typeof Option>
    | React.ReactElement<OptionProps<T>, typeof Option>[];
  icon?: React.ReactElement<{}, typeof Icon>;
  description?: string;
}

class Select<T> extends React.PureComponent<SelectProps<T>> {
  onChange = (selection: T) => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, selection);
  };

  onBlur = () => {
    const { name, setFieldTouched } = this.props;
    setFieldTouched(name);
  };

  render() {
    const {
      className = '',
      iconClassName = '',
      name,
      label,
      value,
      placeholder,
      children,
      valid,
      touched,
      icon,
      description
    } = this.props;

    return (
      <div className={cx(styles.select, className)}>
        <label htmlFor={name}>{label}</label>
        {description && <p className={formStyles.description}>{description}</p>}
        <Downshift onChange={this.onChange} onUserAction={this.onBlur}>
          {({ getToggleButtonProps, getItemProps, isOpen, selectedItem }) => {
            let label: React.ReactNode =
              value === undefined || value === null ? placeholder : value;

            React.Children.forEach(
              children,
              (child: React.ReactElement<OptionProps<T>, typeof Option>) => {
                if (child.props.value === value) {
                  label = child.props.children;
                }
              }
            );

            return (
              <div className={styles.container}>
                <button
                  className={cx(styles.button, {
                    [styles.open]: isOpen,
                    [styles.valid]: !isOpen && touched && valid,
                    [styles.error]: !isOpen && touched && !valid,
                    [styles.withIcon]: !!icon
                  })}
                  {...getToggleButtonProps()}
                >
                  {icon ? (
                    <span className={cx(styles.icon, iconClassName)}>
                      {icon}
                    </span>
                  ) : null}
                  {label}
                  <Arrow />
                </button>
                <div
                  className={cx(styles.listContainer, {
                    [styles.open]: isOpen
                  })}
                >
                  <div className={styles.list}>
                    {React.Children.map(
                      children,
                      (
                        child: React.ReactElement<OptionProps<T>, typeof Option>
                      ) => {
                        return (
                          <div
                            className={cx(styles.listItem, {
                              [styles.selected]:
                                selectedItem === child.props.value,
                              [styles.disabled]: !!child.props.disabled
                            })}
                            {...getItemProps({
                              item: child.props.value,
                              disabled: child.props.disabled
                            })}
                          >
                            {child}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}

export { Select, Option };
