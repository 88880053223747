import * as Yup from 'yup';

export default Yup.object().shape({
  email: Yup.string()
    .email('Email is in incorrect format')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password is too short')
    .required('Password is required')
});
