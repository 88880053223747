import * as React from 'react';

export default function Address() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7143 3C7.56696 3 5 5.56696 5 8.71429C5 9.59821 5.36161 10.567 5.86161 11.5893C6.36161 12.6161 7.01339 13.6875 7.66964 14.6652C8.97321 16.625 10.2723 18.2188 10.2723 18.2188L10.7143 18.7634L11.1563 18.2188C11.1563 18.2188 12.4554 16.625 13.7634 14.6652C14.4152 13.6875 15.067 12.6161 15.567 11.5893C16.067 10.567 16.4286 9.59821 16.4286 8.71429C16.4286 5.56696 13.8616 3 10.7143 3ZM10.7143 4.14286C13.2455 4.14286 15.2857 6.18304 15.2857 8.71429C15.2857 9.25 15.0045 10.1384 14.5402 11.0893C14.0759 12.0446 13.442 13.0804 12.8125 14.0357C11.7634 15.6071 11.0491 16.4732 10.7143 16.8929C10.3795 16.4732 9.66518 15.6071 8.61607 14.0357C7.98661 13.0804 7.35268 12.0446 6.88839 11.0893C6.42411 10.1384 6.14286 9.25 6.14286 8.71429C6.14286 6.18304 8.18304 4.14286 10.7143 4.14286ZM10.7143 6.3125L8.42857 7.83929V11H13V7.83929L10.7143 6.3125ZM10.7143 7.6875L11.8571 8.44643V9.85714H9.57143V8.44643L10.7143 7.6875Z"
        fill="#475F7B"
      />
    </svg>
  );
}
