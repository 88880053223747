import * as React from 'react';
import cx from 'classnames';
import buttonStyles from '../button/button.module.css';
import { PlaidInstitution } from 'resources/plaid/types';
import { useThunkDispatch } from 'resources';
import { useSelector } from 'react-redux';
import { PlaidLink } from 'react-plaid-link';
import { getItemLinkToken } from 'resources/plaid/plaid.selectors';
import {
  createLinkToken,
  removeItemLinkToken
} from 'resources/plaid/plaid.actions';

interface Props {
  plaidLinkedBank?: PlaidInstitution;
}

/**
 * Component which handles logic for an ITEM_LOGIN_REQUIRED error from Plaid
 * when attempting to retrieve a customers accounts at an institution
 *
 * See plaid.com/docs/#managing-item-states
 */
const PlaidItemLoginRequired = ({ plaidLinkedBank }: Props) => {
  const dispatch = useThunkDispatch();
  const linkToken = useSelector(getItemLinkToken);

  // triggered when Plaid link has been updated by the user
  const plaidLinkUpdated = () => {
    // removes the item link which triggers a refresh of account loading after the user reauthenticated
    dispatch(removeItemLinkToken());
  };

  // triggered when the Plaid link flow is exited
  const onPlaidLinkExit = async (err: any) => {
    console.log({ err });
    if (err && err.error_code === 'INVALID_LINK_TOKEN' && plaidLinkedBank) {
      // The link token is only good for a short amount of time (usually 30 minutes).
      // If the link token needs refreshed we will receive this error from Plaid
      // when exiting the modal
      // See https://plaid.com/docs/#create-link-token
      // This will refresh the link token so when the user attempts to re-link
      // it will be an active token
      await dispatch(createLinkToken(plaidLinkedBank.plaidItemId));
    }
  };

  return (
    <div>
      <p>
        {`${
          plaidLinkedBank ? plaidLinkedBank.institutionName : 'Your bank'
        } requires that you log in to continue.`}
      </p>
      <div>
        <PlaidLink
          style={{
            // hardcoding some styles here as overrides because PlaidLink sets some default styles
            padding: '12px 12px',
            outline: 'none',
            borderRadius: '8px',
            border: 'none',
            color: 'white',
            backgroundColor: 'var(--fill-accent-blue)'
          }}
          className={cx(buttonStyles.btn, buttonStyles.btnPrimary)}
          onSuccess={plaidLinkUpdated}
          onExit={onPlaidLinkExit}
          token={linkToken || ''} // this should always be created before this component is shown
        >
          {`Log in to ${
            plaidLinkedBank ? plaidLinkedBank.institutionName : 'your bank'
          }`}
        </PlaidLink>
      </div>
    </div>
  );
};

export default PlaidItemLoginRequired;
