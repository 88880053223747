import * as React from 'react';
import cx from 'classnames';
import styles from './plaid-link.styles.module.css';
import buttonStyles from '../button/button.module.css';
import { PlaidLink } from 'react-plaid-link';
import { useThunkDispatch } from 'resources';
import { createLinkToken } from 'resources/plaid/plaid.actions';
import { useSelector } from 'react-redux';
import { getLinkToken } from 'resources/plaid/plaid.selectors';
import { Spin } from 'antd';
import Button from 'components/button';
import ContactSupportModal from 'components/support/modal';
import AccountLinkHelpModal from './account-link-help/modal';
import * as plaidActions from 'resources/plaid/plaid.actions';

/**
 * type - determines the style to apply to the buttom
 * buttonText - the text to display on the PlaidLink button
 * onSucess - action to take on successful linking
 * onLinkClick - optional action to take when the PlaidLink is launched
 */
interface Props {
  isPrimary: boolean;
  buttonText: string;
  onSuccess: (token: string, metadata: object) => void;
  onLinkClick?: () => void;
}

/**
 * Sets custom styling and configuration on the PlaidLink component
 */
const PlaidLinkButton = ({
  isPrimary,
  buttonText,
  onSuccess,
  onLinkClick
}: Props) => {
  const dispatch = useThunkDispatch();

  const linkToken = useSelector(getLinkToken);

  const [isLoading, setIsLoading] = React.useState(false);
  const [hasTokenError, setHasTokenError] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openTroubleShootModal, setOpenTroubleShootModal] = React.useState(
    false
  );

  const linkManually = () => {
    dispatch(plaidActions.showPciWallet(true));
    setOpenTroubleShootModal(false);
  };

  const onEvent = (event: any, metadata: any) => {
    console.log({ event, metadata });
    const { error_code } = metadata;
    if (['INVALID_MFA'].includes(error_code)) {
      setOpenTroubleShootModal(true);
    }
  };

  const onPlaidLinkExit = async (err: any, metadata: any) => {
    console.log({ err });
    const { status } = metadata;
    if (status === 'institution_not_found') {
      setOpenTroubleShootModal(true);
    } else if (err && err.error_code === 'INVALID_CREDENTIALS') {
      setOpenTroubleShootModal(true);
    }
    if (err && err.error_code === 'INVALID_LINK_TOKEN') {
      // The link token is only good for a short amount of time (usually 30 minutes).
      // If the link token needs refreshed we will receive this error from Plaid
      // when exiting the modal
      try {
        setIsLoading(true);
        await dispatch(createLinkToken());
      } catch (err) {
        setHasTokenError(true);
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (!linkToken) {
        try {
          await dispatch(createLinkToken());
        } catch (err) {
          setHasTokenError(true);
        }
      } else {
        localStorage.setItem('link_token', linkToken);
      }
    })();
  }, [dispatch, linkToken]);

  const onErrorClick = () => {
    setOpenErrorModal(true);
  };

  const onErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const onFailedToLinkAccountModalClose = () => {
    setOpenTroubleShootModal(false);
  };

  if (isLoading) {
    return <Spin size="small" />;
  }

  if (hasTokenError) {
    return (
      <div>
        {isPrimary ? (
          <Button type="primary" onClick={onErrorClick}>
            {buttonText}
          </Button>
        ) : (
          <Button type="link" onClick={onErrorClick}>
            {buttonText}
          </Button>
        )}
        {openErrorModal && (
          <ContactSupportModal
            onClose={onErrorModalClose}
            message="Something went wrong, please try again later."
          />
        )}
      </div>
    );
  }

  if (openTroubleShootModal) {
    return (
      <div>
        <AccountLinkHelpModal
          onClose={onFailedToLinkAccountModalClose}
          linkManually={linkManually}
        />
      </div>
    );
  }

  return (
    <div>
      {linkToken && (
        <div className={styles.linkBankButtonContainer} onClick={onLinkClick}>
          <PlaidLink
            style={{
              // hardcoding some styles here as overrides because PlaidLink sets some default styles
              padding: `${isPrimary ? '0px 0px' : '12px 0px'}`,
              outline: 'none',
              borderRadius: '8px',
              border: 'none',
              color: `${isPrimary ? 'white' : 'var(--fill-accent-blue)'}`,
              backgroundColor: `${
                isPrimary ? 'var(--fill-accent-blue)' : 'transparent'
              }`
            }}
            className={cx(
              buttonStyles.btn,
              isPrimary && buttonStyles.btnPrimary,
              !isPrimary && buttonStyles.btnLink
            )}
            onSuccess={onSuccess}
            onExit={onPlaidLinkExit}
            token={linkToken || ''}
            onEvent={onEvent}
          >
            {buttonText}
          </PlaidLink>
        </div>
      )}
    </div>
  );
};

export default PlaidLinkButton;
