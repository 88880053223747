import * as React from 'react';
import { useSelector } from 'react-redux';
import config from 'helpers/config';
import cx from 'classnames';
import GridSelect from 'components/grid-select';
import { PlaidAccount, PlaidInstitution } from 'resources/plaid/types';
import { useThunkDispatch } from 'resources';
import { getUserAccount } from 'resources/user/user.selectors';
import * as plaidActions from 'resources/plaid/plaid.actions';
import * as plaidSelectors from 'resources/plaid/plaid.selectors';
import ContactSupportModal from 'components/support/modal';
import PlaidLinkButton from './plaid-link';
import styles from './styles.module.css';
import { Spin } from 'antd';
import { PageState } from 'pages/types';
import Button from 'components/button';
import PlaidErrors from './errors';
import { returnFromOauthVerification } from 'resources/flex/onboarding/onboarding.actions';

interface Props {
  onItemSelection: (item?: PlaidInstitution) => void;
  onAccountSelection: (account?: PlaidAccount) => void;
  defaultSelectedItem?: PlaidInstitution;
  defaultSelectedAccount?: PlaidAccount;
  pageName: string;
}

/**
 * Component which includes all logic for selecting a payment account from a linked institution
 */
export const PaymentAccountSelect = ({
  onItemSelection,
  onAccountSelection,
  defaultSelectedItem,
  defaultSelectedAccount
}: Props) => {
  const dispatch = useThunkDispatch();

  const { userId } = useSelector(getUserAccount);
  const items = useSelector(plaidSelectors.getLinkedItems);
  const lastLinkedItem = useSelector(plaidSelectors.getLastLinkedItem);
  const accounts = useSelector(plaidSelectors.getItemAccounts);
  const itemLinkToken = useSelector(plaidSelectors.getItemLinkToken);
  const lastErrorName = useSelector(plaidSelectors.getLastErrorName);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const [selectedItem, setSelectedItem] = React.useState<
    PlaidInstitution | undefined
  >(defaultSelectedItem);
  const [selectedAccount, setSelectedAccount] = React.useState<
    PlaidAccount | undefined
  >(defaultSelectedAccount);
  const [pageState, setPageState] = React.useState<PageState>('initial');
  const [accountsLoading, setAccountsLoading] = React.useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const oauth = urlParams.get('oauth');

  React.useEffect(() => {
    if (oauth) {
      dispatch(returnFromOauthVerification());
      urlParams.delete('oauth');
      window.history.pushState(
        {},
        '',
        `${window.location.pathname}?${urlParams}${window.location.hash}`
      );
    }
  }, [dispatch, oauth, urlParams]);

  // loads items for a user
  React.useEffect(() => {
    let mounted = true;
    (async () => {
      // we check for items to tell us if we've already loaded them
      if (userId) {
        setPageState('loading');
        try {
          if (mounted) {
            await dispatch(plaidActions.loadLinkedItems(userId));
          }
        } catch (err) {
          if (mounted) {
            setPageState('error');
            setErrorMessage('Unable to load your connected banks.');
          }
        }
        if (mounted) {
          setPageState('done');
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, [userId, dispatch]);

  // loads accounts for an item that was selected
  React.useEffect(() => {
    (async () => {
      if (userId && selectedItem && !itemLinkToken && !accounts) {
        setAccountsLoading(true);
        try {
          await dispatch(
            plaidActions.loadAccountsForItem(userId, selectedItem.plaidItemId)
          );
        } catch (err) {
          setErrorMessage(
            `Could not load accounts for ${selectedItem.institutionName}`
          );
        }
        setAccountsLoading(false);
      }
    })();
  }, [userId, selectedItem, itemLinkToken, accounts, dispatch]);

  // called when customer clicks the "try again" button when no accounts were found for their bank
  const onAccountsTryAgain = async () => {
    if (selectedItem) {
      setAccountsLoading(true);
      try {
        await dispatch(
          plaidActions.loadAccountsForItem(userId, selectedItem.plaidItemId)
        );
      } catch (err) {
        setErrorMessage(
          `Could not load accounts for ${selectedItem.institutionName}`
        );
      }
      setAccountsLoading(false);
    }
  };

  const onCloseSupportModal = () => {
    setErrorMessage(undefined);
    setSelectedItem(undefined);
  };

  const selectLastLinkedItem = React.useCallback(() => {
    if (lastLinkedItem !== selectedItem) {
      dispatch(plaidActions.removeItemLinkToken());
      dispatch(plaidActions.clearLoadedAccounts());
      setSelectedItem(lastLinkedItem);
      setSelectedAccount(undefined);
      onItemSelection(lastLinkedItem);
      onAccountSelection(undefined);
    }
  }, [
    dispatch,
    lastLinkedItem,
    onAccountSelection,
    onItemSelection,
    selectedItem
  ]);

  React.useEffect(() => {
    // auto-selects the last item a customer linked after the Plaid link step is complete
    if (lastLinkedItem) {
      selectLastLinkedItem();
    }
  }, [selectLastLinkedItem, lastLinkedItem]);

  const onInstitutionSelect = async (item: PlaidInstitution) => {
    if (item !== selectedItem) {
      dispatch(plaidActions.clearLastLinkedItem());
      dispatch(plaidActions.removeItemLinkToken());
      dispatch(plaidActions.clearLoadedAccounts());
      setSelectedItem(item);
      setSelectedAccount(undefined);
      onItemSelection(item);
      onAccountSelection(undefined);
    }
  };

  const onAccountSelect = (account: PlaidAccount) => {
    if (account !== selectedAccount) {
      setSelectedAccount(account);
      onAccountSelection(account);
    }
  };

  const getInstitutionTitle = (item: PlaidInstitution) => {
    return item.institutionName;
  };

  const getAccountTitle = (account: PlaidAccount) => {
    return account.official_name;
  };

  const getAccountSubtitle = (account: PlaidAccount) => {
    return `${account.subtype} account ending ${account.mask}`;
  };

  const onPlaidLink = async (publicToken: string, metadata: object) => {
    try {
      await dispatch(plaidActions.linkItem({ publicToken, metadata }));
    } catch (err) {
      setErrorMessage('Unable to link your account');
    }
  };

  const areItemsEqual = (
    item?: PlaidInstitution,
    otherItem?: PlaidInstitution
  ): boolean =>
    item !== undefined &&
    otherItem !== undefined &&
    item.institutionId === otherItem.institutionId;

  const areAccountsEqual = (
    account?: PlaidAccount,
    otherAccount?: PlaidAccount
  ): boolean =>
    account !== undefined &&
    otherAccount !== undefined &&
    account.account_id === otherAccount.account_id;

  switch (pageState) {
    case 'loading':
    case 'initial':
      return (
        <div className={styles.spinnerContainer}>
          <Spin size="large" />
        </div>
      );
    case 'error':
      return ContactSupportModal({
        message: errorMessage || '',
        supportPhoneNumber: config.supportPhoneNumber,
        onClose: () => onCloseSupportModal()
      });
    case 'done':
    default:
      return (
        <div>
          <div className={styles.linkBankContainer}>
            <h1 className={styles.stepHeading}>
              {items.length ? `1. Select your bank` : `1. Link your bank`}
            </h1>
            <p>
              {items.length
                ? "Select the bank you'd like to use for payments."
                : "To select your payment account, first link a bank you'd like to use for payments."}
            </p>

            <GridSelect<PlaidInstitution>
              items={items}
              defaultSelectedItem={selectedItem}
              areItemsEqual={areItemsEqual}
              onItemSelect={onInstitutionSelect}
              itemTitle={getInstitutionTitle}
            />

            <PlaidLinkButton
              isPrimary={!items.length}
              buttonText={items.length ? 'Link Another Bank' : 'Link Bank'}
              onSuccess={onPlaidLink}
            />
          </div>

          <div className={styles.selectAccountContainer}>
            <h1
              className={cx(
                styles.stepHeading,
                !selectedItem && styles.disabledText
              )}
            >
              2. Select payment account
            </h1>

            {accountsLoading && (
              <div className={styles.spinnerContainer}>
                <Spin size="large" />
              </div>
            )}

            {!accountsLoading && (
              <div>
                {selectedItem ? (
                  lastErrorName ? (
                    <PlaidErrors selectedItem={selectedItem} />
                  ) : (
                    <div>
                      {accounts && accounts.length ? (
                        <div>
                          <p>
                            {
                              "Select the account you'd like to use for payments."
                            }
                          </p>
                          <GridSelect<PlaidAccount>
                            items={accounts}
                            defaultSelectedItem={selectedAccount}
                            areItemsEqual={areAccountsEqual}
                            onItemSelect={onAccountSelect}
                            itemTitle={getAccountTitle}
                            itemSubtitle={getAccountSubtitle}
                          />
                        </div>
                      ) : (
                        <div>
                          <p>
                            {
                              'Unable to find accounts at this bank. Select or link a different bank'
                            }
                          </p>
                          <Button type={'primary'} onClick={onAccountsTryAgain}>
                            Try again
                          </Button>
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <p className={cx(!selectedItem && styles.disabledText)}>
                    {items && items.length
                      ? `Select a bank to load your accounts.`
                      : `Link a bank to load your accounts.`}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      );
  }
};

export default PaymentAccountSelect;
