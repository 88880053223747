import PaymentOption from 'components/pay-now/payment-option';
import * as React from 'react';
import formStyles from 'styles/form.module.css';
import config from '../../helpers/config';
import { format } from '../../helpers/date';
import { PaymentProfile } from '../../resources/loan/account/types';
import AddPaymentMethod from '../add-payment-method';
import Button from '../button';
import Datepicker from '../date-picker';
import Input from '../form-input';
import { Option, Select } from '../form-select';
import { default as Modal, default as TillModal } from '../modal';
import PaymentMethod from '../payment-method';
import styles from './styles.module.css';
import { ROUTES } from '../../routes';

const paymentTypes = [
  {
    type: 'nextDue',
    title: 'What I Owe'
  },
  {
    type: 'static',
    title: 'Different Amount'
  }
];
const timingTypes = [
  {
    type: 'nextDue',
    title: 'When Due'
  },
  {
    type: 'custom',
    title: 'Different Schedule'
  }
];

const paymentFrequencyTypes = [
  {
    type: 'loan.frequency.monthly',
    autopayType: 'autopay.recurringFrequency.monthly',
    choice: 'Every Month'
  },
  {
    type: 'loan.frequency.biWeekly',
    autopayType: 'autopay.recurringFrequency.biWeekly',
    choice: 'Every Other Week'
  },
  {
    type: 'loan.frequency.weekly',
    autopayType: 'autopay.recurringFrequency.weekly',
    choice: 'Every Week'
  }
];

interface Props {
  min: number;
  max: number;
  amount: number;
  confirmAutopay: (
    amount: number,
    date: Date,
    paymentMethod: PaymentProfile,
    autopayType: string,
    paymentFrequency: string
  ) => void;
  date?: Date;
  isAutopaySubmitting: boolean;
  visible: boolean;
  onClose: () => any;
  methods: PaymentProfile[];
  selectedMethod?: PaymentProfile;
  debitIframeUrl: string;
  achIframeUrl: string;
  frequency: string;
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
  showDebitCard?: boolean;
}

interface State {
  amount: number;
  amountOptionIndex: number;
  timingOptionIndex: number;
  selectOptionMode: boolean;
  amountTouched: boolean;
  amountError: string;
  methods: PaymentProfile[];
  selectedMethod?: PaymentProfile;
  date?: Date;
  dateTouched: boolean;
  dateError: string;
  frequencyValue: string;
  frequencyTouched: boolean;
  showConfirmationModal: boolean;
  autopayType: string;
  paymentFrequency: string;
}

interface ConfirmationModalProps {
  onClose: () => void;
  onConfirmAutopay: () => void;
  amount: number;
  frequency: string;
  nextPaymentdate?: Date;
}

function defineFrequencyChoiceValue(loanFrequency: string) {
  const obj = paymentFrequencyTypes.find(
    frequencyType => frequencyType.type === loanFrequency
  );
  return obj ? obj.choice : 'Every Other Week';
}

function defineAutopayTypeValue(key: string, loanFrequency: string) {
  let obj;
  if (key === 'type') {
    obj = paymentFrequencyTypes.find(
      frequencyType => frequencyType.type === loanFrequency
    );
  } else if (key === 'choice') {
    obj = paymentFrequencyTypes.find(
      frequencyType => frequencyType.choice === loanFrequency
    );
  }
  return obj ? obj.autopayType : 'autopay.recurringFrequency.biWeekly';
}

function ConfirmationModal({
  onClose,
  onConfirmAutopay,
  amount,
  frequency,
  nextPaymentdate
}: ConfirmationModalProps) {
  return (
    <TillModal visible className={styles.confirmationModal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.modalTitle}>Autopay Confirmation</h1>
        <p className={styles.message}>
          {`By selecting "Submit", you authorize us to charge your account in the amount of $${amount} ${frequency.toLowerCase()}. Your first payment will be made on ${
            nextPaymentdate ? format('MM/DD/YYYY', nextPaymentdate) : new Date()
          }. You also agree that the `}
          <a
            href={ROUTES.legal.electronicPaymentTerms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Electronic Payment Terms
          </a>
          {` apply to these transactions.`}
        </p>
        <div className={styles.buttons}>
          <Button type="link" onClick={onClose}>
            Cancel
          </Button>
          <Button type="link" onClick={onConfirmAutopay}>
            Submit
          </Button>
        </div>
      </div>
    </TillModal>
  );
}

class AutoPay extends React.PureComponent<Props, State> {
  state: State = {
    amount: this.props.amount,
    amountOptionIndex: paymentTypes.findIndex(paymentType => {
      const type = this.props.date ? 'nextDue' : 'static';
      return paymentType.type === type;
    }),
    timingOptionIndex: timingTypes.findIndex(
      timingType => timingType.type === 'nextDue'
    ),
    selectOptionMode: true,
    selectedMethod: this.props.selectedMethod,
    amountTouched: false,
    amountError: '',
    methods: this.props.methods,
    date: this.props.date ? this.props.date : new Date(),
    dateTouched: false,
    dateError: '',
    frequencyValue: defineFrequencyChoiceValue(this.props.frequency),
    frequencyTouched: false,
    showConfirmationModal: false,
    autopayType: 'autopay.type.recurring',
    paymentFrequency: defineAutopayTypeValue('type', this.props.frequency)
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.methods.length !== props.methods.length) {
      return {
        selectedMethod: props.selectedMethod || props.methods[0]
      };
    }

    return {};
  }

  onMethodSelect = (method: any) => {
    this.setState({ selectedMethod: method });
  };

  onAmountOptionSelect = (index: number) => {
    this.setState({
      amount: this.props.amount,
      amountOptionIndex: index,
      selectOptionMode: false
    });
  };

  onTimingOptionSelect = (index: number) => {
    this.setState({
      timingOptionIndex: index,
      frequencyValue: defineFrequencyChoiceValue(this.props.frequency),
      selectOptionMode: false
    });
  };

  onOpenSelectOptionMode = () => this.setState({ selectOptionMode: true });

  onTouchAmount = () => this.setState({ amountTouched: true });

  onChangeAmount = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(target.value);
    if (value <= this.props.max && value >= this.props.min) {
      this.setState({ amount: value, amountError: '' });
    } else {
      this.setState({
        amount: value,
        amountError: `please input an amount greater than $${config.autopayMinAmount}`
      });
    }
  };

  onDateChange = (name: string, value?: Date) => {
    if (value && value.valueOf() < Date.now()) {
      this.setState({ date: value, dateError: 'Please select future date' });
    } else {
      this.setState({ date: value, dateError: '' });
    }
  };

  onTouchDate = () => this.setState({ dateTouched: true });

  onFrequencyChange = (name: string, value: string) => {
    this.setState({
      frequencyValue: value,
      paymentFrequency: defineAutopayTypeValue('choice', value)
    });
  };

  onTouchFrequency = () => this.setState({ frequencyTouched: true });

  toggleConfirmationModal = (shouldShow: boolean) =>
    this.setState({ showConfirmationModal: shouldShow });

  confirm = async () => {
    this.toggleConfirmationModal(false);
    const {
      amount,
      date = new Date(),
      selectedMethod,
      autopayType,
      paymentFrequency
    } = this.state;
    const { confirmAutopay } = this.props;
    if (selectedMethod && amount && date) {
      confirmAutopay(
        amount,
        date,
        selectedMethod,
        autopayType,
        paymentFrequency
      );
    }
  };

  render() {
    const {
      amountOptionIndex,
      timingOptionIndex,
      amountTouched,
      amountError,
      amount,
      selectedMethod,
      date,
      dateTouched,
      dateError,
      frequencyValue,
      frequencyTouched,
      showConfirmationModal
    } = this.state;

    const scheduleTypes = ['Every Week', 'Every Other Week', 'Every Month'];

    const scheduleOptions = scheduleTypes.map(time => (
      <Option
        key={time}
        value={time}
        disabled={
          timingOptionIndex ===
          timingTypes.findIndex(timingType => timingType.type === 'nextDue')
        }
      >
        {time}
      </Option>
    ));

    const amountOptions = paymentTypes.map((paymentType, index) => (
      <PaymentOption
        key={index}
        index={index}
        title={paymentType.title}
        selected={index === amountOptionIndex}
        onSelect={this.onAmountOptionSelect}
      />
    ));

    const timingOptions = timingTypes.map((timingType, index) => (
      <PaymentOption
        key={index}
        index={index}
        title={timingType.title}
        selected={index === timingOptionIndex}
        onSelect={this.onTimingOptionSelect}
      />
    ));

    const methods = this.props.methods.map(method => {
      const selected =
        !!selectedMethod &&
        selectedMethod.paymentProfileId === method.paymentProfileId;
      return (
        <PaymentMethod
          key={method.paymentProfileId}
          view="compact"
          method={method}
          selected={selected}
          onClick={this.onMethodSelect}
        />
      );
    });

    return (
      <Modal
        className={styles.modal}
        visible={this.props.visible}
        onClose={this.props.onClose}
      >
        <div>
          {showConfirmationModal && (
            <ConfirmationModal
              onClose={() => this.toggleConfirmationModal(false)}
              onConfirmAutopay={() => this.confirm()}
              amount={amount}
              frequency={frequencyValue}
              nextPaymentdate={date}
            />
          )}
        </div>
        <h1 className={styles.title}>Setup Autopayments</h1>
        <div className={styles.optionsContainer}>
          <div>
            <label>Choose Amount</label>
            <div>{amountOptions}</div>
          </div>
        </div>
        <div>
          <div className={styles.inputContainer}>
            <Input
              className={styles.input}
              name="amount"
              type="number"
              label="Amount"
              value={amount}
              touched={amountTouched}
              valid={!amountError}
              onChange={this.onChangeAmount}
              setFieldTouched={this.onTouchAmount}
              disabled={
                amountOptionIndex ===
                paymentTypes.findIndex(
                  paymentType => paymentType.type === 'nextDue'
                )
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.input}>
              {amountTouched && amountError ? (
                <span className={formStyles.error}>{amountError}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.optionsContainer}>
          <div>
            <label>Choose Timing</label>
            <div>{timingOptions}</div>
          </div>
        </div>
        <div>
          <div className={styles.inputContainer}>
            <Datepicker
              className={styles.input}
              label="Schedule First Payment Date"
              name="schedule"
              value={date}
              touched={dateTouched}
              valid={!dateError}
              setFieldValue={this.onDateChange}
              setFieldTouched={this.onTouchDate}
              disabled={
                timingOptionIndex ===
                timingTypes.findIndex(
                  timingType => timingType.type === 'nextDue'
                )
              }
            />
            <Select<string>
              label="Select Payment Schedule"
              placeholder="Select"
              name="autopayFrequency"
              value={frequencyValue}
              touched={frequencyTouched}
              valid={true}
              setFieldValue={this.onFrequencyChange}
              setFieldTouched={this.onTouchFrequency}
            >
              {scheduleOptions}
            </Select>
          </div>
        </div>
        <div>
          <div className={styles.paymentMethods}>
            <label>Payment Method</label>
            <div className={styles.methods}>{methods}</div>
            <AddPaymentMethod
              className={styles.addPayment}
              debitIframeUrl={this.props.debitIframeUrl}
              achIframeUrl={this.props.achIframeUrl}
              handlePaymentProfileEvent={this.props.handlePaymentProfileEvent}
              showDebitCard={this.props.showDebitCard}
            />
          </div>
          <Button
            type="primary"
            className={styles.action}
            disabled={amount === 0 || !selectedMethod || amountError !== ''}
            onClick={() => this.toggleConfirmationModal(true)}
          >
            <span>Schedule</span>
          </Button>
        </div>
      </Modal>
    );
  }
}

export default AutoPay;
