import { Dispatch, Store } from 'resources/types';
import createApiAction, {
  createAction,
  getAuthHeaders
} from '../../../helpers/apiActionCreator';
import * as accountApi from './account.api';
import { Autopay, Payment, ScheduledPayment } from './types';

export const GET_LOANS_BY_CUSTOMER = 'GET_LOANS_BY_CUSTOMER';
export const ADD_PAYMENT_PROFILE = 'ADD_PAYMENT_PROFILE';
export const LOAD_PAYMENT_TOKEN = 'LOAD_PAYMENT_TOKEN';
export const LOAD_PAYMENT_PROFILES = 'LOAD_PAYMENT_PROFILES';
export const LOAD_AUTOPAYS = 'LOAD_AUTOPAYS';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const EDIT_PAYMENT = 'EDIT_PAYMENT';
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT';
export const SUBMIT_AUTOPAY = 'SUBMIT_AUTOPAY';
export const SUBMIT_AUTOPAY_CANCELLATION = 'SUBMIT_AUTOPAY_CANCELLATION';
export const SUBMIT_TURN_OFF_AUTOPAY = 'SUBMIT_TURN_OFF_AUTOPAY';
export const SUBMIT_TURN_ON_AUTOPAY = 'SUBMIT_TURN_ON_AUTOPAY';

export function loadLoansByCustomer(userId: string) {
  return createApiAction(GET_LOANS_BY_CUSTOMER, accountApi.getLoansByCustomer)(
    userId
  );
}
export function addPaymentProfile(
  userId: string,
  accountToken: string,
  accountTitle: string,
  accountType: 'ach' | 'debit'
) {
  const add = async (dispatch: Dispatch) => {
    await accountApi.addPaymentProfile(
      { userId, accountToken, accountTitle, accountType },
      getAuthHeaders()
    );
    dispatch(loadPaymentProfiles(userId));
  };
  return createAction(ADD_PAYMENT_PROFILE, add)();
}

export type LoadPaymentTokenAction = () => Promise<any>;
export function loadPaymentToken() {
  return createApiAction(LOAD_PAYMENT_TOKEN, accountApi.getPaymentToken)();
}

export type LoadPaymentProfilesAction = (userId: string) => Promise<any>;
export function loadPaymentProfiles(userId: string) {
  return createApiAction(LOAD_PAYMENT_PROFILES, accountApi.getPaymentProfiles)(
    userId
  );
}

export function makePayment(payment: Payment, paymentId?: number) {
  const thunk = async (dispatch: Dispatch, getState: () => Store) => {
    const headers = getAuthHeaders();
    if (paymentId) {
      await accountApi.editPayment(paymentId, payment, headers);
    } else {
      await accountApi.makePayment(payment, headers);
    }

    const { user } = getState();
    if (user) {
      dispatch(loadLoansByCustomer(user.userId));
    }
  };

  return createAction(paymentId ? EDIT_PAYMENT : MAKE_PAYMENT, thunk)();
}

export function cancelPaymentAction(
  payment: ScheduledPayment,
  loanId: number,
  userId: string
) {
  const cancel = async (dispatch: Dispatch) => {
    await accountApi.cancelPayment(loanId, payment.id, getAuthHeaders());
    dispatch(loadLoansByCustomer(userId));
  };
  return createAction(CANCEL_PAYMENT, cancel)();
}

export function submitAutopayment(autopay: Autopay) {
  return createApiAction(SUBMIT_AUTOPAY, accountApi.scheduleAutopay)(autopay);
}

export function submitAutopaymentCancellation(loanId: number) {
  return createApiAction(
    SUBMIT_AUTOPAY_CANCELLATION,
    accountApi.cancelAutopayments
  )(loanId);
}

export function submitTurnOnAutopay(subscriptionId: number) {
  return createApiAction(SUBMIT_TURN_ON_AUTOPAY, accountApi.turnOnAutopay)(
    subscriptionId
  );
}

export function submitTurnOffAutopay(subscriptionId: number) {
  return createApiAction(SUBMIT_TURN_OFF_AUTOPAY, accountApi.turnOffAutopay)(
    subscriptionId
  );
}

export function loadAutopays(subscriptionId: number) {
  return createApiAction(LOAD_AUTOPAYS, accountApi.getAutopays)(subscriptionId);
}
