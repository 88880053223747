import * as React from 'react';

export default function DollarSign() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <g transform="translate(-41 -283)">
        <circle
          cx="25"
          cy="25"
          r="25"
          fill="#1eb5f0"
          transform="translate(41 283)"
        />
        <g>
          <path
            fill="none"
            d="M0 0h28.082v28.082H0z"
            transform="translate(52.372 293.959)"
          />
          <path
            fill="#fff"
            d="M12.732 12.244c-2.656-.69-3.51-1.4-3.51-2.516 0-1.275 1.182-2.165 3.159-2.165 2.083 0 2.855.995 2.925 2.457h2.586a4.664 4.664 0 0 0-3.756-4.458V3h-3.51v2.527c-2.27.491-4.1 1.966-4.1 4.224 0 2.7 2.235 4.049 5.5 4.832 2.925.7 3.51 1.732 3.51 2.82 0 .807-.573 2.094-3.159 2.094-2.41 0-3.358-1.076-3.487-2.457H6.32c.14 2.563 2.059 4 4.306 4.481v2.539h3.51v-2.514c2.282-.433 4.1-1.755 4.1-4.154-.004-3.323-2.848-4.458-5.504-5.148z"
            transform="translate(52.372 293.959) translate(1.075 0.51)"
          />
        </g>
      </g>
    </svg>
  );
}
