import cx from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import Button from 'components/button';
import Input from 'components/form-input';
import baseStyles from 'styles/base.module.css';
import styles from 'styles/form.module.css';

export interface LoginFormProps {
  email: string;
  password: string;
  needsMfa: boolean;
  code: string;
  isEmailPrepopulated: boolean;
}

function LoginForm({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  isValid
}: FormikProps<LoginFormProps>) {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (values.isEmailPrepopulated) {
      ref.current.focus();
    }
  }, [values.isEmailPrepopulated]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={values.email}
          touched={touched.email}
          valid={!errors.email}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          otherProps={{
            autoCapitalize: 'none'
          }}
        />
      </div>
      {touched.email && errors.email && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.email}</span>
        </div>
      )}
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <Input
          name="password"
          type="password"
          placeholder="Password"
          value={values.password}
          touched={touched.password}
          valid={!errors.password}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          focusRef={ref}
        />
      </div>
      {values.needsMfa && (
        <div className={cx(baseStyles.col100, styles.inputContainer)}>
          <Input
            name="code"
            placeholder="MFA Code"
            value={values.code}
            touched={touched.code}
            valid={!errors.code}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            focusRef={ref}
          />
        </div>
      )}
      {touched.password && errors.password && (
        <div className={baseStyles.col100}>
          <span className={styles.error}>{errors.password}</span>
        </div>
      )}
      <div className={cx(styles.actionContainer, baseStyles.center)}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Log In
        </Button>
      </div>
    </form>
  );
}

export default LoginForm;
