import * as React from 'react';

export default function Help() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 0.72728C3.99214 0.72728 0.72728 3.99214 0.72728 8.00001C0.72728 12.0079 3.99214 15.2727 8.00001 15.2727C12.0079 15.2727 15.2727 12.0079 15.2727 8.00001C15.2727 3.99214 12.0079 0.72728 8.00001 0.72728ZM8.00001 1.84617C11.4047 1.84617 14.1539 4.59529 14.1539 8.00001C14.1539 11.4047 11.4047 14.1539 8.00001 14.1539C4.59529 14.1539 1.84616 11.4047 1.84616 8.00001C1.84616 4.59529 4.59529 1.84617 8.00001 1.84617ZM8.06557 4.08392C6.71067 4.16259 5.6049 5.29895 5.6049 6.68007H6.72378C6.72378 5.77098 7.5105 5.05857 8.47203 5.22465C9.15822 5.34266 9.70018 6.01574 9.67833 6.72378C9.66085 7.34441 9.29808 7.73776 8.76487 7.97378C8.4327 8.12238 8.11801 8.26224 7.85141 8.54196C7.5848 8.82168 7.44057 9.23689 7.44057 9.67832H8.55945C8.55945 9.42483 8.59441 9.38549 8.66434 9.31119C8.7299 9.24126 8.91347 9.13199 9.21504 8.9965C10.0498 8.63374 10.7666 7.83392 10.7972 6.75874C10.8365 5.48689 9.92308 4.33741 8.65997 4.11888C8.45892 4.08829 8.25787 4.07518 8.06557 4.08392ZM7.44057 10.7972V11.9161H8.55945V10.7972H7.44057Z"
        fill="#475F7B"
      />
    </svg>
  );
}
