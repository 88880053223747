import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import Button from 'components/button';
import Input from 'components/form-input';
import { changePassword, ChangePassword } from 'resources/user/user.actions';

import schema from './change-password.schema';

import pageStyles from '../../styles/main.module.css';
import formStyles from 'styles/form.module.css';
import styles from './account.module.css';

interface Values {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

function Form({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  isValid
}: FormikProps<Values>) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={formStyles.inputContainer}>
        <Input
          className={styles.input}
          type="password"
          name="currentPassword"
          label="Current password"
          value={values.currentPassword}
          touched={touched.currentPassword}
          valid={!errors.currentPassword}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      {touched.currentPassword && errors.currentPassword ? (
        <div>
          <div className={styles.input}>
            <span className={formStyles.error}>{errors.currentPassword}</span>
          </div>
        </div>
      ) : null}
      <div className={formStyles.inputContainer}>
        <Input
          className={styles.input}
          type="password"
          name="newPassword"
          label="New password"
          value={values.newPassword}
          touched={touched.newPassword}
          valid={!errors.newPassword}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
        <Input
          className={styles.input}
          type="password"
          name="repeatPassword"
          label="Repeat new password"
          value={values.repeatPassword}
          touched={touched.repeatPassword}
          valid={!errors.repeatPassword}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div>
        <div className={styles.input}>
          {touched.newPassword && errors.newPassword ? (
            <span className={formStyles.error}>{errors.newPassword}</span>
          ) : (
            <span className={cx(formStyles.error, styles.label)}>
              8 total characters,one upper case letter, lower case letter, one
              number.
            </span>
          )}
        </div>
        <div className={styles.input}>
          {touched.repeatPassword && errors.repeatPassword ? (
            <span className={formStyles.error}>{errors.repeatPassword}</span>
          ) : null}
        </div>
      </div>
      <div className={formStyles.actionContainer}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Save
        </Button>
      </div>
    </form>
  );
}

const initialValues: Values = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: ''
};

interface Props {
  changePassword: ChangePassword;
}

class ChangePasswordPage extends React.PureComponent<Props> {
  onSubmit = (values: Values) => {
    const { changePassword } = this.props;
    changePassword({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    });
  };

  render() {
    return (
      <div className={pageStyles.tabContent}>
        <h1>Change Password</h1>
        <Formik
          initialValues={initialValues}
          isInitialValid={schema.isValidSync(initialValues)}
          validationSchema={schema}
          onSubmit={this.onSubmit}
          component={Form}
        />
      </div>
    );
  }
}

export default connect(
  () => ({}),
  {
    changePassword
  }
)(ChangePasswordPage);
