import * as React from 'react';

export default function Search() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.5C0 3.81055 3.81055 0 8.5 0C13.1895 0 17 3.81055 17 8.5C17 10.6113 16.2305 12.543 14.9531 14.0312L21.4531 20.5469L20.0469 21.9531L13.4688 15.3906C12.0703 16.4023 10.3555 17 8.5 17C3.81055 17 0 13.1895 0 8.5ZM16 8.5C16 4.35156 12.6484 1 8.5 1C4.35156 1 1 4.35156 1 8.5C1 12.6484 4.35156 16 8.5 16C12.6484 16 16 12.6484 16 8.5Z"
        fill="#4E5A6B"
      />
    </svg>
  );
}
