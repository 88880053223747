import * as Yup from 'yup';

export default Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, 'Please enter a password longer than 8 characters')
    .max(20, 'Please enter a password shorter than 20 characters')
    .required('Password is required'),
  newPassword: Yup.string().required('Password is required'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
    .required('Password is required')
});
