import * as React from 'react';
import { useSelector } from 'react-redux';
import { getPendingFlexibleRentApplication, getApprovedFlexibleRentApplication } from 'resources/user/user.selectors'; // prettier-ignore
import { getLoadDashboardDataLoadingState } from 'resources/loading/loading.selectors';
import { useThunkDispatch } from 'resources';
import { push } from 'connected-react-router';
import { ROUTES } from 'routes';
import getLoanDetails from 'resources/loan/account/account.selectors';
import Spinner from 'components/spinner';

// Pages
import Address from './address';
import CashFlow from './cash-flow';
import Submit from './submit';
import Schedule from './schedule/schedule';
import Community from './community/community';

function Application() {
  const dispatch = useThunkDispatch();

  const loadDashboardDataLoadingState = useSelector(getLoadDashboardDataLoadingState); // prettier-ignore

  const { loanStatusText } = useSelector(getLoanDetails);
  const pendingApplication = useSelector(getPendingFlexibleRentApplication);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);
  const routeToRent =  ['Open', 'Collections'].includes(loanStatusText) || pendingApplication || approvedApplication; // prettier-ignore

  React.useEffect(() => {
    // Wait for all apps to be accessible
    if (loadDashboardDataLoadingState === 'done') {
      if (
        pendingApplication &&
        pendingApplication.applicationType === 'BUDGET_AND_SAVE'
      ) {
        // Pending budget and save apps should go to the confirmation screen in Vue
        window.location.assign('/v/apply/pending');
      } else if (!routeToRent) {
        // Users with no subscription status or pending apps should go to the community match screen
        const denyMatch = window.location.href.includes('denyMatch');
        denyMatch
          ? window.location.assign('/flex/apply/community') // Users that have denied the match from the /apply/community-match screen.
          : window.location.assign('/v/'); // Other non-subscribers hitting this route.
      } else {
        // All other users will be routed from the /rent screen
        dispatch(push(ROUTES.flexpay.rent));
      }
    }
  }, [
    dispatch,
    loadDashboardDataLoadingState,
    pendingApplication,
    routeToRent
  ]);

  return <Spinner />;
}

export default {
  Application,
  Address,
  CashFlow,
  Schedule,
  Submit,
  Community
};
