import * as React from 'react';

export default function Notification() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3C10.375 3 9.85713 3.51786 9.85713 4.14286V5.44643C7.89284 5.95982 6.42855 7.73661 6.42855 9.85714V14.4107C6.42855 14.4107 6.4107 14.7277 6.25891 15.0223C6.10713 15.3214 5.92409 15.5714 5.2857 15.5714V16.7143H16.7143V15.5714C16.0357 15.5714 15.8616 15.3214 15.7187 15.0357C15.5759 14.7455 15.5714 14.433 15.5714 14.433V9.85714C15.5714 7.73661 14.1071 5.95982 12.1428 5.44643V4.14286C12.1428 3.51786 11.625 3 11 3ZM11 16.7143C10.3705 16.7143 9.85713 17.2277 9.85713 17.8571C9.85713 18.4866 10.3705 19 11 19C11.6294 19 12.1428 18.4866 12.1428 17.8571C12.1428 17.2277 11.6294 16.7143 11 16.7143ZM11 6.42857C12.9018 6.42857 14.4286 7.95536 14.4286 9.85714V14.4241C14.4286 14.4241 14.4152 14.9687 14.6964 15.5357C14.7009 15.5491 14.7098 15.558 14.7143 15.5714H7.25891C7.26338 15.5625 7.2723 15.5536 7.27677 15.5491C7.56248 14.9866 7.57141 14.4464 7.57141 14.4464V9.85714C7.57141 7.95536 9.0982 6.42857 11 6.42857ZM4.13838 7.5625C3.9107 7.57143 3.70534 7.71429 3.62052 7.92857C2.81248 9.89286 2.80355 12.1116 3.63391 14.0804C3.75891 14.3705 4.09373 14.5089 4.38391 14.3839C4.67409 14.2589 4.81248 13.9241 4.68748 13.6339C3.97766 11.9554 3.98659 10.0491 4.67855 8.35714C4.75445 8.17857 4.73213 7.97321 4.62498 7.81696C4.51784 7.65625 4.33481 7.55804 4.13838 7.5625ZM17.8571 7.5625C17.6652 7.56696 17.4866 7.66071 17.3839 7.82143C17.2768 7.98214 17.2589 8.18304 17.3348 8.35714C18.0268 10.0491 18.0357 11.9554 17.3259 13.6339C17.2009 13.9241 17.3393 14.2589 17.6294 14.3839C17.9196 14.5089 18.2544 14.3705 18.3794 14.0804C19.2098 12.1116 19.1964 9.89286 18.3928 7.92857C18.3036 7.70536 18.0937 7.5625 17.8571 7.5625Z"
        fill="#475F7B"
      />
    </svg>
  );
}
