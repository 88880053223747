import * as React from 'react';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import Icon from '../icon';

import 'react-datepicker/dist/react-datepicker.css';
import './override.css';
import styles from './date-picker.module.css';

interface Props {
  className?: string;
  label: string;
  name: string;
  value?: Date;
  touched?: boolean;
  valid: boolean;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
}

interface State {
  focused: boolean;
}

class TillDatePicker extends React.PureComponent<Props, State> {
  state = {
    focused: false
  };

  onFocus = () => {
    const { name, setFieldTouched } = this.props;

    setFieldTouched(name);
    this.setState({ focused: true });
  };

  onBlur = () => this.setState({ focused: false });

  onChange = (date: Date) => {
    const { name, setFieldValue, setFieldTouched } = this.props;
    if (!date) {
      setFieldValue(name, undefined);
    } else {
      setFieldValue(name, date);
    }

    setFieldTouched(name);
  };

  render() {
    const {
      className,
      value,
      name,
      label,
      touched,
      valid,
      disabled
    } = this.props;
    return (
      <div className={cx(styles.inputContainer, className)}>
        <label
          htmlFor={name}
          className={cx(styles.label, { [styles.focused]: this.state.focused })}
        >
          {label}
        </label>
        <div className={styles.inputWrap}>
          <DatePicker
            className={cx(styles.input, {
              [styles.focused]: this.state.focused,
              [styles.valid]: !this.state.focused && touched && valid,
              [styles.error]: !this.state.focused && touched && !valid
            })}
            selected={value}
            placeholderText="MM/DD/YYYY"
            dateFormat="MM/dd/yyyy"
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            popperPlacement="bottom-start"
            disabled={disabled}
          />
          <Icon
            type="date"
            className={cx(styles.icon, {
              [styles.focused]: this.state.focused,
              [styles.valid]: !this.state.focused && touched && valid,
              [styles.error]: !this.state.focused && touched && !valid
            })}
          />
        </div>
      </div>
    );
  }
}

export default TillDatePicker;
