import * as React from 'react';
import cx from 'classnames';

import styles from './selector.module.css';

interface Props {
  className?: string;
  selected: boolean;
}

export default function Selector({ selected, className }: Props) {
  return (
    <div
      className={cx(styles.radioContainer, className, {
        [styles.selected]: selected
      })}
    >
      <div className={styles.radio} />
      <span className={styles.label}>
        {selected ? 'Selected' : 'Select as payment method'}
      </span>
    </div>
  );
}
