import React, { useState } from 'react';
import { format as formatNumber } from 'helpers/number';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { submitTurnOnAutopay, submitTurnOffAutopay } from 'resources/loan/account/account.actions'; // prettier-ignore
import { Transaction } from 'resources/loan/account/types';

// UI
import Icon from 'components/icon';
import Spinner from 'components/spinner';
import styles from './autopayToggle.module.css';
import { getUserAccount } from 'resources/user/user.selectors';

interface Autopay {
  name: string;
  frequency: string;
  amountType: string;
  amount: number;
  daysInPeriod?: number;
  account?: string;
  date: string;
  recurringPeriods: number;
  applyDate: string;
  type: string;
}

interface Props {
  autopays: Autopay[];
  transactions: Transaction[];
}

const AutopayToggle = ({ autopays, transactions }: Props) => {
  const dispatch = useThunkDispatch();
  const { cancelAutopayButtonEnabled: isCancelAutopayButtonEnabled } = useFlags(); // prettier-ignore

  const { activeSubscription } = useSelector(getUserAccount);

  // Modals
  const [showCancelAutopay, setShowCancelAutopay] = useState(false);
  const [showTurnOnAutopay, setShowTurnOnAutopay] = useState(false);
  // State
  const [autopayEnabled, setAutopayEnabled] = useState(false);
  const [isBudgetAndSave, setIsBudgetAndSave] = useState(false);
  const [showResponse, setShowResponse] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    if (activeSubscription) {
      setAutopayEnabled(!!activeSubscription.autopayId);
      setIsBudgetAndSave(activeSubscription.program === 'BUDGET_AND_SAVE');
    }
  }, [activeSubscription]);

  const turnOnAutoPay = async () => {
    setIsLoading(true);
    if (activeSubscription) {
      if (activeSubscription.autopayId === null) {
        setShowResponse({
          type: 'error',
          message:
            'Error: We are unable to update your autopay settings at this time. Please contact our support team'
        });
      } else {
        try {
          await dispatch(
            submitTurnOnAutopay(activeSubscription.subscriptionId)
          );
          setShowResponse({ type: 'success', message: 'Your autopays have been successfully turned on.' }); // prettier-ignore
        } catch (err) {
          setShowResponse({ type: 'error', message: err.message });
        }
      }
      setIsLoading(false);
    }
  };

  const turnOffAutoPay = async () => {
    setIsLoading(true);
    if (activeSubscription) {
      if (activeSubscription.autopayId === null) {
        setShowResponse({
          type: 'error',
          message:
            'Error: We are unable to update your autopay settings at this time. Please contact our support team'
        });
      } else {
        try {
          await dispatch(
            submitTurnOffAutopay(activeSubscription.subscriptionId)
          );
          setShowResponse({ type: 'success', message: 'Your autopays have been successfully turned off.' }); // prettier-ignore
        } catch (err) {
          setShowResponse({ type: 'error', message: err.message });
        }
      }
      setIsLoading(false);
    }
  };

  const successResponse = (
    <>
      <p>{showResponse.message}</p>
      <span onClick={() => window.location.reload()}>Back to Dashboard →</span>
    </>
  );

  const errorResponse = (
    <>
      <p className={styles.errorText}> Error: {showResponse.message}</p>
      <a
        href="https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=360001534052"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact Support →
      </a>
    </>
  );

  const disclaimerText = (
    <div className={styles.disclaimerText}>
      <span className={styles.needHelp}>
        Need help with autopay?{' '}
        <a
          href="https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=360000792111"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support <Icon type="new-tab" />
        </a>
      </span>
      <span>
        <b>Note:</b> Your payments may be slightly adjusted on the first of each
        month depending on your payment frequency and changes in your utility
        bills.
      </span>
    </div>
  );

  return isCancelAutopayButtonEnabled &&
    isBudgetAndSave &&
    activeSubscription ? (
    <>
      {/* Autopay Slider */}
      {
        <div className={styles.autopayToggle}>
          <span>
            {autopayEnabled ||
            (!autopayEnabled && activeSubscription.isAutopayEnabled)
              ? 'Turn Off Autopay'
              : 'Turn On Autopay'}
          </span>
          <label
            className={styles.switch}
            onClick={() => {
              autopayEnabled ||
              (!autopayEnabled && activeSubscription.isAutopayEnabled)
                ? setShowCancelAutopay(true)
                : setShowTurnOnAutopay(true);
            }}
          >
            <input
              checked={
                autopayEnabled ||
                (!autopayEnabled && activeSubscription.isAutopayEnabled)
              }
              type="checkbox"
              onChange={() => {}}
            />
            <span className={styles.slider}></span>
          </label>
        </div>
      }

      {/* Turn ON Autopay  Modal*/}
      {showTurnOnAutopay && (
        <div className={styles.modalContainer}>
          <div>
            <Icon
              clickAction={() => setShowTurnOnAutopay(false)}
              className={styles.closeIcon}
              type="close"
            />
            <span className={styles.modalHeader}>
              <Icon type="info" /> Turn On Autopay
            </span>
            <p>
              After selecting <b>"Turn On Autopay",</b> we'll automatically
              withdraw your rent payment from your account on your next payment
              date.
            </p>
            {!!transactions.length && (
              <div className={styles.upcomingAutoPays}>
                <h3>Upcoming Payments This Month</h3>
                {transactions.map(({ date, paymentAmount, info }, i) => (
                  <span key={`transaction${i}`}>
                    {info}
                    <br />
                    {moment(date).format('dddd MMMM Do')}{' '}
                    <strong>{`($${formatNumber(paymentAmount, 2)})`}</strong>
                  </span>
                ))}
              </div>
            )}

            {isLoading ? (
              <Spinner />
            ) : (
              <div className={styles.actionContainer}>
                {showResponse.type === 'success' && successResponse}
                {showResponse.type === 'error' && errorResponse}
                {showResponse.type === '' && (
                  <>
                    <span onClick={() => setShowTurnOnAutopay(false)}>
                      Nevermind
                    </span>
                    <span onClick={turnOnAutoPay}>Turn On Autopay</span>
                  </>
                )}
              </div>
            )}
            {disclaimerText}
          </div>
        </div>
      )}
      {/* Turn OFF Autopay Modal */}
      {showCancelAutopay && activeSubscription && (
        <div className={styles.modalContainer}>
          <div>
            <Icon
              clickAction={() => setShowCancelAutopay(false)}
              className={styles.closeIcon}
              type="close"
            />
            <span className={styles.modalHeader}>
              <Icon type="info" /> Important
            </span>

            <p>
              By selecting "Cancel Autopay", you will
              <b> turn off all scheduled autopays.</b> Are you sure you want to
              cancel automatic payments?
            </p>
            <div className={styles.upcomingAutoPays}>
              <h3>Autopay Schedule</h3>
              {autopays.map(({ name, date, amount }, i) => (
                <span key={`autoPay${i}`}>
                  {name} - {activeSubscription.schedule.frequency}
                  <strong>{`($${formatNumber(amount, 2)})`}</strong>
                </span>
              ))}
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className={styles.actionContainer}>
                {showResponse.type === 'success' && successResponse}
                {showResponse.type === 'error' && errorResponse}
                {showResponse.type === '' && (
                  <>
                    <span onClick={() => setShowCancelAutopay(false)}>
                      Nevermind
                    </span>
                    <span onClick={turnOffAutoPay}>Turn Off Autopay</span>
                  </>
                )}
              </div>
            )}
            {disclaimerText}
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default AutopayToggle;
