import React, { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useThunkDispatch } from 'resources';
import * as plaidActions from 'resources/plaid/plaid.actions';
import ContactSupportModal from 'components/support/modal';
import { push } from 'connected-react-router';
import { ROUTES } from 'routes';

type Stage = 'payments' | 'flex-enrollment';
type LinkToken = string;

const OAuthLink = () => {
  const dispatch = useThunkDispatch();
  const linkToken = localStorage.getItem('link_token') as LinkToken;
  const stage = localStorage.getItem('plaid_stage') as Stage;

  // if we landed on this page without a link token or stage in localStorage, get out
  React.useEffect(() => {
    console.log('linkToken', linkToken);
    console.log('stage', stage);
    if (!linkToken || !stage) {
      console.log('Go to base path.');
      dispatch(push('/'));
    }
  }, [dispatch, linkToken, stage]);

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const redirectRoutes = {
    [`payments`]: `${ROUTES.flex.addPaymentMethod}?oauth=true`,
    [`flex-enrollment`]: `${ROUTES.flex.onboarding.setPaymentAccount}?oauth=true`
  };

  const redirectRoute = redirectRoutes[stage];

  const onCloseFailureModal = () => {
    dispatch(push(redirectRoute));
  };

  const onSuccess = React.useCallback(
    async (public_token: string, metadata: any) => {
      try {
        await dispatch(
          plaidActions.linkItem({ publicToken: public_token, metadata })
        );
        dispatch(push(redirectRoute));
      } catch (err) {
        setErrorMessage(`Unable to link your account.`);
      }
    },
    [dispatch, redirectRoute]
  );

  const onExit = (err: any) => {
    setErrorMessage('Unable to link your account');
  };

  const plaidConfig: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit
  };
  const { open, ready } = usePlaidLink(plaidConfig);
  useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);
  return (
    <>
      {errorMessage && (
        <div>
          <ContactSupportModal
            onClose={onCloseFailureModal}
            message={errorMessage}
          />
        </div>
      )}
    </>
  );
};

export default OAuthLink;
