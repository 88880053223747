import * as React from 'react';
import SidebarDesktop from './sidebar-desktop';
import SidebarMobile from './sidebar-mobile';
import baseStyles from '../../styles/base.module.css';
import { useSelector } from 'react-redux';
import { getPath } from 'resources/router/router.selectors';

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

/**
 * Sidebar which displays on the left side of the screen.
 * This component handles both desktop and mobile screen sizes.
 */
const Sidebar = ({ isMenuOpen, setIsMenuOpen }: Props) => {
  const path = useSelector(getPath);

  return (
    <>
      <SidebarDesktop className={baseStyles.smHidden} />
      <SidebarMobile
        className={baseStyles.smVisible}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        path={path}
      />
    </>
  );
};

export default Sidebar;
