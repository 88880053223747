import * as React from 'react';

export default function Accept() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.634 4.09384C6.5402 3.97777 3.12056 7.21884 3.00449 11.3126C2.88842 15.4063 6.12949 18.8305 10.2232 18.9465C14.317 19.0581 17.7411 15.8215 17.8572 11.7233C17.8795 10.8662 17.75 10.0403 17.5 9.26795L16.5581 10.1563C16.6697 10.6519 16.7277 11.1653 16.7143 11.6965C16.6161 15.1608 13.7188 17.8974 10.2545 17.8037C6.7902 17.7055 4.04913 14.8081 4.14288 11.3438C4.2411 7.87956 7.13842 5.13849 10.6027 5.23224C11.7366 5.26795 12.7902 5.60277 13.6965 6.16081L14.5536 5.34831C13.4286 4.59831 12.0893 4.13402 10.634 4.09384ZM16.1429 6.19206L9.28574 13.0492L7.40181 11.1697L7.00002 10.7635L6.19199 11.5715L6.59824 11.9733L9.28574 14.6653L16.9509 7.00009L16.1429 6.19206Z"
        fill="#475F7B"
      />
    </svg>
  );
}
