import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { authenticationState } from 'resources/user/user.selectors';
import { useEffect } from 'react';

export function useAuthentication() {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector(authenticationState);

  useEffect(() => {
    switch (authState) {
      case 'unauthenticated':
      case 'confirmed':
        dispatch(push(`/signup?redirect_to=${pathname + search}`));
        break;
      case 'unconfirmed':
        dispatch(push(`/activate?redirect_to=${pathname + search}`));
        break;
    }
  });

  return authState;
}
