import { RentRollMatch } from 'resources/flex/apply/apply.reducer';
import { UserAccountData } from './../../user/types';
import moment from 'moment';
import { ROUTES } from './../../../routes';
import { push } from 'connected-react-router';
import { createTypedApiAction } from 'helpers/apiActionCreator';
import createApiAction from '../../../helpers/apiActionCreator';
import { FlexApplyState, PaymentAccount } from './apply.reducer';
import { Dispatch, Store, ThunkResult } from 'resources/types';
import {
  CashFlowDetailsInput,
  RevisedCashFlowInput,
  CommunityApplicationDetails,
  AddressDetails,
  CashFlowDetails
} from './types';
import * as applyApi from './apply.api';
import { DisplayData } from 'domain/types';
import { Community } from '@hellotill/community-types';
import { resetAllLoadingStates } from 'resources/loading/loading.actions';

export type ApplyActionType =
  | 'FLEX_GO_TO_PREVIOUS_STEP'
  | 'FLEX_GO_TO_NEXT_STEP'
  | 'FLEX_APPLY_SEARCH_RENT_ROLL'
  | 'FLEX_APPLY_MATCH_RENT_ROLL'
  | 'FLEX_APPLY_SAVE_MATCH'
  | 'FLEX_APPLY_UNMATCH_RENT_ROLL'
  | 'FLEX_APPLY_SAVE_COMMUNITY_DATA'
  | 'FLEX_APPLY_SAVE_ADDRESS'
  | 'FLEX_APPLY_SAVE_CASH_FLOW'
  | 'FLEX_APPLY_SAVE_REVISED_CASH_FLOW'
  | 'FLEX_APPLY_LOAD_SCHEDULE'
  | 'FLEX_APPLY_SAVE_SCHEDULE'
  | 'FLEX_APPLY_SAVE_PAYMENT_ACCOUNT'
  | 'FLEX_APPLY_SUBMIT_APPLICATION'
  | 'FLEX_APPLY_RESET'
  | 'FLEX_APPLY_GO_TO_STEP'
  | 'FLEX_APPLY_UPDATE_APPLICATION'
  | 'BUDGET_SAVE_LOAD_SCHEDULE'
  | 'BUDGET_SAVE_APPROVE_APPLICATION'
  | 'BUDGET_SAVE_UPDATE_APPLICATION'
  | 'BUDGET_SAVE_ACCEPT_SCHEDULE';

export interface ApplyAction {
  type: ApplyActionType;
  payload: any;
  requestData?: any;
}

export function goToPreviousStep(): ThunkResult<any> {
  return (dispatch: Dispatch, getState: () => Store) => {
    const flexApplicationState = getState().flexApply;

    if (flexApplicationState && flexApplicationState.currentStepIndex === 0) {
      dispatch(push(ROUTES.apply.basePath));
    } else {
      dispatch<ApplyAction>({
        type: 'FLEX_GO_TO_PREVIOUS_STEP',
        payload: {}
      });
    }
  };
}

export function goToNextStep(): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_GO_TO_NEXT_STEP',
      payload: {}
    });
  };
}

export function denyMatch(): ApplyAction {
  return {
    type: 'FLEX_APPLY_UNMATCH_RENT_ROLL',
    payload: {}
  };
}

export function matchRentRoll(): ApplyAction {
  return {
    type: 'FLEX_APPLY_MATCH_RENT_ROLL',
    payload: {}
  };
}

/**
 * This action is fired when the customer denies the rent roll
 * match that we present them
 */
export function denyRentRollMatch(): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch(denyMatch());
    dispatch(goToNextStep());
  };
}

/**
 * This action is fired when the customer confirms the
 * rent roll match that we present them
 * @param rentRollMatch The rent roll match rpesented to
 *  the customer they they confirmed
 * @param user The current user
 */
export function saveRentRollMatchData(
  rentRollMatch: RentRollMatch,
  user: UserAccountData
): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_MATCH',
      payload: rentRollMatch
    });

    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_COMMUNITY_DATA',
      payload: {
        communityData: {
          communityId: rentRollMatch.community_id
        },
        isUnmatched: false
      }
    });

    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_ADDRESS',
      payload: {
        email: user.email,
        address1: rentRollMatch.address1,
        address2: rentRollMatch.address2,
        zipCode: rentRollMatch.postal_code,
        city: rentRollMatch.city,
        state: rentRollMatch.state,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phone,
        communityName: rentRollMatch.community.name
      }
    });

    dispatch(matchRentRoll());
    dispatch(goToNextStep());
  };
}

export function saveCommunity(
  data: CommunityApplicationDetails
): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_COMMUNITY_DATA',
      payload: data
    });

    dispatch(goToNextStep());
  };
}

export function saveAddressData(data: AddressDetails): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_ADDRESS',
      payload: data
    });

    dispatch(goToNextStep());
  };
}

export function saveCashFlowData(data: CashFlowDetailsInput): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    if (
      data.frequency &&
      data.payAmount !== undefined && // explicitly checking for undefined because this can be 0
      data.rentAmount &&
      data.rentalMonthAppliedFor
    ) {
      dispatch<ApplyAction>({ type: 'FLEX_APPLY_SAVE_CASH_FLOW', payload: data }); // prettier-ignore
      // Takes us to the schedule page
      dispatch<ApplyAction>({ type: 'FLEX_APPLY_GO_TO_STEP', payload: '/flex/apply/schedule' }); // prettier-ignore
    } else {
      throw new Error('Missing required fields');
    }
  };
}

export function saveRevisedCashFlowData(
  data: RevisedCashFlowInput
): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    if (data.frequency && data.rentAmount) {
      dispatch({
        type: 'FLEX_APPLY_SAVE_REVISED_CASH_FLOW',
        payload: data
      });
    } else {
      throw new Error('Missing required fields');
    }
  };
}

export function getSchedule(): ThunkResult<any> {
  return async (dispatch: Dispatch, getState: () => Store) => {
    const {
      flexApply: {
        community: {
          communityData: { communityId = 'unmatched' } = {} as Community
        } = {} as CommunityApplicationDetails,
        cashFlow: {
          lastPayday,
          frequency,
          rentAmount,
          semiMonthlyPayDays
        } = {} as CashFlowDetails
      } = {} as FlexApplyState
    } = getState();

    const args = {
      frequency,
      anchorDate: moment.utc(lastPayday).format('YYYY-MM-DD'),
      communityId,
      cashOnHand: 0,
      baseRent: rentAmount,
      totalBilling: rentAmount,
      outstandingBalance: 0,
      firstPaymentDay: semiMonthlyPayDays && semiMonthlyPayDays[0],
      secondPaymentDay: semiMonthlyPayDays && semiMonthlyPayDays[1]
    };

    if (args.baseRent) {
      dispatch(
        createApiAction<applyApi.GetScheduleArgs, DisplayData>(
          'FLEX_APPLY_LOAD_SCHEDULE',
          applyApi.getSchedule
        )(args)
      );
    } else {
      throw new Error('Missing required fields');
    }
  };
}

export function getBudgetAndSaveSchedule(applicationId: any): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    dispatch(
      createApiAction<applyApi.GetBudgetAndSaveScheduleArgs, DisplayData>(
        'BUDGET_SAVE_LOAD_SCHEDULE',
        applyApi.getBudgetAndSaveSchedule
      )(applicationId)
    );
  };
}

export function saveSchedule(): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_SCHEDULE',
      payload: {}
    });

    dispatch(goToNextStep());
  };
}

export function savePaymentAccountData(data: PaymentAccount): ThunkResult<any> {
  return (dispatch: Dispatch) => {
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_SAVE_PAYMENT_ACCOUNT',
      payload: data
    });

    dispatch(goToNextStep());
  };
}

export function submitApplication(
  data: applyApi.SubmitApplicationData
): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.SubmitApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >('FLEX_APPLY_SUBMIT_APPLICATION', applyApi.submitApplication)(data)
    );

    // after successful app submission, navigate to the confirmation modal in the dashboard
    dispatch(push(ROUTES.flexpay.rent));

    // remove all the data from the store that was inputted for the application
    dispatch<ApplyAction>({
      type: 'FLEX_APPLY_RESET',
      payload: {}
    });

    // resets the loading state of the home page so that after submitting an app we see the correct status
    dispatch(resetAllLoadingStates());
  };
}

// Submit AND approve budget and save application
export function submitBudgetAndSaveApplication(
  data: applyApi.SubmitApplicationData
): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.SubmitApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >(
        'FLEX_APPLY_SUBMIT_APPLICATION',
        applyApi.submitBudgetAndSaveApplication // Function will submit AND approve the application
      )(data)
    );

    dispatch(push(ROUTES.flexpay.rent));
    // resets the loading state of the home page so that after submitting an app we see the correct status
    dispatch(resetAllLoadingStates());
  };
}

// Submit AND approve budget and save application
export function submitAndApproveBudgetAndSaveApplication(
  data: applyApi.SubmitApplicationData
): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.SubmitApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >(
        'FLEX_APPLY_SUBMIT_APPLICATION',
        applyApi.submitAndApproveBudgetAndSaveApplication // Function will submit AND approve the application
      )(data)
    );

    dispatch(push(ROUTES.flexpay.rent));
    // resets the loading state of the home page so that after submitting an app we see the correct status
    dispatch(resetAllLoadingStates());
  };
}

// Approve budget and save application
export function approveBudgetAndSaveApplication(
  data: applyApi.ApproveApplicationData
): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.ApproveApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >(
        'BUDGET_SAVE_APPROVE_APPLICATION',
        applyApi.approveBudgetAndSaveApplication // Function will ONLY approve existing budget and save application
      )(data)
    );
    // resets the loading state of the home page so that after submitting an app we see the correct status
    dispatch(resetAllLoadingStates());
  };
}

// Accept budget and save schedule
export function acceptSchedule(data?: any): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.SubmitApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >('BUDGET_SAVE_ACCEPT_SCHEDULE', applyApi.acceptSchedule)(data)
    );
  };
}

// Accept budget and save schedule
export function updateApplication(data?: any): ThunkResult<any> {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<
        applyApi.SubmitApplicationData,
        applyApi.SubmitApplicationResponse,
        ApplyActionType
      >('BUDGET_SAVE_UPDATE_APPLICATION', applyApi.updateApplication)(data)
    );

    // Route to add payment screen.
    // We need to use window.location here since this page is technically not part of the application flow.
    window.location.assign('/rent');
  };
}

export function goToStep(step: string): ApplyAction {
  return {
    type: 'FLEX_APPLY_GO_TO_STEP',
    payload: step
  };
}
