import { loadAccountsByCustomer } from 'resources/flexpay/account/account.actions';
import { push } from 'connected-react-router';
import { PasswordResetRequiredError } from 'domain/errors';
import { transformAccountData } from 'domain/transforms';
import { getQueryVariable } from 'helpers/querystring';
import { Dispatch, Store, ThunkResult } from 'resources/types';
import createApiAction, {
  createTypedApiAction,
  createAction,
  getAuthHeaders,
  requestFail,
  requestFinish,
  requestStart
} from '../../helpers/apiActionCreator';
import { ROUTES } from '../../routes';
import { CommunitySearchResult } from '../community/types';
import {
  Activate,
  Login,
  PasswordForm,
  ResetPassword,
  Signup,
  UserAccountData,
  Payment
} from './types';
import * as userApi from './user.api';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const ACTIVATE = 'ACTIVATE';
export const RESEND_CONFIRMATION_CODE = 'RESEND_CONFIRMATION_CODE';
export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const INITIATE_PASSWORD_RESET = 'INITIATE_PASSWORD_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const NOTIFICATIONS_SAVE = 'NOTIFICATIONS_SAVE';
export const ACCOUNT_DATA_SAVE = 'ACCOUNT_DATA_SAVE';
export const PERSONAL_DATA_SAVE = 'PERSONAL_DATA_SAVE';
export const LOAN_DETAILS_SAVE = 'LOAN_DETAILS_SAVE';
export const PERSONAL_DATA_LOAD = 'PERSONAL_DATA_LOAD';
export const LOAN_ACCOUNT_URL_GET = 'LOAN_ACCOUNT_URL_GET';
export const LINK_PLAID = 'LINK_PLAID';
export const LOAD_BANK_ACCOUNTS = 'LOAD_BANK_ACCOUNTS';
export const RENT_ROLL_MATCH = 'RENT_ROLL_MATCH';

export const FLEXPAY_COMMUNITY_SAVE = 'FLEXPAY_COMMUNITY_SAVE';
export const FLEXPAY_PERSONAL_DATA_SAVE = 'FLEXPAY_PERSONAL_DATA_SAVE';
export const FLEXPAY_ADDRESS_DATA_SAVE = 'FLEXPAY_ADDRESS_DATA_SAVE';

export const SUBMIT_MY_HOME_INFO = 'SUBMIT_MY_HOME_INFO';
export const CONFIRM_COMMUNITY = 'CONFIRM_COMMUNITY';
export const CONFIRM_NO_COMMUNITY_MATCH = 'CONFIRM_NO_COMMUNITY_MATCH';

export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const GET_PREFERRED_PAYMENT_PROFILE = 'GET_PREFERRED_PAYMENT_PROFILE';
export const GET_PAYMENTS = 'GET_PAYMENTS';
export type GET_PAYMENTS_TYPE = 'GET_PAYMENTS';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';

export type LoginAction = (data: Login) => Promise<any>;
export function login(data: Login) {
  return async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(requestStart(LOGIN, data));

    const { search } = getState().router.location;
    try {
      const payload = await userApi.login(data);
      dispatch(requestFinish(LOGIN, data, payload));
      dispatch(push(getQueryVariable(search, 'redirect_to') || '/'));
    } catch (err) {
      if (err instanceof PasswordResetRequiredError) {
        dispatch(initiatePasswordReset(err.email));
        return;
      }

      if (err.code === 'UserNotConfirmedException') {
        // if the user attempted to login to an unconfirmed account send them to the "activate account" page
        dispatch(
          push({
            pathname: ROUTES.auth.activate,
            state: {
              reason: 'unconfirmed_login_attempt',
              email: data.email
            }
          })
        );
        return;
      }

      dispatch(requestFail(LOGIN, data, err));

      throw err;
    }
  };
}

export function logout() {
  const logout = async (dispatch: Dispatch) => {
    try {
      await userApi.logout();
    } catch (err) {
      // fail silently, so we can still redirect on logout
    }
    dispatch(push(ROUTES.auth.signup));
    // A temporary fix for https://hellotill.atlassian.net/browse/FLEX-189
    window.location.reload();
  };

  return createAction(LOGOUT, logout)();
}

export type SignupAction = (data: Signup) => Promise<any>;
export function signup(data: Signup) {
  return createApiAction(SIGNUP, userApi.signup)(data);
}

export function activate(data: Activate) {
  return createApiAction(ACTIVATE, userApi.activate)(data);
}

export function resendConfirmationCode(email: string) {
  return createApiAction(
    RESEND_CONFIRMATION_CODE,
    userApi.resendConfirmationCode
  )(email);
}

export type ChangePassword = (data: PasswordForm) => Promise<any>;
export function changePassword(data: PasswordForm) {
  return createApiAction(PASSWORD_CHANGE, userApi.changePassword)(data);
}

export function initiatePasswordReset(email: string) {
  const initiate = async (dispatch: Dispatch, getState: () => Store) => {
    const { search } = getState().router.location;

    await userApi.initiatePasswordReset(email);
    dispatch(push(`/password/reset${search || '?'}&email=${email}`));
  };
  return createAction(INITIATE_PASSWORD_RESET, initiate)(email);
}

export function resetPassword(data: ResetPassword) {
  return createApiAction(PASSWORD_RESET, userApi.confirmPasswordReset)(data);
}

export type LoadPersonalData = () => Promise<any>;
export function loadPersonalData() {
  return createApiAction(PERSONAL_DATA_LOAD, userApi.getUser)();
}

export function saveAccountData(data: UserAccountData) {
  return createApiAction(ACCOUNT_DATA_SAVE, userApi.updateUser)(
    transformAccountData(data)
  );
}

export function confirmCommunity({ communityId }: CommunitySearchResult) {
  const confirm = async (dispatch: Dispatch) => {
    await userApi.updateUser({ communityId }, getAuthHeaders());

    await dispatch(loadPersonalData());
    dispatch(push(ROUTES.flexpay.rent));

    return communityId;
  };

  return createAction(CONFIRM_COMMUNITY, confirm)();
}

export function confirmNoCommunityMatch() {
  const communityId = 'unmatched';
  const confirm = async (dispatch: Dispatch) => {
    await userApi.updateUser({ communityId }, getAuthHeaders());

    await dispatch(loadPersonalData());
    dispatch(push(ROUTES.flexpay.rent));

    return communityId;
  };

  return createAction(CONFIRM_NO_COMMUNITY_MATCH, confirm)(communityId);
}

export const goToOnboarding = () => push(ROUTES.onboarding.myCommunity);

export const getApplicationsByUser = (userId: string) => {
  return createApiAction(GET_APPLICATIONS, userApi.getApplicationsByUser)({
    userId
  });
};

/*
 * Loads all data necessary for the Flexible Rent dashboard to determine what screen to display
 * to the user
 * @param userId The userId of the customer viewing the dashboard
 */
export const loadFlexibleRentDashboardData = (
  userId: string
): ThunkResult<any> => {
  return async (dispatch: Dispatch) => {
    try {
      await dispatch(loadAccountsByCustomer(userId));
    } catch (error) {
      if (error.response.status === 404) {
        // The user did not have an account in LoanPro because we got a 404
        await dispatch(getApplicationsByUser(userId));
      } else {
        throw error;
      }
    }
  };
};

/**
 * Retrieves a user's preferred payment profile
 * @param userId The user ID of the user to retrieve the payment profile for
 */
export const getPreferredPaymentProfile = (userId: string) => {
  return createApiAction(
    GET_PREFERRED_PAYMENT_PROFILE,
    userApi.getPreferredPaymentProfile
  )(userId);
};

/**
 * Lists a user's payment payments
 * @param userId The user id of the user to get the payments for
 */
export const getPayments = (userId: string): ThunkResult<any> => {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<string, Payment[], GET_PAYMENTS_TYPE>(
        GET_PAYMENTS,
        userApi.getPayments
      )(userId)
    );
  };
};

export const getSubscriptions = (userId: string): ThunkResult<any> => {
  return async (dispatch: Dispatch) => {
    try {
      return await dispatch(
        createApiAction(GET_SUBSCRIPTIONS, userApi.getSubscriptions)(userId)
      );
    } catch (err) {
      throw Error;
    }
  };
};
