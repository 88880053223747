import * as React from 'react';

export default function Invoice() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 0C0.7176 0 0 0.7176 0 1.6V12.8C0 13.6824 0.7176 14.4 1.6 14.4H14.4C15.2824 14.4 16 13.6824 16 12.8V1.6C16 0.7176 15.2824 0 14.4 0H1.6ZM1.6 1.6H14.4L14.4016 12.8H1.6V1.6ZM3.2 3.2V4.8H8.8V3.2H3.2ZM10.4 3.2V4.8H12.8V3.2H10.4ZM3.2 6.4V8H8.8V6.4H3.2ZM10.4 6.4V8H12.8V6.4H10.4ZM8.8 9.6V11.2H12.8V9.6H8.8Z"
        fill="#475F7B"
      />
    </svg>
  );
}
