import * as React from 'react';
import Modal from '../modal';
import Payments from '../payments';

import styles from './styles.module.css';

interface Props {
  showDebitCard?: boolean;
  visible: boolean;
  onClose: () => any;
  debitIframeUrl: string;
  achIframeUrl: string;
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
}

function PaymentModal({
  showDebitCard,
  visible,
  onClose,
  debitIframeUrl,
  achIframeUrl,
  handlePaymentProfileEvent
}: Props) {
  return (
    <Modal visible={visible} onClose={onClose}>
      <div>
        <h1 className={styles.title}>Add Payment Method</h1>
        <Payments
          showDebitCard={showDebitCard}
          debitIframeUrl={debitIframeUrl}
          achIframeUrl={achIframeUrl}
          handlePaymentProfileEvent={handlePaymentProfileEvent}
        />
      </div>
    </Modal>
  );
}

export default PaymentModal;
