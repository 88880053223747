import cx from 'classnames';
import moment from 'moment';
import * as React from 'react';
import config from '../../helpers/config';
import Checkbox from '../checkbox';
import styles from './payments.module.css';
import get from 'lodash/get';

interface Props {
  showDebitCard?: boolean;
  debitIframeUrl: string;
  achIframeUrl: string;
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
}

function getAccountType(data: any) {
  return data.cardType !== undefined ? 'debit' : 'ach';
}

function getAccountTitle(data: any) {
  const accountName =
    getAccountType(data) === 'debit'
      ? getCardName(data)
      : getCheckingAccountName(data);

  return `${accountName} added ${moment().format('MMM Do YYYY HH:mm:ss')}`;
}

function getCardName(data: any) {
  const { cardType = 'Unknown' } = data;
  return `${cardType} Debit Card`;
}

function getCheckingAccountName(data: any) {
  const bankName = get(data, 'checking-account.bank_name', 'Unknown Bank');
  return `${bankName} Checking Account`;
}

function Payments({
  showDebitCard,
  debitIframeUrl,
  achIframeUrl,
  handlePaymentProfileEvent
}: Props) {
  const [paymentType, setPaymentType] = React.useState<
    'debit' | 'ach' | undefined
  >(undefined);

  React.useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event.origin === config.pciWallet.baseUrl) {
        const { status, token, data } = event.data;

        handlePaymentProfileEvent(
          status,
          token,
          getAccountTitle(data),
          getAccountType(data)
        );
      }
    };

    window.addEventListener('message', handleMessageEvent);

    // Effect cleanup
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handlePaymentProfileEvent]);

  const debitIframe = () => (
    <iframe
      title="Debit Card"
      className={cx(styles.paymentForm, styles.debit)}
      frameBorder={0}
      src={debitIframeUrl}
    >
      <p>Your browser does not support iframes.</p>
    </iframe>
  );

  const achIframe = () => (
    <iframe
      title="Bank Account"
      className={cx(styles.paymentForm, styles.ach)}
      frameBorder={0}
      src={achIframeUrl}
    >
      )<p>Your browser does not support iframes.</p>
    </iframe>
  );
  return (
    <div>
      <div className={styles.option}>
        {showDebitCard && (
          <div>
            <div className={styles.cards}>
              <div className={cx(styles.card, styles.visa)}></div>
              <div className={cx(styles.card, styles.amerexpress)}></div>
              <div className={cx(styles.card, styles.discover)}></div>
              <div className={cx(styles.card, styles.mastercard)}></div>
            </div>
            <Checkbox
              className={styles.check}
              name="payments.debitCard"
              value={paymentType === 'debit'}
              onChange={() => setPaymentType('debit')}
            >
              <h4>Debit Card</h4>
              <p>
                Pay using your Visa, Maestro, Discovery, American express or
                Mastercard.
              </p>
            </Checkbox>
          </div>
        )}
        <div
          className={cx(styles.formContainer, {
            [styles.visible]: paymentType === 'debit'
          })}
        >
          {paymentType === 'debit' ? debitIframe() : null}
        </div>
      </div>
      <div className={styles.option}>
        <Checkbox
          className={styles.check}
          name="payments.ach"
          value={paymentType === 'ach'}
          onChange={() => setPaymentType('ach')}
        >
          <h4>Bank Account</h4>
          <p>Pay with an ACH payment directly from your bank account.</p>
        </Checkbox>
        <div
          className={cx(styles.formContainer, {
            [styles.visible]: paymentType === 'ach'
          })}
        >
          {paymentType === 'ach' ? achIframe() : null}
        </div>
      </div>
    </div>
  );
}

export default Payments;
