import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  getPaymentHistory,
  getUserAccount
} from 'resources/user/user.selectors';
import { getPayments } from 'resources/user/user.actions';
import styles from './cards-payment-history.module.css';
import { Spin } from 'antd';
import ContactSupportModal from 'components/support/modal';
import config from 'helpers/config';

/**
 * Component which is responsible for loading and showing a customer's payment history
 */
const PastPaymentsCard = () => {
  const dispatch = useThunkDispatch();

  const { userId } = useSelector(getUserAccount);
  const payments = useSelector(getPaymentHistory);

  const [error, setError] = React.useState<string | undefined>(undefined);
  const [unableToLoadPayments, setUnableToLoadPayments] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      if (userId) {
        try {
          setIsLoading(true);
          await dispatch(getPayments(userId));
        } catch (err) {
          setUnableToLoadPayments(true);
        }
        setIsLoading(false);
      }
    })();
  }, [dispatch, userId]);

  const onErrorClose = () => {
    setError(undefined);
  };

  if (isLoading) {
    return (
      <div className={styles.card}>
        <h2>Payment History</h2>
        <Spin size="large" />
      </div>
    );
  }

  if (unableToLoadPayments) {
    return (
      <div className={styles.card}>
        <h2>Payment History</h2>
        <p>
          We were unable to load your payment history. You can contact us at{' '}
          <a href={`mailto:${config.supportEmailAddress}`}>
            {config.supportEmailAddress}
          </a>{' '}
          or{' '}
          <a href={`tel:${config.supportPhoneNumber}`}>
            {config.supportPhoneNumber}
          </a>{' '}
          for more information.
        </p>
      </div>
    );
  }

  if (!payments.length) {
    return (
      <div className={styles.card}>
        <h2>Payment History</h2>
        <p>
          Your payment history will display here once you have made your first
          payment.
        </p>
      </div>
    );
  }

  return (
    <div>
      {error && <ContactSupportModal onClose={onErrorClose} message={error} />}
      <div className={styles.card}>
        <h2>Payment History</h2>
        <table>
          <tbody>
            {payments
              .slice(0, 3)
              .map(({ id, loanProPaymentId, paymentDate, amount, status }) => (
                <tr key={loanProPaymentId}>
                  <td>
                    <div
                      className={cx(
                        styles.dateColumn,
                        status === 'success' && styles.success,
                        status === 'failed' && styles.failed
                      )}
                    >
                      <p className={styles.rowDayValue}>
                        {moment(paymentDate, 'YYYY-MM-DD').format('D')}
                      </p>
                      <p className={styles.rowMonthValue}>
                        {moment(paymentDate, 'YYYY-MM-DD').format('MMM')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={styles.rowAmountContainer}>
                      <span className={styles.rowAmountValue}>${amount}</span>
                    </div>
                  </td>
                  <td className={styles.endCell}>
                    <div className={styles.columnContainer}>
                      <div className={styles.rowStatusContainer}>
                        <span className={styles.rowStatusValue}>{status}</span>
                      </div>
                      <div className={styles.rowStatusContainer}>
                        <p className={styles.paymentId}>ID: {id}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className={styles.fullHistoryContainer}>
          <a href="/v/rent/payment-history">View Full Payment History</a>
        </div>
      </div>
    </div>
  );
};

export default PastPaymentsCard;
