import * as React from 'react';
import cx from 'classnames';
import { Layout } from 'antd';
import Burger from 'components/burger';

import logo from '../../assets/white-logo.png';
import styles from './header.module.css';
import config from '../../helpers/config';

interface Props {
  hideBurger?: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => any;
}

function Header({ hideBurger = false, isMenuOpen, setIsMenuOpen }: Props) {
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Layout.Header className={cx('header', styles.header)}>
      <div className={cx(styles.leftMenu, { [styles.hidden]: hideBurger })}>
        <Burger isOpen={isMenuOpen} onClick={toggleMenu} />
      </div>
      <div className={styles.logo}>
        <a href={config.marketingSiteUrl}>
          <img src={logo} alt="Till" />
        </a>
      </div>
    </Layout.Header>
  );
}

export default Header;
