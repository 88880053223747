import cx from 'classnames';
import Button from 'components/button';
import TillInput from 'components/form-input';
import { Option, Select } from 'components/form-select';
import Icon from 'components/icon';
import ContactSupportModal from 'components/support/modal';
import ZipInput from 'components/zip-input';
import FormField from 'form-field';
import { Formik, FormikProps } from 'formik';
import { getStates } from 'helpers/address';
import config from 'helpers/config';
import { MASKS } from 'helpers/constants';
import { useAuthentication } from 'helpers/useAuthentication';
import { useScrollToTop } from 'helpers/useScrollToTop';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  goToPreviousStep,
  saveAddressData
} from 'resources/flex/apply/apply.actions';
import {
  getAddress,
  getApplicationCommunityDetails
} from 'resources/flex/apply/apply.selectors';
import {
  CommunityApplicationDetails,
  AddressDetails
} from 'resources/flex/apply/types';
import { UserAccountData } from 'resources/user/types';
import { getUserAccount } from 'resources/user/user.selectors';
import { communityDetailsSchema } from 'schema/flexpay';
import styles from 'styles/form.module.css';
import applyStyles from './apply.module.css';
import useApplicationStep from './useApplicationStep';

/**
 * The address input screen of the Flexible Rent application
 */
export default function AddressComponent() {
  useAuthentication();
  useApplicationStep();

  const dispatch = useThunkDispatch();

  const userAccount = useSelector(getUserAccount);
  const addressDetails = useSelector(getAddress);
  const applicationCommunityDetails = useSelector(
    getApplicationCommunityDetails
  );

  const [hasError, setHasError] = React.useState(false);

  // initial values for the form
  const applicationAddress: AddressDetails = {
    email: (addressDetails && addressDetails.email) || userAccount.email,
    address1:
      (addressDetails && addressDetails.address1) ||
      userAccount.rentalAddress.address1 ||
      '',
    address2:
      (addressDetails && addressDetails.address2) ||
      userAccount.rentalAddress.address2 ||
      '',
    city:
      (addressDetails && addressDetails.city) ||
      userAccount.rentalAddress.city ||
      '',
    state:
      (addressDetails && addressDetails.state) ||
      userAccount.rentalAddress.state ||
      '',
    zipCode:
      (addressDetails && addressDetails.zipCode) ||
      userAccount.rentalAddress.zip ||
      '',
    firstName:
      (addressDetails && addressDetails.firstName) ||
      userAccount.firstName ||
      '',
    lastName:
      (addressDetails && addressDetails.lastName) || userAccount.lastName || '',
    phoneNumber:
      (addressDetails && addressDetails.phoneNumber) || userAccount.phone || ''
  };

  const submit = (data: AddressDetails) => {
    dispatch(saveAddressData(data));
  };

  const onCloseFailureModal = () => {
    setHasError(false);
  };

  useScrollToTop();

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Your Address</h1>
      <Formik
        enableReinitialize
        initialValues={applicationAddress}
        isInitialValid={communityDetailsSchema.isValidSync(applicationAddress)}
        validationSchema={communityDetailsSchema}
        onSubmit={submit}
        render={props => (
          <Form
            {...props}
            applicationCommunityDetails={applicationCommunityDetails}
            userAccount={userAccount}
          />
        )}
      />
      {hasError && (
        <div>
          <ContactSupportModal
            onClose={onCloseFailureModal}
            supportPhoneNumber={config.supportPhoneNumber}
            message={`Something went wrong. Please try submitting your information again or give us a call.`}
          />
        </div>
      )}
    </div>
  );
}

interface FormProps {
  userAccount: UserAccountData;
  applicationCommunityDetails?: CommunityApplicationDetails;
}

function Form({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isValid,
  userAccount,
  applicationCommunityDetails
}: FormProps & FormikProps<AddressDetails>) {
  const dispatch = useThunkDispatch();

  const createOption = (option: string, disabled: boolean) => (
    <Option key={option} value={option} disabled={disabled}>
      {option}
    </Option>
  );
  const stateOptions = getStates().map(state => createOption(state, false));
  const [showCommunityNameField, setShowCommunityNameField] = React.useState(
    false
  );

  React.useEffect(() => {
    if (
      applicationCommunityDetails &&
      applicationCommunityDetails.communityData
    ) {
      if (
        applicationCommunityDetails.isUnmatched &&
        applicationCommunityDetails.communityData.communityId === undefined
      ) {
        setShowCommunityNameField(true);
      }
    }
  }, [applicationCommunityDetails]);

  const goBack = () => {
    dispatch(goToPreviousStep());
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      {showCommunityNameField && (
        <FormField touched={touched.communityName} error={errors.communityName}>
          <TillInput
            label="Community Name"
            placeholder="..."
            name="community"
            value={values.communityName}
            touched={touched.communityName}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            valid={!errors.communityName}
          />
        </FormField>
      )}
      <FormField touched={touched.address1} error={errors.address1}>
        <TillInput
          label="Address Line 1"
          placeholder="Enter your address"
          name="address1"
          value={values.address1}
          touched={touched.address1}
          valid={!errors.address1}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          icon={<Icon type="address" />}
        />
      </FormField>
      <FormField touched={touched.address2} error={errors.address2}>
        <TillInput
          label="Unit Number"
          placeholder="Apartment number, suite, etc."
          name="address2"
          value={values.address2 || ''}
          touched={touched.address2}
          valid={!errors.address2}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </FormField>
      <FormField touched={touched.zipCode} error={errors.zipCode}>
        <ZipInput
          placeholder="Enter Zip Code"
          touched={touched.zipCode}
          valid={!errors.zipCode}
          name="zipCode"
          value={values.zipCode}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      </FormField>
      <FormField touched={touched.city} error={errors.city}>
        <TillInput
          label="City"
          placeholder="Enter your city"
          name="city"
          value={values.city}
          touched={touched.city}
          valid={!errors.city}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
      </FormField>
      <FormField touched={touched.state} error={errors.state}>
        <Select<string>
          label="Select State"
          placeholder="Select State:"
          name="state"
          value={values.state}
          touched={touched.state}
          valid={!errors.state}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        >
          {stateOptions}
        </Select>
      </FormField>
      {!userAccount.firstName && (
        <FormField touched={touched.firstName} error={errors.firstName}>
          <TillInput
            label="First Name"
            placeholder="Enter your first name"
            name="firstName"
            value={values.firstName}
            touched={touched.firstName}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            valid={!errors.firstName}
          />
        </FormField>
      )}
      {!userAccount.lastName && (
        <FormField touched={touched.lastName} error={errors.lastName}>
          <TillInput
            label="Last Name"
            placeholder="Enter your last name"
            name="lastName"
            value={values.lastName}
            touched={touched.lastName}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            valid={!errors.lastName}
          />
        </FormField>
      )}
      {!userAccount.phone && (
        <FormField touched={touched.phoneNumber} error={errors.phoneNumber}>
          <TillInput
            label="Phone Number"
            placeholder="Enter your phone number"
            name="phoneNumber"
            value={values.phoneNumber}
            touched={touched.phoneNumber}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            valid={!errors.phoneNumber}
            mask={MASKS.PHONE}
          />
        </FormField>
      )}
      <div className={cx(styles.actionContainer, applyStyles.actionContainer)}>
        <Button type="button" onClick={goBack}>
          Back
        </Button>
        <Button type="primary" onClick={handleSubmit} disabled={!isValid}>
          Next
        </Button>
      </div>
    </form>
  );
}
