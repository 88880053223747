import * as React from 'react';

export default function Arrow() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6161 10.5161L9.65229 5.26158C9.27001 4.85691 8.62613 4.85691 8.24385 5.26158V5.26158C7.89141 5.63466 7.8911 6.21786 8.24313 6.59133L11.9427 10.5161L8.24313 14.4409C7.8911 14.8144 7.89141 15.3976 8.24385 15.7706V15.7706C8.62613 16.1753 9.27001 16.1753 9.65229 15.7706L14.6161 10.5161Z"
        fill="#38A7FF"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="7"
        y="4"
        width="8"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6161 10.5161L9.65229 5.26158C9.27001 4.85691 8.62613 4.85691 8.24385 5.26158V5.26158C7.89141 5.63466 7.8911 6.21786 8.24313 6.59133L11.9427 10.5161L8.24313 14.4409C7.8911 14.8144 7.89141 15.3976 8.24385 15.7706V15.7706C8.62613 16.1753 9.27001 16.1753 9.65229 15.7706L14.6161 10.5161Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
}
