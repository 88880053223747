import {
  CallHistoryMethodAction,
  getSearch,
  push
} from 'connected-react-router';
import { Formik, FormikActions } from 'formik';
import { getQueryVariable } from 'helpers/querystring';
import { LocationState, Path } from 'history';
import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Store } from 'resources/types';
import { login, LoginAction } from 'resources/user/user.actions';
import { authenticationState } from 'resources/user/user.selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Spinner from 'components/spinner';
import LoginForm, { LoginFormProps } from './form';
import authStyles from '../auth.module.css';
import styles from './styles.module.css';
import schema from './schema';
import { Alert } from 'antd';
import { MFARequiredError } from '../../../domain/errors';

interface Props {
  navigate: (
    path: Path,
    state?: LocationState
  ) => CallHistoryMethodAction<[Path, LocationState?]>;
  login: LoginAction;
}

interface LoginComponentState {
  isConfirmed?: boolean;
  email?: string;
}

function LoginComponent({ navigate, login }: Props) {
  const { state } = useLocation<LoginComponentState>();
  const { useVueLogin: shouldUseVueLogin } = useFlags();

  const { isConfirmed, email } = state || {};

  const [errorMessage, setErrorMessage] = React.useState();
  const authState = useSelector(authenticationState);
  const search = useSelector<Store, string>(getSearch);

  React.useEffect(() => {
    if (shouldUseVueLogin) {
      if (authState === 'unauthenticated') {
        window.location.replace('/v/login');
      } else if (authState === 'authenticated') {
        window.location.replace('/v/');
      }
    } else {
      if (authState === 'authenticated') {
        const dest = getQueryVariable(search, 'redirect_to') || '/';
        if (dest.startsWith('/v/')) {
          window.location.replace(dest);
        } else {
          navigate(dest);
        }
      }
    }
  });

  const onLoginSubmit = async (
    data: LoginFormProps,
    { setSubmitting, resetForm }: FormikActions<LoginFormProps>
  ) => {
    try {
      setErrorMessage(undefined);

      await login({
        email: data.email,
        password: data.password,
        code: data.code === '' ? undefined : data.code
      });
    } catch (err) {
      setErrorMessage(err.message);
      if (err instanceof MFARequiredError) {
        resetForm({
          password: data.password,
          email: data.email,
          code: '',
          needsMfa: true,
          isEmailPrepopulated: data.email !== undefined
        });
      }
    }

    setSubmitting(false);
  };

  return (
    <div className={authStyles.container}>
      {isConfirmed && (
        <Alert
          className={styles.alert}
          message="Your email is confirmed!"
          type="success"
        />
      )}
      {shouldUseVueLogin ? (
        <Spinner />
      ) : (
        <>
          <h1 className={authStyles.title}>Log in</h1>
          {errorMessage && <p className={authStyles.error}>{errorMessage}</p>}
          <Formik
            initialValues={{
              email: email || '',
              password: '',
              code: '',
              needsMfa: false,
              isEmailPrepopulated: email !== undefined
            }}
            validationSchema={schema}
            onSubmit={onLoginSubmit}
            component={LoginForm}
          />
          <Link to={`/signup${search}`}>New user?</Link>
          <Link to={`/password/forgot${search}`}>Forgot password?</Link>
        </>
      )}
    </div>
  );
}

export default connect(
  (state: Store) => ({
    search: getSearch(state)
  }),
  {
    navigate: push,
    login
  }
)(LoginComponent);
