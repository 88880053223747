import { DisplayData } from 'domain/types';
import { PayFrequency } from 'domain/types';
import moment from 'moment';

const PAYMENT_DATE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_DATE_FORMAT = 'dddd MMMM Do, YYYY';

export interface PaymentInfoCardProps {
  step: number;
  title: string;
  messages: string[];
  disclaimers: string[];
}

/**
 * Maps a flex schedule into the props for displaying the data to
 * a user during Flexpay enrollment
 *
 * The flex schedule that we are mapping includes a recurrence rule that follows
 * the data model outlined here: https://www.notion.so/hellotill/Flexpay-Payment-Data-Design-d1b4f04bc14e4d90b2c16fd3aa12da92
 *
 * The recurrence rule in the schedule follows the structure outlined in this spec: https://tools.ietf.org/html/rfc5545#section-3.3.10
 *
 * @param flexSchedule The schedule to map to props
 */
const createPaymentInfoCardProps = (
  displayData: DisplayData
): PaymentInfoCardProps[] => {
  const paymentInfo: PaymentInfoCardProps[] = [];

  if (displayData.hasCurrentMonthPayments) {
    // add the initial payment as a message
    const firstMonthMessages = [
      `${
        displayData.initialPayment === 0
          ? 'Nothing'
          : `$${formatDecimals(displayData.initialPayment)}`
      } paid today`
    ];

    // add each first month payment as a separate message
    displayData.currentMonthPayments.forEach(payment => {
      // flex-schedule-calculator has payment.amount's type as string | number
      // need to clean that up, but in the meantime doing this to always show two decimals on the schedule
      const amount = formatDecimals(parseFloat(payment.amount.toString()));
      firstMonthMessages.push(
        `$${amount} due on ${moment
          .utc(payment.date, PAYMENT_DATE_FORMAT)
          .format(DISPLAY_DATE_FORMAT)}`
      );
    });

    paymentInfo.push({
      step: paymentInfo.length + 1,
      title: `${moment
        .utc()
        .format('MMMM')
        .toUpperCase()} RENT SCHEDULE`,
      messages: firstMonthMessages,
      disclaimers: []
    });
  }

  const ongoingMonthMessage = `Starting next month, you'll be charged $${formatDecimals(
    displayData.futureMonthPerPayment
  )} ${displayFrequency(displayData.frequency)} ${displayDayOfWeek(
    displayData.frequency,
    displayData.futureMonthPaymentDate
  )}.`;

  paymentInfo.push({
    step: paymentInfo.length + 1,
    title: 'ONGOING PAYMENT SCHEDULE',
    messages: [ongoingMonthMessage],
    disclaimers: [
      'IMPORTANT: THIS AMOUNT WILL VARY IF THE ACTUAL CHARGES UNDER YOUR LEASE GO UP OR DOWN'
    ]
  });

  // add the fee message to the last section
  paymentInfo.push({
    step: paymentInfo.length + 1,
    title: 'FLEXIBLE RENT CHARGE',
    messages: [
      `$${formatDecimals(
        displayData.fee
      )} per month - already included in your payments.`
    ],
    disclaimers: []
  });

  return paymentInfo;
};

/**
 * Returns the frequency in the way we want to display it to the customer
 * @param schedule A flexible rent payment schedule
 */
export const displayFrequency = (frequency: PayFrequency): string => {
  if (frequency === 'Bi-weekly') {
    return 'every other week';
  } else if (frequency === 'Monthly' || frequency === 'Semi-monthly') {
    return 'every month';
  } else {
    return frequency.toLowerCase();
  }
};

/**
 * Returns the days to display to the customer (will only need days if its a monthly or semi-monthly schedule)
 * @param schedule A flexible rent payment schedule
 */
export const displayDays = (
  monthlyPaymentDates: string[]
): string | undefined => {
  if (monthlyPaymentDates.length) {
    const message = `on the ${monthlyPaymentDates[0]} `;
    return monthlyPaymentDates.length === 1
      ? message
      : `${message}and ${monthlyPaymentDates[1]} `;
  }

  // we don't need to show days for other cases, just default to empty
  return '';
};

export const displayDayOfWeek = (
  frequency: string,
  futureMonthPaymentDate: Date
): string | undefined => {
  if (frequency === 'Monthly' || frequency === 'Semi-monthly') {
    return '';
  } else return `on ${moment.utc(futureMonthPaymentDate).format('dddd')}s`;
};

/**
 * Formats an amount to two decimal places unless it doesn't have any decimals
 */
export const formatDecimals = (amount: number): string => {
  return amount % 1 === 0 ? amount.toString() : amount.toFixed(2);
};

export default createPaymentInfoCardProps;
