import * as React from 'react';
import Button from '../button';
import styles from './edit-payment.module.css';
import TillModal from '../modal';
import cx from 'classnames';
import { format as formatNumber } from 'helpers/number';
import { format as formatDate } from 'helpers/date';

interface Props {
  payment: { id: string | number; date: string | Date; amount: number };
  onEditPaymentClick: (payment: any) => void;
  onCancelPaymentClick: (payment: any) => void;
  onClose: () => void;
}

export function EditPayment({
  payment,
  onEditPaymentClick,
  onCancelPaymentClick,
  onClose
}: Props) {
  return (
    <TillModal
      className={styles.modal}
      onClose={onClose}
      visible
      key={payment.id}
    >
      <>
        <h1 className={styles.title}>Edit/Cancel Payment?</h1>
        <p className={styles.message}>
          Are you sure you want to edit or cancel the $
          {formatNumber(payment.amount, 2)} payment you have scheduled on{' '}
          {formatDate('MMM D YYYY', payment.date)}?
        </p>
        <div className={styles.buttons}>
          <Button
            type="primary"
            className={cx(styles.button, styles.editButton)}
            onClick={() => onEditPaymentClick(payment)}
          >
            Edit Payment
          </Button>
          <Button
            type="primary"
            className={cx(styles.button, styles.cancelButton)}
            onClick={() => onCancelPaymentClick(payment)}
          >
            Cancel Payment
          </Button>
        </div>
      </>
    </TillModal>
  );
}
