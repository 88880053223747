import { AnyAction } from 'redux';
import {
  PROMPT_SIGNATURE_REQUEST,
  COMPLETE_SIGNATURE
} from './signature.actions';
import { SignatureRequest } from './signature.api';

export interface SignatureState {
  signatureRequest?: SignatureRequest;
  signatureRequestId?: string;
}

const defaultState: SignatureState = {};

export default function userReducer(
  state: SignatureState | undefined = defaultState,
  action: AnyAction
): SignatureState {
  switch (action.type) {
    case PROMPT_SIGNATURE_REQUEST:
      return {
        ...state,
        signatureRequest: action.payload
      };
    case COMPLETE_SIGNATURE:
      return {
        ...state,
        signatureRequest: undefined
      };
    default:
      return state;
  }
}
