import { Layout } from 'antd';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import baseStyles from '../../styles/base.module.css';
import Header from '../header';
import styles from './layout.module.css';
import { useUserData } from '../../helpers/useUserData';
import { useAuthentication } from '../../helpers/useAuthentication';
import { getUserAccount } from '../../resources/user/user.selectors';
import Sidebar from 'layouts/sidebar/sidebar';
import * as FullStory from '@fullstory/browser';

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

function MainLayout({ children }: Props) {
  useAuthentication();
  useUserData();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { userId, email, firstName, lastName, communityId } = useSelector(
    getUserAccount
  );
  const ldClient = useLDClient();

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=9cdd784b-c9aa-447e-b0ef-f2b8a5dd3f22';
    script.id = 'ze-snippet';
    script.async = true;

    // @ts-ignore
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'right', vertical: 'bottom' }
      }
    };

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Tracking hook for feature flags and marketing analytics
  useEffect(() => {
    if (ldClient && userId.length && communityId && communityId.length) {
      ldClient.identify({
        key: userId,
        email,
        custom: {
          communityId
        }
      });
    }

    FullStory.init({ orgId: '16FZ4V' });

    FullStory.identify(userId, {
      displayName: `${firstName} ${lastName}`,
      email
    });
  }, [communityId, email, firstName, lastName, ldClient, userId]);

  return (
    <Layout>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Layout>
        <div
          className={cx(baseStyles.overlay, {
            [baseStyles.hidden]: !isMenuOpen
          })}
        />
        <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Layout>
          <Layout.Content className={styles.content}>{children}</Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
