import axios from 'axios';
import { PasswordResetRequiredError, MFARequiredError } from 'domain/errors';
import { transformPasswordReset } from 'domain/transforms';
import { FlexApplication, User } from 'domain/types';
import config from 'helpers/config';
import { clearTokens, setToken } from 'helpers/token';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {
  Activate,
  Login,
  Signup,
  ResetPassword,
  GetApplicationsByUserPayload
} from 'resources/user/types';
import { PasswordForm, PaymentProfile, Payment } from './types';
import APIError, { handleError } from 'helpers/api.error';
import { transformPhoneNumber } from '../../domain/transforms';

export async function login(data?: Login) {
  try {
    const response = await axios.post(`${config.apiUrl}/login`, data, {
      withCredentials: true
    });

    const { accessToken } = response.data;
    setToken('accessToken', accessToken);
  } catch (err) {
    const errorCode = get(err, 'response.data.code');
    const errorMessage = get(err, 'response.data.message');

    // Our API needs a better way to express this.
    if (errorMessage.toLowerCase().includes('mfa')) {
      throw new MFARequiredError();
    }

    const email = data && data.email;

    if (errorCode === 'PasswordResetRequiredException' && email) {
      throw new PasswordResetRequiredError(email);
    }

    handleError(err, 'Unable to log in. Check your email and password.');
  }
}

export async function logout() {
  try {
    await axios({
      method: 'post',
      url: `${config.apiUrl}/logout`,
      withCredentials: true
    });
    clearTokens();
    return;
  } catch (err) {
    // Sentry alert will fire to let us know there are issues with logout but this error will ultimately be caught and ignored from the user's perspective
    handleError(err, 'Unable to log out.');
  }
}

export async function signup(data?: Signup) {
  if (data && data.password !== data.confirmPassword) {
    throw new APIError('Passwords do not match', 422);
  }
  try {
    const params = {
      ...omit(data, 'confirmPassword'),
      phoneNumber: data && transformPhoneNumber(data.phoneNumber)
    };
    const response = await axios.post(`${config.apiUrl}/api/user/`, params);

    return response.data;
  } catch (err) {
    handleError(err, 'Unable to sign up. Check your email and password.');
  }
}

export async function activate(data?: Activate) {
  try {
    const response = await axios.post(
      `${config.apiUrl}/api/user/confirmation`,
      data
    );

    return response.data;
  } catch (err) {
    handleError(err, 'Unable to activate. Check your code.');
  }
}

export async function resendConfirmationCode(email?: string) {
  try {
    const response = await axios.post(
      `${config.apiUrl}/api/user/confirmation/code`,
      {
        email
      }
    );

    return response.data;
  } catch (err) {
    handleError(err, 'Unable to activate. Check your code.');
  }
}

export async function getUser(_data?: any, headers?: any) {
  try {
    const response = await axios.get<User>(`${config.apiUrl}/user`, {
      headers
    });
    return response.data;
  } catch (err) {
    handleError(err, 'Unable to get user information.');
  }
}

export async function changePassword(data?: PasswordForm, headers?: any) {
  try {
    const response = await axios.put(
      `${config.apiUrl}/api/user/password`,
      data,
      {
        headers
      }
    );
    return response.data;
  } catch (err) {
    handleError(err, 'Unable to change password.');
  }
}

export async function initiatePasswordReset(email?: string) {
  try {
    const response = await axios.post(
      `${config.apiUrl}/api/user/password/code`,
      {
        email
      }
    );

    return response.data;
  } catch (err) {}
}

export async function confirmPasswordReset(data?: ResetPassword) {
  if (!data || data.password !== data.confirmPassword) {
    throw new APIError('Passwords do not match', 422);
  }
  try {
    const response = await axios.post(
      `${config.apiUrl}/api/user/password`,
      transformPasswordReset(data)
    );

    return response.data;
  } catch (err) {
    handleError(err, 'Unable to reset password.');
  }
}

export async function updateUser(data?: Partial<User>, headers?: any) {
  try {
    const response = await axios.patch(`${config.apiUrl}/api/user/`, data, {
      headers
    });
    return response.data;
  } catch (err) {
    handleError(err, 'Unable to update account information');
  }
}

/**
 * Retrieves applications by user with the option to filter by status
 */
export const getApplicationsByUser = async (
  payload?: GetApplicationsByUserPayload,
  headers?: any
): Promise<FlexApplication[]> => {
  try {
    if (!payload) {
      throw new Error('Query not included in request');
    }

    const statusQuery = payload.status ? `status=${payload.status}` : '';

    const response = await axios.get(
      `${config.apiUrl}/api/v2/flex/user/${payload.userId}/application?${statusQuery}`,
      { headers }
    );
    return response.data;
  } catch (err) {
    return handleError(err, 'Unable to retrieve applications.');
  }
};

export const getPreferredPaymentProfile = async (
  userId?: string,
  headers?: any
): Promise<PaymentProfile> => {
  try {
    if (!userId) {
      throw new Error('User ID missing');
    }

    const response = await axios.get(
      `${config.apiUrl}/api/user/${userId}/preferredPaymentProfile`,
      { headers }
    );

    return response.data;
  } catch (err) {
    return handleError(err, 'Unable to retrieve preferred payment profile');
  }
};

/**
 * Lists a user's past payments
 */
export const getPayments = async (
  userId: string,
  headers?: any
): Promise<Payment[]> => {
  try {
    const response = await axios.get(
      `${config.apiUrl}/user/${userId}/payments`,
      {
        headers
      }
    );
    return response.data.map((payment: Payment) => {
      const id = payment.loanProPaymentId.toString();
      let status = payment.processorStatus;
      if (payment.status === 'settled successfully') {
        status = 'success';
      }
      return {
        ...payment,
        status: status.toLowerCase(),
        id
      };
    });
  } catch (err) {
    return handleError(err, 'Unable to retrieve payments');
  }
};

export async function getBalance(_data: any, userId: string, headers?: any) {
  try {
    const response = await axios.get<User>(
      `${config.apiUrl}/api/user/${userId}/balance`,
      {
        headers
      }
    );
    return response.data;
  } catch (err) {
    handleError(err, 'Unable to get user balance.');
  }
}

export const getSubscriptions = async (userId: string, headers: any) => {
  try {
    const response = await axios.get(
      `${config.apiUrl}/user/${userId}/subscriptions`,
      {
        headers
      }
    );
    return response.data;
  } catch (err) {
    return handleError(err, 'Unable to get subscription.');
  }
};
