import * as React from 'react';

export default function Plus() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13V17C13 17.5523 12.5523 18 12 18H11C10.4477 18 10 17.5523 10 17V13H6C5.44772 13 5 12.5523 5 12V11C5 10.4477 5.44772 10 6 10H10V6C10 5.44772 10.4477 5 11 5H12C12.5523 5 13 5.44772 13 6V10H17C17.5523 10 18 10.4477 18 11V12C18 12.5523 17.5523 13 17 13H13Z"
        fill="#475F7B"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="13"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 13V17C13 17.5523 12.5523 18 12 18H11C10.4477 18 10 17.5523 10 17V13H6C5.44772 13 5 12.5523 5 12V11C5 10.4477 5.44772 10 6 10H10V6C10 5.44772 10.4477 5 11 5H12C12.5523 5 13 5.44772 13 6V10H17C17.5523 10 18 10.4477 18 11V12C18 12.5523 17.5523 13 17 13H13Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  );
}
