import { Community } from '@hellotill/community-types';
import { CommunityState, CommunitySearchResult } from './types';
import { CommunityAction } from './community.action';

const communityReducer = (
  state: CommunityState = {},
  action: CommunityAction
): CommunityState => {
  switch (action.type) {
    case 'GET_COMMUNITY_BY_ID':
      return {
        ...state,
        community: action.payload as Partial<Community>
      };
    case 'SEARCH_COMMUNITY':
      return {
        ...state,
        searchResults: action.payload as CommunitySearchResult[]
      };
    default:
      return state;
  }
};

export default communityReducer;
