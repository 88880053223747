import * as Yup from 'yup';
import config from 'helpers/config';

export const communityDetailsSchema = Yup.object().shape({
  address1: Yup.string().required('Address line 1 is required'),
  address2: Yup.string(),
  zipCode: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only numbers')
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  landlord: Yup.string(),
  communityName: Yup.string(),
  email: Yup.string()
    .email()
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .matches(config.phoneRegExp, 'Phone number is in incorrect format')
    .required('Phone number is required')
});

const dateSchema = Yup.date()
  .typeError('Invalid date')
  .min('01/01/2020')
  .max(new Date().toDateString(), 'Please choose a date in the past');

const semiMonthlyPayDaysSchema = Yup.string();

export const cashFlowDetailsSchema = Yup.object().shape({
  frequency: Yup.string().required('Pay frequency is required'),
  payAmount: Yup.number()
    .typeError('Please enter a valid number')
    .min(0, 'Please enter a number greater than zero.')
    .required('Amount is required'),
  lastPayday: dateSchema.when('frequency', {
    is: value => value !== 'Semi-monthly',
    then: dateSchema.required('Last Pay Date is required')
  }),
  semiMonthlyPayDays: semiMonthlyPayDaysSchema.when('frequency', {
    is: value => value === 'Semi-monthly',
    then: semiMonthlyPayDaysSchema.required('Please select your pay days')
  }),
  rentAmount: Yup.number()
    .typeError('Please enter a valid number')
    .positive('Please enter a number greater than 0')
    .required('Current rent amount is required'),
  rentalMonthAppliedFor: Yup.string().required('This field is required')
});

export const revisedCashFlowDetailsSchema = Yup.object().shape({
  frequency: Yup.string().required('Pay frequency is required'),
  lastPayday: dateSchema.when('frequency', {
    is: value => value !== 'Semi-monthly',
    then: dateSchema.required('Last Pay Date is required')
  }),
  semiMonthlyPayDays: semiMonthlyPayDaysSchema.when('frequency', {
    is: value => value === 'Semi-monthly',
    then: semiMonthlyPayDaysSchema.required(
      'Semi-monthly pay days are required'
    )
  }),
  rentAmount: Yup.number()
    .typeError('Current rent amount must be a number')
    .positive('Current rent amount must be positive')
    .required('Current rent amount is required')
});

export const rentHelpDetailsSchema = Yup.object().shape({
  assistanceRequested: Yup.boolean().required(),
  assistanceAbleToPayAmount: Yup.number().when('assistanceRequested', {
    is: value => value === true,
    then: Yup.number()
      .typeError('Please enter a valid number')
      .positive('Please enter a number greater than 0')
      .required('Please enter a valid amount so we can best assist you.')
  }),

  assistanceReason: Yup.string().when('assistanceRequested', {
    is: value => value === true,
    then: Yup.string().required('This field is required')
  }),
  assistanceEarningsType: Yup.string().when('assistanceRequested', {
    is: value => value === true,
    then: Yup.string().required('This field is required')
  })
});
