import map from 'lodash/map';
import uniq from 'lodash/uniq';

const data = [
  { state: 'Alabama', city: 'Huntsville', zip: ['35801', '35816'] },
  { state: 'Alaska', city: 'Anchorage', zip: ['99501', '99524'] },
  { state: 'Arizona', city: 'Phoenix', zip: ['85001', '85055'] },
  { state: 'Arkansas', city: 'Little Rock', zip: ['72201', '72217'] },
  { state: 'California', city: 'Sacramento', zip: ['94203', '94209'] },
  { state: 'California', city: 'Los Angeles', zip: ['90001', '90089'] },
  { state: 'California', city: 'Beverly Hills', zip: ['90209', '90213'] },
  { state: 'Colorado', city: 'Denver', zip: ['80201', '80239'] },
  { state: 'Connecticut', city: 'Hartford', zip: ['06101', '06112'] },
  { state: 'Delaware', city: 'Dover', zip: ['19901', '19905'] },
  {
    state: 'District of Columbia',
    city: 'Washington',
    zip: ['20001', '20020']
  },
  { state: 'Florida', city: 'Pensacola', zip: ['32501', '32509'] },
  { state: 'Florida', city: 'Miami', zip: ['33124', '33190'] },
  { state: 'Florida', city: 'Orlando', zip: ['32801', '32837'] },
  { state: 'Georgia', city: 'Atlanta', zip: ['30301', '30381'] },
  { state: 'Hawaii', city: 'Honolulu', zip: ['96801', '96830'] },
  { state: 'Idaho', city: 'Montpelier', zip: ['83254'] },
  { state: 'Illinois', city: 'Chicago', zip: ['60601', '60641'] },
  { state: 'Illinois', city: 'Springfield', zip: ['62701', '62709'] },
  { state: 'Indiana', city: 'Indianapolis', zip: ['46201', '46209'] },
  { state: 'Iowa', city: 'Davenport', zip: ['52801', '52809'] },
  { state: 'Iowa', city: 'Des Moines', zip: ['50301', '50323'] },
  { state: 'Kansas', city: 'Wichita', zip: ['67201', '67221'] },
  { state: 'Kentucky', city: 'Hazard', zip: ['41701', '41702'] },
  { state: 'Louisiana', city: 'New Orleans', zip: ['70112', '70119'] },
  { state: 'Maine', city: 'Freeport', zip: ['04032', '04034'] },
  { state: 'Maryland', city: 'Baltimore', zip: ['21201', '21237'] },
  { state: 'Massachusetts', city: 'Boston', zip: ['02101', '02137'] },
  { state: 'Michigan', city: 'Coldwater', zip: ['49036'] },
  { state: 'Michigan', city: 'Gaylord', zip: ['49734', '49735'] },
  { state: 'Minnesota', city: 'Duluth', zip: ['55801', '55808'] },
  { state: 'Mississippi', city: 'Biloxi', zip: ['39530', '39535'] },
  { state: 'Missouri', city: 'St. Louis', zip: ['63101', '63141'] },
  { state: 'Montana', city: 'Laurel', zip: ['59044'] },
  { state: 'Nebraska', city: 'Hastings', zip: ['68901', '68902'] },
  { state: 'Nevada', city: 'Reno', zip: ['89501', '89513'] },
  { state: 'New Hampshire', city: 'Ashland', zip: ['03217'] },
  { state: 'New Jersey', city: 'Livingston', zip: ['07039'] },
  { state: 'New Mexico', city: 'Santa Fe', zip: ['87500', '87506'] },
  { state: 'New York', city: 'New York', zip: ['10001', '10048'] },
  { state: 'North Carolina', city: 'Oxford', zip: ['27565'] },
  { state: 'North Dakota', city: 'Walhalla', zip: ['58282'] },
  { state: 'Ohio', city: 'Cleveland', zip: ['44101', '44179'] },
  { state: 'Oklahoma', city: 'Tulsa', zip: ['74101', '74110'] },
  { state: 'Oregon', city: 'Portland', zip: ['97201', '97225'] },
  { state: 'Pennsylvania', city: 'Pittsburgh', zip: ['15201', '15244'] },
  { state: 'Rhode Island', city: 'Newport', zip: ['02840', '02841'] },
  { state: 'South Carolina', city: 'Camden', zip: ['29020'] },
  { state: 'South Dakota', city: 'Aberdeen', zip: ['57401', '57402'] },
  { state: 'Tennessee', city: 'Nashville', zip: ['37201', '37222'] },
  { state: 'Texas', city: 'Austin', zip: ['78701', '78705'] },
  { state: 'Utah', city: 'Logan', zip: ['84321', '84323'] },
  { state: 'Vermont', city: 'Killington', zip: ['05751'] },
  { state: 'Virginia', city: 'Altavista', zip: ['24517'] },
  { state: 'Washington', city: 'Bellevue', zip: ['98004', '98009'] },
  { state: 'West Virginia', city: 'Beaver', zip: ['25813'] },
  { state: 'Wisconsin', city: 'Milwaukee', zip: ['53201', '53228'] },
  { state: 'Wyoming', city: 'Pinedale', zip: ['82941'] }
];

export function getStates() {
  return uniq(map(data, 'state'));
}

export function getCities() {
  return uniq(map(data, 'city'));
}

export function lookup(zip: string) {
  return data.find(
    city =>
      zip.length === city.zip[0].length &&
      zip >= city.zip[0] &&
      zip <= city.zip[1]
  );
}
