import cx from 'classnames';
import { Formik } from 'formik';
import { useUserData } from 'helpers/useUserData';
import * as React from 'react';
import { useThunkDispatch } from 'resources';
import { UserAccountData } from 'resources/user/types';
import { saveAccountData } from 'resources/user/user.actions';
import { accountSchema } from 'schema/user';
import pageStyles from 'styles/main.module.css';
import styles from './account.module.css';
import Form from './form';
import Icon from 'components/icon';

const cancelSubscriptionUrl =
  'https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=360001534552';

function PersonalData() {
  const dispatch = useThunkDispatch();
  const userAccount = useUserData();

  const onSubmit = (values: UserAccountData) => {
    dispatch(saveAccountData(values));
  };

  return (
    <>
      <div className={cx(pageStyles.tabContent, styles.container)}>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={userAccount}
          isInitialValid={accountSchema.isValidSync(userAccount)}
          validationSchema={accountSchema}
          component={Form}
        />
        <div className={styles.footer}>
          We store your personal data in the United States. See our privacy
          policy for more information.
        </div>
      </div>
      <div className={cx(pageStyles.tabContent, styles.container)}>
        <a
          href={cancelSubscriptionUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '16px',
            color: 'var(--fill-accent-red)'
          }}
        >
          Cancel your Till subscription.
          <Icon type="new-tab" />
        </a>
      </div>
    </>
  );
}

export default PersonalData;
