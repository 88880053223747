import * as Yup from 'yup';
import config from 'helpers/config';

export default Yup.object().shape({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  phoneNumber: Yup.string()
    .matches(config.phoneRegExp, 'Phone number is in incorrect format')
    .required('Please enter a valid phone number'),
  email: Yup.string()
    .email('Email is in incorrect format')
    .required('Please enter a valid email address'),
  password: Yup.string()
    .min(8, 'Please enter a password longer than 8 characters')
    .max(20, 'Please enter a password shorter than 20 characters')
    .required('Please enter a password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
    .required('Please confirm your password'),
  agreedToTerms: Yup.boolean()
    .required('You must agree to the terms and conditions to continue')
    .oneOf([true], 'You must agree to the terms and conditions to continue')
});
