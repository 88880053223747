import cx from 'classnames';
import * as React from 'react';
import { CommunitySearchResult } from 'resources/community/types';
import styles from './community.module.css';
import Icon from 'components/icon';

interface Props {
  community: CommunitySearchResult;
  onCommunityClick: (community: CommunitySearchResult) => void;
  selected: boolean;
}

/**
 * Displays a community search result which includes information about
 * the community to help the customer determine if it is where they live
 */
const CommunityCard = ({ community, onCommunityClick, selected }: Props) => {
  return (
    <div
      className={cx(styles.card, selected && styles.selected)}
      onClick={() => {
        onCommunityClick(community);
      }}
    >
      <div className={cx(styles.infoContainer)}>
        <div className={cx(styles.itemInfo)}>
          <h3 className={styles.communityName}>{community.name}</h3>
          <p className={styles.streetAddress}>{community.address1}</p>
          <p className={styles.cityStateZip}>
            {community.city}, {community.state} {community.postalCode}
          </p>
        </div>
        <div className={cx(styles.iconContainer)}>
          <Icon
            type="check"
            className={cx(styles.icon, selected && styles.selected)}
          />
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
