import * as React from 'react';

export interface TabProps {
  className?: string;
  title: React.ReactChild;
  route: string;
  children: React.ReactChild[] | React.ReactChild;
}

export function Tab(props: TabProps) {
  return null;
}

export default Tab;
