import TillModal from 'components/modal';
import * as React from 'react';
import styles from './styles.module.css';
import config from 'helpers/config';

interface Props {
  onClose: () => void;
  supportPhoneNumber?: string;
  message: string;
}

export default function ContactSupportModal({
  onClose,
  supportPhoneNumber,
  message
}: Props) {
  return (
    <TillModal visible className={styles.modal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.title}>Please give us a call</h1>
        <p className={styles.message}>{message}</p>
        <p className={styles.message}>
          {'Please give us a call at '}
          <a href={`tel:${supportPhoneNumber || config.supportPhoneNumber}`}>
            {supportPhoneNumber || config.supportPhoneNumber}
          </a>
          {', option #2 to process your request.'}
        </p>
      </div>
    </TillModal>
  );
}
