import * as React from 'react';
import findIndex from 'lodash/findIndex';
import cx from 'classnames';
import Icon, { IconType } from 'components/icon';

import styles from './progress-bar.module.css';

interface Props {
  className?: string;
  items: Array<{
    key: string;
    icon?: IconType;
    label: string;
    visible?: boolean;
  }>;
  activeItem: string | undefined;
}

const getIcon = (type: IconType) => <Icon type={type} />;

function ProgressBar({ className = '', items, activeItem }: Props) {
  const activeIndex =
    activeItem && findIndex(items, item => item.key === activeItem);
  const listItems = items
    .filter(item => item.visible !== false)
    .map((item, idx) => (
      <li
        key={item.key}
        className={cx(styles.item, {
          [styles.active]: item.key === activeItem,
          [styles.completed]: activeIndex !== undefined && idx < activeIndex
        })}
      >
        <span className={cx(styles.bubble, { [styles.hasIcon]: !!item.icon })}>
          {item.icon ? (
            getIcon(item.icon)
          ) : (
            <span className={styles.bubbleAnchor} />
          )}
        </span>
        {item.label}
      </li>
    ));

  return <ul className={cx(styles.progress, className)}>{listItems}</ul>;
}

export default ProgressBar;
