import * as React from 'react';
import moment from 'moment';
import styles from './schedule.module.css';
import { useSelector } from 'react-redux';
import Spinner from 'components/spinner';
import {
  getScheduleDisplayData,
  getFlexApplyState
} from 'resources/flex/apply/apply.selectors';

import cx from 'classnames';

/**
 * Displays cards with payment info for Flexible Rent
 */
const PaymentCalendar = () => {
  const scheduleDisplayData = useSelector(getScheduleDisplayData);
  const flexApplyState = useSelector(getFlexApplyState);

  const { firstRecurringPaymentDate, hasCurrentMonthPayments, initialPayment } = scheduleDisplayData || {}; // prettier-ignore
  const { rentalMonthAppliedFor } = (flexApplyState && flexApplyState.cashFlow) || {}; // prettier-ignore

  const currentMonth = moment(Date.now()).format('MMMM YYYY');
  const startOfMonth = moment(firstRecurringPaymentDate).clone().startOf('month').format('YYYY-MM-DD hh:mm'); // prettier-ignore
  const startOfMonthDayNumber = moment(startOfMonth).weekday();
  const daysInMonth = moment(firstRecurringPaymentDate || Date.now()).daysInMonth(); // prettier-ignore

  return (
    <>
      {hasCurrentMonthPayments ? (
        <div className={styles.contentContainer}>
          <div className={styles.cardHeadingContainer}>
            <h3 className={styles.cardHeading}>
              Proposed {moment(firstRecurringPaymentDate).format('MMMM')}{' '}
              Payment Schedule
            </h3>
          </div>
          <p className={styles.mainCardSecondParagraph}>
            {rentalMonthAppliedFor === currentMonth
              ? "Looks like you're behind on rent this month - don't worry, Till has no late fees. Here's how we can break out your payments to help you catch up. "
              : "Great, here's a proposed payment schedule based on the inputs from your application. "}
            This schedule is subject to change based on the actual details of
            your lease.
          </p>

          <div className={styles.paymentCalendar}>
            {/* DAYS OF WEEK */}
            {['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'].map((day, i) => (
              <div key={`${day}${i}`} className={styles.weekDay}>
                <span>{day}</span>
              </div>
            ))}

            {/* PREV MONTH DAYS */}
            {[...Array(startOfMonthDayNumber)].map((e, i) => (
              <span key={`${e}${i}`}></span>
            ))}

            {/* FIRST DAY AND DAYS WITH PAYMENTS */}
            {scheduleDisplayData &&
              [...Array(daysInMonth)].map((e, i) => {
                const monthDate = `${i + 1}`;
                const today = moment(Date.now()).date();

                const matchDate = scheduleDisplayData.currentMonthPayments.filter(
                  date => moment.utc(date.date).format('DD') === monthDate
                );

                if (today === i + 1) {
                  return (
                    <div
                      key={`date${i}`}
                      className={cx(styles.isToday, styles.isPaymentDate)}
                    >
                      <span className={styles.paymentAmountDue}>
                        {initialPayment === 0 ? 'Nothing' : initialPayment}
                        <br /> due today.
                      </span>
                    </div>
                  );
                } else if (matchDate[0] && matchDate[0].date) {
                  return (
                    <div key={`date${i}`} className={styles.isPaymentDate}>
                      <span>
                        {`$${matchDate[0].amount} due`} <br />
                        {`on ${moment.utc(matchDate[0].date).format('MMM D')}`}
                      </span>
                    </div>
                  );
                } else return <span key={`date${i}`}>{monthDate}</span>;
              })}
          </div>
        </div>
      ) : (
        <Spinner size="default" tip="Loading schedule" />
      )}
    </>
  );
};

export default PaymentCalendar;
