import * as React from 'react';
import cx from 'classnames';
import Selector from './selector';

import baseStyles from '../../styles/payment-method.module.css';
import styles from './payment-method.module.css';
import { PaymentProfile } from '../../resources/loan/account/types';

interface MethodProps {
  selected: boolean;
  view: 'compact' | 'large';
}

function DebitCardComponent({
  view,
  selected,
  cardType = '',
  cardNumber = '',
  cardExpiration = '',
  title
}: MethodProps & PaymentProfile) {
  if (view === 'compact') {
    return (
      <div className={cx(styles.listItem, { [styles.selected]: selected })}>
        <div className={styles.radio} />
        <div className={cx(styles.cardType, styles[cardType])} />
        <div className={styles.content}>
          <span>Debit Card</span>
          <span className={styles.accountNumber}>{cardNumber}</span>
          <span className={styles.feeText}>
            A 2.5% fee will be added to your payment when using this payment{' '}
            method.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(baseStyles.card, styles.card, {
        [baseStyles.selected]: selected
      })}
    >
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <div className={cx(baseStyles.triangle, styles.triangle)} />
      <span>Debit Card</span>
      <h3>{cardNumber}</h3>
      <div className={baseStyles.cardTypes}>
        <div className={cx(baseStyles.cardType, styles[cardType])} />
      </div>
      <Selector selected={selected} />
      <h6>{title}</h6>
      <div className={baseStyles.footer}>
        <span>{cardExpiration}</span>
      </div>
    </div>
  );
}

export default DebitCardComponent;
