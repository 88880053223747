import * as React from 'react';

export default function NoLateFees() {
  return (
    <svg viewBox="0 0 46 46">
      <g transform="translate(-18 -256)">
        <circle
          cx="23"
          cy="23"
          r="23"
          fill="#1eb5f0"
          transform="translate(18 256)"
        />
        <path
          fill="#fff"
          d="M39 270h4a1 1 0 000-2h-4a1 1 0 000 2zm2 5a1 1 0 00-.98.89l1.98 1.97V276a1 1 0 00-1-1zm0-2a6.99 6.99 0 016.26 10.12l1.47 1.47a8.954 8.954 0 00-.7-10.2l.75-.75a.993.993 0 000-1.4l-.01-.01a.993.993 0 00-1.4 0l-.75.75A8.962 8.962 0 0041 271a8.863 8.863 0 00-4.57 1.29l1.45 1.45A6.962 6.962 0 0141 273zm8.19 14.88l-16.32-16.32a1 1 0 00-1.41 1.41l2.07 2.07a8.984 8.984 0 0012.45 12.46l1.79 1.79a1 1 0 001.42-1.41zM41 287a7 7 0 01-7-7 6.876 6.876 0 01.96-3.52l9.57 9.57A7.032 7.032 0 0141 287z"
        />
      </g>
    </svg>
  );
}
