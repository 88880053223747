import { Store } from 'resources/types';
import { LoadingState } from './loading.reducer';

/**
 * Returns the current loading state of useUserData hook
 */
export const isUseUserDataLoading = ({ loading }: Store): boolean =>
  loading.personalDataLoadingState === 'loading' ||
  loading.communityLoadingState === 'loading';

/**
 * Returns the current loading state of the LoadDashboardData hook
 */
export const getLoadDashboardDataLoadingState = ({
  loading
}: Store): LoadingState => loading.dashboardDataLoadingState;
