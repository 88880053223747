import { Community } from '@hellotill/community-types';
import axios from 'axios';
import config from 'helpers/config';
import { CommunitySearchResult } from './types';

export const lookupCommunities = async (postalCode: string, headers?: any) => {
  try {
    const response = await axios.get<Community[]>(
      `${config.apiUrl}/api/community?postalCode=${postalCode}`,
      {
        headers
      }
    );

    return response.data;
  } catch (err) {
    return [];
  }
};

export const getCommunity = async (communityId?: string, headers?: any) => {
  if (communityId === 'unmatched') return {};
  try {
    const response = await axios.get<Community>(
      `${config.apiUrl}/api/community/${communityId}`,
      {
        headers
      }
    );

    return response.data;
  } catch (err) {
    return {};
  }
};

export const searchCommunity = async (
  name = '',
  headers?: any
): Promise<CommunitySearchResult[]> => {
  try {
    const response = await axios.get<Partial<Community>[]>(
      `${config.apiUrl}/api/community/search?name=${encodeURIComponent(name)}`,
      {
        headers
      }
    );

    const results: CommunitySearchResult[] = [];
    for (const {
      communityId,
      name,
      address1,
      city,
      state,
      postalCode
    } of response.data) {
      if (communityId && name && address1 && city && state && postalCode) {
        results.push({
          communityId,
          name,
          address1,
          city,
          state,
          postalCode
        });
      }
    }
    return results;
  } catch (err) {
    return [];
  }
};
