import { transformUser } from 'domain/transforms';
import { User } from 'domain/types';
import { getToken } from 'helpers/token';
import get from 'lodash/get';
import { AnyAction } from 'redux';
import { UserState } from './types';
import {
  ACTIVATE,
  LOAN_ACCOUNT_URL_GET,
  LOGIN,
  LOGOUT,
  PERSONAL_DATA_LOAD,
  PERSONAL_DATA_SAVE,
  SIGNUP,
  CONFIRM_NO_COMMUNITY_MATCH,
  CONFIRM_COMMUNITY,
  LOAD_BANK_ACCOUNTS,
  GET_APPLICATIONS,
  GET_PREFERRED_PAYMENT_PROFILE,
  GET_PAYMENTS,
  GET_SUBSCRIPTIONS
} from './user.actions';
import { LOCATION_CHANGE } from 'connected-react-router';

const defaultState: UserState = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  rentalAddress: {},
  authState: getToken('accessToken') ? 'authenticated' : 'unauthenticated',
  loanAccount: {
    status: 'initial'
  },
  applicationState: {
    applications: [],
    isLoaded: false
  },
  payments: [],
  subscriptions: []
};

export default function userReducer(
  state: UserState | undefined = defaultState,
  action: AnyAction
): UserState {
  const errorStatus = get(action, 'error.status');

  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        loanAccount: {
          status: 'initial'
        }
      };
    case PERSONAL_DATA_SAVE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case PERSONAL_DATA_LOAD:
      return {
        ...state,
        ...transformUser(action.payload as User)
      };
    case LOGIN:
      return {
        ...state,
        authState: 'authenticated'
      };
    case LOGOUT:
      return {
        ...defaultState,
        authState: 'unauthenticated'
      };
    case SIGNUP:
      // We need to get this into state so we can pass it into Hubspot
      return {
        ...state,
        email: action.requestData.email,
        firstName: action.requestData.firstName,
        lastName: action.requestData.lastName,
        userId: action.payload.userId,
        authState: 'unconfirmed'
      };
    case ACTIVATE:
      return {
        ...state,
        authState: 'confirmed'
      };
    case `${LOAN_ACCOUNT_URL_GET}_START`:
      return {
        ...state,
        loanAccount: {
          status: 'loading'
        }
      };
    case `${LOAN_ACCOUNT_URL_GET}_FAIL`:
      return {
        ...state,
        loanAccount: {
          status: 'error'
        }
      };
    case LOAN_ACCOUNT_URL_GET:
      return {
        ...state,
        loanAccount: {
          status: 'content',
          url: action.payload.url
        }
      };
    case `${PERSONAL_DATA_LOAD}_FAIL`:
      return {
        ...state,
        authState:
          errorStatus === 401 || errorStatus === 403
            ? 'unauthenticated'
            : state.authState
      };
    case `${CONFIRM_NO_COMMUNITY_MATCH}_START`:
      return {
        ...state,
        communityId: action.requestData
      };
    case CONFIRM_NO_COMMUNITY_MATCH:
    case CONFIRM_COMMUNITY:
      return {
        ...state,
        communityId: action.payload
      };
    case LOAD_BANK_ACCOUNTS:
      return {
        ...state,
        plaidAccounts: action.payload
      };
    case GET_APPLICATIONS:
      return {
        ...state,
        applicationState: {
          applications: action.payload,
          isLoaded: true
        }
      };
    case GET_PREFERRED_PAYMENT_PROFILE:
      return {
        ...state,
        preferredPaymentProfile: action.payload
      };
    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload
      };
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload
      };
  }

  return state;
}
