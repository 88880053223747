import * as React from 'react';
import styles from './schedule.module.css';
import Icon from 'components/icon';

export interface PaymentInfoCardProps {
  step: number;
  title: string;
  messages: string[];
  disclaimers: string[];
}

/**
 * Displays Flexible Rent payment information to a user
 * @param props.step The number to display on the tile
 * @param props.title The title to display on the tile
 * @param props.messages The messages to display on the tile
 */
const PaymentInfoCard = ({
  step,
  title,
  messages,
  disclaimers
}: PaymentInfoCardProps) => {
  const messagesListItems = messages.map((message, index) => {
    return (
      <li key={index} className={styles.cardListItem}>
        <Icon type="check" className={styles.cardListCheck} />
        <p className={styles.cardListText}>{message}</p>
      </li>
    );
  });

  const disclaimerListItems = disclaimers.map((disclaimer, index) => {
    return (
      <p key={index} className={styles.disclaimer}>
        {disclaimer}
      </p>
    );
  });

  return (
    // <div className={styles.cardWithBackground}>
    //   <div className={styles.stepContainer}>
    //     <p className={styles.stepNumber}>{step}</p>
    //   </div>
    <div className={styles.contentContainer}>
      <div className={styles.cardHeadingContainer}>
        <h3 className={styles.cardHeading}>{title}</h3>
      </div>
      <ul className={styles.cardList}>{messagesListItems}</ul>
      <React.Fragment>{disclaimerListItems}</React.Fragment>
    </div>
    // </div>
  );
};

export default PaymentInfoCard;
