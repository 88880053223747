import * as React from 'react';
import Button from 'components/button';
import { push } from 'connected-react-router';
import { useThunkDispatch } from 'resources';
import { ROUTES } from 'routes';
import styles from './success.module.css';
import config from 'helpers/config';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'resources/user/user.selectors';
import { useUserData } from 'helpers/useUserData';
import { useAuthentication } from 'helpers/useAuthentication';
import { isUseUserDataLoading } from 'resources/loading/loading.selectors';
import { setContractComplete } from 'resources/flex/onboarding/onboarding.actions';
import Icon from 'components/icon';
import cx from 'classnames';

/**
 * Displays when a customer signs the Flexible Rent contract
 */
const Success = () => {
  useAuthentication();
  useUserData();

  const dispatch = useThunkDispatch();
  const user = useSelector(getUserAccount);
  const isLoading = useSelector(isUseUserDataLoading);

  React.useEffect(() => {
    dispatch(setContractComplete());
  }, [dispatch, user]);

  const onGetStarted = () => {
    dispatch(push(ROUTES.flex.onboarding.setPaymentAccount));
  };

  return (
    <div className={styles.content}>
      <p className={styles.mainHeading}>
        Thank You for Completing Your Contract!
      </p>
      <p className={styles.finishSetup}>
        Now, let's set up your payment account
      </p>
      <div className={styles.listContainer}>
        <div className={styles.stepsContainer}>
          <p className={styles.description}>
            <span className={cx(styles.stepNumber, styles.completeStep)}>
              <Icon type="check" className={styles.completedIcon} />
            </span>
            <span>Sign your contract</span>
          </p>
          <p className={styles.description}>
            <span
              className={cx(styles.stepNumber, styles.incompleteStep)}
            ></span>
            Connect a payment account
          </p>
          <p className={styles.description}>
            <span className={cx(styles.stepNumber, styles.incompleteStep)}>
              {' '}
            </span>{' '}
            Review and confirm upcoming payments
          </p>
        </div>
      </div>
      <Button
        className={styles.setupButton}
        type="primary"
        onClick={onGetStarted}
        disabled={isLoading}
      >
        Continue
      </Button>
      <p className={styles.contact}>
        If you have questions, our team is here to help at{' '}
        <a href={`mailto:${config.supportEmailAddress}`}>
          {config.supportEmailAddress}
        </a>{' '}
        or{' '}
        <a href={`tel:${config.supportPhoneNumber}`}>
          {config.supportPhoneNumber}
        </a>
      </p>
    </div>
  );
};

export default Success;
