import TillModal from './index';
import * as React from 'react';
import styles from './modal.module.css';

interface Props {
  onClose: () => void;
  title: string;
  message: string;
}

export function InformationModal({ onClose, title, message }: Props) {
  return (
    <TillModal visible className={styles.informationModal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
      </div>
    </TillModal>
  );
}
