import { Community } from '@hellotill/community-types';
import {
  PayFrequency,
  AssistanceReason,
  AssistanceEarningsType
} from 'domain/types';

type AssistanceReasonKeyValues = {
  [key: string]: AssistanceReason | undefined;
};
export const assistanceReasonOptions: AssistanceReasonKeyValues = {
  'Incurred a large unexpected expense': 'unexpected-expense',
  'Day-to-day expenses were higher than income': 'day-to-day-expenses',
  'Lower than expected income': 'lower-than-expected-income',
  'Lost job': 'lost-job',
  'Another reason': 'other'
};

type AssistanceEarningsTypeKeyValues = {
  [key: string]: AssistanceEarningsType | undefined;
};
export const assistanceEarningsTypeOptions: AssistanceEarningsTypeKeyValues = {
  'Fixed monthly or weekly salary': 'salaried',
  Hourly: 'hourly',
  'Commission-based': 'commissioned',
  Unemployed: 'unemployed',
  Other: 'other'
};

type FrequencyKeyValues = { [key: string]: PayFrequency | undefined };
export const frequencyOptions: FrequencyKeyValues = {
  Weekly: 'Weekly',
  'Every other week': 'Bi-weekly',
  'Semi-monthly': 'Semi-monthly',
  Monthly: 'Monthly'
};

// these day combinations are defined by LoanPro as valid days for semi-monthly payment schedules
// https://help.loanpro.io/article/8dsa154s7g-payment-frequencies
type SemiMonthlyPayDayValues = { [key: string]: number[] };
export const semiMonthlyPayDayOptions: SemiMonthlyPayDayValues = {
  '1st and 16th': [1, 16],
  '2nd and 17th': [2, 17],
  '3rd and 18th': [3, 18],
  '4th and 19th': [4, 19],
  '5th and 20th': [5, 20],
  '6th and 21st': [6, 21],
  '7th and 22nd': [7, 22],
  '8th and 23rd': [8, 23],
  '9th and 24th': [9, 24],
  '10th and 25th': [10, 25],
  '11th and 26th': [11, 26],
  '12th and 27th': [12, 27],
  '13th and 28th': [13, 28]
};

export interface AddressDetails {
  email: string;
  address1: string;
  address2?: string;
  zipCode: string;
  city: string;
  state: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  communityName?: string;
}

export interface CashFlowDetails {
  frequency: PayFrequency;
  payAmount: number;
  lastPayday?: Date;
  semiMonthlyPayDays?: number[];
  rentAmount: number;
  balance: number;
  rentalMonthAppliedFor: string;
}

// community name will exist if the user types in their community name
// instead of selecting one from the list
export interface CommunityApplicationDetails {
  communityData?: Partial<Community>;
  isUnmatched: boolean;
}

export interface CashFlowDetailsInput {
  frequency?: PayFrequency;
  payAmount?: number;
  lastPayday?: Date;
  semiMonthlyPayDays?: number[];
  rentAmount?: number;
  balance?: number;
  rentalMonthAppliedFor?: string;
}

export interface RentHelpDetailsInput {
  assistanceRequested?: boolean;
  assistanceAbleToPayAmount?: number;
  assistanceReason?: AssistanceReason;
  assistanceEarningsType?: AssistanceEarningsType;
}

export interface RevisedCashFlowInput {
  frequency?: PayFrequency;
  lastPayday?: Date;
  semiMonthlyPayDays?: number[];
  rentAmount?: number;
  balance?: number;
}
