import * as React from 'react';

export default function Coins() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76923 3C6.03846 3 3 6.03846 3 9.76923C3 12.2885 4.38942 14.4904 6.4375 15.6538C7.65865 17.7212 9.85096 19 12.2308 19C15.9615 19 19 15.9615 19 12.2308C19 9.85096 17.7212 7.65865 15.6538 6.4375C14.4904 4.38942 12.2885 3 9.76923 3ZM9.76923 4.23077C11.774 4.23077 13.5144 5.3125 14.4856 6.91346L14.6154 7.13942C14.7933 7.47115 14.9279 7.80769 15.0385 8.14904C15.0769 8.26442 15.1202 8.37981 15.149 8.5C15.1731 8.61058 15.1923 8.72596 15.2115 8.83654C15.2644 9.14423 15.3077 9.45192 15.3077 9.76923C15.3077 12.8221 12.8221 15.3077 9.76923 15.3077C9.45192 15.3077 9.14423 15.2644 8.83654 15.2115C8.72596 15.1923 8.61058 15.1731 8.5 15.149C8.37981 15.1202 8.26442 15.0769 8.14904 15.0385C7.80769 14.9279 7.47115 14.7933 7.13942 14.6154L6.67308 14.3606C5.20192 13.3654 4.23077 11.6779 4.23077 9.76923C4.23077 6.71635 6.71635 4.23077 9.76923 4.23077ZM9.13942 6.69231C9.13942 7.38462 8.75962 7.92308 7.92308 7.92308V9.15385C8.38462 9.15385 8.80289 9.02404 9.15385 8.81731V12.8462H10.3846V6.69231H9.13942ZM16.4423 8.66346C17.2885 9.64904 17.7692 10.9087 17.7692 12.2308C17.7692 15.2837 15.2837 17.7692 12.2308 17.7692C10.9087 17.7692 9.64904 17.2885 8.66346 16.4423C8.6875 16.4471 8.71154 16.4471 8.73077 16.4471C9.07212 16.5 9.41346 16.5385 9.76923 16.5385C10.2356 16.5385 10.6923 16.4904 11.1346 16.4038C12.0144 16.2212 12.8317 15.8654 13.5481 15.3798C15.351 14.1635 16.5385 12.101 16.5385 9.76923C16.5385 9.41346 16.5 9.07212 16.4471 8.73077C16.4471 8.71154 16.4471 8.6875 16.4423 8.66346Z"
        fill="#475F7B"
      />
    </svg>
  );
}
