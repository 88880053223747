import { Spin } from 'antd';
import cx from 'classnames';
import Button from 'components/button';
import TillInput from 'components/form-input';
import TillModal from 'components/modal';
import { useAuthentication } from 'helpers/useAuthentication';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { searchCommunity } from 'resources/community/community.action';
import baseStyles from 'styles/base.module.css';
import { useThunkDispatch } from '../../resources';
import { getSearchResults } from '../../resources/community/community.selectors';
import { CommunitySearchResult } from '../../resources/community/types';
import {
  confirmCommunity,
  confirmNoCommunityMatch
} from '../../resources/user/user.actions';
import styles from './onboarding.module.css';

export function MyCommunity() {
  useAuthentication();

  const [isLoading, setLoading] = React.useState(false);
  const [selectedCommunity, setSelectedCommunity] = React.useState<
    CommunitySearchResult
  >();
  const searchResults = useSelector(getSearchResults);
  const dispatch = useThunkDispatch();

  const search = React.useCallback(
    debounce(
      async (name: string) => {
        try {
          await dispatch(searchCommunity(name));
        } finally {
          setLoading(false);
        }
      },
      250,
      {
        trailing: true
      }
    ),

    [dispatch]
  );
  const onCommunityNameChange = (event: React.ChangeEvent<any>) => {
    setLoading(true);
    const name = event.target.value;
    search(name);
  };

  const onSelectCommunity = (community: CommunitySearchResult) => {
    setSelectedCommunity(community);
  };

  const onConfirmNoMatch = () => {
    dispatch(confirmNoCommunityMatch());
  };

  const onCloseConfirmationModal = () => {
    setSelectedCommunity(undefined);
  };

  const onConfirmCommunity = (community: CommunitySearchResult) => {
    setSelectedCommunity(undefined);
    dispatch(confirmCommunity(community));
  };

  const onUpdateInformation = () => {
    setSelectedCommunity(undefined);
  };

  return (
    <div className={styles.container}>
      <h1>My Community</h1>
      <h3>This helps us know what products you're eligible for</h3>

      <div className={baseStyles.row}>
        <div className={cx(baseStyles.col, styles.input)}>
          <TillInput
            label="Community Name"
            placeholder="Start typing to see a list"
            name="community"
            onChange={onCommunityNameChange}
            valid
            setFieldTouched={() => {}}
          />
        </div>
        <div>
          <Spin
            className={cx(
              styles.spin,
              baseStyles.col25,
              !isLoading && styles.hidden
            )}
            size="large"
          />
        </div>
      </div>

      {searchResults.length > 0 && (
        <div className={styles.matches}>
          {searchResults.map(community => (
            <CommunityCard
              key={community.communityId}
              showButton
              community={community}
              onCommunityClick={onSelectCommunity}
            />
          ))}
        </div>
      )}

      {searchResults.length > 0 && (
        <Button type={'link'} onClick={onConfirmNoMatch}>
          I don't see my community
        </Button>
      )}

      {selectedCommunity && (
        <ConfirmCommunityModal
          community={selectedCommunity}
          onClose={onCloseConfirmationModal}
          onConfirm={onConfirmCommunity}
          onReject={onUpdateInformation}
        />
      )}
    </div>
  );
}

interface CommunityProps {
  community: CommunitySearchResult;
  onCommunityClick?: (community: CommunitySearchResult) => void;
  showButton?: boolean;
}
function CommunityCard({
  community,
  onCommunityClick,
  showButton
}: CommunityProps) {
  return (
    <div className={styles.card}>
      <h2>{community.name}</h2>
      <p className={styles.streetAddress}>{community.address1}</p>
      <p className={styles.cityStateZip}>
        {community.city}, {community.state} {community.postalCode}
      </p>
      {showButton && (
        <Button
          onClick={() => {
            onCommunityClick && onCommunityClick(community);
          }}
        >
          I live here
        </Button>
      )}
    </div>
  );
}

interface ConfirmCommunityModalProps {
  community: CommunitySearchResult;
  onClose: () => void;
  onConfirm: (community: CommunitySearchResult) => void;
  onReject: (community: CommunitySearchResult) => void;
}
function ConfirmCommunityModal({
  onClose,
  community,
  onConfirm,
  onReject
}: ConfirmCommunityModalProps) {
  return (
    <TillModal visible onClose={onClose}>
      <div className={styles.confirmationModal}>
        <h1>Confirm Community</h1>
        <h3>We want to confirm that you live in</h3>
        <CommunityCard community={community} />
        <div className={styles.buttons}>
          <Button
            type="primary"
            onClick={() => {
              onConfirm(community);
            }}
          >
            Yes! That's my community
          </Button>
          <Button
            type="button"
            onClick={() => {
              onReject(community);
            }}
          >
            No, update my information
          </Button>
        </div>
      </div>
    </TillModal>
  );
}
