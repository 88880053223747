import * as React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import AnalyticsListener from './AnalyticsListener';
import Layout from './pages/layout';
import { Store } from './resources/types';
import configureStore from './resources/store';

const initialState: Partial<Store> = {};

const history = createBrowserHistory();
const store = configureStore(initialState, history);

export default function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AnalyticsListener history={history}>
            <Layout />
          </AnalyticsListener>
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>
  );
}
