import { AnyAction } from 'redux';

import { LoanAccountState, PaymentProfile } from './types';
import {
  GET_LOANS_BY_CUSTOMER,
  LOAD_PAYMENT_TOKEN,
  LOAD_PAYMENT_PROFILES,
  LOAD_AUTOPAYS
} from './account.actions';

const defaultState: LoanAccountState = {
  id: 0,
  autopayEnabled: false,
  pastDue: 'current',
  amountDue: 0,
  daysPastDue: 0,
  nextPaymentDate: new Date(),
  nextPaymentAmount: 0,
  loanAmount: 0,
  loanStatusText: 'Loading',
  loanSubStatusText: 'Performing',
  payoff: 0,
  principalBalance: 0,
  transactions: [],
  state: 'Virginia',
  paymentProfiles: [],
  paymentFrequency: 'loan.frequency.biWeekly',
  payments: []
};

/**
 * Returns the difference of paymentMethods between paymentProfiles before a new payment is added and after a new payment is added
 * Used to change the selected Payment Method highlighted in the paynow modal
 */
export function loadPaymentProfilesDiff(
  previousPaymentProfiles: PaymentProfile[],
  currentPaymentProfiles: PaymentProfile[]
): any {
  const previousIds = previousPaymentProfiles
    .filter(profile => profile.active)
    .map(profile => profile.paymentProfileId);
  const currentIds = currentPaymentProfiles
    .filter(profile => profile.active)
    .map(profile => profile.paymentProfileId);

  const diffIds = currentIds
    .filter(id => !previousIds.includes(id))
    .concat(previousIds.filter(id => !currentIds.includes(id)));

  if (diffIds.length !== 1) {
    return null;
  }

  const diffId = diffIds[0];
  return currentPaymentProfiles.find(
    paymentProfile => paymentProfile.paymentProfileId === diffId || null
  );
}

export default function accountReducer(
  state: LoanAccountState | undefined = defaultState,
  action: AnyAction
): LoanAccountState {
  const previousPaymentProfiles = state.paymentProfiles;
  switch (action.type) {
    case GET_LOANS_BY_CUSTOMER:
      return {
        ...state,
        ...action.payload.loan
      };
    case LOAD_PAYMENT_TOKEN:
      return {
        ...state,
        oboToken: action.payload.oboToken
      };
    case LOAD_PAYMENT_PROFILES:
      return {
        ...state,
        paymentProfiles: action.payload,
        newPaymentMethod: loadPaymentProfilesDiff(
          previousPaymentProfiles,
          action.payload
        )
      };
    case LOAD_AUTOPAYS:
      return {
        ...state,
        ...action.payload
      };
  }
  return state;
}
