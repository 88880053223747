import * as React from 'react';

export default function House() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50">
      <g transform="translate(-41 -283)">
        <circle
          cx="25"
          cy="25"
          r="25"
          fill="#1eb5f0"
          transform="translate(41 283)"
        />
        <path
          fill="#fff"
          d="M17.735 11.134v8.445h-5.067v-5.067H7.6v5.067H2.534v-8.445H0L10.134 1l10.134 10.134zm-.845-4.988v-4.3h-2.533v1.766z"
          transform="translate(55.866 296.71)"
        />
      </g>
    </svg>
  );
}
