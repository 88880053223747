import * as React from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import Icon from '../icon';

import styles from './modal.module.css';

interface Props {
  className?: string;
  visible: boolean;
  onClose: () => any;
  children: React.ReactChild | React.ReactChild[];
}

function TillModal({ className, visible, onClose, children }: Props) {
  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      className={cx(styles.modal, className)}
      overlayClassName={styles.modalOverlay}
    >
      <button type="button" className={styles.closeBtn} onClick={onClose}>
        <Icon type="close" className={styles.closeIcon} />
      </button>
      <div className={styles.content}>{children}</div>
    </Modal>
  );
}

export default TillModal;
