import { Store } from '../../types';
import { LoanAccountState, PaymentProfile } from './types';

export default function getLoanDetails({ loan }: Store): LoanAccountState {
  if (!loan) {
    return {
      id: 171,
      autopayEnabled: true,
      pastDue: 'current',
      amountDue: 153.43,
      daysPastDue: 0,
      nextPaymentDate: new Date(),
      nextPaymentAmount: 51.33,
      loanAmount: 0,
      loanStatusText: 'Loading',
      loanSubStatusText: 'Performing',
      payoff: 154.45,
      principalBalance: 434.23,
      paymentFrequency: 'loan.frequency.biWeekly',
      state: 'Virginia',
      transactions: [],
      paymentProfiles: [],
      payments: []
    };
  }
  return loan;
}

export function getPaymentToken({ loan }: Store) {
  return loan && loan.oboToken;
}

export function getPaymentMethods({ loan }: Store): PaymentProfile[] {
  if (!loan) {
    return [];
  }

  return loan.paymentProfiles.filter(paymentProfile => paymentProfile.active);
}

export function getNewPaymentMethod({ loan }: Store) {
  return loan && loan.newPaymentMethod;
}

export function getLoanPayments({ loan }: Store) {
  if (!loan) {
    return { transactions: [], scheduledPayments: [], autopays: [] };
  }

  return {
    transactions: loan.transactions,
    scheduledPayments: loan.payments.filter(
      payment =>
        payment.type === 'autopay.type.single' &&
        payment.status === 'autopay.status.pending'
    ),
    autopays: loan.payments.filter(
      payment =>
        payment.type === 'autopay.type.recurring' &&
        payment.status === 'autopay.status.pending'
    )
  };
}
