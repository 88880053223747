import { SubmitApplicationData } from './../resources/flex/apply/apply.api';
import { PaymentAccount } from 'resources/flex/onboarding/onboarding.reducer';
import moment from 'moment';
import { ResetPassword, UserAccountData } from 'resources/user/types';
import {
  FlexApplication,
  PasswordResetRequest,
  PayFrequency,
  User
} from './types';

import { DisplayData } from 'domain/types';
import { CashFlowDetails } from 'resources/flex/apply/types';

const LAST_PAYDAY_DATE_FORMAT = 'YYYY-MM-DD';
/**
 * Creates an application object to send to the API from the user's inputs
 * @param user The user ID of the user submitting the application
 * @param flexApplyState The flex application data
 */
export const createFlexibleRentApplicationRequest = (
  data: SubmitApplicationData
) => {
  const user = data.user;
  const address = data.flexApplyState.address;
  const cashFlow = {
    ...data.flexApplyState.cashFlow,
    balance: 0 // TODO: Need a better way to handle this
  } as CashFlowDetails;

  const community = data.flexApplyState.community;
  if (address && cashFlow) {
    const semiMonthlyFirstPayDay =
      cashFlow.semiMonthlyPayDays && cashFlow.semiMonthlyPayDays[0];
    const semiMonthlySecondPayDay =
      cashFlow.semiMonthlyPayDays && cashFlow.semiMonthlyPayDays[1];
    delete cashFlow.semiMonthlyPayDays;

    return {
      ...cashFlow,
      lastPayday:
        cashFlow.lastPayday &&
        moment.utc(cashFlow.lastPayday).format(LAST_PAYDAY_DATE_FORMAT),
      semiMonthlyFirstPayDay,
      semiMonthlySecondPayDay,
      userId: user.userId,
      communityId:
        (community.communityData && community.communityData.communityId) ||
        'unmatched',
      communityName: address.communityName,
      /*
        This will default enrollment date to tomorrow for all applications.
        It will help us ensure that applications can create valid
        payment schedules, and enable us to update them if necessary
      */
      enrollmentDate: moment()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      residentCode:
        data.flexApplyState.confirmedRentRollMatch &&
        data.flexApplyState.confirmedRentRollMatch.resident_code,
      householdId:
        data.flexApplyState.confirmedRentRollMatch &&
        data.flexApplyState.confirmedRentRollMatch.household_id,
      user: {
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phoneNumber: transformPhoneNumber(user.phone || ''),
        address1: address.address1 || '',
        address2: address.address2 || '',
        city: address.city || '',
        state: address.state || '',
        postalCode: address.zipCode.slice(0, 5) || ''
      },
      referralCode: data.referralCode,
      assistanceAbleToPayAmount: data.flexApplyState.assistanceAbleToPayAmount,
      assistanceEarningsType: data.flexApplyState.assistanceEarningsType,
      assistanceReason: data.flexApplyState.assistanceReason,
      assistanceRequested: data.flexApplyState.assistanceRequested
    };
  }

  return undefined;
};

export function createApplicationToUpdate(data: any) {
  const application = {
    payAmount: data.payAmount,
    householdId: data.householdId,
    status: data.status,
    user: data.user,
    balance: data.balance,
    assistanceRequested: data.assistanceRequested,
    communityName: data.communityName,
    enrollmentDate: data.enrollmentDate,
    frequency: data.frequency,
    communityId: data.communityId,
    lastPayday: data.lastPayday,
    rentalMonthAppliedFor: data.rentalMonthAppliedFor,
    applicationType: data.applicationType,
    residentCode: data.residentCode,
    monthlyFee: data.monthlyFee,
    rentAmount: data.rentAmount,
    assistanceAbleToPayAmount: data.assistanceAbleToPayAmount,
    assistanceReason: data.assistanceReason,
    assistanceEarningsType: data.assistanceEarningsType
  };

  return application;
}

export function transformUser(user: User): UserAccountData {
  return {
    userId: user.userId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    dateOfBirth: user.birthdate
      ? moment(user.birthdate, 'YYYY-MM-DD').toDate()
      : undefined,
    phone: user.phoneNumber ? user.phoneNumber.replace('+1', '') : undefined,
    rentalAddress: {
      address1: user.address1 || undefined,
      address2: user.address2 || undefined,
      city: user.city || undefined,
      state: user.state || undefined,
      zip: user.postalCode || undefined
    },
    communityId: user.communityId,
    hasActiveSubscription: user.subscriptions.some(
      (sub: any) => sub.status === 'active'
    ),
    activeSubscription: user.subscriptions.find(
      (sub: any) => sub.status === 'active'
    )
  };
}

export function transformPhoneNumber(phoneNumber: string) {
  return `+1${phoneNumber.replace(/-/g, '')}`;
}

function transformDateOfBirth(dateOfBirth: Date) {
  return moment(dateOfBirth).format('YYYY-MM-DD');
}

export function transformAccountData(
  data?: UserAccountData
): Partial<User> | undefined {
  if (!data) {
    return undefined;
  }

  return {
    firstName: data.firstName,
    lastName: data.lastName,
    birthdate: data.dateOfBirth ? transformDateOfBirth(data.dateOfBirth) : null,
    phoneNumber: data.phone ? transformPhoneNumber(data.phone) : null,
    address1: data.rentalAddress.address1 || null,
    address2: data.rentalAddress.address2 || null,
    city: data.rentalAddress.city || null,
    state: data.rentalAddress.state || null,
    postalCode: data.rentalAddress.zip || null
  };
}

export function transformPasswordReset(
  data: ResetPassword
): PasswordResetRequest | null {
  return {
    email: data.email,
    code: data.code.trim(),
    password: data.password
  };
}

/**
 * Transforms a Flexible Rent application into
 * the fields required by the calculator
 */
export const transformApplicationForCalculator = (
  application: FlexApplication
) => {
  if (!application.monthlyFee) {
    throw new Error('Monthly fee is missing from application');
  }

  return {
    frequency: application.frequency,
    lastPayday: application.lastPayday,
    semiMonthlyIncomeOneFirstPayDate:
      application.semiMonthlyIncomeOneFirstPayDate,
    semiMonthlyIncomeOneSecondPayDate:
      application.semiMonthlyIncomeOneSecondPayDate,
    rentAmount: application.rentAmount,
    verifiedRentAmount: application.verifiedRentAmount,
    balance: application.balance,
    rentalMonthAppliedFor: application.rentalMonthAppliedFor,
    enrollmentDate: application.enrollmentDate,
    monthlyFee: application.monthlyFee,
    leaseEndDate: application.leaseEndDate
  };
};

/**
 * Transforms a selected payment account into what is expected by the enrollment API
 */
export const transformPaymentMethod = (paymentSelection: PaymentAccount) => {
  if (paymentSelection.accountType === 'plaid') {
    return {
      accountType: paymentSelection.accountType,
      plaidItemId:
        paymentSelection.plaidBankItem &&
        paymentSelection.plaidBankItem.plaidItemId,
      plaidAccountId:
        paymentSelection.plaidBankAccount &&
        paymentSelection.plaidBankAccount.account_id
    };
  } else if (paymentSelection.selectedPaymentProfileId) {
    return {
      paymentProfileId: paymentSelection.selectedPaymentProfileId
    };
  } else {
    return {
      accountType: paymentSelection.accountType,
      accountTitle: paymentSelection.pciAccountTitle,
      accountToken: paymentSelection.pciAccountToken
    };
  }
};

interface Schedule {
  metadata?: {
    description: string;
    startDate: string;
    startingPaymentsDates: string[];
    startingPaymentsAmount: string;
    recurringPaymentDate: string;
    recurringPaymentAmount: string;
    enrollmentPaymentAmount: string;
  };
}

/**
 * @description transforms responses from v2 calculator into [DisplayData] for compatibility
 */
export const transformToDisplayData = (data: any, frequency: PayFrequency) => {
  const { weekly, bi_weekly, monthly, semi_monthly, fee } = data;

  let schedule: Schedule = {};

  if (frequency === 'Weekly') {
    schedule = weekly;
  }

  if (frequency === 'Bi-weekly') {
    schedule = bi_weekly;
  }

  if (frequency === 'Monthly') {
    schedule = monthly;
  }

  if (frequency === 'Semi-monthly') {
    schedule = semi_monthly;
  }

  // transform into display data for compatibility
  const displayData = ({
    fee,
    initialPayment:
      schedule.metadata &&
      parseFloat(schedule.metadata.enrollmentPaymentAmount),
    firstRecurringPaymentDate: schedule.metadata && schedule.metadata.startDate,
    currentMonthPayments:
      schedule.metadata &&
      schedule.metadata.startingPaymentsDates.map(date => ({
        date,
        amount:
          schedule.metadata &&
          parseFloat(schedule.metadata.startingPaymentsAmount)
      })),
    frequency,
    futureMonthPerPayment:
      schedule.metadata && parseFloat(schedule.metadata.recurringPaymentAmount),
    futureMonthPaymentDate:
      schedule.metadata && schedule.metadata.recurringPaymentDate,
    hasCurrentMonthPayments:
      schedule.metadata && schedule.metadata.startingPaymentsDates.length !== 0,
    monthlyPaymentDays: []
  } as unknown) as DisplayData;

  // add the day of the month as an ordinal
  // Monthly example [1st]
  // Semi-monthly example [1st, 16th]
  const monthlyPaymentDays: string[] = [];
  if (frequency === 'Semi-monthly') {
    monthlyPaymentDays.push('1st');
    monthlyPaymentDays.push('16th');
  } else {
    const formattedDay = moment(
      schedule.metadata && schedule.metadata.startDate
    ).format('Do');
    monthlyPaymentDays.push(formattedDay);
  }

  displayData.monthlyPaymentDays = monthlyPaymentDays;

  return displayData;
};
