import {
  createApplicationToUpdate,
  createFlexibleRentApplicationRequest,
  transformToDisplayData
} from './../../../domain/transforms';
import axios from 'axios';
import config from 'helpers/config';
import { FlexApplyState } from './apply.reducer';
import { handleError } from 'helpers/api.error';
import { PayFrequency } from 'domain/types';

export interface GetScheduleArgs {
  communityId?: string;
  frequency: PayFrequency;
  anchorDate?: string;
  baseRent?: number;
  totalBilling?: number;
  outstandingBalance?: number;
  cashOnHand: number;
}

export const getSchedule = async (args?: GetScheduleArgs, headers?: any) => {
  try {
    if (!args) throw new Error();

    const { frequency } = args;

    const url = `${config.apiUrl}/schedule`;

    const { data } = await axios.post(url, args, { headers });

    const displayData = transformToDisplayData(data, frequency);

    return displayData;
  } catch (err) {
    return handleError(
      new Error('Could not load schedule'),
      'Could not load schedule'
    );
  }
};

export interface GetBudgetAndSaveScheduleArgs {
  applicationId?: any;
}

export const getBudgetAndSaveSchedule = async (
  applicationId?: any,
  headers?: any
) => {
  try {
    if (!applicationId) throw new Error();

    const response = await axios.get(
      `${config.apiUrl}/application/${applicationId}/schedule`,
      { headers }
    );

    return response.data;
  } catch (err) {
    return handleError(
      new Error('Could not load schedule'),
      'Could not load schedule'
    );
  }
};

export interface SubmitApplicationData {
  flexApplyState: FlexApplyState;
  user?: any;
  referralCode?: string;
  applicationId?: string;
}

export interface ApproveApplicationData {
  applicationId: string;
}

export interface SubmitApplicationResponse {
  applicationId: string;
}

/**
 * Creates a Flexible Rent application
 */
export const submitApplication = async (
  data?: SubmitApplicationData,
  headers?: any
): Promise<SubmitApplicationResponse> => {
  try {
    if (data) {
      // The in-progress application from local storage is already properly transformed.
      const application = createFlexibleRentApplicationRequest(data);

      if (!application) {
        throw new Error();
      }

      const response = await axios.post(
        `${config.apiUrl}/application`,
        application,
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to create application');
  }
};

/**
 * Submits existing application
 */
export const submitBudgetAndSaveApplication = async (
  data?: SubmitApplicationData,
  headers?: any
) => {
  try {
    if (data) {
      const application = createFlexibleRentApplicationRequest(data);

      if (!application) {
        throw new Error();
      }

      const response = await axios.post(
        `${config.apiUrl}/application`,
        application,
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to submit application');
  }
};

/**
 * Approves existing application
 */
export const approveBudgetAndSaveApplication = async (
  data?: ApproveApplicationData,
  headers?: any
) => {
  try {
    if (data) {
      // Next request approves the application
      const response = await axios.put(
        `${config.apiUrl}/application/${data.applicationId}/approve`,
        {},
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to approve application');
  }
};

/**
 * Creates a Budget & Save application
 */
export const submitAndApproveBudgetAndSaveApplication = async (
  data?: SubmitApplicationData,
  headers?: any
): Promise<SubmitApplicationResponse> => {
  try {
    if (data) {
      const application = createFlexibleRentApplicationRequest(data);

      if (!application) {
        throw new Error();
      }

      // First request submits the application
      const response = await axios.post(
        `${config.apiUrl}/application`,
        application,
        { headers }
      );

      // Next request approves the application
      await axios.put(
        `${config.apiUrl}/application/${response.data}/approve`,
        {},
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to create application');
  }
};

/**
 * Accepts the schedule on the application
 */
export const acceptSchedule = async (
  data?: any,
  headers?: any
): Promise<SubmitApplicationResponse> => {
  try {
    if (data) {
      // Accept schedule
      const response = await axios.put(
        `${config.apiUrl}/application/${data.applicationId}/accept-schedule`,
        { ...data.scheduleData },
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to update application');
  }
};

/**
 * Updates the existing application
 */
export const updateApplication = async (
  data?: any,
  headers?: any
): Promise<SubmitApplicationResponse> => {
  try {
    if (data) {
      const application = createApplicationToUpdate(data.application);

      // Update schedule
      const response = await axios.put(
        `${config.apiUrl}/api/v2/flex/application/${data.applicationId}`,
        application,
        { headers }
      );

      return response.data;
    } else {
      throw new Error();
    }
  } catch (err) {
    return handleError(err, 'Unable to update application');
  }
};
