import { Spin } from 'antd';
import cx from 'classnames';
import Button from 'components/button';
import { InformationModal } from 'components/modal/information-modal';
import PayNowModal from 'components/pay-now/pay-now-modal';
import ContactSupportModal from 'components/support/modal';
import { push } from 'connected-react-router';
import config from 'helpers/config';
import { format as formatNumber } from 'helpers/number';
import { usePaymentProfiles } from 'helpers/usePaymentProfiles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import {
  addPaymentProfile,
  cancelPaymentAction,
  loadLoansByCustomer,
  loadPaymentToken,
  makePayment
} from 'resources/loan/account/account.actions';
import getLoanDetails, {
  getLoanPayments,
  getNewPaymentMethod,
  getPaymentMethods
} from 'resources/loan/account/account.selectors';
import {
  PaymentProfile,
  ScheduledPayment,
  Transaction
} from 'resources/loan/account/types';
import { getUserAccount } from 'resources/user/user.selectors';
import { EditPayment } from '../../components/pay-now/edit-payment';
import { getPaymentCard, getSummaryCard } from './cards/cards';
import Chart from './chart';
import styles from './dashboard.module.css';
import OverdueAlert from './overdue-alert';

function DashboardOverview() {
  usePaymentProfiles();

  const [isPayNowModalOpen, setPayNowModalOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [selectedTransaction, setSelectedTransaction] = React.useState<
    Transaction | undefined
  >(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isPaymentSuccessful, setPaymentSuccessful] = React.useState(false);
  const [
    isCancelPaymentSuccessful,
    setCancelPaymentSuccessful
  ] = React.useState(false);
  const [paymentToEdit, setPaymentToEdit] = React.useState<
    ScheduledPayment | undefined
  >();
  const dispatch = useThunkDispatch();

  const { userId, email } = useSelector(getUserAccount);
  const loan = useSelector(getLoanDetails);
  const { autopays, scheduledPayments, transactions } = useSelector(
    getLoanPayments
  );
  const paymentProfiles = useSelector(getPaymentMethods);
  const selectedPaymentMethod = useSelector(getNewPaymentMethod);

  const onEnableAutopayment = () => {
    dispatch(push(`/payments`));
  };

  const onPayNowClick = () => {
    setPayNowModalOpen(true);
  };

  const onPayNowClose = () => {
    setPaymentToEdit(undefined);
    setPayNowModalOpen(false);
  };

  const onCloseErrorModal = () => {
    setErrorMessage(undefined);
  };

  const onCloseSuccessModal = () => {
    setPaymentSuccessful(false);
    setPayNowModalOpen(false);
    setCancelPaymentSuccessful(false);
  };

  const onSchedulePaymentClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setPayNowModalOpen(true);
  };

  const onEditPaymentClick = (payment: ScheduledPayment) => {
    setPaymentToEdit(payment);
  };

  const handlePaymentProfileEvent = (
    status?: number,
    accountToken?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => {
    if (status === 200 && accountToken && accountTitle && accountType) {
      (async () => {
        await dispatch(
          addPaymentProfile(userId, accountToken, accountTitle, accountType)
        );
      })();
    }
  };

  const submitPayment = (
    amount: number,
    date: Date,
    paymentProfile: PaymentProfile,
    paymentId?: number
  ) => {
    (async () => {
      try {
        setSubmitting(true);
        await dispatch(
          makePayment(
            {
              amount,
              date,
              info: `One-time payment scheduled on ${date}`,
              loanId: loan.id,
              paymentProfileId: paymentProfile.paymentProfileId,
              paymentProfileType: paymentProfile.type
            },
            paymentId
          )
        );
        setPaymentSuccessful(true);
      } catch (error) {
        setErrorMessage(
          error.message || 'We were unable to complete your payment'
        );
      }
      setSubmitting(false);
    })();
  };

  const editPayment = (payment: ScheduledPayment) => {
    setPayNowModalOpen(true);
  };

  const cancelPayment = async (payment: ScheduledPayment) => {
    setPaymentToEdit(undefined);
    try {
      await dispatch(cancelPaymentAction(payment, loan.id, userId));
      setCancelPaymentSuccessful(true);
    } catch (error) {
      setErrorMessage('We were unable to cancel your payment.');
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (userId.length) {
          await dispatch(loadLoansByCustomer(userId));
          setLoading(false);
        } else {
        }
      } catch (error) {}
    })();
  }, [dispatch, userId]);

  React.useEffect(() => {
    (async () => {
      try {
        if (!loan.oboToken) {
          await dispatch(loadPaymentToken());
        } else {
        }
      } catch (error) {}
    })();
  }, [dispatch, loan.oboToken]);

  const paidAmount = loan.loanAmount - loan.principalBalance;
  const percentPaid = ((paidAmount || 0) / loan.loanAmount) * 100;
  const isValidLoan = loan.id > 0 && loan.loanStatusText !== 'Closed';

  return (
    <div>
      <h1 className={styles.title}>Overview</h1>
      {isLoading ? (
        <div>
          <Spin className={styles.spin} size="large" tip="Loading Loan" />
        </div>
      ) : (
        <div className={styles.layout}>
          {isValidLoan ? (
            <div>
              <section className={styles.col}>
                {getSummaryCard(loan, onPayNowClick)}
                {getPaymentCard(
                  transactions,
                  scheduledPayments,
                  autopays,
                  onEnableAutopayment,
                  onSchedulePaymentClick,
                  onEditPaymentClick
                )}
                <div className={cx(styles.card, styles.paymentInfo)}>
                  {loan.daysPastDue > 0 ? (
                    <OverdueAlert
                      className={styles.alert}
                      days={Math.abs(loan.daysPastDue)}
                    />
                  ) : (
                    <div className={styles.paymentDetails}>
                      <p>
                        Did you know, you can save money by making an extra
                        payment now? Pay Now with your Bank Account or Debit
                        Card
                      </p>
                    </div>
                  )}
                </div>
              </section>
              {!['Virginia', null].includes(loan.state) && ( // hiding if state is Virginia or null
                <section className={cx(styles.card, styles.stats)}>
                  <Chart value={percentPaid}>
                    <div className={styles.chartInner}>
                      <span>{percentPaid.toFixed(0)}%</span>
                      <span>Already paid off</span>
                    </div>
                  </Chart>
                  <table className={styles.amounts}>
                    <tbody>
                      <tr>
                        <td>Paid off:</td>
                        <td>${formatNumber(paidAmount || 0, 2)}</td>
                      </tr>
                      <tr>
                        <td>Left to Pay:</td>
                        <td>${formatNumber(loan.payoff, 2)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={styles.supportText}>
                    <span>
                      Please give us a call at {config.supportPhoneNumber},
                      option #2 for your most updated payoff amount.
                    </span>
                  </div>
                  {percentPaid > 75 ? (
                    <div className={styles.action}>
                      <Button type="primary" className={styles.actionBtn}>
                        Apply for New Loan
                      </Button>
                      <p>
                        {
                          'Congrats! You are eligible for a new loan by paying off >75% of the previous one.'
                        }
                      </p>
                    </div>
                  ) : null}
                </section>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
      {isPayNowModalOpen && (
        <PayNowModal
          showDebitCard={true}
          min={loan.amountDue}
          max={loan.payoff}
          amount={
            paymentToEdit
              ? paymentToEdit.amount
              : selectedTransaction
              ? selectedTransaction.paymentAmount
              : loan.amountDue
          }
          date={
            paymentToEdit
              ? paymentToEdit.date
              : selectedTransaction
              ? selectedTransaction.date
              : new Date()
          }
          visible
          makePayment={submitPayment}
          onClose={onPayNowClose}
          debitIframeUrl={`https://securepayments.loanpro.io/api/capture-form/${loan.oboToken}`}
          achIframeUrl={`https://securepayments.loanpro.io/api/check-capture-form/${loan.oboToken}?account_type=checking`}
          handlePaymentProfileEvent={handlePaymentProfileEvent}
          methods={paymentProfiles}
          isSubmitting={isSubmitting}
          selectedMethod={selectedPaymentMethod || paymentProfiles[0]}
          paymentId={paymentToEdit && paymentToEdit.id}
        />
      )}
      {errorMessage && (
        <ContactSupportModal
          onClose={onCloseErrorModal}
          supportPhoneNumber={config.supportPhoneNumber}
          message={errorMessage}
        />
      )}

      {isPaymentSuccessful && (
        <InformationModal
          onClose={onCloseSuccessModal}
          title={'Success!'}
          message={`We have received your payment request. We will send you an email with confirmation number to ${email} as soon as we have processed the payment.`}
        />
      )}

      {isCancelPaymentSuccessful && (
        <InformationModal
          onClose={onCloseSuccessModal}
          title={'Payment Canceled'}
          message={`To reschedule a payment, click "Schedule Payment" on any of your future due dates.`}
        />
      )}

      {paymentToEdit && (
        <EditPayment
          payment={paymentToEdit}
          onClose={() => {
            setPaymentToEdit(undefined);
          }}
          onEditPaymentClick={editPayment}
          onCancelPaymentClick={cancelPayment}
        />
      )}
    </div>
  );
}

export default DashboardOverview;
