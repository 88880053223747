import { LoadingAction } from './loading.actions';

export type LoadingState = 'default' | 'loading' | 'done' | 'error';

export type LoadingDataState = {
  dashboardDataLoadingState: LoadingState;
  personalDataLoadingState: LoadingState;
  communityLoadingState: LoadingState;
};

const initialState: LoadingDataState = {
  dashboardDataLoadingState: 'default',
  personalDataLoadingState: 'default',
  communityLoadingState: 'default'
};

const loadingReducer = (
  state: LoadingDataState = initialState,
  action: LoadingAction
): LoadingDataState => {
  switch (action.type) {
    case 'SET_PERSONAL_DATA_LOADING_STATE':
      return {
        ...state,
        personalDataLoadingState: action.payload
      };
    case 'SET_COMMUNITY_LOADING_STATE':
      return {
        ...state,
        communityLoadingState: action.payload
      };
    case 'SET_DASHBOARD_DATA_LOADING_STATE':
      return {
        ...state,
        dashboardDataLoadingState: action.payload
      };
    case 'RESET_ALL_LOADING_STATES':
      return {
        ...state,
        dashboardDataLoadingState: 'default',
        communityLoadingState: 'default',
        personalDataLoadingState: 'default'
      };
    default:
      return state;
  }
};

export default loadingReducer;
