import cx from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import Button from 'components/button';
import Input from 'components/form-input';
import { Signup } from 'resources/user/types';
import baseStyles from 'styles/base.module.css';
import styles from 'styles/form.module.css';
import signupStyles from './signup.module.css';
import { ROUTES } from '../../../routes';
import { MASKS } from 'helpers/constants';
import Spinner from 'components/spinner';

declare global {
  interface Window {
    __hsUserToken: String;
  }
}

function SignupForm({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldTouched,
  isValid
}: FormikProps<Signup>) {
  return (
    <form
      onSubmit={handleSubmit}
      className={cx(styles.form, signupStyles.form)}
    >
      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="firstName">First Name</label>
        <Input
          name="firstName"
          placeholder="Jane"
          value={values.firstName}
          valid={!errors.firstName}
          touched={touched.firstName}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
        {touched.firstName && errors.firstName && (
          <span className={styles.error}>{errors.firstName}</span>
        )}
      </div>

      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="lastName">Last Name</label>
        <Input
          name="lastName"
          placeholder="Smith"
          value={values.lastName}
          valid={!errors.lastName}
          touched={touched.lastName}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
        {touched.lastName && errors.lastName && (
          <span className={styles.error}>{errors.lastName}</span>
        )}
      </div>

      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="phoneNumber">Phone Number</label>
        <Input
          name="phoneNumber"
          placeholder="000-000-0000"
          value={values.phoneNumber}
          valid={!errors.phoneNumber}
          touched={touched.phoneNumber}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          mask={MASKS.PHONE}
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <span className={styles.error}>{errors.phoneNumber}</span>
        )}
      </div>

      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="email">Email</label>
        <Input
          type="email"
          name="email"
          placeholder="Email address"
          value={values.email}
          touched={touched.email}
          valid={!errors.email}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          otherProps={{
            autoCapitalize: 'none'
          }}
        />
        {touched.email && errors.email && (
          <span className={styles.error}>{errors.email}</span>
        )}
      </div>

      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="password">Password</label>
        <Input
          name="password"
          type="password"
          placeholder="Create a password"
          value={values.password}
          touched={touched.password}
          valid={!errors.password}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
        {touched.password && errors.password && (
          <span className={styles.error}>{errors.password}</span>
        )}
      </div>

      <div className={cx(baseStyles.col100, styles.inputContainer)}>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirm password"
          value={values.confirmPassword}
          touched={touched.confirmPassword}
          valid={!errors.confirmPassword}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <span className={styles.error}>{errors.confirmPassword}</span>
        )}
      </div>

      <div className={signupStyles.termsAgreementCheckbox}>
        <input
          type="checkbox"
          name="agreedToTerms"
          id="agreedToTerms"
          onChange={handleChange}
          checked={values.agreedToTerms}
        />
        <label htmlFor="agreedToTerms">
          By creating an account, I agree to the{' '}
          <a
            href={ROUTES.legal.termsOfService}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service,
          </a>{' '}
          <a
            href={ROUTES.legal.esignConsent}
            target="_blank"
            rel="noopener noreferrer"
          >
            E-SIGN ACT Consent and Electronic Communications Policy,
          </a>{' '}
          <a
            href={ROUTES.legal.consentToBeContacted}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consent to be Contacted,
          </a>
          {' and '}
          <a
            href={ROUTES.legal.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
        </label>
      </div>
      <div className={signupStyles.submitButtonContainer}>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Get Started
        </Button>
        {isSubmitting && <Spinner size="large" fullScreen />}
      </div>
    </form>
  );
}

export default SignupForm;
