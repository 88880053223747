import * as React from 'react';

export default function HighPriority() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 0.00418091C7.72082 0.00418091 7.44582 0.108348 7.23332 0.316681L0.31665 7.23335C-0.100016 7.65418 -0.100016 8.34585 0.31665 8.76668L7.23332 15.6833C7.65415 16.1042 8.34582 16.1042 8.76665 15.6833L15.6833 8.76668C16.1 8.35001 16.1 7.65418 15.6833 7.23335L8.76665 0.316681C8.55415 0.108348 8.27915 0.00418091 7.99998 0.00418091ZM8.00832 1.07085L14.9292 7.99168C14.9375 8.00001 14.9375 8.00418 14.9292 8.01251L8.00832 14.9292C7.99998 14.9375 7.99582 14.9375 7.98748 14.9292L1.07498 8.01251C1.06248 8.00001 1.05832 8.00001 1.07082 7.99168L7.98748 1.07501C7.99998 1.06251 7.99998 1.05835 8.00832 1.07085ZM7.46665 4.26668V9.60001H8.53332V4.26668H7.46665ZM7.46665 10.6667V11.7333H8.53332V10.6667H7.46665Z"
        fill="#475F7B"
      />
    </svg>
  );
}
