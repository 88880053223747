import * as React from 'react';
import { Layout } from 'antd';

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

function SignUpLayout({ children }: Props) {
  return <Layout>{children}</Layout>;
}

export default SignUpLayout;
