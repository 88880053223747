import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';
import ConfirmScheduledPayments from './confirmScheduledPayments';
import SetPrimaryPaymentAccount from './setPrimaryPaymentAccount';
import Success from './success';

function Onboarding() {
  return <Redirect to={ROUTES.flex.onboarding.setPaymentAccount} />;
}

export default {
  Onboarding,
  ConfirmScheduledPayments,
  SetPrimaryPaymentAccount,
  Success
};
