import * as React from 'react';

export default function Return() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38798 0.660706L0.771912 4.27272L4.38798 7.88473L5.1185 7.15421L2.76055 4.7922H10.5974C12.5982 4.7922 14.2338 6.44804 14.2338 8.44885C14.2338 10.4497 12.6023 12.0649 10.5974 12.0649H9.03896V13.1039H10.5974C12.9797 13.1039 14.8953 11.2816 15.1672 8.96833H15.2727V8.44885C15.2727 5.87986 13.1705 3.75324 10.5974 3.75324H2.76055L5.1185 1.39122L4.38798 0.660706ZM2.80519 12.0649V13.1039H3.84415V12.0649H2.80519ZM4.88311 12.0649V13.1039H5.92207V12.0649H4.88311ZM6.96103 12.0649V13.1039H8V12.0649H6.96103Z"
        fill="#475F7B"
      />
    </svg>
  );
}
