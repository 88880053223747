import { PlaidAccount } from './../plaid/types';
import { Store } from '../types';
import { UserAccountData, RentalAddress, Payment } from './types';
import { FlexApplication } from 'domain/types';

function getInitial(name?: string) {
  return name && name.length ? name[0] : '-';
}

export function getInitials({ user }: Store): string {
  return user
    ? `${getInitial(user.firstName)}${getInitial(user.lastName)}`
    : '';
}

export function getRentalAddress({ user }: Store): RentalAddress | undefined {
  return user && user.rentalAddress;
}

export function getUserAccount({ user }: Store): UserAccountData {
  if (!user) {
    return {
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      rentalAddress: {},
      activeSubscription: undefined
    };
  }
  return user;
}

export function authenticationState({ user }: Store) {
  return user && user.authState;
}

export function getLoanAccount({ user }: Store) {
  return user && user.loanAccount;
}

export function isCommunityMatched({ user }: Store) {
  if (!user || !user.communityId) {
    return false;
  }
  return user.communityId !== 'unmatched';
}

/**
 * Returns the accounts for the last bank that the user selected
 */
export function getAccountsForSelectedBank({ user }: Store): PlaidAccount[] {
  return user && user.plaidAccounts ? user.plaidAccounts : [];
}

/**
 * Returns a pending Flexible Rent application for a user if
 * one exists, otherwise undefined
 */
export const getPendingFlexibleRentApplication = ({
  user
}: Store): FlexApplication | undefined => {
  return user
    ? user.applicationState.applications.find(
        app => app.status === 'pending' || app.status === 'flagged'
      )
    : undefined;
};

/**
 * Returns an approved Flexible Rent application for a user if
 * one exists, otherwise undefined
 */
export const getApprovedFlexibleRentApplication = ({
  user
}: Store): FlexApplication | undefined => {
  return user
    ? user.applicationState.applications.find(
        app =>
          app.status === 'approved' ||
          app.status === 'schedule_expired' ||
          app.status === 'payment_method_invalid'
      )
    : undefined;
};

/**
 * Returns whether or not the applications have been successfully loaded
 */
export const getApplicationLoadingState = ({ user }: Store): boolean => {
  return user ? user.applicationState.isLoaded : false;
};

/**
 * Returns the user's payments that are in the store
 */
export const getPaymentHistory = ({ user }: Store): Payment[] => {
  return user && user.payments ? user.payments : [];
};

export const getActiveSubscription = ({ user }: Store) => {
  return user && user.subscriptions
    ? user.subscriptions.find(sub => sub.status === 'active')
    : undefined;
};

export const getAllSubscriptions = ({ user }: Store) => {
  return user && user.subscriptions
    ? user.subscriptions.filter(
        sub =>
          sub.status === 'active' ||
          sub.status === 'closed' ||
          sub.status === 'collections'
      )
    : [];
};
