import axios from 'axios';
import config from 'helpers/config';
import APIError, { handleError } from '../../../helpers/api.error';
import { Autopay, Payment, PaymentAccountType } from './types';
import { format as formatDate } from 'helpers/date';

export async function getAccountsByCustomer(userId?: string, headers?: any) {
  const response = await axios.get(
    `${config.apiUrl}/api/flexpay/account/${userId}`,
    { headers }
  );
  return response.data;
}

export async function addPaymentProfile(
  data?: {
    userId: string;
    accountType: PaymentAccountType;
    accountToken?: string;
    accountTitle?: string;
  },
  headers?: any
) {
  if (!data) {
    throw new Error('Unable to add payment profile');
  }

  const { userId, accountToken, accountTitle, accountType } = data;

  try {
    const response = await axios.post(
      `${config.apiUrl}/api/loan/account/${userId}/payment/profile`,
      { accountToken, accountTitle, accountType },
      { headers }
    );
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function addPlaidPaymentProfile(
  data?: {
    userId: string;
    plaidItemId?: string;
    plaidAccountId?: string;
  },
  headers?: any
) {
  if (!data) {
    throw new Error('Unable to add payment profile');
  }

  const { userId, plaidItemId, plaidAccountId } = data;

  try {
    const response = await axios.post(
      `${config.apiUrl}/api/loan/account/${userId}/payment/profile`,
      { accountType: 'ach', plaidItemId, plaidAccountId },
      { headers }
    );
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getPaymentToken(_data?: any, headers?: any) {
  try {
    const response = await axios.get(
      `${config.apiUrl}/api/loan/account/payment/token`,
      { headers }
    );
    return response.data;
  } catch (err) {
    throw new APIError('Unable to get payment token', 404);
  }
}

export async function getPaymentProfiles(userId?: string, headers?: any) {
  if (!userId) {
    throw new APIError('You are not logged in.', 401);
  }
  const response = await axios.get(
    `${config.apiUrl}/api/loan/account/${userId}/payment/profile`,
    { headers }
  );
  return response.data;
}

export async function updateAutopay(
  data?: {
    userId: string;
    paymentProfileId: number;
  },
  headers?: any
) {
  if (!data) {
    throw new Error('Unable to update autopay');
  }
  try {
    await axios.post(
      `${config.apiUrl}/api/flexpay/account/${data.userId}/autopay`,
      { paymentProfileId: data.paymentProfileId },
      {
        headers
      }
    );
    return { paymentProfileId: data.paymentProfileId };
  } catch (err) {
    handleError(err, 'We were unable to update your autopay.');
  }
}

export async function makePayment(payment?: Payment, headers?: any) {
  try {
    if (!payment) {
      throw new Error('Please select a payment method.');
    }

    const { loanId } = payment;
    // Send date as a YYYY-MM-DD string to prevent timezone issues
    const date = formatDate('YYYY-MM-DD', payment.date);
    await axios.post(
      `${config.apiUrl}/api/loan/${loanId}/payment`,
      { ...payment, date },
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to process your payment.');
  }
}

// FIXME this does not work with flex accounts
export async function editPayment(
  paymentId: number,
  payment?: Payment,
  headers?: any
) {
  try {
    if (!payment) {
      throw new Error('Please select a payment method.');
    }

    const { loanId } = payment;
    await axios.patch(
      `${config.apiUrl}/api/loan/${loanId}/payment/${paymentId}`,
      payment,
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to edit your payment.');
  }
}

// FIXME this does not work with flex accounts
export async function cancelPayment(
  loanId: number,
  paymentId: number,
  headers?: any
) {
  try {
    await axios.delete(
      `${config.apiUrl}/api/loan/${loanId}/payment/${paymentId}`,
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to cancel your payment.');
  }
}

// FIXME this does not work with flex accounts
export async function scheduleAutopay(autopayments?: Autopay, headers?: any) {
  try {
    if (!autopayments) {
      throw new Error('Please select all autopayment fields.');
    }
    const { loanId } = autopayments;
    // Send date as a YYYY-MM-DD string to prevent timezone issues
    const date = formatDate('YYYY-MM-DD', autopayments.date);
    await axios.post(
      `${config.apiUrl}/api/loan/${loanId}/autopay`,
      { ...autopayments, date },
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to schedule your autopayments.');
  }
}

// FIXME this does not work with flex accounts
export async function cancelAutopayments(loanId?: number, headers?: any) {
  try {
    if (!loanId) {
      throw new Error('Missing loan Id');
    }
    await axios.delete(`${config.apiUrl}/api/loan/${loanId}/autopay`, {
      headers
    });
  } catch (err) {
    handleError(err, 'We were unable to cancel your autopayments.');
  }
}
