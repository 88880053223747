import * as React from 'react';
import styles from './account-summary.module.css';
import Icon from 'components/icon';

interface Props {
  pastDueAmountDue: number;
}

function PastDueCard({ pastDueAmountDue }: Props) {
  const amount = Math.abs(pastDueAmountDue);

  return (
    <div className={styles.autopayDetails}>
      <div className={styles.card}>
        <div className={styles.autopayDetails}>
          <p>
            Past Due Balance:
            <span className={styles.highlight}>{`$${amount} `}</span>
          </p>

          <a href="/rent/pay?amount=0" className={styles.summaryOption}>
            <Icon type="debt" />
            <p>Make a one-time payment.</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PastDueCard;
