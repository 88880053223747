import cx from 'classnames';
import * as React from 'react';
import ACHComponent from './ach';
import DebitCardComponent from './debit-card';
import styles from './payment-method.module.css';
import { PaymentProfile } from '../../resources/loan/account/types';

interface Props {
  className?: string;
  view?: 'compact' | 'large';
  method: PaymentProfile;
  selected: boolean;
  onClick?: (method: any) => any;
}

class PaymentMethod extends React.PureComponent<Props> {
  onClick = () => {
    const { method, onClick } = this.props;
    if (onClick) {
      onClick(method);
    }
  };

  render() {
    const { view = 'large', method, selected, className, onClick } = this.props;

    let props = {};

    if (onClick) {
      props = {
        tabIndex: 0,
        role: 'button',
        onClick: this.onClick
      };
    }

    let content = null;

    switch (method.type) {
      case 'paymentAccount.type.checking': {
        content = <ACHComponent {...method} selected={selected} view={view} />;
        break;
      }
      case 'paymentAccount.type.credit': {
        content = (
          <DebitCardComponent
            {...(method as any)}
            selected={selected}
            view={view}
          />
        );
        break;
      }
    }

    return (
      <div {...props} className={cx(styles.container, className)}>
        {content}
      </div>
    );
  }
}

export default PaymentMethod;
