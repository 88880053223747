import {
  setPersonalDataLoadingState,
  setCommunityLoadingState
} from './../resources/loading/loading.actions';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { getPath } from 'resources/router/router.selectors';
import { loadPersonalData } from 'resources/user/user.actions';
import { getCommunityById } from 'resources/community/community.action';
import {
  authenticationState,
  getUserAccount
} from 'resources/user/user.selectors';

export function useUserData() {
  const dispatch = useThunkDispatch();

  const path = useSelector(getPath);
  const authState = useSelector(authenticationState);
  const user = useSelector(getUserAccount);

  React.useEffect(() => {
    dispatch(setPersonalDataLoadingState('default'));
    (async () => {
      if (authState === 'authenticated') {
        try {
          dispatch(setPersonalDataLoadingState('loading'));
          await dispatch(loadPersonalData());
        } catch (err) {}
        dispatch(setPersonalDataLoadingState('done'));
      }
    })();
  }, [authState, path, dispatch, user.userId]);

  React.useEffect(() => {
    dispatch(setCommunityLoadingState('default'));
    if (!user.communityId) return;
    (async () => {
      try {
        dispatch(setCommunityLoadingState('loading'));
        await dispatch(getCommunityById(user.communityId));
      } catch (err) {}
      dispatch(setCommunityLoadingState('done'));
    })();
  }, [user.communityId, dispatch]);

  return user;
}
