import * as React from 'react';
import Input from '../form-input';
import { lookup } from '../../helpers/address';

function getNamespace(name: string): string {
  return name
    .split('.')
    .slice(0, -1)
    .join('.');
}

interface ZipInputProps {
  placeholder?: string;
  name: string;
  value: any;
  touched?: boolean;
  valid: boolean;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

class ZipInput extends React.PureComponent<ZipInputProps> {
  onChange = ({ target }: React.ChangeEvent<any>) => {
    const zip = target.value;
    const { setFieldValue, name } = this.props;
    setFieldValue(name, zip);

    const address = lookup(zip);
    const namespace = getNamespace(name);
    if (address) {
      setFieldValue(namespace ? `${namespace}.state` : 'state', address.state);
      setFieldValue(namespace ? `${namespace}.city` : 'city', address.city);
    }
  };

  render() {
    const {
      name,
      value,
      setFieldTouched,
      touched,
      valid,
      placeholder = ''
    } = this.props;

    return (
      <Input
        label="Zip/Postal code"
        placeholder={placeholder}
        touched={touched}
        valid={valid}
        name={name}
        value={value}
        onChange={this.onChange}
        setFieldTouched={setFieldTouched}
      />
    );
  }
}

export default ZipInput;
