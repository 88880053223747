import * as React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import cx from 'classnames';
import Icon from 'components/icon';
import { Link } from 'react-router-dom';
import { getPath } from 'resources/router/router.selectors';
import {
  getApprovedFlexibleRentApplication,
  getPendingFlexibleRentApplication,
  getUserAccount
} from 'resources/user/user.selectors';
import { ROUTES } from '../../routes';
import styles from './sidebar-menu.module.css';
import dropDownStyles from './payment-schedule-dropdown.module.css';
import * as accountSelector from '../../resources/flexpay/account/account.selectors';
import LoadDashboardData from 'helpers/loadDashboardData';
import { useThunkDispatch } from 'resources';
import { logout } from 'resources/user/user.actions';
import Spinner from 'components/spinner';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Visibility {
  visible: boolean;
}

interface Props {
  className?: string;
}

type MenuItem = {
  key: string;
  title: string;
  visible: boolean;
  icon: JSX.Element;
  newTab?: boolean;
  app?: string;
};

const paymentScheduleFormUrl =
  'https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=';
const paymentScheduleDropdownItems = [
  { formId: '360001534452', text: 'Set up autopay' },
  { formId: '360001534052', text: 'Make a change to my autopay' },
  { formId: '360001559051', text: 'Change my payment due date' },
  { formId: '360001534352', text: 'Change my payment frequency' },
  { formId: '360001534532', text: 'Cancel autopay' }
];

const Chevron = () => (
  <span className={styles.icon}>
    <svg
      name="more-icon"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#304156"
      strokeWidth="2"
    >
      <polyline points="6 9 12 15 18 9" />
    </svg>
  </span>
);

/**
 * The menu to display in the sidebar on both desktop and mobile devices
 */
const SidebarMenu = ({ className }: Props) => {
  const dispatch = useThunkDispatch();

  const { userId } = useSelector(getUserAccount);
  LoadDashboardData(userId);

  const path = useSelector(getPath);
  const hasOpenFlexAccount = useSelector(accountSelector.hasOpenFlexAccount);
  const pendingApplication = useSelector(getPendingFlexibleRentApplication);
  const approvedApplication = useSelector(getApprovedFlexibleRentApplication);

  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const showMenuOptions = hasOpenFlexAccount;
  const shouldShowApply =
    !showMenuOptions && !pendingApplication && !approvedApplication;
  const {
    vueSettingsOn: isVueSettings,
    useVuePaymentMethodScreen
  } = useFlags();

  const sidebarMenu = [
    {
      title: 'Till',
      visible: true,
      items: [
        {
          key: ROUTES.flexpay.rent,
          title: 'Overview',
          visible: !shouldShowApply,
          icon: <Icon type="dashboard" className={styles.menuIcon} />
        },
        {
          key: '/v/rent/payment-history',
          title: 'Payment History',
          visible: showMenuOptions,
          icon: <Icon type="view-list" className={styles.menuIcon} />,
          app: 'vue'
        },
        {
          key: useVuePaymentMethodScreen
            ? '/v/rent/payment-method'
            : ROUTES.flexpay.payments,
          title: 'Payment Method',
          visible: showMenuOptions,
          icon: <Icon type="bank" className={styles.menuIcon} />,
          app: 'vue'
        },
        {
          key: '/payment-schedule',
          title: 'Payment Schedule',
          visible: showMenuOptions,
          icon: <Chevron />
        },
        {
          key: ROUTES.apply.basePath,
          title: 'Apply Now',
          visible: shouldShowApply,
          icon: <Icon type="apply" className={styles.menuIcon} />
        }
      ]
    },
    {
      title: 'Account',
      visible: true,
      items: [
        {
          key: isVueSettings ? '/v/settings' : ROUTES.account,
          title: 'Settings',
          visible: true,
          icon: <Icon type="settings" className={styles.menuIcon} />,
          app: 'vue'
        },
        {
          key: 'logout',
          title: 'Logout',
          visible: true,
          icon: <Icon type="logout" className={styles.menuIcon} />
        }
      ]
    }
  ];

  const isVisible = (v: Visibility) => v.visible;

  const onLogout = async () => {
    try {
      setIsLoggingOut(true);
      await dispatch(logout());
    } catch (err) {}
    setIsLoggingOut(false);
  };

  const renderItem = (item: MenuItem) => {
    const inner = (
      <div
        className={cx(styles.menuItem, {
          [styles.menuItemActive]: path === item.key
        })}
      >
        {item.icon} {item.title}
      </div>
    );
    const options = item.newTab
      ? { target: '_blank', rel: 'noopener noreferrer' }
      : undefined;

    if (item.key === 'logout') {
      return (
        <a key={item.key} onClick={onLogout} {...options}>
          {inner}
        </a>
      );
    } else if (item.key === '/payment-schedule') {
      return (
        <div
          key="payment-schedule"
          className={cx(styles.menuItem, dropDownStyles.paymentSchedule, {
            [styles.menuItemActive]: path === item.key
          })}
        >
          {item.icon} {item.title}
          <div className={cx(dropDownStyles.paymentScheduleDropdown)}>
            {paymentScheduleDropdownItems.map(({ formId, text }, i) => (
              <a
                key={`menu-item${i}`}
                href={`${paymentScheduleFormUrl}${formId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text}
                <Icon type="new-tab" />
              </a>
            ))}
          </div>
        </div>
      );
    } else if (item.key.startsWith('http') || item.app === 'vue') {
      return (
        <a key={item.key} href={item.key} {...options}>
          {inner}
        </a>
      );
    } else {
      return (
        <React.Fragment key={item.key}>
          <Link to={item.key} {...options}>
            {inner}
          </Link>
        </React.Fragment>
      );
    }
  };

  const menu = sidebarMenu.filter(isVisible).map(group => {
    return (
      <Menu.ItemGroup
        className={styles.group}
        key={group.title}
        title={<div className={styles.sidebarTitle}>{group.title}</div>}
      >
        {group.items.filter(isVisible).map(renderItem)}
      </Menu.ItemGroup>
    );
  });

  return (
    <div className={cx(styles.sidebar, className)}>
      {isLoggingOut && <Spinner size="large" fullScreen />}
      <Menu className={styles.sidebarMenu}>{menu}</Menu>
    </div>
  );
};

export default SidebarMenu;
