import createApiAction, {
  getAuthHeaders
} from '../../helpers/apiActionCreator';
import * as signatureApi from './signature.api';
import * as userApi from '../user/user.api';
import { Dispatch } from 'resources/types';

export const PROMPT_SIGNATURE_REQUEST = 'PROMPT_SIGNATURE_REQUEST';
export const COMPLETE_SIGNATURE = 'COMPLETE_SIGNATURE';

export function promptSignatureRequest(userId: string) {
  return async (dispatch: Dispatch) => {
    if (userId && userId.length > 0) {
      const headers = getAuthHeaders();

      const applications = await userApi.getApplicationsByUser(
        { userId, status: 'approved' },
        headers
      );

      if (applications.length > 0) {
        const application = applications[0];

        const useV2Endpoint =
          application.baseRent !== undefined ||
          application.totalBilling !== undefined ||
          application.outstandingBalance !== undefined;

        if (useV2Endpoint) {
          dispatch(
            createApiAction<any, any>(
              PROMPT_SIGNATURE_REQUEST,
              signatureApi.createSignatureRequestV2
            )()
          );
        } else {
          dispatch(
            createApiAction<any, any>(
              PROMPT_SIGNATURE_REQUEST,
              signatureApi.createSignatureRequestV1
            )()
          );
        }
      }
    }
  };
}

export function completeSignature(
  signatureRequestId: string,
  applicationId: string
) {
  return createApiAction(COMPLETE_SIGNATURE, signatureApi.contractSigned)({
    signatureRequestId,
    applicationId
  });
}
