import * as React from 'react';
import { format as formatDate } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import { Transaction } from 'resources/loan/account/types';

// UI
import Icon from 'components/icon';
import AutopayToggle from '../../../components/autopay-toggle';
import OverdueLabel from 'components/overdue-label';
import styles from './cards.module.css';
import cx from 'classnames';

interface DueDatesProps {
  transactions: Transaction[];
}
export function DueDatesCard({ transactions }: DueDatesProps) {
  const paymentSchedule = transactions.map((transaction, i) => {
    const overdue =
      formatDate('YYYYMMDD', transaction.date) <
      formatDate('YYYYMMDD', new Date());
    return (
      <tr key={i}>
        <td>
          {overdue ? (
            <OverdueLabel />
          ) : (
            formatDate('MMM D YYYY', transaction.date)
          )}
        </td>
        <td>${formatNumber(transaction.paymentAmount, 2)}</td>
      </tr>
    );
  });
  // todo: hide disclaimer text for B&S accounts
  return (
    <div className={cx(styles.card, styles.payments)}>
      <div className={styles.header}>
        <div>
          <h2 className={styles.title}>Your Payment Schedule</h2>
          <p className={styles.disclaimerText}>
            The amounts below may include a prorated portion of your monthly
            Till service fee.
          </p>
        </div>
        <AutopayToggle autopays={[]} transactions={transactions} />
      </div>
      <div className={styles.content}>
        {transactions.length === 0 ? (
          "Congrats! You've made all your rent payments this month. We're working on your next month's rent schedule with your landlord and will have it ready on the 1st."
        ) : (
          <table>
            <thead>
              <tr>
                <th>
                  <Icon type="date" /> Date
                </th>
                <th>
                  <Icon type="debt" /> Amount
                </th>
              </tr>
            </thead>
            <tbody>{paymentSchedule}</tbody>
          </table>
        )}
      </div>
    </div>
  );
}
