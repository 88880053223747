import cx from 'classnames';
import Button from 'components/button';
import Icon from 'components/icon';
import Switch from 'components/switch';
import { format as formatDate } from 'helpers/date';
import { format as formatNumber } from 'helpers/number';
import * as React from 'react';
import styles from './cards.module.css';

interface ScheduledPaymentsProps {
  payments: { date: Date; amount: number }[];
  onEditPaymentClick: (payment: any) => void;
  onEnableAutopayment: () => void;
}
export function ScheduledPaymentsCard({
  payments,
  onEditPaymentClick,
  onEnableAutopayment
}: ScheduledPaymentsProps) {
  const paymentSchedule = payments.map((payment, i) => {
    return (
      <tr key={i}>
        <td>{formatDate('DD MMMM YYYY', payment.date)}</td>
        <td>${formatNumber(payment.amount, 2)}</td>
        <td>
          <Button
            type="button"
            className={styles.tableBtn}
            onClick={() => onEditPaymentClick(payment)}
          >
            Edit Payment
          </Button>
        </td>
      </tr>
    );
  });
  return (
    <div className={cx(styles.card, styles.payments)}>
      <div className={styles.header}>
        <h2 className={styles.title}>Scheduled Payments</h2>

        <Switch
          className={styles.autopaymentSwitch}
          name="autopayment"
          label="Turn on autopayments"
          value={false}
          onChange={onEnableAutopayment}
        />
      </div>
      <div className={styles.content}>
        <table>
          <thead>
            <tr>
              <th>
                <Icon type="date" /> Due date
              </th>
              <th>
                <Icon type="debt" /> Amount
              </th>
              <th>
                <Icon type="edit-file" /> Edit Payment
              </th>
            </tr>
          </thead>
          <tbody>{paymentSchedule}</tbody>
        </table>
      </div>
    </div>
  );
}
