import { DisplayData } from 'domain/types';
import { FlexApplyState, FlexApplyStep, RentRollMatch } from './apply.reducer';
import { Store } from 'resources/types';
import { Community } from '@hellotill/community-types';
import { CommunityApplicationDetails } from './types';

export const getSteps = ({ flexApply }: Store) => {
  return flexApply ? flexApply.steps : [];
};

/**
 * Retrieves the next pending step in the application
 */
export const getNextPendingStep = ({
  flexApply
}: Store): FlexApplyStep | undefined => {
  return flexApply && flexApply.steps.find(step => step.status === 'pending');
};

/**
 * Retrieves the current application step
 */
export const getCurrentStep = ({
  flexApply
}: Store): FlexApplyStep | undefined => {
  return flexApply && flexApply.steps[flexApply.currentStepIndex];
};

export const getAddress = ({ flexApply }: Store) => {
  return flexApply && flexApply.address;
};

export const getCashFlow = ({ flexApply }: Store) => {
  return flexApply && flexApply.cashFlow;
};

export const getCreatedApplicationId = ({
  flexApply
}: Store): string | undefined => {
  return flexApply && flexApply.createdApplicationId;
};

export const getFlexApplyState = ({
  flexApply
}: Store): FlexApplyState | undefined => {
  return flexApply;
};

export const getScheduleDisplayData = ({
  flexApply
}: Store): DisplayData | undefined => {
  return flexApply && flexApply.scheduleDisplayData;
};

export const getSelectedCommunity = ({
  flexApply
}: Store): Partial<Community> | undefined => {
  return flexApply && flexApply.community && flexApply.community.communityData;
};

export const getApplicationCommunityDetails = ({
  flexApply
}: Store): CommunityApplicationDetails | undefined => {
  return flexApply && flexApply.community && flexApply.community;
};

export const getRentRollMatches = ({
  flexApply
}: Store): RentRollMatch[] | undefined => {
  return flexApply && flexApply.rentRollMatches;
};
