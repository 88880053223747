import { Formik, FormikActions } from 'formik';
import * as React from 'react';
import { useThunkDispatch } from 'resources';
import { ForgotPassword } from 'resources/user/types';
import { initiatePasswordReset } from 'resources/user/user.actions';
import { forgotPasswordSchema } from 'schema/user';
import authStyles from '../auth.module.css';
import ForgotPasswordForm from './form';

function ForgotPasswordComponent() {
  const [errorMessage, setErrorMessage] = React.useState();
  const dispatch = useThunkDispatch();

  const complete = async (
    data: ForgotPassword,
    { setSubmitting }: FormikActions<ForgotPassword>
  ) => {
    try {
      setErrorMessage(undefined);
      await dispatch(initiatePasswordReset(data.email));
    } catch (err) {
      setErrorMessage(err.message);
    }

    setSubmitting(false);
  };

  return (
    <div className={authStyles.container}>
      <h1 className={authStyles.title}>Forgot Password</h1>
      <p>
        Enter your email address. If we have an account with that address, we
        will send you a code to reset your password.
      </p>
      {errorMessage && <p className={authStyles.error}>{errorMessage}</p>}
      <Formik
        initialValues={{ email: '' }}
        validationSchema={forgotPasswordSchema}
        onSubmit={complete}
        component={ForgotPasswordForm}
      />
    </div>
  );
}

export default ForgotPasswordComponent;
