import { combineReducers, Action } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user/user.reducer';
import flexpayAcceptance from './flexpay/acceptance/acceptance.reducer';
import flexApply from './flex/apply/apply.reducer';
import flexAccountState from './flexpay/account/account.reducer';
import loan from './loan/account/account.reducer';
import community from './community/community.reducer';
import signature from './signature/signature.reducer';
import plaid from './plaid/plaid.reducer';
import loading from './loading/loading.reducer';
import onboarding from './flex/onboarding/onboarding.reducer';
import { Store } from './types';
import { Reducer } from 'react';
import { History } from 'history';

export default function createReducer(
  history: History
): Reducer<Store | undefined, Action> {
  return combineReducers<Store | undefined>({
    router: connectRouter(history),
    user,
    loan,
    flexAccountState,
    flexpayAcceptance,
    flexApply,
    community,
    signature,
    plaid,
    loading,
    onboarding
  });
}
