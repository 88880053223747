import { FlexpayAcceptanceState } from '../acceptance/types';
import { AcceptanceAction } from './acceptance.actions';

export default function flexAcceptanceReducer(
  state: FlexpayAcceptanceState | undefined = {},
  action: AcceptanceAction
): FlexpayAcceptanceState {
  switch (action.type) {
    case 'LOAD_SCHEDULE':
      return {
        ...state,
        scheduleDisplayData: action.payload
      };
    case 'LOAD_APPLICATIONS':
      return {
        ...state,
        approvedApplications: action.payload
      };
    default:
      return state;
  }
}
