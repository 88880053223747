import * as React from 'react';
import moment from 'moment';
import { AccountSummaryCard, getPaymentCard } from './cards/cards';
import PastDueCard from './cards/cards.past-due';
import PastPaymentsCard from './cards/cards.payment-history';
import { getAccountPayments } from 'resources/flexpay/account/account.selectors';
import { useSelector } from 'react-redux';
import styles from './dashboard.module.css';
import { FlexAccountState } from 'resources/flexpay/account/types';
import { push } from 'connected-react-router';
import { useThunkDispatch } from 'resources';
import { ROUTES } from 'routes';
import { getUserAccount } from 'resources/user/user.selectors';
import { getSubscriptions } from 'resources/user/user.actions';
import { useEffect, useState } from 'react';
import ContactSupportModal from 'components/support/modal';
import config from 'helpers/config';
import InactiveAccountCard from './cards/cards.inactive-account';

interface FlexAccountSummaryProps {
  account: FlexAccountState;
}

/**
 * Shows a summary of an enrolled user's flex account
 */
const FlexAccountSummary = ({ account }: FlexAccountSummaryProps) => {
  const dispatch = useThunkDispatch();

  const { autopays, scheduledPayments, payoff } = useSelector(
    getAccountPayments
  );
  const { userId, activeSubscription } = useSelector(getUserAccount);
  const [errorMessage, setErrorMessage] = useState<string>();
  const inCollections =
    account.loanStatusText === 'Collections' ||
    (activeSubscription && activeSubscription.status === 'collections');

  const onPayNowClick = () => {
    dispatch(push(ROUTES.flexpay.pay));
  };

  useEffect(() => {
    const fetchSubscriptions = async () => {
      const subscription = await dispatch(getSubscriptions(userId));
      // @ts-ignore
      if (subscription[0].program === 'V3_FLEXIBLE_RENT') {
        window.location.href = '/v/rent';
      }
    };

    fetchSubscriptions().catch(() =>
      setErrorMessage('Unable to load subscriptions')
    );
  }, [dispatch, userId]);

  let nextPaymentAmount = account.nextPaymentAmount;
  let nextPaymentDate = account.nextPaymentDate;

  if (autopays.length > 0) {
    nextPaymentAmount = autopays[0].amount;
    nextPaymentDate = moment(autopays[0].applyDate).toDate();
  } else if (scheduledPayments.length > 0) {
    nextPaymentAmount = scheduledPayments[0].paymentAmount;
    nextPaymentDate = scheduledPayments[0].date;
  }
  return (
    <>
      <div>
        <section className={styles.col}>
          <h1 className={styles.title}>Account Summary</h1>
          {inCollections ? (
            <>
              <InactiveAccountCard />
              <PastDueCard pastDueAmountDue={payoff} />
              <PastPaymentsCard />
            </>
          ) : (
            <>
              <PastDueCard pastDueAmountDue={account.amountDue} />
              <AccountSummaryCard
                nextPaymentDate={nextPaymentDate}
                nextPaymentAmount={nextPaymentAmount}
                onPayNowClick={onPayNowClick}
                daysPastDue={account.daysPastDue}
                pastDueAmountDue={account.amountDue}
                autopays={autopays}
                activeSubscription={activeSubscription}
              />
              {getPaymentCard(scheduledPayments, autopays)}
              <PastPaymentsCard />
            </>
          )}
        </section>
      </div>
      {errorMessage && (
        <div>
          <ContactSupportModal
            message={errorMessage}
            supportPhoneNumber={config.supportPhoneNumber}
            onClose={() => {
              setErrorMessage(undefined);
            }}
          />
        </div>
      )}
    </>
  );
};
export default FlexAccountSummary;
