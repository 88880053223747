import { getSearch, push } from 'connected-react-router';
import { Formik, FormikActions } from 'formik';
import { getQueryVariable } from 'helpers/querystring';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { Store } from 'resources/types';
import { ResetPassword } from 'resources/user/types';
import ResetForm from './form';
import authStyles from '../auth.module.css';
import schema from './schema';
import { resetPassword } from 'resources/user/user.actions';

function ResetPasswordComponent() {
  const [errorMessage, setErrorMessage] = React.useState();
  const dispatch = useThunkDispatch();
  const search = useSelector<Store, string>(getSearch);
  const initialValues: ResetPassword = {
    email: getQueryVariable(search, 'email') || undefined,
    code: '',
    password: '',
    confirmPassword: ''
  };
  const complete = async (
    data: ResetPassword,
    { setSubmitting }: FormikActions<ResetPassword>
  ) => {
    try {
      setErrorMessage(undefined);
      await dispatch(resetPassword(data));
      dispatch(push(getQueryVariable(search, 'redirect_to') || '/'));
    } catch (err) {
      setErrorMessage(err.message);
    }

    setSubmitting(false);
  };

  return (
    <div className={authStyles.container}>
      <h1 className={authStyles.title}>Reset Password</h1>
      <p>
        We just sent an email to the address associated with your account. Use
        the code provided to reset your password.
      </p>
      {errorMessage && <p className={authStyles.error}>{errorMessage}</p>}
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={schema}
        onSubmit={complete}
        component={ResetForm}
      />
    </div>
  );
}

export default ResetPasswordComponent;
