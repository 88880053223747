import * as React from 'react';
import { useSelector } from 'react-redux';
import createPaymentInfoCardProps from './createPaymentInfoCardProps';
import PaymentInfoCard from './paymentInfoCard';
import PaymentCalendar from './paymentCalendar';
import { getScheduleDisplayData } from 'resources/flex/apply/apply.selectors';
import { PaymentInfoCardProps } from './createPaymentInfoCardProps';
import styles from './schedule.module.css';

/**
 * Displays cards with payment info for Flexible Rent
 */
const PaymentInfo = () => {
  const scheduleDisplayData = useSelector(getScheduleDisplayData);
  const [paymentInfo, setPaymentInfo] = React.useState<
    PaymentInfoCardProps[]
  >();
  const [currentMonthPayment, setCurrentMonthPayment] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (scheduleDisplayData) {
      // set a contract for this component and then have the calculator return it for me
      setPaymentInfo(createPaymentInfoCardProps(scheduleDisplayData));
      setCurrentMonthPayment(scheduleDisplayData.hasCurrentMonthPayments);
    }
  }, [scheduleDisplayData]);

  return (
    <>
      {paymentInfo && (
        <div className={styles.mainCardContainer}>
          {paymentInfo.length > 0 ? (
            paymentInfo.map((info, i) =>
              currentMonthPayment && i === 0 ? (
                <PaymentCalendar key={i} />
              ) : (
                <PaymentInfoCard key={i} {...info} />
              )
            )
          ) : (
            <p>
              Your payment schedule is not yet available. We may still be
              creating your schedule. Try again later or contact us.
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
