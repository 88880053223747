import * as React from 'react';
import { ROUTES } from '../../routes';
import { Link } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import Icon from '../icon';

import styles from './styles.module.css';

const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '8px',
  height: '8px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

function EnrollmentSuccessMessage() {
  const [isConfettiActive, setIsConfettiActive] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    setIsConfettiActive(true);
  }, []);

  return (
    <div className={styles.successOuter}>
      <h3 className={styles.title}>Success!</h3>
      <p>
        You have successfully enrolled in Flexible Rent. Please allow a few
        minutes for us to generate your schedule. If you still don't see it,
        please reach out to our support team.
      </p>
      <div className={styles.confettiContainer}>
        <Confetti active={isConfettiActive} config={confettiConfig} />
      </div>
      <Link to={ROUTES.flexpay.rent}>View My Schedule</Link>
      <a
        href="https://support.hellotill.com/hc/en-us/categories/360002920692-Flexible-Rent-FAQs"
        target="_blank"
        rel="noopener noreferrer"
      >
        How to Get In Touch
        <Icon type="new-tab" />
      </a>
    </div>
  );
}

export default EnrollmentSuccessMessage;
