import { dateUtils } from '@hellotill/client-utils';
import cx from 'classnames';
import Button from 'components/button';
import DatePicker from 'components/date-picker';
import Input from 'components/form-input';
import config from '../../../helpers/config';
import { Select } from 'components/form-select';
import ContactSupportModal from 'components/support/modal';
import { Formik, FormikProps } from 'formik';
import { useAuthentication } from 'helpers/useAuthentication';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFlexApplyState } from 'resources/flex/apply/apply.selectors';
import { getRentRollMatches } from 'resources/flex/apply/apply.selectors';
import {
  goToPreviousStep,
  saveCashFlowData
} from 'resources/flex/apply/apply.actions';
import { getCashFlow } from 'resources/flex/apply/apply.selectors';
import {
  CashFlowDetailsInput,
  frequencyOptions,
  semiMonthlyPayDayOptions
} from 'resources/flex/apply/types';
import { cashFlowDetailsSchema } from 'schema/flexpay';
import styles from 'styles/form.module.css';
import applyStyles from './apply.module.css';
import useApplicationStep from './useApplicationStep';
import { createOption, createOptionComponents } from 'helpers/formHelpers';
import FormField from 'form-field';

export default function CashFlowDetailsComponent() {
  useAuthentication();
  useApplicationStep();

  const dispatch = useThunkDispatch();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const submit = async (data: CashFlowDetailsInput) => {
    try {
      dispatch(saveCashFlowData(data));
    } catch (err) {
      setErrorMessage('All required fields must be completed.');
    }
  };

  const pendingCashFlow = useSelector(getCashFlow);
  const initialValues: CashFlowDetailsInput = {};
  const cashFlowDetails = {
    ...initialValues,
    ...pendingCashFlow
  };

  return (
    <>
      <div className={styles.container}>
        <Formik
          enableReinitialize
          initialValues={cashFlowDetails}
          isInitialValid={cashFlowDetailsSchema.isValidSync(cashFlowDetails)}
          validationSchema={cashFlowDetailsSchema}
          onSubmit={submit}
          component={YourCashFlowForm}
        />
        {errorMessage && (
          <ContactSupportModal
            onClose={() => setErrorMessage(undefined)}
            supportPhoneNumber={config.supportPhoneNumber}
            message={errorMessage}
          />
        )}
      </div>
      <div className={styles.explainer}>
        <span>
          Some form of income is required to participate. Income may include
          full-time, part-time, gig economy, unemployment, retirement, SSI, etc.
        </span>
        <span>
          No income? Check out
          <a href="https://relevium.org/"> Revelium Foundation</a> for more
          options.
        </span>
      </div>
    </>
  );
}

function YourCashFlowForm({
  isSubmitting,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isValid
}: FormikProps<CashFlowDetailsInput>) {
  const dispatch = useThunkDispatch();
  const flexApplyState = useSelector(getFlexApplyState);

  const { budgetAndSaveEnabled: isBudgetAndSaveEnabled } = useFlags();

  // Constants
  const now = moment().toISOString();
  const currentMonthName = dateUtils
    .getCurrentRentalMonth(now, 15)
    .slice(0, -5);

  const rentRollData = useSelector(getRentRollMatches);
  const shouldShowLastPayday =
    values.frequency !== 'Semi-monthly' && values.frequency !== undefined;
  const shouldShowSemiMonthlyOptions =
    values.frequency === 'Semi-monthly' && values.frequency !== undefined;
  const shouldShowRentFields =
    (flexApplyState && flexApplyState.community.isUnmatched) ||
    (rentRollData && !rentRollData[0]) ||
    !isBudgetAndSaveEnabled;

  // Selector Options
  const frequencyOptionComponents = createOptionComponents<string>(frequencyOptions); // prettier-ignore
  const semiMonthlyOptionComponents = createOptionComponents<number[]>(semiMonthlyPayDayOptions); // prettier-ignore
  const rentalMonthOptions = () =>
    [
      // Will always return just the current and next month. (No => current month, Yes => next month)
      { month: dateUtils.getCurrentRentalMonth(now, 15), optionText: 'No' },
      { month: dateUtils.getNextRentalMonth(now, 15), optionText: 'Yes' }
    ].map(({ month, optionText }) =>
      createOption(false, undefined, month, optionText)
    );
  const rentMonth = rentalMonthOptions()[0].key;

  const goBack = () => {
    dispatch(goToPreviousStep());
  };

  React.useEffect(() => {
    if (rentRollData && rentRollData[0]) {
      const { rentAmount, rentalMonthAppliedFor } = values;
      // When a user is RR matched set these values automatically as the form fields will NOT appear
      !rentAmount && setFieldValue('rentAmount', parseFloat(rentRollData[0].rent_amount)); // prettier-ignore
      !rentalMonthAppliedFor && setFieldValue('rentalMonthAppliedFor', rentMonth); // prettier-ignore
    }
  }, [dispatch, rentMonth, rentRollData, setFieldValue, values]);

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <FormField error={errors.frequency} touched={touched.frequency}>
        <Select<string>
          label="How often are you paid?"
          name="frequency"
          value={values.frequency}
          touched={touched.frequency}
          valid={!errors.frequency}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          description="This helps us set your payment frequency. If you have multiple sources of income, use your primary source."
        >
          {frequencyOptionComponents}
        </Select>
      </FormField>
      {shouldShowLastPayday && (
        <FormField error={errors.lastPayday} touched={touched.lastPayday}>
          <DatePicker
            label="When were you last paid?"
            name="lastPayday"
            value={
              values.lastPayday ? moment(values.lastPayday).toDate() : undefined
            }
            touched={touched.lastPayday}
            valid={!errors.lastPayday}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
        </FormField>
      )}
      {shouldShowSemiMonthlyOptions && (
        <FormField
          error={errors.semiMonthlyPayDays}
          touched={touched.semiMonthlyPayDays}
        >
          <Select<number[]>
            label="When are your semi-monthly pay days?"
            name="semiMonthlyPayDays"
            value={values.semiMonthlyPayDays}
            touched={touched.semiMonthlyPayDays}
            valid={!errors.semiMonthlyPayDays}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          >
            {semiMonthlyOptionComponents}
          </Select>
        </FormField>
      )}
      <FormField error={errors.payAmount} touched={touched.payAmount}>
        <Input
          label="How much do you make each paycheck?"
          placeholder="$--"
          name="payAmount"
          type="number"
          value={values.payAmount === undefined ? '' : values.payAmount}
          touched={touched.payAmount}
          valid={!errors.payAmount}
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          description="If your income varies, enter the average."
        />
      </FormField>

      {// Show income fields when the Users is NOT Rent Rol match OR the Budget and Save feature flag IS disabled
      shouldShowRentFields && (
        <div>
          <h1 className={styles.title}>Your Rent</h1>

          <FormField error={errors.rentAmount} touched={touched.rentAmount}>
            <Input
              label="How much is your monthly rent including utilities?"
              placeholder="$--"
              name="rentAmount"
              type="number"
              value={values.rentAmount === undefined ? '' : values.rentAmount}
              touched={touched.rentAmount}
              valid={!errors.rentAmount}
              onChange={handleChange}
              setFieldTouched={setFieldTouched}
              description="Include all fees (ex. parking), and use your last month for variable charges like utilities."
            />
          </FormField>

          <FormField
            error={errors.rentalMonthAppliedFor}
            touched={touched.rentalMonthAppliedFor}
          >
            <Select
              label={`Have you paid ${currentMonthName}'s rent?`}
              name="rentalMonthAppliedFor"
              value={values.rentalMonthAppliedFor}
              touched={touched.rentalMonthAppliedFor}
              valid={!errors.rentalMonthAppliedFor}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              description="This helps Till set the start date of your new payment schedule."
            >
              {rentalMonthOptions()}
            </Select>
          </FormField>
        </div>
      )}

      <div className={cx(styles.actionContainer, applyStyles.actionContainer)}>
        <Button type="button" onClick={goBack}>
          Back
        </Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !isValid}
        >
          Next
        </Button>
      </div>
    </form>
  );
}
