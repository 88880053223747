import * as React from 'react';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import { Tab, TabProps } from './tab';
import TabItem from './tab-item';

import styles from './tabs.module.css';

function findChildIndex(
  children:
    | React.ReactElement<TabProps, typeof Tab>[]
    | React.ReactElement<TabProps, typeof Tab>,
  selectedRoute: string
): number {
  if (!isArray(children)) {
    return 0;
  }

  const index = findIndex(children, child => {
    if (child.type === Tab) {
      return child.props.route === selectedRoute;
    }
    return false;
  });

  return index > -1 ? index : 0;
}

interface TabsProps {
  className?: string;
  selectedRoute?: string;
  onSelect?: (key: string) => void;
  children:
    | React.ReactElement<TabProps, typeof Tab>[]
    | React.ReactElement<TabProps, typeof Tab>;
}

interface TabsState {
  selectedIndex: number;
}

class Tabs extends React.PureComponent<TabsProps, TabsState> {
  state = {
    selectedIndex: 0
  };

  static getDerivedStateFromProps(props: TabsProps) {
    if (!props.selectedRoute) {
      return {
        selectedIndex: 0
      };
    }

    return {
      selectedIndex: findChildIndex(props.children, props.selectedRoute)
    };
  }

  onTabSelect = (route: string) => {
    const { onSelect, children, selectedRoute } = this.props;
    this.setState({
      selectedIndex: selectedRoute ? findChildIndex(children, selectedRoute) : 0
    });

    if (onSelect) {
      onSelect(route);
    }
  };

  render() {
    const { className = '', children } = this.props;

    let tabItems;
    let content;
    if (isArray(children)) {
      tabItems = React.Children.map(
        children,
        (child: React.ReactElement<TabProps, typeof Tab>, index) => {
          if (index === this.state.selectedIndex) {
            content = child.props.children;
          }
          const { title, route } = child.props;
          return (
            <TabItem
              title={title}
              route={route}
              onTabSelect={this.onTabSelect}
              active={index === this.state.selectedIndex}
            />
          );
        }
      );
    } else {
      content = children.props.children;
      const { title, route } = children.props;
      tabItems = (
        <TabItem
          title={title}
          route={route}
          onTabSelect={this.onTabSelect}
          active
        />
      );
    }

    return (
      <div className={className}>
        <ul className={styles.tabItems}>{tabItems}</ul>
        <div className={styles.tabContent}>{content}</div>
      </div>
    );
  }
}

export { Tabs, Tab };
export default Tabs;
