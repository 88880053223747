export class PasswordResetRequiredError extends Error {
  email: string;

  __proto__: any;

  constructor(email: string) {
    super(`Password reset required for ${email}`);

    // a workaround to make `instanceof` work in ES5 with babel
    Object.setPrototypeOf(this, PasswordResetRequiredError.prototype);
    this.constructor = PasswordResetRequiredError;
    this.__proto__ = PasswordResetRequiredError.prototype; // eslint-disable-line

    this.email = email;
  }
}

export class MFARequiredError extends Error {
  __proto__: any;

  constructor() {
    super(`MFA Required`);

    // a workaround to make `instanceof` work in ES5 with babel
    Object.setPrototypeOf(this, MFARequiredError.prototype);
    this.constructor = MFARequiredError;
    this.__proto__ = MFARequiredError.prototype; // eslint-disable-line
  }
}
