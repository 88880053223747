import * as React from 'react';
import cx from 'classnames';
import PaymentAccountSelect from 'components/payment-account-select';
import PciWalletIframe from 'components/pci-wallet-iframe/pci-wallet-iframe';
import { PlaidAccount, PlaidInstitution } from 'resources/plaid/types';
import styles from './setPrimaryPaymentAccount.module.css';
import Button from 'components/button';
import { useThunkDispatch } from 'resources';
import {
  setPrimaryPaymentMethod,
  keepPrimaryPaymentMethod
} from 'resources/flex/onboarding/onboarding.actions';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'resources/user/user.selectors';
import { PaymentAccount } from 'resources/flex/onboarding/onboarding.reducer';
import ContactSupportModal from 'components/support/modal';
import { Spin } from 'antd';
import UseOnboardingStep from './useOnboardingStep';
import {
  getSelectedPaymentAccount,
  isOnboardingLoading
} from 'resources/flex/onboarding/onboarding.selector';
import * as plaidSelectors from 'resources/plaid/plaid.selectors';
import * as plaidActions from 'resources/plaid/plaid.actions';

/**
 * The step in the onboarding flow where the user
 * selects the account they want to use to pay for Flexible Rent
 */
const SetPrimaryPaymentAccount = () => {
  UseOnboardingStep();

  const dispatch = useThunkDispatch();

  const { userId, preferredPaymentProfile } = useSelector(getUserAccount);
  const isLoading = useSelector(isOnboardingLoading);
  const showPciWallet = useSelector(plaidSelectors.showPciWallet);

  // the payment account selected during onboarding
  const paymentAccountOnboarding = useSelector(getSelectedPaymentAccount);

  const [isButtonDisabled, setIsButtonDisabled] = React.useState<boolean>(true);
  const [paymentAccount, setPaymentAccount] = React.useState<PaymentAccount>();
  const [selectedPlaidItem, setSelectedPlaidItem] = React.useState<
    PlaidInstitution | undefined
  >(paymentAccountOnboarding && paymentAccountOnboarding.plaidBankItem);
  const [selectedPlaidAccount, setSelectedPlaidAccount] = React.useState<
    PlaidAccount | undefined
  >(paymentAccountOnboarding && paymentAccountOnboarding.plaidBankAccount);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setIsButtonDisabled(
      paymentAccountOnboarding === undefined ||
        paymentAccountOnboarding.plaidBankItem === undefined ||
        paymentAccountOnboarding.plaidBankAccount === undefined
    );
  }, [paymentAccountOnboarding]);

  React.useEffect(() => {
    if (selectedPlaidItem && selectedPlaidAccount) {
      setPaymentAccount({
        linkType: 'plaid',
        accountType: 'plaid',
        plaidBankAccount: selectedPlaidAccount,
        plaidBankItem: selectedPlaidItem
      });
    }
  }, [selectedPlaidItem, selectedPlaidAccount]);

  const onAccountSelection = (account?: PlaidAccount) => {
    if (account) {
      setSelectedPlaidAccount(account);
    } else {
      setPaymentAccount({});
    }
    setIsButtonDisabled(!account);
  };

  const onItemSelection = (item?: PlaidInstitution) => {
    setSelectedPlaidItem(item);
    setSelectedPlaidAccount(undefined);
  };

  const onPciWalletAccountAdded = (
    type: 'debit' | 'ach',
    token: string,
    title: string
  ) => {
    setSelectedPlaidItem(undefined);
    setSelectedPlaidAccount(undefined);
    setPaymentAccount({
      linkType: 'pciwallet',
      accountType: type,
      pciAccountToken: token,
      pciAccountTitle: title
    });
  };

  const linkManually = () => {
    dispatch(plaidActions.showPciWallet(true));
  };

  const onPciCancelClick = () => {
    dispatch(plaidActions.showPciWallet(false));
  };

  const handleSubmit = async () => {
    if (paymentAccount) {
      try {
        dispatch(setPrimaryPaymentMethod(paymentAccount));
      } catch (err) {
        setError('We ran into a problem setting your payment method.');
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      // if PCI Wallet was just used to add an account, submit and move to next step
      if (paymentAccount && paymentAccount.linkType === 'pciwallet') {
        try {
          await dispatch(setPrimaryPaymentMethod(paymentAccount));
        } catch (err) {
          setError('We ran into a problem setting your payment method.');
        }
      }
    })();
  }, [dispatch, userId, paymentAccount]);

  const onErrorModalClose = () => {
    setError(undefined);
  };

  const useCurrentSelection = () => {
    dispatch(keepPrimaryPaymentMethod());
  };

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.content}>
      {showPciWallet ? (
        <div>
          <h1 className={styles.title}>Add Account</h1>
          <PciWalletIframe
            onCancelClick={onPciCancelClick}
            onAccountAdded={onPciWalletAccountAdded}
          />
        </div>
      ) : (
        <div>
          {preferredPaymentProfile &&
            /*
            If there is a payment profile with no account number, it means that
            the payment profile is a debit/credit card and we shouldn't show it
            */
            preferredPaymentProfile.accountNumber !== undefined && (
              <div>
                <div className={styles.currentSelection}>
                  <h2 className={styles.currentHeader}>
                    Current Payment Account
                  </h2>
                  <p className={styles.currentBank}>
                    {`${preferredPaymentProfile.bankName} ${preferredPaymentProfile.accountNumber}`}
                  </p>
                </div>
                <Button type="primary" onClick={useCurrentSelection}>
                  Use {preferredPaymentProfile.bankName}{' '}
                  {preferredPaymentProfile.accountNumber.slice(-4)} for Payments
                </Button>
                <p className={styles.chooseAnotherDescription}>
                  or select a different payment method below
                </p>
              </div>
            )}
          <PaymentAccountSelect
            onItemSelection={onItemSelection}
            onAccountSelection={onAccountSelection}
            defaultSelectedItem={selectedPlaidItem}
            defaultSelectedAccount={selectedPlaidAccount}
            pageName={'Payment Account'}
          />

          <div className={cx(styles.actionContainer)}>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={isButtonDisabled}
            >
              <div className={styles.buttonContents}>
                <span>Set Payment Account</span>
              </div>
            </Button>
          </div>

          <h3 className={styles.pciDescription}>
            Having trouble linking your bank?
          </h3>
          <p className={styles.linkManually} onClick={linkManually}>
            Enter account and routing number manually
          </p>
        </div>
      )}
      {error && (
        <ContactSupportModal onClose={onErrorModalClose} message={error} />
      )}
    </div>
  );
};

export default SetPrimaryPaymentAccount;
