import * as React from 'react';
import cx from 'classnames';

import styles from './switch.module.css';

interface Props {
  className?: string;
  name: string;
  label: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
}

class Switch extends React.PureComponent<Props> {
  render() {
    const { className, name, label, value, onChange } = this.props;

    return (
      <div className={cx(styles.switch, className)}>
        <label htmlFor={name}>
          {label}
          <input
            type="checkbox"
            id={name}
            checked={value}
            onChange={onChange}
          />
          <span className={styles.slider} />
        </label>
      </div>
    );
  }
}

export default Switch;
