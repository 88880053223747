import isArray from 'lodash/isArray';

export function buildQueryString(query: { [key: string]: any }): string {
  let queryString = '';
  const parts = Object.keys(query).reduce((acc: string[], key) => {
    if (!query[key]) {
      return acc;
    }
    if (isArray(query[key])) {
      query[key].forEach((value: string) =>
        acc.push(`${key}=${encodeURIComponent(value)}`)
      );
    } else {
      acc.push(`${key}=${encodeURIComponent(query[key])}`);
    }

    return acc;
  }, []);
  if (parts && parts.length) {
    queryString = `?${parts.join('&')}`;
  }

  return queryString;
}

export function getQueryVariable(query: string, variable: string) {
  // if we are trying to get the redirect parameter, assume it's the last query param in `query`
  if (variable === 'redirect_to' && query.includes(variable)) {
    const splitIndex = query.indexOf(variable);
    return query.substring(splitIndex + variable.length + 1);
  }

  // otherwise break up the query param to get individual fields and find the variable
  for (const queryVariable of query.substring(1).split('&')) {
    const keyValuePair = queryVariable.split('=');
    if (
      keyValuePair.length === 2 &&
      decodeURIComponent(keyValuePair[0]) === variable
    ) {
      return decodeURIComponent(keyValuePair[1]);
    }
  }

  return null;
}
