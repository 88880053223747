import { Layout, Spin } from 'antd';
import ProgressBar from 'components/progress-nav-bar';
import { useAuthentication } from 'helpers/useAuthentication';
import { useUserData } from 'helpers/useUserData';
import Header from 'layouts/header';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getSteps } from 'resources/flex/onboarding/onboarding.selector';
import { isUseUserDataLoading } from 'resources/loading/loading.selectors';
import { getPath } from 'resources/router/router.selectors';
import styles from './index.module.css';

interface Props {
  children: React.ReactChild | React.ReactChild[];
}

export default function FlexOnboardingLayout({ children }: Props) {
  useAuthentication();
  useUserData();

  const path = useSelector(getPath);
  const steps = useSelector(getSteps);
  const useUserDataLoading = useSelector(isUseUserDataLoading);

  return (
    <Layout className={styles.layout}>
      <Header isMenuOpen={false} setIsMenuOpen={() => {}} hideBurger />
      <Layout>
        <div className={styles.content}>
          <h1 className={styles.header}>Flexible Rent Onboarding</h1>
          <ProgressBar
            className={styles.progress}
            items={steps}
            activeItem={path}
          />
          {useUserDataLoading ? (
            <div className={styles.spinnerContainer}>
              <Spin size="large" />
            </div>
          ) : (
            children
          )}
        </div>
      </Layout>
    </Layout>
  );
}
