import * as React from 'react';
import cx from 'classnames';

import styles from './checkbox.module.css';

interface Props {
  className?: string;
  name: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  children?: React.ReactChild | React.ReactChild[];
}

function Check() {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44912 8.6786L1.13019 5.25802L0 6.41462L4.44912 11L14 1.1566L12.8778 0L4.44912 8.6786Z"
        fill="#38A7FF"
      />
    </svg>
  );
}

class Checkbox extends React.PureComponent<Props> {
  render() {
    const { className = '', name, children, onChange, value } = this.props;

    return (
      <div className={cx(styles.checkbox, className)}>
        <input
          type="checkbox"
          id={name}
          checked={value}
          className={styles.input}
          onChange={onChange}
        />
        <label htmlFor={name}>
          <Check />
          {children}
        </label>
      </div>
    );
  }
}

export default Checkbox;
