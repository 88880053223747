import * as React from 'react';

export default function Sad() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42857 0.116058C3.33482 0.116058 0 3.45088 0 7.54463C0 11.6428 3.33482 14.9777 7.42857 14.9777C11.5223 14.9777 14.8571 11.6428 14.8571 7.54463C14.8571 3.45088 11.5223 0.120522 7.42857 0.116058ZM7.42857 1.25892C10.9063 1.25892 13.7143 4.06695 13.7143 7.54463C13.7143 11.0268 10.9063 13.8348 7.42857 13.8348C3.95089 13.8348 1.14286 11.0268 1.14286 7.54463C1.14286 4.06695 3.95089 1.25892 7.42857 1.25892ZM4.57143 4.71427C3.94196 4.71427 3.42857 5.22767 3.42857 5.85713C3.42857 6.48659 3.94196 6.99999 4.57143 6.99999C5.20089 6.99999 5.71429 6.48659 5.71429 5.85713C5.71429 5.22767 5.20089 4.71427 4.57143 4.71427ZM10.2857 4.71427C9.65625 4.71427 9.14286 5.22767 9.14286 5.85713C9.14286 6.48659 9.65625 6.99999 10.2857 6.99999C10.9152 6.99999 11.4286 6.48659 11.4286 5.85713C11.4286 5.22767 10.9152 4.71427 10.2857 4.71427ZM7.42857 9.2857C6.14286 9.2857 5.26786 9.74106 4.74554 10.2232C4.22322 10.7053 4.03125 11.2411 4.03125 11.2411L5.11161 11.6161C5.11161 11.6161 5.19196 11.3661 5.52232 11.0625C5.85268 10.7589 6.40179 10.4286 7.42857 10.4286C8.41518 10.4286 8.96875 10.7545 9.3125 11.067C9.65625 11.3795 9.75446 11.6384 9.75446 11.6384L10.817 11.2187C10.817 11.2187 10.6071 10.692 10.0804 10.2187C9.55357 9.74552 8.68304 9.2857 7.42857 9.2857Z"
        fill="#475F7B"
      />
    </svg>
  );
}
