import * as React from 'react';

export default function Arrow() {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.06 0.166748L4 3.25717L0.94 0.166748L0 1.11817L4 5.16675L8 1.11817L7.06 0.166748Z"
        fill="#B3C0CE"
      />
    </svg>
  );
}
