import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './tab-item.module.css';

interface TabItemProps {
  title: React.ReactChild;
  route: string;
  onTabSelect: (route: string) => void;
  active?: boolean;
}

class TabItem extends React.PureComponent<TabItemProps> {
  onTabSelect = () => {
    const { onTabSelect, route } = this.props;
    onTabSelect(route);
  };

  render() {
    const { title, route, active } = this.props;
    return (
      <li className={cx(styles.tabItem, { [styles.tabItemActive]: active })}>
        <Link to={route} onClick={this.onTabSelect}>
          {title}
        </Link>
      </li>
    );
  }
}

export default TabItem;
