import moment from 'moment';
import curry from 'lodash/curry';

export const format = curry((format: string, date: Date | string) => {
  return moment(date).format(format);
});

type Interval =
  | 'year'
  | 'years'
  | 'y'
  | 'quarter'
  | 'quarters'
  | 'Q'
  | 'month'
  | 'months'
  | 'M'
  | 'week'
  | 'weeks'
  | 'w'
  | 'day'
  | 'days'
  | 'd'
  | 'hour'
  | 'hours'
  | 'h'
  | 'minute'
  | 'minutes'
  | 'm'
  | 'second'
  | 'seconds'
  | 's'
  | 'millisecond'
  | 'milliseconds'
  | 'ms';

export const diff = curry(
  (interval: Interval, date1: Date | string, date2: Date | string) => {
    return moment(date1).diff(date2, interval);
  }
);
