import * as React from 'react';
import cx from 'classnames';

import Address from './address';
import Arrow from './arrow';
import InProgress from './in-progress';
import Coins from './coins';
import Recurring from './recurring';
import Accept from './accept';
import Debt from './debt';
import Info from './info';
import Check from './check';
import Close from './close';
import PersonalData from './personal-data';
import Date from './date';
import Cancel from './cancel';
import Upload from './upload';
import Notification from './notification';
import Trash from './trash';
import Alarm from './alarm';
import Plus from './plus';
import Dashboard from './dashboard';
import Return from './return';
import Apply from './apply';
import Connect from './connect';
import Toolbox from './toolbox';
import EditFile from './edit-file';
import Expired from './expired';
import Search from './search';
import Invoice from './invoice';
import GoodQuality from './good-quality';
import HighPriority from './high-priority';
import Sad from './sad';
import File from './file';
import Help from './help';
import FileDoneOutlined from './file-done-outlined';
import NewTab from './open-in-new';
import Bank from './bank';
import Account from './account';
import Logout from './logout';
import Settings from './settings';
import ViewList from './view-list';
import House from './house';
import Community from './community';
import DollarSign from './dollar-sign';

import styles from './icon.module.css';
import NoLateFees from './no-late-fees';

export type IconType =
  | 'address'
  | 'arrow'
  | 'in-progress'
  | 'coins'
  | 'accept'
  | 'debt'
  | 'check'
  | 'close'
  | 'personal-data'
  | 'date'
  | 'cancel'
  | 'upload'
  | 'notification'
  | 'trash'
  | 'alarm'
  | 'plus'
  | 'dashboard'
  | 'return'
  | 'apply'
  | 'connect'
  | 'toolbox'
  | 'edit-file'
  | 'expired'
  | 'search'
  | 'invoice'
  | 'good-quality'
  | 'high-priority'
  | 'sad'
  | 'file'
  | 'help'
  | 'info'
  | 'file-done-outlined'
  | 'new-tab'
  | 'bank'
  | 'account'
  | 'logout'
  | 'settings'
  | 'view-list'
  | 'house'
  | 'community'
  | 'recurring'
  | 'no-late-fees'
  | 'dollar-sign';
interface Props {
  clickAction?: () => void;
  type: IconType;
  className?: string;
}

export default function Icon({ clickAction, type, className = '' }: Props) {
  let icon;

  switch (type) {
    case 'address': {
      icon = <Address />;
      break;
    }
    case 'arrow': {
      icon = <Arrow />;
      break;
    }
    case 'in-progress': {
      icon = <InProgress />;
      break;
    }
    case 'coins': {
      icon = <Coins />;
      break;
    }
    case 'recurring': {
      icon = <Recurring />;
      break;
    }
    case 'no-late-fees': {
      icon = <NoLateFees />;
      break;
    }
    case 'accept': {
      icon = <Accept />;
      break;
    }
    case 'debt': {
      icon = <Debt />;
      break;
    }
    case 'check': {
      icon = <Check />;
      break;
    }
    case 'close': {
      icon = <Close />;
      break;
    }
    case 'personal-data': {
      icon = <PersonalData />;
      break;
    }
    case 'date': {
      icon = <Date />;
      break;
    }
    case 'cancel': {
      icon = <Cancel />;
      break;
    }
    case 'upload': {
      icon = <Upload />;
      break;
    }
    case 'notification': {
      icon = <Notification />;
      break;
    }
    case 'trash': {
      icon = <Trash />;
      break;
    }
    case 'alarm': {
      icon = <Alarm />;
      break;
    }
    case 'plus': {
      icon = <Plus />;
      break;
    }
    case 'dashboard': {
      icon = <Dashboard />;
      break;
    }
    case 'return': {
      icon = <Return />;
      break;
    }
    case 'apply': {
      icon = <Apply />;
      break;
    }
    case 'connect': {
      icon = <Connect />;
      break;
    }
    case 'toolbox': {
      icon = <Toolbox />;
      break;
    }
    case 'edit-file': {
      icon = <EditFile />;
      break;
    }
    case 'expired': {
      icon = <Expired />;
      break;
    }
    case 'search': {
      icon = <Search />;
      break;
    }
    case 'invoice': {
      icon = <Invoice />;
      break;
    }
    case 'good-quality': {
      icon = <GoodQuality />;
      break;
    }
    case 'high-priority': {
      icon = <HighPriority />;
      break;
    }
    case 'sad': {
      icon = <Sad />;
      break;
    }
    case 'file': {
      icon = <File />;
      break;
    }
    case 'help': {
      icon = <Help />;
      break;
    }
    case 'file-done-outlined': {
      icon = <FileDoneOutlined />;
      break;
    }
    case 'new-tab':
      icon = <NewTab />;
      break;
    case 'bank':
      icon = <Bank />;
      break;
    case 'account':
      icon = <Account />;
      break;
    case 'logout':
      icon = <Logout />;
      break;
    case 'settings':
      icon = <Settings />;
      break;
    case 'house':
      icon = <House />;
      break;
    case 'view-list':
      icon = <ViewList />;
      break;
    case 'community':
      icon = <Community />;
      break;
    case 'dollar-sign':
      icon = <DollarSign />;
      break;
    case 'info':
    default: {
      icon = <Info />;
      break;
    }
  }
  return (
    <span onClick={clickAction} className={cx(styles.icon, className)}>
      {icon}
    </span>
  );
}
