import { Store } from '../../types';
import { FlexAccountState, PaymentProfile } from './types';

export const AUTOPAY_RECURRING = 'autopay.type.recurring';
export const AUTOPAY_RECURRING_MATCH = 'autopay.type.recurringMatch';

export default function getFlexAccountState({
  flexAccountState
}: Store): FlexAccountState {
  if (!flexAccountState) {
    return {
      id: 171,
      autopayEnabled: true,
      pastDue: 'current',
      amountDue: 153.43,
      daysPastDue: 0,
      nextPaymentDate: new Date(),
      nextPaymentAmount: 51.33,
      loanAmount: 0,
      loanStatusText: 'Loading',
      loanSubStatusText: 'Performing',
      payoff: 154.45,
      principalBalance: 434.23,
      paymentFrequency: 'loan.frequency.biWeekly',
      state: 'Virginia',
      transactions: [],
      paymentProfiles: [],
      payments: [],
      scheduledPayments: [],
      advancements: [],
      amountFundedToLandlord: '',
      enrollmentMonth: ''
    };
  }
  return flexAccountState;
}

export const hasOpenFlexAccount = ({ flexAccountState }: Store): boolean => {
  return flexAccountState
    ? ['Open', 'Collections'].includes(flexAccountState.loanStatusText)
    : false;
};

export function getPaymentToken({ flexAccountState }: Store) {
  return flexAccountState && flexAccountState.oboToken;
}

export function getBankPaymentMethods({
  flexAccountState
}: Store): PaymentProfile[] {
  if (!flexAccountState) {
    return [];
  }
  return flexAccountState.paymentProfiles.filter(
    paymentProfile =>
      paymentProfile.active &&
      paymentProfile.type !== 'paymentAccount.type.credit'
  );
}

export function getAllPaymentMethods({
  flexAccountState
}: Store): PaymentProfile[] {
  if (!flexAccountState) {
    return [];
  }
  return flexAccountState.paymentProfiles.filter(
    paymentProfile => paymentProfile.active
  );
}

/**
 * Retrieve the id of the Payment Method (aka Payment Profile) that will be used for Flexible rent
 * payments. Can be used to identify the active method from output of getPaymentMethods.
 */
export function getSelectedPaymentMethodId({ flexAccountState }: Store) {
  return (
    flexAccountState &&
    flexAccountState.selectedPaymentMethod &&
    flexAccountState.selectedPaymentMethod.id
  );
}

export function getNewPaymentMethod({ flexAccountState }: Store) {
  return flexAccountState && flexAccountState.newPaymentMethod;
}

export function getAccountPayments({ flexAccountState }: Store) {
  if (!flexAccountState) {
    return { transactions: [], scheduledPayments: [], autopays: [], payoff: 0 };
  }

  return {
    ...flexAccountState,
    transactions: flexAccountState.transactions,
    scheduledPayments: flexAccountState.scheduledPayments,
    autopays: flexAccountState.payments
      .sort(function(a, b) {
        if (a.applyDate < b.applyDate) {
          return -1;
        } else if (a.applyDate > b.applyDate) {
          return 1;
        }
        return 0;
      })
      .filter(
        payment =>
          (payment.type === AUTOPAY_RECURRING ||
            payment.type === AUTOPAY_RECURRING_MATCH) &&
          payment.status === 'autopay.status.pending'
      )
  };
}
