import * as React from 'react';

export default function File() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45455 0.72728C1.4555 0.72728 0.636368 1.54641 0.636368 2.54546V13.4546C0.636368 14.4536 1.4555 15.2727 2.45455 15.2727H10.9394C11.9385 15.2727 12.7576 14.4536 12.7576 13.4546V4.71876L8.7661 0.72728H2.45455ZM2.45455 1.93941H7.90909V5.57577H11.5455V13.4546C11.5455 13.7955 11.2803 14.0606 10.9394 14.0606H2.45455C2.11364 14.0606 1.84849 13.7955 1.84849 13.4546V2.54547C1.84849 2.20456 2.11364 1.93941 2.45455 1.93941ZM9.12122 2.7964L10.6885 4.36364H9.12122V2.7964Z"
        fill="#475F7B"
      />
    </svg>
  );
}
