import cx from 'classnames';
import * as React from 'react';
import styles from './button.module.css';

interface Props {
  type?: string;
  className?: string;
  onClick?: (e?: any) => void;
  children: React.ReactChild | React.ReactChild[];
  disabled?: boolean;
}

function Button({
  type = 'primary',
  className = '',
  onClick,
  children,
  disabled
}: Props) {
  let style;
  switch (type) {
    case 'link':
      style = styles.btnLink;
      break;
    case 'button':
      style = styles.btnButton;
      break;
    case 'primary':
    default:
      style = styles.btnPrimary;
      break;
  }

  return (
    <button
      type={type === 'primary' ? 'submit' : 'button'}
      className={cx(styles.btn, style, className)}
      onClick={onClick}
      disabled={!!disabled}
    >
      {children}
    </button>
  );
}

export default Button;
