import * as React from 'react';

export default function InProgress() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3C10.4663 3 9.94711 3.05769 9.44231 3.15385L9.68269 4.36538C10.1058 4.27885 10.5481 4.23077 11 4.23077C11.2356 4.23077 11.4663 4.24519 11.6923 4.26923C11.9038 4.28846 12.1154 4.32212 12.3173 4.36538V4.36058C15.4327 4.97115 17.7692 7.70673 17.7692 11C17.7692 14.2933 15.4327 17.0288 12.3173 17.6394V17.6346C12.1106 17.6779 11.899 17.7115 11.6827 17.7308C11.4567 17.7548 11.2308 17.7692 11 17.7692C10.5481 17.7692 10.1106 17.7115 9.68269 17.6298L9.44231 18.8317C9.94711 18.9327 10.4663 19 11 19C15.4135 19 19 15.4135 19 11C19 6.58654 15.4135 3 11 3ZM6.55769 4.35096C5.6875 4.9375 4.94712 5.69231 4.36058 6.5625L5.38462 7.24519C5.875 6.50962 6.50481 5.87019 7.24039 5.375L6.55769 4.35096ZM14.2163 8.14423L10.7885 12.2596L8.80769 10.3942L7.96154 11.2981L10.9038 14.0481L15.1683 8.93269L14.2163 8.14423ZM3.16827 9.44231C3.06731 9.94711 3 10.4663 3 11C3 11.5337 3.05769 12.0529 3.15385 12.5577L4.36538 12.3173C4.27885 11.8942 4.23077 11.4519 4.23077 11C4.23077 10.5481 4.28846 10.1106 4.375 9.68269L3.16827 9.44231ZM5.375 14.7596L4.35096 15.4423C4.9375 16.3125 5.69231 17.0529 6.5625 17.6394L7.24519 16.6154C6.50962 16.125 5.87019 15.4952 5.375 14.7596Z"
        fill="#475F7B"
      />
    </svg>
  );
}
