import * as React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

import styles from './chart.module.css';

interface Props {
  value: number;
  children: React.ReactChild | React.ReactChild[];
}

interface AmountValue {
  value: number;
}

const cleanData = (amounts: Array<AmountValue>) =>
  amounts.some(({ value }) => isNaN(value)) ? [] : amounts;

const COLORS = ['#73D500', '#FD4D7A'];

function Chart({ value, children }: Props) {
  const data = cleanData([{ value }, { value: 100 - value }]);

  const cells = data.map((value, idx) => <Cell key={idx} fill={COLORS[idx]} />);

  return data.length > 0 ? (
    <div className={styles.container}>
      <PieChart width={230} height={230} className={styles.chart}>
        <Pie
          data={data}
          cx={110}
          cy={110}
          startAngle={450}
          endAngle={90}
          innerRadius={110}
          outerRadius={115}
          paddingAngle={5}
          dataKey="value"
        >
          {cells}
        </Pie>
      </PieChart>
      <div className={styles.inner}>{children}</div>
    </div>
  ) : (
    <h3 className={styles.heading}>No Outstanding Loans</h3>
  );
}

export default Chart;
