import * as React from 'react';

export default function Recurring() {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46">
      <g transform="translate(-18 -321)">
        <circle
          cx="23"
          cy="23"
          r="23"
          fill="#1eb5f0"
          transform="translate(18 321)"
        />
        <g>
          <path
            fill="none"
            d="M0 0h26.833v26.833H0z"
            transform="translate(27.583 330.583)"
          />
          <path
            fill="#fff"
            d="M12.944 6.507v2a.553.553 0 0 0 .95.391l3.12-3.118a.554.554 0 0 0 0-.794l-3.119-3.119a.559.559 0 0 0-.95.4v2A8.942 8.942 0 0 0 4 13.215a8.752 8.752 0 0 0 .637 3.3 1.121 1.121 0 0 0 1.834.38 1.074 1.074 0 0 0 .257-1.163 6.51 6.51 0 0 1-.492-2.516 6.714 6.714 0 0 1 6.708-6.709zm6.474 3.03a1.079 1.079 0 0 0-.257 1.163 6.7 6.7 0 0 1-6.216 9.224v-2a.553.553 0 0 0-.95-.391l-3.12 3.117a.554.554 0 0 0 0 .794l3.119 3.119a.558.558 0 0 0 .95-.391V22.16a8.942 8.942 0 0 0 8.944-8.944 8.752 8.752 0 0 0-.637-3.3 1.121 1.121 0 0 0-1.834-.38z"
            transform="translate(27.583 330.583) translate(0.472 0.201)"
          />
        </g>
      </g>
    </svg>
  );
}
