import axios from 'axios';
import config from 'helpers/config';
import {
  PlaidAccount,
  PlaidCreateItemLinkData,
  PlaidInstitution
} from './types';
import { handleError } from 'helpers/api.error';

/**
 * Retrieves all linked Plaid items (aka institutions) that have been previously
 * linked to a user
 */
export const getLinkedItems = async (
  userId?: string,
  headers?: any
): Promise<PlaidInstitution[]> => {
  try {
    const response = await axios.get(
      `${config.apiUrl}/api/user/${userId}/plaid`,
      {
        headers
      }
    );
    return response.data;
  } catch (err) {
    return handleError(err, 'Unable to retrieve items');
  }
};

export interface GetAccountsData {
  userId: string;
  plaidItemId: string;
}

/**
 * Retrives all accounts for a specific Plaid item (aka institution)
 */
export const getAccountsForItem = async (
  data?: GetAccountsData,
  headers?: any
): Promise<PlaidAccount[]> => {
  if (data) {
    const response = await axios.get(
      `${config.apiUrl}/api/user/${data.userId}/plaid/${data.plaidItemId}/accounts`,
      { headers }
    );
    return response.data;
  } else {
    throw new Error('Missing required data');
  }
};

/**
 * Links a Plaid item (aka institution) to a user
 */
export const linkitem = async (
  data: PlaidCreateItemLinkData,
  headers?: any
): Promise<PlaidInstitution> => {
  try {
    const response = await axios.post(`${config.apiUrl}/api/user/plaid`, data, {
      headers
    });
    return response.data.payload;
  } catch (err) {
    return handleError(err, 'Unable to link item');
  }
};

/**
 * Sends a request to the API to create a generic Plaid link or a
 * link for a specific Plaid item if an `itemId` is passed in.
 */
export const createLinkToken = async (itemId?: string, headers?: any) => {
  try {
    console.log(`${window.location.origin}/oauth-verification`);
    const response = await axios.post(
      `${config.apiUrl}/plaid-link-token`,
      {
        itemId,
        redirectPath: `${window.location.origin}/oauth-verification`
      },
      {
        headers
      }
    );
    return response.data.token;
  } catch (err) {
    return handleError(err, 'Unable to create item link');
  }
};
