import * as React from 'react';
import { Option } from 'components/form-select';

/**
 * Creates an option to be used in a list of options for forms
 */
export function createOption<T>(
  disabled: boolean,
  key: T | undefined = undefined,
  option: any,
  text: any
) {
  return (
    <Option
      key={key ? key : option}
      value={key ? key : option}
      disabled={disabled}
    >
      {text ? text : option}
    </Option>
  );
}

/**
 * Creates an options input component for selecting from a list of options
 */
export function createOptionComponents<T>(options: {
  [key: string]: T | undefined;
}) {
  return Object.keys(options).reduce<JSX.Element[]>((result, key) => {
    result.push(createOption(false, options[key], key, null));
    return result;
  }, []);
}
