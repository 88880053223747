import { Community } from '@hellotill/community-types';
import { createTypedApiAction } from '../../helpers/apiActionCreator';
import * as communityApi from './community.api';
import { CommunitySearchResult } from './types';

export type CommunityActionType = 'GET_COMMUNITY_BY_ID' | 'SEARCH_COMMUNITY';

export interface CommunityAction {
  type: CommunityActionType;
  payload: Partial<Community> | CommunitySearchResult[];
}

/**
 * Retrieves a user's flex payment schedule
 * @param userId The user id of the user to fetch a payment schedule for
 */
export const getCommunityById = (communityId?: string) => {
  return createTypedApiAction<string, Partial<Community>, CommunityActionType>(
    'GET_COMMUNITY_BY_ID',
    communityApi.getCommunity
  )(communityId);
};

export const searchCommunity = (name: string) => {
  return createTypedApiAction<
    string,
    CommunitySearchResult[],
    CommunityActionType
  >('SEARCH_COMMUNITY', communityApi.searchCommunity)(name);
};
