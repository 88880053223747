import { AnyAction } from 'redux';

import { FlexAccountState, PaymentProfile } from './types';
import {
  GET_ACCOUNTS_BY_CUSTOMER,
  LOAD_PAYMENT_TOKEN,
  LOAD_PAYMENT_PROFILES,
  SELECT_PAYMENT_PROFILE,
  GET_LOANS_BY_CUSTOMER
} from './account.actions';

const defaultState: FlexAccountState = {
  id: 0,
  autopayEnabled: false,
  pastDue: 'current',
  amountDue: 0,
  daysPastDue: 0,
  nextPaymentDate: new Date(),
  nextPaymentAmount: 0,
  loanAmount: 0,
  loanStatusText: 'Loading',
  loanSubStatusText: 'Performing',
  payoff: 0,
  principalBalance: 0,
  transactions: [],
  state: 'Virginia',
  paymentProfiles: [],
  paymentFrequency: 'loan.frequency.biWeekly',
  payments: [],
  scheduledPayments: [],
  advancements: [],
  amountFundedToLandlord: '',
  enrollmentMonth: ''
};

/**
 * Returns the difference of paymentMethods between paymentProfiles before a new payment is added and after a new payment is added
 * Used to change the selected Payment Method highlighted in the paynow modal
 */
export function loadPaymentProfilesDiff(
  previousPaymentProfiles: PaymentProfile[],
  currentPaymentProfiles: PaymentProfile[]
): any {
  const previousIds = previousPaymentProfiles
    .filter(profile => profile.active)
    .map(profile => profile.paymentProfileId);
  const currentIds = currentPaymentProfiles
    .filter(profile => profile.active)
    .map(profile => profile.paymentProfileId);

  const diffIds = currentIds
    .filter(id => !previousIds.includes(id))
    .concat(previousIds.filter(id => !currentIds.includes(id)));

  if (diffIds.length !== 1) {
    return null;
  }

  const diffId = diffIds[0];
  return currentPaymentProfiles.find(
    paymentProfile => paymentProfile.paymentProfileId === diffId || null
  );
}

/**
 * Find the payment profile on the current flex recurring payment.
 */
export function loadSelectedPaymentMethod(payload: any) {
  let selectedPaymentProfile = undefined;
  if (payload.nextAutopayId && payload.payments.length > 0) {
    selectedPaymentProfile = payload.payments.find(
      (v: any) => v.id === payload.nextAutopayId
    ).paymentMethod;
  }
  return selectedPaymentProfile;
}

/**
 * Updated state to reflect payment profile selection changes
 */
export function updateSelectedPaymentMethod(
  state: FlexAccountState,
  payload: any
) {
  if (payload === undefined || payload.paymentProfileId === undefined) {
    return undefined;
  }
  const profile = state.paymentProfiles.find(
    v => v.paymentProfileId === payload.paymentProfileId
  );
  if (profile !== undefined) {
    return {
      id: profile.paymentProfileId,
      type: profile.type,
      title: profile.title
    };
  }
  return undefined;
}

export default function flexAccountReducer(
  state: FlexAccountState | undefined = defaultState,
  action: AnyAction
): FlexAccountState {
  const previousPaymentProfiles = state.paymentProfiles;
  switch (action.type) {
    case GET_ACCOUNTS_BY_CUSTOMER:
      return {
        ...state,
        ...action.payload,
        selectedPaymentMethod: loadSelectedPaymentMethod(action.payload)
      };
    case GET_LOANS_BY_CUSTOMER:
      return {
        ...state,
        ...action.payload.loan
      };
    case LOAD_PAYMENT_TOKEN:
      return {
        ...state,
        oboToken: action.payload.oboToken
      };
    case LOAD_PAYMENT_PROFILES:
      return {
        ...state,
        paymentProfiles: action.payload,
        newPaymentMethod: loadPaymentProfilesDiff(
          previousPaymentProfiles,
          action.payload
        )
      };
    case SELECT_PAYMENT_PROFILE:
      return {
        ...state,
        selectedPaymentMethod: updateSelectedPaymentMethod(
          state,
          action.payload
        )
      };
  }

  return state;
}
