import * as React from 'react';

export default function Debt() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3C9.98558 3 9.15385 3.83173 9.15385 4.84615V5.46154H7.01923C6.1875 5.46154 5.45192 6.02885 5.24039 6.83173L3.23077 14.3606C3.07692 14.9279 3 15.5144 3 16.101V17.1442C3 18.1587 3.83173 18.9904 4.84615 18.9904L17.1538 19C18.1683 19 19 18.1683 19 17.1538V16.1154C19 15.524 18.9231 14.9375 18.7692 14.3702V14.3654L16.6298 6.83173C16.4135 6.02885 15.6779 5.46154 14.8462 5.46154H12.8462V4.84615C12.8462 3.83173 12.0144 3 11 3ZM11 4.23077C11.3462 4.23077 11.6154 4.5 11.6154 4.84615V5.46154H10.3846V4.84615C10.3846 4.5 10.6538 4.23077 11 4.23077ZM7.01923 6.69231H14.8462C15.1298 6.69231 15.3654 6.875 15.4423 7.14904V7.15865L17.5817 14.6971C17.7067 15.1587 17.7692 15.6346 17.7692 16.1154V17.1538C17.7692 17.5 17.5 17.7692 17.1538 17.7692L4.84615 17.7596C4.5 17.7596 4.23077 17.4904 4.23077 17.1442V16.101C4.23077 15.6202 4.29327 15.1442 4.41827 14.6731L6.42788 7.14904C6.5 6.875 6.74039 6.69231 7.01923 6.69231ZM10.399 7.92308V9.15385H9.76923C8.75481 9.15385 7.92308 9.98558 7.92308 11C7.92308 12.0144 8.75481 12.8462 9.76923 12.8462H12.2308C12.5769 12.8462 12.8462 13.1154 12.8462 13.4615C12.8462 13.8077 12.5769 14.0769 12.2308 14.0769H7.92308V15.3077H10.399V16.5385H11.6298V15.3077H12.2308C13.2452 15.3077 14.0769 14.476 14.0769 13.4615C14.0769 12.4471 13.2452 11.6154 12.2308 11.6154H9.76923C9.42308 11.6154 9.15385 11.3462 9.15385 11C9.15385 10.6538 9.42308 10.3846 9.76923 10.3846H12.8462V9.15385H11.6298V7.92308H10.399Z"
        fill="#475F7B"
      />
    </svg>
  );
}
