import * as React from 'react';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';
import Icon from '../icon';

import styles from './input.module.css';
import formStyles from 'styles/form.module.css';

interface Props {
  className?: string;
  type?: string;
  touched?: boolean;
  mask?: Array<string | RegExp>;
  valid: boolean;
  name: string;
  label?: string;
  value?: string | number;
  placeholder?: string;
  icon?: React.ReactElement<{}, typeof Icon>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  focusRef?: React.MutableRefObject<any>;
  description?: string;
  otherProps?: React.HTMLAttributes<any>;
}

interface State {
  focused: boolean;
}

class TillInput extends React.PureComponent<Props, State> {
  state = {
    focused: false
  };

  onFocus = () => this.setState({ focused: true });
  onBlur = () => {
    const { setFieldTouched, name } = this.props;
    setFieldTouched(name);
    this.setState({ focused: false });
  };

  render() {
    const {
      type = 'text',
      className = '',
      touched,
      valid,
      label,
      name,
      value,
      placeholder,
      onChange,
      icon,
      mask,
      disabled,
      focusRef,
      description,
      otherProps
    } = this.props;

    let input;
    if (mask) {
      input = (
        <MaskedInput
          type={type}
          className={cx(styles.input, {
            [styles.focused]: this.state.focused,
            [styles.valid]: !this.state.focused && touched && valid,
            [styles.error]: !this.state.focused && touched && !valid
          })}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placeholder={placeholder}
          mask={mask}
          placeholderChar="_"
          disabled={disabled}
          ref={focusRef}
          {...otherProps}
        />
      );
    } else {
      input = (
        <input
          type={type}
          className={cx(styles.input, {
            [styles.focused]: this.state.focused,
            [styles.valid]: !this.state.focused && touched && valid,
            [styles.error]: !this.state.focused && touched && !valid
          })}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placeholder={placeholder}
          disabled={disabled}
          ref={focusRef}
          {...otherProps}
        />
      );
    }

    return (
      <div className={cx(styles.inputContainer, className)}>
        {label && (
          <label
            htmlFor={name}
            className={cx(styles.label, {
              [styles.focused]: this.state.focused
            })}
          >
            {label}
          </label>
        )}
        {description && <p className={formStyles.description}>{description}</p>}
        <div className={styles.inputWrap}>
          {input}
          {icon ? <span className={styles.icon}>{icon}</span> : null}
        </div>
      </div>
    );
  }
}

export default TillInput;
