import * as Sentry from '@sentry/browser';
import * as React from 'react';
import moment from 'moment';
import Icon from 'components/icon';
import styles from './confirmScheduledPayments.module.css';
import { Spin } from 'antd';
import Button from 'components/button';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { getUserAccount } from 'resources/user/user.selectors';
import { useThunkDispatch } from 'resources';
import { enrollFlexibleRentApplication } from 'resources/flexpay/acceptance/acceptance.actions';
import { push } from 'connected-react-router';
import { getScheduleDisplayData } from 'resources/flexpay/acceptance/acceptance.selectors';
import {
  displayDays,
  displayFrequency,
  formatDecimals
} from '../apply/schedule/createPaymentInfoCardProps';
import ContactSupportModal from 'components/support/modal';
import { goToPreviousStep } from 'resources/flex/onboarding/onboarding.actions';
import UseOnboardingStep from './useOnboardingStep';
import {
  getOnboardingError,
  getSelectedPaymentAccount,
  isOnboardingLoading
} from 'resources/flex/onboarding/onboarding.selector';
import { useAuthentication } from 'helpers/useAuthentication';

/**
 * Step in the onboarding flow which shows the user their payment schedule and
 * where they confirm their first payment and autopay setup.
 */
const ConfirmScheduledPayments = () => {
  useAuthentication();
  UseOnboardingStep();

  const dispatch = useThunkDispatch();

  const { userId, preferredPaymentProfile } = useSelector(getUserAccount);
  // the payment account selected during onboarding
  const paymentAccountOnboarding = useSelector(getSelectedPaymentAccount);

  const scheduleDisplayData = useSelector(getScheduleDisplayData);
  const isLoading = useSelector(isOnboardingLoading);
  const onboardingError = useSelector(getOnboardingError);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setError(onboardingError);
  }, [onboardingError]);

  const onConfirmPayment = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(enrollFlexibleRentApplication(userId));
      setIsSubmitting(false);
    } catch (error) {
      // capture every error at enrollment in Sentry
      Sentry.captureException(error);
      setError('We were unable to complete your enrollment.');
      setIsSubmitting(false);
      return;
    }

    try {
      dispatch(push('/flexpay/enrollment/success'));
    } catch (err) {
      Sentry.captureException(error);
      // redirect the user automatically to the dashboard
      window.location.reload();
    }
  };

  const onErrorClose = () => {
    setError(undefined);
  };

  const onBack = () => {
    dispatch(goToPreviousStep());
  };

  const bankName =
    (paymentAccountOnboarding &&
      paymentAccountOnboarding.plaidBankItem &&
      paymentAccountOnboarding.plaidBankItem.institutionName) ||
    (preferredPaymentProfile && preferredPaymentProfile.bankName);

  const accountNumber = `****
    ${(paymentAccountOnboarding &&
      paymentAccountOnboarding.plaidBankAccount &&
      paymentAccountOnboarding.plaidBankAccount.mask) ||
      (preferredPaymentProfile && preferredPaymentProfile.accountNumber)}`;

  if (isLoading || !scheduleDisplayData) {
    return (
      <div className={styles.spinnerContainer}>
        <Spin size="large" />
      </div>
    );
  } else {
    return (
      <div className={styles.scheduleContainer}>
        <div className={styles.scheduleContent}>
          <h1 className={styles.pageHeading}>Your Payment Schedule</h1>

          <p className={styles.disclaimer}>
            {scheduleDisplayData.initialPayment === 0
              ? `Confirm your autopay schedule.`
              : `Review and process your initial payment.`}
          </p>

          {scheduleDisplayData.initialPayment !== 0 && (
            <div>
              <div className={styles.paymentHeadingContainer}>
                <h2 className={styles.paymentInfoHeading}>First Payment</h2>
              </div>

              <div className={styles.paymentInfoContainer}>
                <p
                  className={cx(
                    styles.paymentInfoText,
                    styles.paymentInfoAmount
                  )}
                >
                  ${formatDecimals(scheduleDisplayData.initialPayment)}
                </p>
                <div className={styles.marginBottomContainer}>
                  <Icon type="date" className={styles.dateIcon} />
                  <p
                    className={cx(
                      styles.paymentInfoText,
                      styles.paymentInfoDate
                    )}
                  >
                    Charged today
                  </p>
                </div>
                <div className={styles.dateContainer}>
                  <Icon type="coins" className={styles.dateIcon} />
                  <p
                    className={cx(
                      styles.paymentInfoText,
                      styles.paymentInfoDate
                    )}
                  >
                    {`${bankName} ${accountNumber}`}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className={styles.buttonsContainer}>
            <Button
              className={styles.backButton}
              type="button"
              onClick={onBack}
              disabled={isSubmitting}
            >
              Back
            </Button>
            <Button
              className={styles.confirmPaymentsButton}
              type="primary"
              onClick={onConfirmPayment}
              disabled={isSubmitting}
            >
              {scheduleDisplayData.initialPayment === 0
                ? `Confirm Autopay`
                : `Process Initial Payment`}
            </Button>
          </div>
          {isSubmitting && (
            <Spin size="large" tip={'This will take a minute to complete.'} />
          )}

          {scheduleDisplayData.currentMonthPayments.length !== 0 && (
            <div>
              <div className={styles.paymentHeadingContainer}>
                <h2 className={styles.paymentInfoHeading}>
                  {moment(scheduleDisplayData.firstRecurringPaymentDate).format(
                    'MMMM'
                  )}
                  &nbsp;Payment Schedule
                </h2>
              </div>
              <p className={styles.disclaimerSmall}>
                Your ongoing rent payments will be set up on autopay.
              </p>

              <div className={styles.paymentInfoContainer}>
                {scheduleDisplayData.currentMonthPayments.map(
                  (payment, index) => {
                    return (
                      <div key={index}>
                        <p
                          className={cx(
                            styles.paymentInfoText,
                            styles.paymentInfoAmount
                          )}
                        >
                          $
                          {formatDecimals(
                            parseFloat(payment.amount.toString())
                          )}
                        </p>
                        <div className={styles.marginBottomContainer}>
                          <Icon type="date" className={styles.dateIcon} />
                          <p
                            className={cx(
                              styles.paymentInfoText,
                              styles.paymentInfoLongDate
                            )}
                          >
                            Charged on&nbsp;
                            {moment(payment.date, 'YYYY-MM-DD').format(
                              'dddd MMM. Do, YYYY'
                            )}
                          </p>
                        </div>
                        <div className={styles.dateContainer}>
                          <Icon type="coins" className={styles.dateIcon} />
                          <p
                            className={cx(
                              styles.paymentInfoText,
                              styles.paymentInfoDate
                            )}
                          >
                            {`${bankName} ${accountNumber}`}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}

          <div className={styles.paymentHeadingContainer}>
            <h2 className={styles.paymentInfoHeading}>
              Ongoing Payment Schedule
            </h2>
          </div>
          <p className={styles.disclaimerSmall}>
            Your ongoing rent payments will be set up on autopay.
          </p>

          <div className={styles.paymentInfoContainer}>
            <p className={cx(styles.paymentInfoText, styles.paymentInfoAmount)}>
              ${formatDecimals(scheduleDisplayData.futureMonthPerPayment)}
            </p>
            <div className={styles.marginBottomContainer}>
              <Icon type="alarm" className={styles.dateIcon} />
              <p
                className={cx(
                  styles.paymentInfoText,
                  styles.paymentInfoFrequency
                )}
              >
                Charged {displayDays(scheduleDisplayData.monthlyPaymentDays)}
                {displayFrequency(scheduleDisplayData.frequency)}
              </p>
            </div>
            <div className={styles.marginBottomContainer}>
              <Icon type="date" className={styles.dateIcon} />
              <p
                className={cx(
                  styles.paymentInfoText,
                  styles.paymentInfoLongDate
                )}
              >
                Starting on&nbsp;
                {moment(scheduleDisplayData.futureMonthPaymentDate).format(
                  'dddd MMM. Do, YYYY'
                )}
              </p>
            </div>
            <div className={styles.dateContainer}>
              <Icon type="coins" className={styles.dateIcon} />
              <p className={cx(styles.paymentInfoText, styles.paymentInfoDate)}>
                {`${bankName} ${accountNumber}`}
              </p>
            </div>
          </div>
        </div>
        {error && (
          <ContactSupportModal onClose={onErrorClose} message={error} />
        )}
      </div>
    );
  }
};

export default ConfirmScheduledPayments;
