import cx from 'classnames';
import Button from 'components/button';
import Icon from 'components/icon';
import config from '../../../helpers/config';
import ContactSupportModal from 'components/support/modal';
import { useAuthentication } from 'helpers/useAuthentication';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from 'resources';
import { getUserAccount } from 'resources/user/user.selectors';
import formStyles from 'styles/form.module.css';
import { useScrollToTop } from '../../../helpers/useScrollToTop';
import { ROUTES } from '../../../routes';
import baseStyles from '../../../styles/base.module.css';
import styles from './submit.module.css';
import applyStyles from './apply.module.css';
import { Spin } from 'antd';
import * as applyActions from '../../../resources/flex/apply/apply.actions';
import { getFlexApplyState } from 'resources/flex/apply/apply.selectors';
import useApplicationStep from './useApplicationStep';
import ActionModal from 'components/action-modal';
import FormField from 'form-field';
import TillInput from 'components/form-input';

/**
 * The last page of the Flexible Rent application where a customer agrees and submits
 * their application
 */
export default function Submit() {
  useAuthentication();
  useApplicationStep();

  const dispatch = useThunkDispatch();

  const user = useSelector(getUserAccount);
  const flexApplyState = useSelector(getFlexApplyState);

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [plaidError, setPlaidError] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [referralCode, setReferralCode] = React.useState<string>();
  const [referralCodeTouched, setReferralCodeTouched] = React.useState(false);
  const [referralCodeError, setReferralCodeError] = React.useState<string>();

  const submit = async () => {
    setLoading(true);
    try {
      if (flexApplyState) {
        await dispatch(
          applyActions.submitApplication({
            user,
            flexApplyState: { ...flexApplyState, ...flexApplyState.assistance },
            referralCode
          })
        );
      } else {
        setErrorMessage('Unable to submit application');
      }
    } catch (error) {
      if (error.code === 'ITEM_LOGIN_REQUIRED') {
        setPlaidError(
          error.message +
            ' If this error persists, try entering your account and routing number manually.'
        );
      } else {
        setErrorMessage('Unable to submit application');
      }
    }

    setLoading(false);
  };

  const onCloseErrorModal = () => {
    setErrorMessage(undefined);
    setPlaidError(undefined);
  };

  const onPlaidErrorAction = () => {
    dispatch(applyActions.goToStep(ROUTES.flex.apply.payment));
  };

  const goBack = () => {
    dispatch(applyActions.goToPreviousStep());
  };

  const onTouchReferralCode = () => {
    setReferralCodeTouched(true);
  };

  const onChangeReferralCode = ({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (
      target.value !== '' &&
      target.value.match(/^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/) === null
    ) {
      setReferralCodeError(
        'Referral code must be in the following format XXX-XXX'
      );
    } else {
      setReferralCodeError(undefined);
    }
    setReferralCode(target.value);
  };

  useScrollToTop();

  const buttonText = 'Agree and Submit Application';
  return (
    <div>
      <div className={baseStyles.row}>
        <div className={cx(baseStyles.col)}>
          <h2>Agree and Submit Application</h2>
          <hr />
          <h6>Please read carefully</h6>
        </div>
      </div>
      <FormField error={referralCodeError} touched={referralCodeTouched}>
        <TillInput
          placeholder="XXX-XXX"
          name="referralCode"
          type="text"
          value={referralCode === undefined ? '' : referralCode}
          touched={referralCodeTouched}
          valid={!referralCodeError}
          onChange={onChangeReferralCode}
          setFieldTouched={onTouchReferralCode}
          description="If you have a referral code, enter it here."
        />
      </FormField>
      <div className={cx(baseStyles.row, styles.agreementContainer)}>
        <div
          className={cx(baseStyles.col, formStyles.inputCol, styles.agreement)}
        >
          <div className={styles.title}>
            {`By clicking "${buttonText}", you acknowledge that you read, understand and agree:`}
          </div>
          <div className={styles.item}>
            <Icon type="check" />
            To the{' '}
            <a
              href={ROUTES.legal.termsOfService}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {', and '}
            <a
              href={ROUTES.legal.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className={styles.item}>
            <Icon type="check" />
            {'To the '}
            <a
              href={ROUTES.legal.esignConsent}
              target="_blank"
              rel="noopener noreferrer"
            >
              E-SIGN ACT Consent and Electronic Communications Policy
            </a>
            {' and '}
            <a
              href={ROUTES.legal.consentToBeContacted}
              target="_blank"
              rel="noopener noreferrer"
            >
              Consent to be Contacted
            </a>
            {'.'}
          </div>
          <div className={styles.item}>
            <Icon type="check" />
            That all of the information provided in this application is true and
            accurate.
          </div>
        </div>
      </div>
      <div
        className={cx(formStyles.actionContainer, applyStyles.actionContainer)}
      >
        <Button type="button" onClick={goBack} disabled={loading}>
          Back
        </Button>
        {!loading ? (
          <Button
            type="primary"
            onClick={submit}
            disabled={loading || referralCodeError !== undefined}
          >
            {buttonText}
          </Button>
        ) : (
          <Spin
            className={loading ? styles.spinner : styles.spinnerHidden}
            size={'small'}
          />
        )}
      </div>
      {plaidError && (
        <ActionModal
          onClose={onCloseErrorModal}
          onAction={onPlaidErrorAction}
          message={plaidError}
          title="Sorry, something went wrong."
          buttonText="Update My Payment Selection"
        />
      )}
      {errorMessage && (
        <ContactSupportModal
          onClose={onCloseErrorModal}
          supportPhoneNumber={config.supportPhoneNumber}
          message={errorMessage}
        />
      )}
    </div>
  );
}
