import { Spin } from 'antd';
import cx from 'classnames';
import * as React from 'react';
import formStyles from 'styles/form.module.css';
import { PaymentProfile } from '../../resources/loan/account/types';
import baseStyles from '../../styles/base.module.css';
import AddPaymentMethod from '../add-payment-method';
import Button from '../button';
import Datepicker from '../date-picker';
import Input from '../form-input';
import TillModal from '../modal';
import PaymentMethod from '../payment-method';
import PaymentOption from './payment-option';
import styles from './styles.module.css';
import { ROUTES } from '../../routes';

const PAY_DUE = 0;
const PAY_ADDITIONAL = 1;
const SCHEDULE_PAYMENT = 2;
const PAY_ENTIRE = 3;
const CHECK_BALANCE = 4;
const ENROLL_RENT_PROTECTION = 5;

function formatPaymentMethod(method: PaymentProfile) {
  if (method.accountNumber) {
    return `${method.accountNumber} account`;
  }

  if (method.cardNumber) {
    return `${method.cardNumber} Debit Card`;
  }
}

interface Props {
  showDebitCard?: boolean;
  account?: 'flex' | 'loan';
  min: number;
  max: number;
  date?: string | Date;
  amount?: number;
  makePayment: (
    amount: number,
    date: Date,
    paymentMethod: PaymentProfile,
    paymentId?: number
  ) => void;
  methods: PaymentProfile[];
  debitIframeUrl: string;
  achIframeUrl: string;
  handlePaymentProfileEvent: (
    status?: number,
    token?: string,
    accountTitle?: string,
    accountType?: 'ach' | 'debit'
  ) => void;
  isSubmitting?: boolean;
  selectedMethod?: PaymentProfile;
  paymentId?: number;
}

interface State {
  optionIndex: number;
  selectOptionMode: boolean;
  amount: number;
  amountTouched: boolean;
  amountError: string;
  date?: Date;
  dateTouched: boolean;
  dateError: string;
  methods: PaymentProfile[];
  selectedMethod?: PaymentProfile;
  showConfirmationModal: boolean;
}

interface ConfirmationModalProps {
  onClose: () => void;
  onConfirmPayment: () => void;
}

function ConfirmationModal({
  onClose,
  onConfirmPayment
}: ConfirmationModalProps) {
  return (
    <TillModal visible className={styles.confirmationModal} onClose={onClose}>
      <div className={styles.content}>
        <h1 className={styles.modalTitle}>Confirm Payment</h1>
        <p className={styles.message}>
          {'By selecting "Confirm Payment", you acknowledge and agree that '}
          <a
            href={ROUTES.legal.electronicPaymentTerms}
            target="_blank"
            rel="noopener noreferrer"
          >
            Electronic Payment Terms
          </a>
          {' apply to these transactions.'}
        </p>
        <div className={styles.buttons}>
          <Button type="link" onClick={onClose}>
            Cancel
          </Button>
          <Button type="link" onClick={onConfirmPayment}>
            Confirm Payment
          </Button>
        </div>
      </div>
    </TillModal>
  );
}

class PayNow extends React.PureComponent<Props, State> {
  state: State = {
    methods: this.props.methods,
    optionIndex:
      this.props.date && this.props.account !== 'flex'
        ? SCHEDULE_PAYMENT
        : PAY_DUE,
    selectOptionMode: true,
    amount: this.props.amount || this.props.min,
    amountTouched: false,
    amountError: '',
    date: this.props.date ? new Date(this.props.date) : new Date(),
    dateTouched: false,
    dateError: '',
    selectedMethod: this.props.selectedMethod,
    showConfirmationModal: false
  };

  /* 
  Method compares the state vs props to see if they are the same, if not, state gets reassigned to props with the latest changes
  */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.methods.length !== props.methods.length) {
      return {
        selectedMethod: props.selectedMethod || props.methods[0]
      };
    }

    return {};
  }

  onMethodSelect = (method: any) => {
    this.setState({ selectedMethod: method });
  };

  onOptionSelect = (index: number) => {
    if (index === PAY_ENTIRE) {
      this.setState({
        amount: this.props.max,
        date: new Date(),
        optionIndex: index,
        selectOptionMode: false
      });
    } else if (index === SCHEDULE_PAYMENT) {
      this.setState({
        amount: this.props.amount || this.props.min,
        optionIndex: index,
        selectOptionMode: false
      });
    } else {
      this.setState({
        amount: this.props.min,
        date: new Date(),
        optionIndex: index,
        selectOptionMode: false
      });
    }
  };

  onChangeAmount = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(target.value);
    if (value <= this.props.max) {
      this.setState({ amount: value, amountError: '' });
    } else {
      this.setState({
        amount: value,
        amountError: `The amount should be less than $${this.props.max}`
      });
    }
  };
  onTouchAmount = () => this.setState({ amountTouched: true });

  onDateChange = (name: string, value?: Date) => {
    if (value && value.valueOf() < Date.now()) {
      this.setState({ date: value, dateError: 'Please select date in future' });
    } else {
      this.setState({ date: value, dateError: '' });
    }
  };
  onTouchDate = () => this.setState({ dateTouched: true });

  toggleConfirmationModal = (shouldShow: boolean) =>
    this.setState({ showConfirmationModal: shouldShow });

  pay = async () => {
    this.toggleConfirmationModal(false);
    const { amount, date = new Date(), selectedMethod } = this.state;
    const { makePayment, paymentId } = this.props;
    if (selectedMethod && amount && date) {
      makePayment(amount, date, selectedMethod, paymentId);
    }
  };

  onOpenSelectOptionMode = () => this.setState({ selectOptionMode: true });

  render() {
    const {
      selectedMethod,
      optionIndex,
      amount,
      amountTouched,
      amountError,
      date,
      dateTouched,
      dateError,
      showConfirmationModal
    } = this.state;

    const isEditingPayment = !!this.props.paymentId;

    const payOptions =
      this.props.account === 'flex'
        ? [PAY_DUE, PAY_ADDITIONAL]
        : [PAY_DUE, PAY_ADDITIONAL, SCHEDULE_PAYMENT, PAY_ENTIRE];

    const options = isEditingPayment
      ? null
      : payOptions.map(index => {
          let props: {
            index: number;
            title: string;
            amount?: number;
            bodyText?: string;
            selected: boolean;
            className?: string;
          } = {
            index,
            title: '',
            amount: undefined,
            selected: index === optionIndex,
            className: isEditingPayment ? baseStyles.hidden : undefined
          };

          switch (index) {
            case PAY_DUE: {
              props = {
                ...props,
                title: 'Pay Amount Due',
                amount: this.props.min
              };
              break;
            }
            case PAY_ADDITIONAL: {
              props = {
                ...props,
                title: 'Pay Different Amount'
              };
              break;
            }
            case SCHEDULE_PAYMENT: {
              props = {
                ...props,
                title: 'Schedule a Payment',
                className: undefined
              };
              break;
            }
            case PAY_ENTIRE: {
              props = {
                ...props,
                title: 'Payoff Entire Loan',
                amount: this.props.max
              };
              break;
            }
            case CHECK_BALANCE: {
              props = {
                ...props,
                title: 'Check My Balance'
              };
              break;
            }
            case ENROLL_RENT_PROTECTION: {
              props = {
                ...props,
                title: 'Enroll in Rent Protection'
              };
              break;
            }
          }

          return (
            <PaymentOption
              key={index}
              {...props}
              onSelect={this.onOptionSelect}
            />
          );
        });

    const methods = this.props.methods.map(method => {
      const selected =
        !!selectedMethod &&
        selectedMethod.paymentProfileId === method.paymentProfileId;
      return (
        <PaymentMethod
          key={method.paymentProfileId}
          view="compact"
          method={method}
          selected={selected}
          onClick={this.onMethodSelect}
        />
      );
    });

    return (
      <div>
        {showConfirmationModal && (
          <ConfirmationModal
            onClose={() => this.toggleConfirmationModal(false)}
            onConfirmPayment={() => this.pay()}
          />
        )}
        {options && (
          <div
            className={cx(styles.paymentOptions, baseStyles.fadein, {
              [baseStyles.smHidden]: !this.state.selectOptionMode
            })}
          >
            <label className={baseStyles.smVisible}>Select an option</label>
            {options}
          </div>
        )}

        <div
          className={cx(baseStyles.fadein, {
            [baseStyles.smHidden]: options && this.state.selectOptionMode
          })}
        >
          {options && (
            <div className={baseStyles.smVisible}>
              <PaymentOption
                {...options[this.state.optionIndex].props}
                className={styles.selectedOptionBtn}
                optionClassName={styles.selectedOption}
                triangleClassName={styles.selectedOptionTriangle}
                onSelect={this.onOpenSelectOptionMode}
              />
            </div>
          )}
          <div className={styles.inputContainer}>
            <Input
              className={styles.input}
              name="amount"
              type="number"
              label="Amount"
              value={amount}
              touched={amountTouched}
              valid={!amountError}
              onChange={this.onChangeAmount}
              setFieldTouched={this.onTouchAmount}
              disabled={optionIndex === PAY_DUE || optionIndex === PAY_ENTIRE}
            />
            <Datepicker
              className={styles.input}
              name="date"
              label="Pay on"
              value={date}
              touched={dateTouched}
              valid={!dateError}
              setFieldTouched={this.onTouchDate}
              setFieldValue={this.onDateChange}
              disabled={optionIndex !== SCHEDULE_PAYMENT}
            />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.input}>
              {amountTouched && amountError ? (
                <span className={formStyles.error}>{amountError}</span>
              ) : null}
            </div>
            <div className={styles.input}>
              {dateTouched && dateError ? (
                <span className={formStyles.error}>{dateError}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.paymentMethods}>
            <label>Payment Method</label>
            <div className={styles.methods}>{methods}</div>
            <AddPaymentMethod
              showDebitCard={this.props.showDebitCard}
              className={styles.addPayment}
              debitIframeUrl={this.props.debitIframeUrl}
              achIframeUrl={this.props.achIframeUrl}
              handlePaymentProfileEvent={this.props.handlePaymentProfileEvent}
            />
          </div>
          <Button
            type="primary"
            className={styles.action}
            disabled={
              !selectedMethod || this.props.isSubmitting || amount === 0
            }
            onClick={() => this.toggleConfirmationModal(true)}
          >
            <span className={baseStyles.smHidden}>
              Pay {optionIndex !== SCHEDULE_PAYMENT ? 'Now' : ''} ${amount}{' '}
              {selectedMethod
                ? `with ${formatPaymentMethod(selectedMethod)}`
                : ''}
            </span>
            <span className={baseStyles.smVisible}>
              {isEditingPayment ? 'Edit payment' : 'Submit payment'}
            </span>
          </Button>
        </div>
        {this.props.isSubmitting && <Spin className={styles.spin} />}
      </div>
    );
  }
}

export default PayNow;
