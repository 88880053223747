import { PlaidAccount, PlaidInstitution } from './../../plaid/types';
import { PaymentAccountType } from './../../flexpay/account/types';
import { IconType } from 'components/icon';
import {
  CashFlowDetails,
  CommunityApplicationDetails,
  AddressDetails,
  RentHelpDetailsInput
} from './types';
import { ROUTES } from 'routes';
import { ApplyAction } from './apply.actions';
import {
  DisplayData,
  AssistanceReason,
  AssistanceEarningsType
} from 'domain/types';

export interface FlexApplyStep {
  key: string;
  icon: IconType;
  label: string;
  status: 'pending' | 'complete';
  visible?: boolean;
}
export interface FlexApplyState {
  steps: FlexApplyStep[];
  currentStepIndex: number;
  address?: AddressDetails;
  cashFlow?: CashFlowDetails;
  assistance?: RentHelpDetailsInput;
  community: CommunityApplicationDetails;
  createdApplicationId?: string;
  scheduleDisplayData?: DisplayData;
  budgetAndSaveScheduleDisplayData?: any;
  rentRollMatches?: RentRollMatch[];
  confirmedRentRollMatch?: RentRollMatch;
  assistanceAccepted?: string | null;
  assistanceAmount?: number;
  assistanceAbleToPayAmount?: number;
  assistanceReason?: AssistanceReason;
  assistanceEarningsType?: AssistanceEarningsType;
  assistanceRequested?: boolean;
}

export interface RentRollMatch {
  community_id: string;
  community: {
    name: string;
  };
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  community_code: string;
  resident_code: string;
  household_id: string;
  rent_amount: string;
  budget_and_save_eligible: boolean;
  rent_protection_eligible: boolean;
  payment_plan_eligible: boolean;
  program_eligibility_with_assistance: string;
  program_eligibility_with_assistance_reason: string;
  program_eligibility_without_assistance: string;
  program_eligibility_without_assistance_reason: string;
  rent_protection_payback_months: number;
  payment_plan_payback_months: number;
  rent_protection_max_amount: number;
  payment_plan_max_amount: number;
  balance: number;
  newly_added_fixed_billing: number;
  newly_added_variable_billing: number;
  past_due_fixed_billing: number;
  past_due_variable_billing: number;
  as_of_date: string;
}

export interface PaymentAccount {
  linkType?: 'plaid' | 'pciwallet';
  accountType?: PaymentAccountType;
  plaidBankItem?: PlaidInstitution;
  plaidBankAccount?: PlaidAccount;
  pciAccountToken?: string;
  pciAccountTitle?: string;
}

/**
 * Returns the default state of the flex application
 */
const getDefaultState = (): FlexApplyState => {
  return {
    steps: [
      {
        key: `${ROUTES.flex.apply.community}`,
        icon: 'address',
        label: 'Community',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.apply.address}`,
        icon: 'address',
        label: 'Address',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.apply.cashFlow}`,
        icon: 'coins',
        label: 'Cash Flow',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.apply.schedule}`,
        icon: 'date',
        label: 'Schedule',
        status: 'pending'
      },
      {
        key: `${ROUTES.flex.apply.submit}`,
        icon: 'check',
        label: 'Submit',
        status: 'pending'
      }
    ],
    community: {
      isUnmatched: false
    },
    currentStepIndex: 0
  };
};

/**
 * Finds a step in the state by its key
 */
const findStep = (state: FlexApplyState, key: string) => {
  return state.steps.find(step => step.key === key);
};

export default function enrollmentReducer(
  state: FlexApplyState = getDefaultState(),
  action: ApplyAction
) {
  let step;
  const newState = {
    ...state
  };

  switch (action.type) {
    case 'FLEX_APPLY_SEARCH_RENT_ROLL':
      newState.rentRollMatches = action.payload;
      return newState;
    case 'FLEX_APPLY_UNMATCH_RENT_ROLL':
      newState.steps = getDefaultState().steps;
      newState.community = {
        isUnmatched: true
      };
      newState.address = undefined;
      newState.confirmedRentRollMatch = undefined;
      return newState;
    case 'FLEX_APPLY_SAVE_MATCH':
      newState.confirmedRentRollMatch = action.payload;
      return newState;
    case 'FLEX_APPLY_SAVE_COMMUNITY_DATA':
      newState.community = {
        ...state.community,
        communityData: action.payload.communityData,
        isUnmatched: action.payload.isUnmatched
      };
      step = findStep(newState, ROUTES.flex.apply.community);
      if (step) {
        step.status = 'complete';
      }
      return newState;
    case 'FLEX_APPLY_SAVE_ADDRESS':
      newState.address = {
        ...state.address,
        ...action.payload
      };
      step = findStep(newState, ROUTES.flex.apply.address);
      if (step) {
        step.status = 'complete';
      }
      newState.address = action.payload;
      return newState;
    case 'FLEX_APPLY_SAVE_CASH_FLOW':
      step = findStep(state, ROUTES.flex.apply.cashFlow);
      if (step) {
        step.status = 'complete';
      }
      newState.cashFlow = action.payload;
      return newState;
    case 'FLEX_APPLY_LOAD_SCHEDULE':
      newState.scheduleDisplayData = action.payload;
      return newState;
    case 'BUDGET_SAVE_LOAD_SCHEDULE':
      const { data } = action.payload;
      const paymentPeriodMonths =
        (data.assistanceAccepted === 'RENT_PROTECTION'
          ? data.rentProtectionPaybackMonths
          : data.paymentPlanPaybackMonths) || 0;

      newState.scheduleDisplayData = {
        ...action.payload,
        data: { ...data, paymentPeriodMonths }
      };
      return newState;
    case 'FLEX_APPLY_SAVE_SCHEDULE':
      step = findStep(state, ROUTES.flex.apply.schedule);
      if (step) {
        step.status = 'complete';
      }
      return newState;
    case 'FLEX_APPLY_SAVE_REVISED_CASH_FLOW':
      newState.cashFlow = {
        ...newState.cashFlow,
        ...action.payload
      };
      return newState;
    case 'FLEX_APPLY_SUBMIT_APPLICATION':
      step = findStep(state, ROUTES.flex.apply.submit);
      if (step) {
        step.status = 'complete';
      }
      const newEndpointEnabled =
        action && action.requestData && action.requestData.useNewEndpoint;
      newState.createdApplicationId = newEndpointEnabled
        ? action.payload
        : action.payload.applicationId;

      return newState;
    case 'FLEX_GO_TO_NEXT_STEP':
      newState.currentStepIndex = newState.currentStepIndex + 1;
      return newState;
    case 'FLEX_GO_TO_PREVIOUS_STEP':
      newState.currentStepIndex = newState.currentStepIndex - 1;
      return newState;
    case 'FLEX_APPLY_RESET':
      return {
        ...getDefaultState(),
        createdApplicationId: state.createdApplicationId
      };
    case 'FLEX_APPLY_GO_TO_STEP':
      newState.steps.forEach((step, index) => {
        if (step.key !== action.payload) {
          step.status = 'complete';
        } else if (step.key === action.payload) {
          newState.currentStepIndex = index;
        }
      });
      return newState;
    default:
      return newState;
  }
}
