import * as React from 'react';

export default function Expired() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22384 0.27272C2.89342 0.27272 0.181885 2.98426 0.181885 6.31468C0.181885 9.08391 2.06475 11.3968 4.60846 12.1049L5.2693 10.9143C3.13468 10.4738 1.52454 8.58041 1.52454 6.31468C1.52454 3.72377 3.63293 1.61538 6.22384 1.61538C7.72384 1.61538 9.05077 2.33915 9.9109 3.44055C10.32 3.19929 10.7868 3.06817 11.285 3.03146C10.2046 1.37936 8.34272 0.27272 6.22384 0.27272ZM5.55251 2.95804V5.64335H2.8672V6.98601H6.89517V2.95804H5.55251ZM11.5106 4.31118C10.9284 4.31118 10.341 4.58915 10.0106 5.1451L10.0053 5.15559L4.45111 15.1416V15.1468C3.78503 16.2587 4.65566 17.7273 5.95636 17.7273H17.2326C18.5333 17.7273 19.4039 16.2587 18.7326 15.1416L13.021 5.15034L13.0158 5.1451C12.6854 4.58915 12.098 4.31118 11.5106 4.31118ZM11.5106 5.63286C11.6469 5.63286 11.778 5.70104 11.862 5.83216H11.8672L17.5787 15.8234L17.584 15.8339C17.7466 16.1066 17.5892 16.3846 17.2326 16.3846H5.95636C5.59972 16.3846 5.44238 16.1066 5.60497 15.8339L5.61021 15.8234L11.1644 5.83216C11.2431 5.70104 11.3794 5.63286 11.5106 5.63286ZM10.9231 8.32867V12.3566H12.2658V8.32867H10.9231ZM10.9231 13.6993V15.0419H12.2658V13.6993H10.9231Z"
        fill="#475F7B"
      />
    </svg>
  );
}
