import axios from 'axios';
import config from 'helpers/config';
import { format as formatDate } from 'helpers/date';
import APIError, { handleError } from '../../../helpers/api.error';
import { Autopay, Payment } from './types';

export async function getLoansByCustomer(userId?: string, headers?: any) {
  try {
    const response = await axios.get(
      `${config.apiUrl}/api/loan/account/${userId}/loans`,
      { headers }
    );
    return response.data;
  } catch (err) {
    handleError(err, 'Unable to load Rental Loan account.');
  }
}

export async function addPaymentProfile(
  data?: {
    userId: string;
    accountToken: string;
    accountTitle: string;
    accountType: 'ach' | 'debit';
  },
  headers?: any
) {
  try {
    if (!data) {
      throw new Error('Unable to add payment profile');
    }

    const { userId, accountToken, accountTitle, accountType } = data;
    const response = await axios.post(
      `${config.apiUrl}/api/loan/account/${userId}/payment/profile`,
      { accountToken, accountTitle, accountType },
      { headers }
    );
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function getPaymentToken(_data?: any, headers?: any) {
  try {
    const response = await axios.get(
      `${config.apiUrl}/api/loan/account/payment/token`,
      { headers }
    );
    return response.data;
  } catch (err) {
    throw new APIError('Unable to get payment token', 404);
  }
}

export async function getPaymentProfiles(userId?: string, headers?: any) {
  if (!userId) {
    throw new APIError('You are not logged in.', 401);
  }
  const response = await axios.get(
    `${config.apiUrl}/api/loan/account/${userId}/payment/profile`,
    { headers }
  );

  return response.data;
}

export async function makePayment(payment?: Payment, headers?: any) {
  try {
    if (!payment) {
      throw new Error('Please select a payment method.');
    }

    const { loanId } = payment;
    // Send date as a YYYY-MM-DD string to prevent timezone issues
    const date = formatDate('YYYY-MM-DD', payment.date);
    await axios.post(
      `${config.apiUrl}/api/loan/${loanId}/payment`,
      { ...payment, date },
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to process your payment.');
  }
}

export async function editPayment(
  paymentId: number,
  payment?: Payment,
  headers?: any
) {
  try {
    if (!payment) {
      throw new Error('Please select a payment method.');
    }

    const { loanId } = payment;
    await axios.patch(
      `${config.apiUrl}/api/loan/${loanId}/payment/${paymentId}`,
      payment,
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to edit your payment.');
  }
}

export async function cancelPayment(
  loanId: number,
  paymentId: number,
  headers?: any
) {
  try {
    await axios.delete(
      `${config.apiUrl}/api/loan/${loanId}/payment/${paymentId}`,
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to cancel your payment.');
  }
}

export async function scheduleAutopay(autopayments?: Autopay, headers?: any) {
  try {
    if (!autopayments) {
      throw new Error('Please select all autopayment fields.');
    }
    const { loanId } = autopayments;
    // Send date as a YYYY-MM-DD string to prevent timezone issues
    const date = formatDate('YYYY-MM-DD', autopayments.date);
    await axios.post(
      `${config.apiUrl}/api/loan/${loanId}/autopay`,
      { ...autopayments, date },
      {
        headers
      }
    );
  } catch (err) {
    handleError(err, 'We were unable to schedule your autopayments.');
  }
}

export async function cancelAutopayments(loanId?: number, headers?: any) {
  try {
    if (!loanId) {
      throw new Error('Missing loan Id');
    }
    await axios.delete(`${config.apiUrl}/api/loan/${loanId}/autopay`, {
      headers
    });
  } catch (err) {
    handleError(err, 'We were unable to cancel your autopayments.');
  }
}

export async function turnOnAutopay(subscriptionId?: number, headers?: any) {
  try {
    if (!subscriptionId) {
      throw new Error('Missing subscription ID');
    }

    await axios.post(
      `${config.apiUrl}/subscription/${subscriptionId}/autopay`,
      {},
      { headers }
    );
  } catch (err) {
    handleError(err, 'We were unable to cancel your autopayments.');
  }
}

export async function turnOffAutopay(subscriptionId?: number, headers?: any) {
  try {
    if (!subscriptionId) {
      throw new Error('Missing subscription ID');
    }
    await axios.delete(
      `${config.apiUrl}/subscription/${subscriptionId}/autopay`,
      { headers }
    );
  } catch (err) {
    handleError(err, 'We were unable to cancel your autopayments.');
  }
}

export async function getAutopays(subscriptionId?: number, headers?: any) {
  try {
    if (!subscriptionId) {
      throw new Error('Missing subscription ID');
    }
    const response = await axios.get(
      `${config.apiUrl}/subscription/${subscriptionId}/autopays`,
      {
        headers
      }
    );
    return response.data;
  } catch (err) {
    handleError(err, 'We are unable to retrieve your autopayments.');
    throw new Error('Unable to retrieve your autopayments');
  }
}
