import React from 'react';
import { Switch, Route as ReactRoute } from 'react-router-dom';
import routes, { Route } from '../routes';

export default function Routes() {
  const items = routes.map((route: Route) => (
    <ReactRoute
      key={route.path}
      path={route.path}
      exact={route.exact}
      component={(props: object) => (
        <route.Layout {...props}>
          <route.Component {...props} />
        </route.Layout>
      )}
    />
  ));

  return <Switch>{items}</Switch>;
}
