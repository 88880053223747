import { ThunkResult, Dispatch, Store } from './../../types';
import { PaymentAccount } from 'resources/flex/onboarding/onboarding.reducer';

export type OnboardingActionType =
  | 'ONBOARDING_GO_TO_PREVIOUS_STEP'
  | 'ONBOARDING_GO_TO_NEXT_STEP'
  | 'ONBOARDING_CONTRACT_COMPLETE'
  | 'ONBOARDING_PAYMENT_ACCOUNT_COMPLETE'
  | 'ONBOARDING_CONFIRM_PAYMENT_COMPLETE'
  | 'SET_LOADING'
  | 'SET_ONBOARDING_DATA_LOADED'
  | 'SET_ERROR'
  | 'SET_SHOULD_REDIRECT'
  | 'OAUTH_VERIFICATION_COMPLETE';

export interface OnboardingAction {
  type: OnboardingActionType;
  payload: any;
}

/**
 * Marks the contract signature portion of onboarding as complete in order
 * to move to next step
 */
export const setContractComplete = () => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'ONBOARDING_CONTRACT_COMPLETE',
      payload: {}
    });
    dispatch(goToNextStep());
  };
};

export const returnFromOauthVerification = () => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'OAUTH_VERIFICATION_COMPLETE',
      payload: {}
    });
  };
};

/**
 * Adds the payment method and sets it as the primary on the user's account
 * @param account The account to set as the primary payment method
 */
export const setPrimaryPaymentMethod = (
  account: PaymentAccount
): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'ONBOARDING_PAYMENT_ACCOUNT_COMPLETE',
      payload: account
    });

    dispatch(goToNextStep());
  };
};

/**
 * The user wants to use the primary payment method that they previously selected,
 * so just take them to the next step
 */
export const keepPrimaryPaymentMethod = (): ThunkResult<any> => {
  return (dispatch: Dispatch, getState: () => Store) => {
    const state = getState();
    if (state.user && state.user.preferredPaymentProfile) {
      dispatch<OnboardingAction>({
        type: 'ONBOARDING_PAYMENT_ACCOUNT_COMPLETE',
        payload: {
          selectedPaymentProfileId:
            state.user.preferredPaymentProfile.paymentProfileId
        }
      });

      dispatch(goToNextStep());
    } else {
      throw new Error('No payment profile selected for this user');
    }
  };
};

/**
 * Takes the user to the next step in the onboarding flow
 */
export const goToNextStep = (): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'ONBOARDING_GO_TO_NEXT_STEP',
      payload: {}
    });
  };
};

/**
 * Takes the user to the previous step in the onboarding flow
 */
export const goToPreviousStep = (): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'ONBOARDING_GO_TO_PREVIOUS_STEP',
      payload: {}
    });
  };
};

/**
 * Sets whether or not data for onboarding is loading
 * @param loading The loading state
 */
export const setLoading = (loading: boolean): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'SET_LOADING',
      payload: loading
    });
  };
};

/**
 * Sets whether or not the data for onboarding has been loaded.
 * This is used to determine whether all the data is done loading
 * @param loaded The state of the data loading
 */
export const setIsDataLoaded = (loaded: boolean): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'SET_ONBOARDING_DATA_LOADED',
      payload: loaded
    });
  };
};

/**
 * Sets an error in the onboarding flow
 * @param error The error message to display
 */
export const setError = (error?: string): ThunkResult<any> => {
  return (dispatch: Dispatch) => {
    dispatch<OnboardingAction>({
      type: 'SET_ERROR',
      payload: error
    });
  };
};
