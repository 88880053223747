import * as React from 'react';
import { PaymentProfile } from '../../resources/loan/account/types';
import config from 'helpers/config';

import Button from '../button';
import TillModal from '../modal';
import { Spin } from 'antd';
import ContactSupportModal from 'components/support/modal';
import PaymentMethod from '../payment-method';

import cx from 'classnames';
import baseStyles from 'styles/base.module.css';
import styles from './payment-method-list.module.css';
import formStyles from 'styles/form.module.css';
import { ROUTES } from '../../routes';

interface Props {
  className: string;
  userId: string;
  paymentMethods: PaymentProfile[];
  selectedPaymentMethodId: number | undefined;
  handlePaymentMethodSelect: any; //hrm
}

/**
 * Provides a list of payment profile cards and interactivity to select one as
 * active profile for recurring payments.
 */
const PaymentMethodList = (props: Props) => {
  const {
    className,
    userId,
    paymentMethods,
    selectedPaymentMethodId,
    handlePaymentMethodSelect
  } = props;

  const [profileMarkedForSelection, markProfileForSelection] = React.useState<
    PaymentProfile | undefined
  >(undefined);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  // Saves state to API after confirmation
  const onMethodConfirm = async () => {
    const method = profileMarkedForSelection;
    if (!method) return;
    setIsLoading(true);
    try {
      await handlePaymentMethodSelect(userId, method.paymentProfileId);
      markProfileForSelection(undefined);
      setIsLoading(false);
    } catch (err) {
      markProfileForSelection(undefined);
      setIsLoading(false);
      setErrorMessage(
        'We are unable to update your payment method at this time'
      );
    }
  };

  const onClose = () => {
    markProfileForSelection(undefined);
  };

  const onMethodSelect = async (
    isSelected: boolean,
    method: PaymentProfile
  ) => {
    if (!isSelected) markProfileForSelection(method);
  };

  return (
    <div className={cx(baseStyles.row, className)}>
      {paymentMethods.map(method => {
        const isSelected =
          selectedPaymentMethodId === method.paymentProfileId ||
          paymentMethods.length === 1;

        return (
          <React.Fragment key={method.paymentProfileId}>
            <PaymentMethod
              className={cx(styles.method, baseStyles.smHidden)}
              method={method}
              selected={isSelected}
              onClick={method => onMethodSelect(isSelected, method)}
            />
            <PaymentMethod
              view="compact"
              className={cx(styles.method, baseStyles.smVisible)}
              method={method}
              selected={isSelected}
              onClick={method => onMethodSelect(isSelected, method)}
            />
          </React.Fragment>
        );
      })}

      {profileMarkedForSelection && (
        <TillModal
          visible
          className={styles.confirmationModal}
          onClose={onClose}
        >
          <div className={styles.content}>
            <h1 className={styles.modalTitle}>Confirm Payment Method</h1>
            <p className={styles.message}>
              {`Future automatic payments will be withdrawn from: ${profileMarkedForSelection.title}`}
            </p>
            <p className={styles.message}>
              {'By selecting "Confirm", you acknowledge and agree that '}
              <a
                href={ROUTES.legal.electronicPaymentTerms}
                target="_blank"
                rel="noopener noreferrer"
              >
                Electronic Payment Terms
              </a>
              {' apply to these transactions.'}
            </p>
            <div className={formStyles.actionContainer}>
              <Button
                type="button"
                onClick={onClose}
                className={styles.button}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onMethodConfirm}
                className={styles.button}
                disabled={isLoading}
              >
                Confirm
              </Button>
              {isLoading && <Spin className={styles.spin} size="small" />}
            </div>
          </div>
        </TillModal>
      )}
      {errorMessage && (
        <ContactSupportModal
          onClose={() => setErrorMessage(undefined)}
          supportPhoneNumber={config.supportPhoneNumber}
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default PaymentMethodList;
