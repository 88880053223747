import * as React from 'react';
import { useThunkDispatch } from '../resources';
import { getUserAccount } from '../resources/user/user.selectors';
import { useSelector } from 'react-redux';
import { loadPaymentProfiles } from '../resources/loan/account/account.actions';

export function usePaymentProfiles() {
  const dispatch = useThunkDispatch();
  const { userId } = useSelector(getUserAccount);

  React.useEffect(() => {
    (async () => {
      try {
        if (userId.length) {
          await dispatch(loadPaymentProfiles(userId));
        } else {
        }
      } catch (error) {}
    })();
  }, [userId, dispatch]);
}
