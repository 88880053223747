import * as React from 'react';
import styles from './styles.module.css';
import cx from 'classnames';
import authStyles from '../auth.module.css';
import { useThunkDispatch } from 'resources';
import { getSearch, push, replace } from 'connected-react-router';
import { getQueryVariable } from 'helpers/querystring';
import { useSelector } from 'react-redux';
import { Store } from 'resources/types';
import { activate, resendConfirmationCode } from 'resources/user/user.actions';
import { useLocation } from 'react-router-dom';
import { ActivatePageState, ResendLink } from 'resources/user/types';
import ResendLinkForm from './form';
import schema from './schema';
import { Formik, FormikActions } from 'formik';
import { Spin } from 'antd';
import Button from 'components/button';
import ContactSupportModal from 'components/support/modal';
import { ROUTES } from 'routes';

interface ActivateComponentState {
  reason?: ActivatePageState;
  email?: string;
}

/**
 * Page a customer lands on when they successfully activate their account. Also,
 * where they land if they need to resend their confirmation link for some reason.
 */
function ActivateComponent() {
  const dispatch = useThunkDispatch();

  const { state } = useLocation<ActivateComponentState>();
  const { reason, email } = state || {};

  const queryString = useSelector<Store, string>(getSearch);
  const [pageState, setPageState] = React.useState<
    ActivatePageState | undefined
  >(reason);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isSending, setSending] = React.useState<boolean>(false);
  const [emailToActivate, setEmailToActivate] = React.useState<
    string | undefined
  >(email);

  React.useEffect(() => {
    const code = getQueryVariable(queryString, 'code');
    const email = getQueryVariable(queryString, 'email');

    setEmailToActivate(email || '');

    if (code && email) {
      (async () => {
        try {
          setLoading(true);
          await dispatch(activate({ code, email }));
          // change the page to login and send data for populating email and showing message to user
          dispatch(
            replace({
              pathname: ROUTES.auth.login,
              state: {
                isConfirmed: true,
                email
              }
            })
          );
          return;
        } catch (err) {
          if (err.code === 'ExpiredCodeException') {
            setPageState('expired_code');
          } else if (
            err.code === 'NotAuthorizedException' &&
            err.message ===
              'User cannot be confirmed. Current status is CONFIRMED'
          ) {
            setPageState('error_already_confirmed');
          } else {
            setPageState('error_activation');
          }
        }
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [dispatch, queryString]);

  const onSubmit = async (
    data: ResendLink,
    { setSubmitting }: FormikActions<ResendLink>
  ) => {
    try {
      setSending(true);
      setEmailToActivate(data.email);
      await dispatch(resendConfirmationCode(data.email));
      setPageState('successful_resend');
    } catch (err) {
      if (
        err.code === 'InvalidParameterException' &&
        err.message === 'User is already confirmed.'
      ) {
        setPageState('error_already_confirmed');
      } else {
        setPageState('error_resend');
      }
    }
    setSubmitting(false);
    setSending(false);
  };

  const onGoToLogin = () => {
    dispatch(push(`/login`));
  };

  const onErrorClose = () => {
    setPageState('none');
  };

  const getMessage = (state?: ActivatePageState): string => {
    switch (state) {
      case 'unconfirmed_login_attempt':
        return `You haven't confirmed your email yet. Please input your email and we'll send you another link.`;
      case 'expired_code':
        return `Your confirmation link has expired. Please input your email and we'll send you another link.`;
      case 'error_activation':
        return `We ran into an issue confirming your email. Please input your email and we'll send you another link.`;
      case 'successful_resend':
        return `An email containing your account activation link has been sent to ${emailToActivate ||
          'your email'}. Check your inbox and click the link in the email to activate your account.`;
      case 'error_already_confirmed':
        return `Your account is already activated. Login to continue.`;
      case 'none':
      default:
        return `Please enter your email to resend the email confirmation link.`;
    }
  };

  const getContent = (state?: ActivatePageState): React.ReactElement => {
    switch (state) {
      case 'error_already_confirmed':
        return (
          <Button type="primary" onClick={onGoToLogin}>
            Login
          </Button>
        );
      case 'error_resend':
        return (
          <ContactSupportModal
            message={'We failed to send your confirmation link.'}
            onClose={onErrorClose}
          />
        );
      case 'successful_resend':
        return <div></div>;
      default:
        return (
          <div>
            <Formik
              initialValues={{ email: emailToActivate || '' }}
              isInitialValid={schema.isValidSync({ email: emailToActivate })}
              validationSchema={schema}
              onSubmit={onSubmit}
              component={ResendLinkForm}
            />
            {isSending && (
              <div className={cx(authStyles.container, styles.container)}>
                <Spin size={'small'} />
              </div>
            )}
          </div>
        );
    }
  };
  return (
    <div className={cx(authStyles.container, styles.container)}>
      <h3 className={authStyles.title}>Activate Your Account</h3>
      <p className={styles.message}>{getMessage(pageState)}</p>
      {isLoading ? (
        <div className={cx(authStyles.container, styles.container)}>
          <Spin size={'large'} />
        </div>
      ) : (
        getContent(pageState)
      )}
    </div>
  );
}

export default ActivateComponent;
