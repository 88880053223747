import { setDashboardDataLoadingState } from './../resources/loading/loading.actions';
import { loadFlexibleRentDashboardData } from './../resources/user/user.actions';
import { useThunkDispatch } from 'resources';
import { useEffect } from 'react';

/**
 * Loads all data necessary to determine if a user should be
 * on a specific page or needs redirected
 *
 * @param userId The ID of the user to load data for
 */
const LoadDashboardData = (userId: string) => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(setDashboardDataLoadingState('default'));
    (async () => {
      if (userId) {
        try {
          dispatch(setDashboardDataLoadingState('loading'));
          await dispatch(loadFlexibleRentDashboardData(userId));
        } catch (err) {}
        dispatch(setDashboardDataLoadingState('done'));
      }
    })();
  }, [dispatch, userId]);
};

export default LoadDashboardData;
