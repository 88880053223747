import cx from 'classnames';
import Button from 'components/button';
import DatePicker from 'components/date-picker';
import Input from 'components/form-input';
import { Option, Select } from 'components/form-select';
import ZipInput from 'components/zip-input';
import { FormikProps } from 'formik';
import { getStates } from 'helpers/address';
import { MASKS } from 'helpers/constants';
import * as React from 'react';
import { UserAccountData } from 'resources/user/types';
import formStyles from 'styles/form.module.css';
import styles from './account.module.css';
import moment from 'moment';

function Form({
  isValid,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  values,
  touched,
  errors,
  handleChange
}: FormikProps<UserAccountData>) {
  const stateOptions = getStates().map(state => (
    <Option key={state} value={state}>
      {state}
    </Option>
  ));

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.col}>
        <div>
          <h2>Personal Data</h2>
          <hr />
          <h6>Your Name And Birth Date</h6>
        </div>
        <div className={formStyles.inputContainer}>
          <Input
            name="firstName"
            label="First name"
            value={values.firstName}
            valid={!errors.firstName}
            touched={touched.firstName}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.firstName && errors.firstName ? (
            <div className={formStyles.error}>{errors.firstName}</div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <Input
            name="lastName"
            label="Last name"
            value={values.lastName}
            valid={!errors.lastName}
            touched={touched.lastName}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.lastName && errors.lastName ? (
            <div className={formStyles.error}>{errors.lastName}</div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <DatePicker
            label="Date of Birth"
            name="dateOfBirth"
            value={
              values.dateOfBirth
                ? moment(values.dateOfBirth).toDate()
                : undefined
            }
            touched={touched.dateOfBirth}
            valid={!errors.dateOfBirth}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
          {touched.dateOfBirth && errors.dateOfBirth ? (
            <div className={formStyles.error}>{errors.dateOfBirth}</div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <Input
            disabled
            type="email"
            name="email"
            label="Email"
            value={values.email}
            valid={!errors.email}
            touched={touched.email}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.email && errors.email ? (
            <div className={formStyles.error}>{errors.email}</div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <Input
            name="phone"
            label="Phone number"
            value={values.phone || ''}
            valid={!errors.phone}
            touched={touched.phone}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
            mask={MASKS.PHONE}
          />
          {touched.phone && errors.phone ? (
            <div className={formStyles.error}>{errors.phone}</div>
          ) : null}
        </div>
      </div>

      <div className={styles.col}>
        <div>
          <h2>My Home</h2>
          <hr />
          <h6>YOUR ADDRESS AND DETAILS</h6>
        </div>
        <div className={formStyles.inputContainer}>
          <Input
            name="rentalAddress.address1"
            label="Address 1"
            value={values.rentalAddress.address1 || ''}
            valid={!errors.rentalAddress || !errors.rentalAddress.address1}
            touched={touched.rentalAddress && touched.rentalAddress.address1}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.rentalAddress &&
          touched.rentalAddress.address1 &&
          errors.rentalAddress &&
          errors.rentalAddress.address1 ? (
            <div className={formStyles.error}>
              {errors.rentalAddress.address1}
            </div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <Input
            name="rentalAddress.address2"
            label="Address 2"
            value={values.rentalAddress.address2 || ''}
            valid={!errors.rentalAddress || !errors.rentalAddress.address2}
            touched={touched.rentalAddress && touched.rentalAddress.address2}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.rentalAddress &&
          touched.rentalAddress.address2 &&
          errors.rentalAddress &&
          errors.rentalAddress.address2 ? (
            <div className={formStyles.error}>
              {errors.rentalAddress.address2}
            </div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <ZipInput
            placeholder="..."
            touched={touched.rentalAddress && touched.rentalAddress.zip}
            valid={!errors.rentalAddress || !errors.rentalAddress.zip}
            name="rentalAddress.zip"
            value={values.rentalAddress.zip || ''}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          />
          {touched.rentalAddress &&
          touched.rentalAddress.zip &&
          errors.rentalAddress &&
          errors.rentalAddress.zip ? (
            <div className={formStyles.error}>{errors.rentalAddress.zip}</div>
          ) : null}
        </div>

        <div className={cx(styles.row, formStyles.inputContainer)}>
          <Input
            label="City"
            placeholder="Enter your city"
            name="rentalAddress.city"
            value={values.rentalAddress.city || ''}
            valid={!errors.rentalAddress || !errors.rentalAddress.city}
            touched={touched.rentalAddress && touched.rentalAddress.city}
            onChange={handleChange}
            setFieldTouched={setFieldTouched}
          />
          {touched.rentalAddress &&
          touched.rentalAddress.city &&
          errors.rentalAddress &&
          errors.rentalAddress.city ? (
            <div className={formStyles.error}>{errors.rentalAddress.city}</div>
          ) : null}
        </div>

        <div className={formStyles.inputContainer}>
          <Select<string>
            label="Select State"
            placeholder="Select State:"
            name="rentalAddress.state"
            value={values.rentalAddress.state || ''}
            touched={touched.rentalAddress && touched.rentalAddress.state}
            valid={!errors.rentalAddress || !errors.rentalAddress.state}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
          >
            {stateOptions}
          </Select>
          {touched.rentalAddress &&
          touched.rentalAddress.state &&
          errors.rentalAddress &&
          errors.rentalAddress.state ? (
            <div className={formStyles.error}>{errors.rentalAddress.state}</div>
          ) : null}
        </div>
      </div>
      <div className={formStyles.actionContainer}>
        <Button
          className={styles.submit}
          type="primary"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export default Form;
