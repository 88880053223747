import { ThunkResult, Store } from './../types';
import { Dispatch } from 'resources/types';
import { GetAccountsData } from './plaid.api';
import { createAction, createTypedApiAction } from 'helpers/apiActionCreator';
import {
  PlaidAccount,
  PlaidCreateItemLinkData,
  PlaidInstitution
} from './types';
import * as api from 'resources/plaid/plaid.api';

export interface PlaidAction {
  type: PlaidActionType;
  payload: any;
}

export interface PlaidErrorAction {
  type: PlaidActionType;
  asyncAction: PlaidActionType;
  requestData: any;
  error: {
    data: string;
    status: number;
  };
}

export type PlaidActionType =
  | 'LOAD_LINKED_ITEMS'
  | 'LINK_ITEM'
  | 'LINK_ITEM_FAIL'
  | 'CLEAR_LOADED_ACCOUNTS'
  | 'SELECT_ITEM'
  | 'SELECT_ACCOUNT'
  | 'CREATE_LINK_TOKEN'
  | 'CREATE_ITEM_LINK_TOKEN'
  | 'REMOVE_ITEM_LINK_TOKEN'
  | 'LOAD_ACCCOUNTS_FOR_ITEM'
  | 'CLEAR_LAST_LINKED_ITEM'
  | 'PLAID_SET_ERROR'
  | 'CLEAR_LAST_ERROR'
  | 'SHOW_PCI_WALLET';

/**
 * Loads all linked Plaid items (aka institutions) for a user
 * @param userId The user id of the user to fetch items for
 */
export const loadLinkedItems = (userId: string): ThunkResult<any> => {
  return async (dispatch: Dispatch) => {
    await dispatch(
      createTypedApiAction<string, PlaidInstitution[], PlaidActionType>(
        'LOAD_LINKED_ITEMS',
        api.getLinkedItems
      )(userId)
    );
  };
};

/**
 * Loads accounts associated with a user and item (aka institution)
 * If an ITEM_LOGIN_REQUIRED error is returned for an account, it
 * triggers the associated call to set up a direct link to re-authenticate.
 * When all errors occur, this will increase the number of errors counter by 1
 * so we can take appropriate action on the client.
 * @param userId The ID of the user to load accounts for
 * @param plaidItemId The ID of the item to load accounts for
 */
export const loadAccountsForItem = (userId: string, plaidItemId: string) => {
  const loadAccounts = async (dispatch: Dispatch, getState: () => Store) => {
    try {
      dispatch<PlaidAction>({
        type: 'CLEAR_LAST_ERROR',
        payload: {}
      });
      await dispatch(
        createTypedApiAction<GetAccountsData, PlaidAccount[], PlaidActionType>(
          'LOAD_ACCCOUNTS_FOR_ITEM',
          api.getAccountsForItem
        )({ userId, plaidItemId })
      );
    } catch (err) {
      // increase the error count in this session so we can help the customer
      // enter their bank account info manually if they can't continue using Plaid
      dispatch<PlaidAction>({
        type: 'PLAID_SET_ERROR',
        payload: err.response && err.response.data && err.response.data.code
      });
      if (
        err.response &&
        err.response.data &&
        err.response.data.code === 'ITEM_LOGIN_REQUIRED'
      ) {
        await dispatch(createLinkToken(plaidItemId));
      } else {
        throw err;
      }
    }
  };
  return createAction('LOAD_PLAID_ACCOUNTS_FOR_ITEM', loadAccounts)();
};

export const clearLastLinkedItem = (): PlaidAction => ({
  type: 'CLEAR_LAST_LINKED_ITEM',
  payload: {}
});

/**
 * Links a Plaid item to a user
 * @param token The token for the item to link to the user
 */
export const linkItem = (data: PlaidCreateItemLinkData) => {
  return createTypedApiAction<any, PlaidInstitution, PlaidActionType>(
    'LINK_ITEM',
    api.linkitem
  )(data);
};

/**
 * Creates a link token for opening the Plaid link modal.
 * If an `itemId` is passed to this action, this link token will
 * be created for re-authenticating a specific Plaid item.
 */
export const createLinkToken = (itemId?: string) => {
  return createTypedApiAction<string | undefined, string, PlaidActionType>(
    itemId ? 'CREATE_ITEM_LINK_TOKEN' : 'CREATE_LINK_TOKEN',
    api.createLinkToken
  )(itemId);
};

/**
 * Removes the link from the Store that was used to re-authenticate a specific Plaid item
 */
export const removeItemLinkToken = (): PlaidAction => ({
  type: 'REMOVE_ITEM_LINK_TOKEN',
  payload: {}
});

/**
 * Clears all loaded accounts and associated state. This
 * action is normally triggered when a new item is selected.
 */
export const clearLoadedAccounts = (): PlaidAction => ({
  type: 'CLEAR_LOADED_ACCOUNTS',
  payload: {}
});

/**
 * Determines whether to show the PCI Wallet entry screen or not
 */
export const showPciWallet = (showPci: boolean): PlaidAction => ({
  type: 'SHOW_PCI_WALLET',
  payload: showPci
});
