import { LoadingState } from './loading.reducer';

export type LoadingActionType =
  | 'SET_DASHBOARD_DATA_LOADING_STATE'
  | 'SET_PERSONAL_DATA_LOADING_STATE'
  | 'SET_COMMUNITY_LOADING_STATE'
  | 'RESET_ALL_LOADING_STATES';

export interface LoadingAction {
  type: LoadingActionType;
  payload: LoadingState;
}

export const setPersonalDataLoadingState = (
  state: LoadingState
): LoadingAction => ({
  type: 'SET_PERSONAL_DATA_LOADING_STATE',
  payload: state
});

export const setCommunityLoadingState = (
  state: LoadingState
): LoadingAction => ({
  type: 'SET_COMMUNITY_LOADING_STATE',
  payload: state
});

export const setDashboardDataLoadingState = (
  state: LoadingState
): LoadingAction => ({
  type: 'SET_DASHBOARD_DATA_LOADING_STATE',
  payload: state
});

export const resetAllLoadingStates = (): LoadingAction => ({
  type: 'RESET_ALL_LOADING_STATES',
  payload: 'default'
});
