import * as React from 'react';

export default function Connect() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6853 0.727272C12.1468 0.727272 10.8881 1.98601 10.8881 3.52447C10.8881 4.06206 11.0498 4.56031 11.3121 4.98864L8.71153 7.44056H6.35576C6.09353 6.16871 4.96153 5.2028 3.61538 5.2028C2.07692 5.2028 0.818176 6.46154 0.818176 8C0.818176 9.53846 2.07692 10.7972 3.61538 10.7972C4.96153 10.7972 6.09353 9.83129 6.35576 8.55944H8.71153L11.3427 10.9633C11.0586 11.4003 10.8881 11.9161 10.8881 12.4755C10.8881 14.014 12.1468 15.2727 13.6853 15.2727C15.2238 15.2727 16.4825 14.014 16.4825 12.4755C16.4825 10.9371 15.2238 9.67832 13.6853 9.67832C13.1084 9.67832 12.5664 9.85315 12.1206 10.1591L9.76922 8.00874V7.97378L12.0769 5.80594C12.5358 6.125 13.0865 6.32168 13.6853 6.32168C15.2238 6.32168 16.4825 5.06294 16.4825 3.52447C16.4825 1.98601 15.2238 0.727272 13.6853 0.727272ZM13.6853 1.84614C14.6206 1.84614 15.3636 2.58914 15.3636 3.52446C15.3636 4.45977 14.6206 5.20278 13.6853 5.20278C12.75 5.20278 12.007 4.45977 12.007 3.52446C12.007 2.58914 12.75 1.84614 13.6853 1.84614ZM3.61538 6.32168C4.55069 6.32168 5.2937 7.06469 5.2937 8C5.2937 8.93532 4.55069 9.67832 3.61538 9.67832C2.68006 9.67832 1.93706 8.93532 1.93706 8C1.93706 7.06469 2.68006 6.32168 3.61538 6.32168ZM13.6853 10.7972C14.6206 10.7972 15.3636 11.5402 15.3636 12.4755C15.3636 13.4108 14.6206 14.1538 13.6853 14.1538C12.75 14.1538 12.007 13.4108 12.007 12.4755C12.007 11.5402 12.75 10.7972 13.6853 10.7972Z"
        fill="#475F7B"
      />
    </svg>
  );
}
