import {
  createStore,
  applyMiddleware,
  AnyAction,
  Store as ReduxStore
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { Store } from './types';
import createReducer from './reducer';
import { History } from 'history';

export default function configureStore(
  initialState: Partial<Store>,
  history: History
) {
  const store: ReduxStore = createStore<Store | undefined, AnyAction, {}, {}>(
    createReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
  );

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(createReducer(history));
    });
  }

  return store;
}
