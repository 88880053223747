import * as React from 'react';

export default function Apply() {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96104 0.727264C4.24432 0.727264 2.84416 2.12743 2.84416 3.84415C2.84416 5.49188 4.14286 6.83928 5.76623 6.9448C2.99026 7.0422 0.758118 9.3271 0.758118 12.1274H1.79708C1.79708 9.83441 3.6599 7.97158 5.95292 7.97158C7.76705 7.97158 9.29708 9.14853 9.8612 10.776L10.6648 9.96834C9.86526 8.23132 8.14042 7.00973 6.12338 6.9448C7.76299 6.85957 9.07792 5.50405 9.07792 3.84415C9.07792 2.12743 7.67776 0.727264 5.96104 0.727264ZM5.96104 1.76621C7.11364 1.76621 8.03896 2.69153 8.03896 3.84413C8.03896 4.99673 7.11364 5.92205 5.96104 5.92205C4.80844 5.92205 3.88312 4.99673 3.88312 3.84413C3.88312 2.69153 4.80844 1.76621 5.96104 1.76621ZM13.1201 9.01459L8.47727 13.6534L6.57792 11.7622L5.8474 12.4967L8.47727 15.1266L13.8507 9.75323L13.1201 9.01459Z"
        fill="#475F7B"
      />
    </svg>
  );
}
